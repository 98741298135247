/*------------------------------------*\
    # component.hamburger
\*------------------------------------*/

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
    height: 40px;
    width: 40px;
    display: inline-block;
    cursor: pointer;

    text-transform: none;
    background: $color-primary;
    margin: 0;
    border-radius: 50%;
    border: 1px solid color(white a(0.3));
    overflow: visible;
    outline: 0;
    -webkit-tap-highlight-color: transparent;

    &.is-active {
        background: white;
    }
}

.hamburger-inner {
    position: absolute;
    top: 16px;
    left: 10px;

    &:before,
    &:after {
        height: 2px;
        background-color: white;
        position: absolute;
        border-radius: 2px;

        transition: all 0.15s linear;
    }

    &:before,
    &:after {
        content: '';
        display: block;
    }

    &:before {
        width: 20px;
        top: 0;
    }

    &:after {
        width: 12px;
        top: 6px;
    }

    .is-active & {
        
        &:before {
            width: 16px;
            transform: rotate(-45deg);
            top: 3px;
            left: 2px;
            background: $color-primary;
        }

        &:after {
            width: 16px;
            transform: rotate(45deg);
            top: 3px;
            left: 2px;
            background: $color-primary;
        }
    }
}
