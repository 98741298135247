/*------------------------------------*\
    # component.input-group
\*------------------------------------*/

.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
  max-width: 800px;
  margin: auto;

  &--inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      flex-grow: 1;

      .field {
          flex-grow: 1;
      }
  }
}

.input-group > .btn {
  @media (--screen-to-sml) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
  }
}

.input-group--invertOnSmall {
  @media (--screen-to-sml) {
      flex-direction: column-reverse;
  }
}
