/*------------------------------------*\
    # component.valuation-sidebar
\*------------------------------------*/

.valuation-sidebar {
    @media (--screen-from-lrg) {
        lost-column: 3/12;
    }

    @media (--screen-to-lrg) {
        display: none;
    }
}

.valuation-sidebar__step {
    display: block;
    padding: calc($unit * 4) 0;
    transition: all 0.2s ease-out;
    margin-left: calc($unit * 4);

    @mixin type-scale $type-small, 4;
    color: color(white a(0.5));

    border-left: 3px solid $color-secondary;
    padding-left: calc($unit * 4);

    &.active {
        font-weight: $type-weight-bold;
        opacity: 1;
        color: $color-secondary;

        & ~ .valuation-sidebar__step {
            border-left: 2px solid color(white a(0.1));
            color: white;
        }
    }

    @media (--screen-to-med) {
        margin-right: calc($unit * 4);
    }
}
