/*------------------------------------*\
    # view.home-buyers
\*------------------------------------*/

.home-buyers {
    padding: 30px 0px;
    background-color: white;

    @media (--screen-from-lrg) {
        padding: 100px 0px;
        padding-bottom: 0px;
    }
}

.home-buyers__split {
    lost-flex-container: row;
    margin-top: calc($unit * 10);

    @media (--screen-from-med) {
        margin-top: calc($unit * 15);
    }
}

.home-buyers__content {
    @media (--screen-from-lrg) {
        lost-column: 1/2;
    }
}

.home-buyers__text {
    max-width: 400px;
}

.home-buyers__arrow {
    transform: scaleX(-1);
    margin-left: calc($unit * 2);
}

.home-buyers__images {
    width: 100%;
    position: relative;

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 20);
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2;
        margin-bottom: -130px;
        top: 80px;
    }
}

.home-buyers__title {
    max-width: 611px;
}

.home-buyers__image {
    width: 100%;
    position: relative;

    & img {
        border-radius: 16px;
        width: 100%;
    }
}

.home-buyers__image-text {
    @media (--screen-to-sml) {
        margin-top: calc($unit * 5);
    }

    @media (--screen-from-sml) {
        position: absolute;
        padding: 40px 36px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    @media (--screen-from-med) and (--screen-to-xlrg) {
        padding: 20px 18px;
    }
}

.home-buyers__image-description {
    @media (--screen-from-sml) {
        width: 50%;
    }
    @media (--screen-to-sml) {
        margin-top: calc($unit * 1);
    }
}

.home-buyers__features {
    margin-top: calc($unit * 20);
    overflow: hidden;
}

.home-buyers__icon {
    width: 76px;
}

.home-buyers__feature {
    margin-bottom: calc($unit * 15);
    display: flex;
    justify-content: align-start;
    align-items: center;
}

.home-buyers__feature__description {
    max-width: 288px;
}
