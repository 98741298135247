/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: $type-weight-bold;
    font-family: $ff-sans;
    font-display: optional;
    @mixin type-scale $type-base, 5;
    color: $color-primary;
}

/**
 * Paragraphs
 */
p {
    margin: 0;

    font-size: responsive 14px 16px;
    font-range: 320px 1440px;

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;
}

/**
 * Lists (ol, ul, dd)
 */
ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}
ol {
    list-style: decimal;
}

ol li,
ul li,
dl dd,
dl dt {
    font-size: responsive 14px 16px;
    font-range: 320px 1440px;

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;
}

/**
 * Anchors
 */

a {
    color: $color-primary;
    text-decoration: none;

    &:link {
    }

    &:hover {
        color: color($color-primary blackness(+25%));
    }

    &:focus {
        color: color($color-primary blackness(+25%));
    }

    &:visited {
    }
}

.anchor--negative {
    color: white;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: white;
        text-decoration: none;
    }
}

.anchor--secondary {
    color: $color-secondary;

    &:hover,
    &:focus {
        color: color($color-secondary a(0.7));
    }
}

/**
 * Typographic details
 */

hr {
    padding: calc($unit * 2) 0;
    border: 0;
    border-bottom: 1px solid color(white blackness(+15%));
}

em {
}

b,
strong {
    font-weight: $type-weight-bold;
}

address {
}

small {
}

pre {
}

code {
}

sub {
}

sup {
}

strike {
}

/**
 * Tables
 */

table {
    th {
        text-align: left;
    }
}

/**
 * Blockquote
 */

blockquote {
    margin: 0;
}
