/*------------------------------------*\
    # component.earnings-potential
\*------------------------------------*/

.earnings-potential {
}

.earnings-potential__details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.earnings-potential__bar {
    position: relative;
    height: 8px;
    border-radius: $global-rounded;
    background: $color-success;
    margin-bottom: calc($unit * 5);
}

.earnings-potential__bar__current {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: $global-rounded;
    background: $color-primary;
}

.earnings-potential__potential {
    margin-left: 100px;
}

.earnings-potential__details {
    margin-bottom: calc($unit * 12);
    margin-top: calc($unit * 3);
}
