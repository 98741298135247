/*------------------------------------*\
    # view.home-team
\*------------------------------------*/

.v-home-team {
    padding: calc($unit * 15) 0;
    background: $color-bg;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-home-team--alt {
    background-color: white;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.v-home-team .wrapper {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-team__heading {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 10);
    }
    @media (--screen-from-lrg) {
        lost-column: 3/12;
    }
}

.v-home-team__content {
    @media (--screen-from-lrg) {
        lost-column: 6/12;
        lost-offset: 1/12;
    }
}

.v-home-team__person {
    display: flex;
    margin-top: calc($unit * 10);
}

.v-home-team__person__photo {
    min-width: 70px;
    margin-right: calc($unit * 8);
}

.v-home-team__person__content {
    padding-top: calc($unit * 2);
}
