/*------------------------------------*\
    # utils.well
\*------------------------------------*/

.well {
}

.well--basic {
    padding: calc($unit * 8);
    border: 1px solid color($color-dark a(0.1));
    border-radius: $global-radial;
}
