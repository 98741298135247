/*------------------------------------*\
    # component.rtf-formatted
\*------------------------------------*/

.rtf-formatted {
    p,
    ul,
    ol,
    dl,
    table,
    object,
    img {
        margin-bottom: calc($unit * 4);
        font-size: responsive 14px 16px;
        font-range: 320px 1440px;

        line-height: responsive 20px 24px;
        line-height-range: 320px 1440px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: calc($unit * 6) 0;
        font-weight: $type-weight-regular;
        color: $color-text-dark;
    }

    h1 {
        font-size: responsive 20px 31px;
        font-range: 320px 1440px;

        line-height: responsive 28px 40px;
        line-height-range: 320px 1440px;
    }

    h2 {
        font-size: responsive 20px 25px;
        font-range: 320px 1440px;

        line-height: responsive 28px 36px;
        line-height-range: 320px 1440px;
    }

    h3 {
        font-size: responsive 16px 20px;
        font-range: 320px 1440px;

        line-height: responsive 24px 28px;
        line-height-range: 320px 1440px;
    }

    ul,
    ol,
    dl {
        list-style: inherit;
        list-style-position: inside;
        margin: calc($unit * 4) 0;
    }

    ol {
        list-style: decimal;
        list-style-position: inside;
    }

    ul li,
    ol li {
        margin-bottom: calc($unit * 1);

        @mixin type-scale $type-small, 5;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol li {
    }

    p {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    blockquote {
        position: relative;
        font-style: italic;
        padding-left: calc($unit * 2);
        opacity: 0.8;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 2px;
            background: $color-primary;
            opacity: 0.5;
        }
    }
}
