/*------------------------------------*\
    # component.listing-list-item
\*------------------------------------*/

.listing-list-item {
    width: 100%;
    padding-top: calc($unit * 5);
    padding-bottom: calc($unit * 5);
    padding-left: calc($unit * 3);
    padding-right: calc($unit * 3);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

    lost-flex-container: row;
}

.listing-list-item__exclusive-badge {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 5px 6px;
    background-color: color($color-primary a(0.7));
    border-radius: 0px 0px 16px 16px;
}

.listing-list-item--sold {
    position: relative;
    pointer-events: none;

    .listing-list-item__badge-list {
        filter: grayscale(100%);
    }

    .listing-list-item__image {
        filter: grayscale(100%);
        position: relative;

        &:after {
            content: 'SOLD!';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-10deg);

            @mixin type-scale $type-small, 5;
            color: white;
            background: $color-warning;
            font-weight: $type-weight-bold;
            padding: calc($unit * 2) calc($unit * 4);
            border-radius: $global-radial;
        }
    }
}

.listing-list-item__image {
    position: relative;
    display: block;
    height: 115px;
    width: 115px;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.15s;

    &:hover,
    &:focus {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0px);
    }

    &.is-locked {
        filter: grayscale(100);
    }
}

.listing-list-item__image-wrapper {
    position: relative;
    display: none;

    @media (--screen-from-med) {
        display: block;
        lost-column: 2/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 1.5/12;
    }

    &.is-locked {
        filter: grayscale(100);
    }
}

.listing-list-item__info {
    @media (--screen-to-med) {
        order: 1;
        lost-column: 1/2 0;
    }

    @media (--screen-from-med) {
        lost-column: 4/12;
        padding-left: 30px;
    }

    @media (--screen-from-lrg) {
        lost-column: 2.5/12;
        padding-left: 20px;
    }
}

.listing-list-item__graphs {
    lost-flex-container: row;

    @media (--screen-to-med) {
        order: 3;
        width: 100%;

        margin-top: calc($unit * 6);
    }

    @media (--screen-from-med) and (--screen-to-lrg) {
        lost-column: 4/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 6/12;
    }
}

.listing-list-item__graph {
    lost-column: 1/2;
    align-self: flex-end;

    @media (--screen-from-med) and (--screen-to-lrg) {
        lost-column: 1/1;

        .chartjs-render-monitor {
            height: 40px !important;
        }
    }
}

.listing-list-item__price {
    @media (--screen-to-med) {
        order: 2;
        lost-column: 1/2;
    }

    @media (--screen-from-med) {
        lost-column: 2/12;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    font-size: responsive 16px 25px;
    font-range: 600px 1440px;

    line-height: responsive 24px 36px;
    line-height-range: 600px 1440px;
}

.listing-list-item__price-icon {
    @media (--screen-from-med) and (--screen-to-lrg) {
        display: none;
    }
    @media (--screen-to-sml) {
        display: none;
    }
}

.listing-list-item__range {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.listing-list-item__title {
    margin: 0;

    &.is-locked {
        filter: grayscale(100);
    }
}

.listing-list-item__title__sold {
    @media (--screen-from-lrg) {
        display: none;
    }
}

.listing-list-item__revenue {
    margin-top: calc($unit * 2);

    @media (--screen-from-med) {
        margin-top: calc($unit * 4);
        text-align: right;
    }
}

.listing-list-item__offer-status {
    svg {
        background-color: transparent;
        animation: shadow-pulse 2s infinite ease-out;
        border-radius: $global-rounded;
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 -10px rgba(70, 35, 109, 0.8);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
}
