/*------------------------------------*\
    # component.toggle
\*------------------------------------*/

.toggle {
    display: inline-flex;
    align-items: center;
    box-shadow: 0 0px 8px 3px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.toggle__button {
    height: calc($unit * 6);
    padding: 2px calc($unit * 3) 0 calc($unit * 3);
    border-radius: 3px;
    outline: 0;

    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: $type-weight-bold;
    color: color(white a(0.5));
    color: color($color-text a(0.5));
    background: color(white a(0.8));

    &.is-active {
        color: $color-primary;
        background: white;
    }

    &:hover,
    &:focus {
        color: $color-primary;
        background: white;
        background: color($color-primary a(0.1));
    }

    &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
}
