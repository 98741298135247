/*------------------------------------*\
    # component.checkout-table
\*------------------------------------*/

.checkout-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    /* Every row except first */
    & > * + * {
        margin-top: calc($unit * 2);
    }

    @media (--screen-from-xlrg) {
        & > * + * {
            margin-top: calc($unit * 5);
        }
    }
}

.checkout-table__row {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;

    &__data {
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: calc($unit * 3) calc($unit * 6);
        background: $color-bg;
        text-align: center;

        &:nth-of-type(1) {
            text-align: center;

            @media (--screen-from-xlrg) {
                justify-content: flex-start;
                text-align: start;
            }
        }

        &:nth-of-type(2) {
            border-radius: calc($unit * 2.5) 0 0 calc($unit * 2.5);
        }

        &:nth-of-type(3) {
            border-radius: 0 calc($unit * 2.5) calc($unit * 2.5) 0;
        }

        @media (--screen-from-xlrg) {
            border-radius: calc($unit * 2.5);
        }
    }

    @media (--screen-from-xlrg) {
        grid-gap: calc($unit * 5);
    }
}

.checkout-table__first-row {
    order: 2;

    @media (--screen-from-xlrg) {
        order: 1;
    }
}

.checkout-table__second-row {
    order: 1;

    @media (--screen-from-xlrg) {
        order: 2;
    }
}

.checkout-table__third-row {
    order: 3;
}

.checkout-table__most-popular {
    position: absolute;
    top: calc($unit * -6);

    padding: $unit calc($unit * 2.5);
    color: white;
    background: $color-secondary;
    border-radius: 0px calc($unit * 2.5) 0 calc($unit * 2.5);

    @media (--screen-from-xlrg) {
        top: 0;
        right: 0;
        background: $color-primary;
    }
}

.checkout-table__icon {
    width: calc($unit * 5);
    height: calc($unit * 5);

    @media (--screen-from-xlrg) {
        width: calc($unit * 6);
        height: calc($unit * 6);
    }
}

.checkout-table__initial-row {
    background: white;
    text-align: center;

    @media (--screen-from-xlrg) {
        background: $color-bg;
    }
}

.checkout-table__unauthorized-message {
    display: block;
    @mixin type-scale $type-small, 5;
    margin-top: calc($unit * 4);
    color: $color-primary;
    word-break: break-word;
}
