/*------------------------------------*\
    # view.home-header
\*------------------------------------*/

.v-home-header {
    z-index: 0;
    position: relative;
    padding: calc($unit * 15) 0;
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    border-radius: 0 0 0 20px;
    color: white;

    @media (--screen-to-lrg) {
        padding-top: calc($unit * 35);
    }

    @media (--screen-from-lrg) {
        padding-top: calc($unit * 35);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-repeat: repeat-x;
        background-image: url(../assets/graphics/graphic-keyvisual.svg);
        background-size: cover;
        background-position: right top;
    }
}

.v-home-header__title {
    margin-bottom: calc($unit * 8);

    @media (--screen-from-lrg) {
        lost-column: 8/12;
        margin-bottom: calc($unit * 10);
    }
}

.v-home-header__content {
    z-index: 1;

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-header__content__intro {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12;
    }
}

.v-home-header__content__info {
    @media (--screen-from-lrg) {
        lost-column: 6/12;
    }
}

.v-home-header__visual {
    @media (--screen-to-lrg) {
        display: none;
    }

    z-index: -1;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;

    text-align: right;
}

.v-home-header__visual img {
}

/* Sellers */
.v-home-header__features {
    margin-bottom: calc($unit * 10);

    @media (--screen-from-sml) {
        lost-flex-container: row;
    }
}

.v-home-header__example-link {
    &:hover,
    &:focus {
        color: color($color-secondary lightness(+20%));
    }
}

.v-home-header__features__item {
    display: flex;

    @media (--screen-to-med) {
        margin-bottom: calc($unit * 10);

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (--screen-from-med) {
        lost-column: 4/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 3/12;

        &:nth-child(2),
        &:nth-child(3) {
            lost-offset: 1/12;
        }
    }
}

.v-home-header__features__item img {
    flex: 1;
    max-width: 60px;
    margin-right: calc($unit * 6);
}

.v-home-header__features__item__content {
    flex: 1;
}
