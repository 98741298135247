/*------------------------------------*\
    # utils.visibiity
\*------------------------------------*/

.visi {
}

.visi-hide--sml {
    @media (--screen-to-sml) {
        display: none;
    }
}

.visi-hide--med {
    @media (--screen-to-med) {
        display: none;
    }
}

.visi-hide--lrg {
    @media (--screen-to-lrg) {
        display: none;
    }
}
