/*------------------------------------*\
    # component.btn-grid
\*------------------------------------*/

.btn-grid {
    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.btn-grid--1of3 > .btn {
    margin-bottom: calc($unit * 4);
    lost-column: 1/1;

    @media (--screen-from-sml) {
        lost-column: 6/12 2;
        margin-bottom: calc($unit * 6);
    }

    @media (--screen-from-xlrg) {
        lost-column: 4/12 3;
    }
}

.btn-grid--1of2 > .btn {
    margin-bottom: calc($unit * 4);

    @media (--screen-from-sml) {
        lost-column: 6/12 2;
        margin-bottom: calc($unit * 6);
    }
}
