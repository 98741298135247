/*------------------------------------*\
    # component.submitted-listings
\*------------------------------------*/

.submitted-listings {
    margin-bottom: calc($unit * 30);
}

.submitted-listings__item {
    background: white;
    border-radius: $global-radial;
    padding: calc($unit * 5) calc($unit * 8);
    lost-flex-container: row;

    & + & {
        margin-top: calc($unit * 5);
    }
}

.submitted-listings__column {
    @media (--screen-to-sml) {
        width: 100%;
        margin-bottom: calc($unit * 4);
    }

    @media (--screen-from-sml) {
        lost-column: 6/12 2;
    }

    @media (--screen-from-lrg) {
        lost-column: 3/12 4;
    }
}
