/*------------------------------------*\
    # component.inbox
\*------------------------------------*/

.inbox {
    z-index: 3;
    position: absolute;
    top: 76px;
    right: 0;
    background: white;
    width: 400px;
    border-radius: $global-radial;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-out;

    @media (max-width: 1100px) {
        position: fixed;
        left: 0;
        width: 100%;
        top: 68px;
        padding-bottom: 68px;
    }
}

.inbox.is-active {
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;

    .inbox__empty__icon {
        transform: scale(1);
        opacity: 1;
    }
    .inbox__empty__text {
        transform: translateY(0px);
        opacity: 1;
    }
}

.inbox__header {
    padding: calc($unit * 4);
}

.inbox__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 16px;

    &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.inbox__item__message {
    max-height: 100px;
    overflow: hidden;
}

.inbox__list {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.inbox__button {
    display: block;
    text-align: left;
    margin: 0;

    border-radius: 0;
    padding: calc($unit * 2) calc($unit * 4);
    color: $color-text;
    width: 100%;
    white-space: initial;

    font-size: 13px;
    line-height: 18px;

    &:hover,
    &:focus {
        background: color($color-text a(0.1));
    }
}

.inbox__button--unread {
    font-weight: $type-weight-bold;
    color: $color-text-dark;
}

.inbox__footer {
    display: flex;
    justify-content: space-between;
    text-align: right;
    padding: calc($unit * 1);
}

.inbox__timestamp {
    font-size: 12px;
    line-height: 16px;
    color: color($color-text a(0.6));
    margin-top: $unit;
}

.inbox__empty {
    padding: calc($unit * 2) calc($unit * 4);
}

.inbox__empty__icon {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.5s ease-out;
}

.inbox__empty__text {
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.5s ease-out;
}
