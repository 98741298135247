/*------------------------------------*\
    # component.top-bar
\*------------------------------------*/

.top-bar {
    margin: 0 auto;
    text-align: center;
    background: #5ff199;
    color: white;
    font-weight: $type-weight-bold;
    padding: calc($unit * 2) calc($unit * 6);

    position: relative;
    z-index: 0;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        background-image: url(../assets/images/pattern-1.svg);
        opacity: 0.2;
        background-repeat: repeat;
        background-size: 13%;
    }
    @media (--screen-to-lrg) {
        padding-right: calc($unit * 15);
    }
}

.top-bar__cta {
    @media (--screen-to-lrg) {
        display: block;
    }
}
