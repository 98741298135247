/*------------------------------------*\
    # block.sidenav
\*------------------------------------*/

.sidenav {
    position: sticky;
    top: 0;
    left: 0;

    padding: calc($unit * 20) 0 calc($unit * 15);
}

.sidenav__item {
}

.sidenav__item > a {
    position: relative;
    display: block;
    padding: 6px 0;

    @mixin type-scale $type-small, 5;
    color: $color-text;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -24px;
        height: 100%;
        width: 1px;
        background: color($color-dark a(0.1));
        transition: all 0.2s ease-out, width 1ms;
    }

    &:hover {
        &:before {
            background: $color-primary;
            width: 2px;
            outline: 0;
        }
    }
}

.sidenav__item--active  {
    a {
        &:before {
            background: $color-primary;
            width: 2px;
        }
    }
}

.sidenav__item > a > span {
    font-weight: $type-weight-bold;
    color: $color-primary;
}

.sidenav__item ul {
    padding-left: calc($unit * 3);
}

.sidenav__item ul li {
    padding: 6px 0;
    @mixin type-scale $type-small, 5;
    color: $color-text;
}

.sidenav__btn {
    padding: calc($unit * 4);
}
