/*------------------------------------*\
    # component.formatted
\*------------------------------------*/

.formatted {
    p,
    ul,
    ol,
    dl,
    table,
    object,
    img {
        margin-bottom: calc($unit * 4);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: calc($unit * 6) 0;
        font-weight: $type-weight-regular;
        color: $color-text-dark;
    }

    h1 {
        font-size: responsive 20px 31px;
        font-range: 320px 1440px;

        line-height: responsive 28px 40px;
        line-height-range: 320px 1440px;
    }

    h2 {
        font-size: responsive 20px 25px;
        font-range: 320px 1440px;

        line-height: responsive 28px 36px;
        line-height-range: 320px 1440px;
    }

    h3 {
        font-size: responsive 16px 20px;
        font-range: 320px 1440px;

        line-height: responsive 24px 28px;
        line-height-range: 320px 1440px;
    }

    ul,
    ol,
    dl {
        list-style: inherit;
        list-style-position: inside;
        margin: calc($unit * 4) 0;
    }

    ol {
        list-style: decimal;
        padding-left: calc($unit * 3);
    }

    ul li,
    ol li {
        margin-bottom: calc($unit * 1);

        @mixin type-scale $type-small, 5;
        @media (--screen-from-med) {
            @mixin type-scale $type-base, 7;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol li {
    }

    ul li {
        padding-left: 1.5em;
        text-indent: -1.4em;
    }

    p {
        @mixin type-scale $type-small, 5;
        @media (--screen-from-med) {
            @mixin type-scale $type-base, 7;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
