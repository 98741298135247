/*------------------------------------*\
    # view.not-found
\*------------------------------------*/

.v-not-found {
    text-align: center;
    padding-top: calc($unit * 10);
    padding-bottom: calc($unit * 20);
}

.v-not-found__text {
    max-width: 400px;
    margin: 0 auto;
}

.v-not-found .swing {
    transform-origin: 37px 159px;
    animation: swing 4s ease-out;
    transform: rotate(20deg);
}

.v-not-found__svg {
    height: 240px;
}

@keyframes swing {
    0% {
        transform: rotate(-45deg);
    }
    30% {
        transform: rotate(-40deg);
    }
    40% {
        transform: rotate(39deg);
    }
    50% {
        transform: rotate(9deg);
    }
    60% {
        transform: rotate(30deg);
    }
    70% {
        transform: rotate(12deg);
    }
    80% {
        transform: rotate(25deg);
    }
    90% {
        transform: rotate(18deg);
    }
    100% {
        transform: rotate(20deg);
    }
}
