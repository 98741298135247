/*------------------------------------*\
    # plugins.react-table
\*------------------------------------*/

.react-table-wrap {
    overflow: auto;
    max-width: 100%;
}

.react-table {
    display: block;

    tr {
        display: block;
    }

    th,
    td {
        width: 1%;
        position: relative;
    }

    thead {
        width: 100%;
        display: block;

        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        th {
            position: relative;
        }

        .react-table__content {
            padding-top: 8px;
            padding-bottom: 8px;
            border-right: 1px solid color($color-dark a(0.1));
        }

        th > div {
            padding: 16px 4px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            width: 100%;
        }
    }

    tbody {
        display: block;
        width: 100%;

        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        td {
            font-size: 14px;
            line-height: 16px;
            padding: 12px 8px;
        }
    }

    input,
    select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: white;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline-width: 0;
        width: 100%;
    }

    .btn {
        &:hover,
        &:active,
        &:focus {
            transform: none;
        }
    }
}

.react-table__col {
    padding-top: 4px;
    padding-bottom: 4px;
}

.react-table__col--sortedAsc {
    box-shadow: 0 -1px 0 0 #4a1172;
}

.react-table__col--sortedDesc {
    box-shadow: 0 1px 0 0 #4a1172;
}

.react-table__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.react-table__filter {
    position: relative;
    padding-top: 12px;
    padding-right: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.react-table__resizer {
    display: inline-block;
    position: absolute;
    width: 8px;
    top: 8px;
    bottom: 8px;
    right: 0;
    cursor: col-resize;
    z-index: 10;
}

.react-table-pagination {
    padding-top: 16px;
    display: inline-flex;
    font-size: 14px;
    line-height: 16px;

    input {
        height: 32px;
        border: 1px solid rgba(57, 18, 84, 0.1);
        padding: 5px 7px;
        border-radius: 0;
        outline-width: 0;
        background: transparent;
        width: 70px;
        text-align: center;
    }

    select {
        height: 32px;
        border: 1px solid rgba(57, 18, 84, 0.1);
        padding: 5px 7px;
        border-radius: 0;
        outline-width: 0;
        background: transparent;
    }
}

.react-table-pagination__info {
    display: inline-block;
    white-space: nowrap;
}

.react-table-pagination__controls {
    padding: 0 32px;
}

.react-table-pagination__btn {
    appearance: none;
    border: 0;
    background: transparent;
    display: block;
    height: 32px;
    padding: 0 24px;
    box-shadow: inset 0 0 0 1px rgba(74, 17, 114, 0.6);
    color: rgba(74, 17, 114, 0.8);
}

.react-table-pagination__btn--disabled {
    opacity: 0.5;
    cursor: default;
}
