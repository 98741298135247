/*------------------------------------*\
    # component.loading
\*------------------------------------*/

@keyframes spinnerAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading,
.loading:after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.loading {
    position: relative;
    margin: 60px auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 3px solid $color-primary;
    border-right: 3px solid $color-primary;
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    transform: translateZ(0);
    animation: spinnerAnimation 0.75s infinite linear;
}

.loading--page {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

.loading--page__logo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    fill: $color-text;

    path {
        opacity: 0.5;
    }

    path:nth-child(1) {
        animation: fill 3s linear infinite;
        animation-delay: 50ms;
    }
    path:nth-child(2) {
        animation: fill 3s linear infinite;
        animation-delay: 100ms;
    }
    path:nth-child(3) {
        animation: fill 3s linear infinite;
        animation-delay: 150ms;
    }
    path:nth-child(4) {
        animation: fill 3s linear infinite;
        animation-delay: 200ms;
    }
    path:nth-child(5) {
        animation: fill 3s linear infinite;
        animation-delay: 250ms;
    }
    path:nth-child(6) {
        animation: fill 3s linear infinite;
        animation-delay: 300ms;
    }
    path:nth-child(7) {
        animation: fill 3s linear infinite;
        animation-delay: 350ms;
    }
    path:nth-child(8) {
        animation: fill 3s linear infinite;
        animation-delay: 400ms;
    }
    path:nth-child(9) {
        animation: fill 3s linear infinite;
        animation-delay: 450ms;
    }
    path:nth-child(10) {
        animation: fill 3s linear infinite;
        animation-delay: 500ms;
    }
    path:nth-child(11) {
        animation: fill 3s linear infinite;
        animation-delay: 550ms;
    }
    path:nth-child(12) {
        animation: fill 3s linear infinite;
        animation-delay: 600ms;
    }
    path:nth-child(13) {
        animation: fill 3s linear infinite;
        animation-delay: 650ms;
    }
    path:nth-child(14) {
        animation: fill 3s linear infinite;
        animation-delay: 700ms;
    }
}

.loading--inline {
    margin: 0;
    height: 15px;
    width: 15px;
    border-width: 2px;
    margin-left: calc($unit * 2);

    border-top-color: white;
    border-right-color: white;
}

.loading--small {
    margin: 0;
}

@keyframes fill {
    0% {
        opacity: 0.5;
    }
    4% {
        fill: $color-primary;
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}
