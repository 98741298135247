/*------------------------------------*\
    # component.social
\*------------------------------------*/

.social {
    display: inline-flex;
}

.social__link {
    display: block;
    width: 36px;
    height: 36px;
    margin-right: calc($unit * 3);
    border-radius: 50%;
    border: 1px solid color($color-dark a(0.1));
    transition: all 0.15s;
}

.social__link:hover,
.social__link:focus {
    cursor: pointer;
    background-color: color($color-primary a(0.2));
}

.social__link--facebook {
    background: url(../assets/icons/smm-facebook.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--instagram {
    background: url(../assets/icons/smm-instagram.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--linkedin {
    background: url(../assets/icons/smm-linkedin.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--twitter {
    background: url(../assets/icons/smm-twitter.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--skype {
    background: url(../assets/icons/smm-skype.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}
