/*------------------------------------*\
    # block.infoblock-swot
\*------------------------------------*/

.infoblock-swot {
}

.infoblock-swot__layout {
    @media (--screen-from-lrg) {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &:before {
            position: absolute;
            display: block;
            content: '';
        }

        &:before {
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: color($color-dark a(0.1));
        }
    }
}

.infoblock-swot__layout__item {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 6);

        &:last-child {
            margin-bottom: 0;
        }
    }
    @media (--screen-from-lrg) {
        flex: 1 0 50%;

        &:nth-child(n) {
            padding-right: calc($unit * 8);
        }

        &:nth-child(2n) {
            padding-left: calc($unit * 8);
        }

        &:nth-child(1),
        &:nth-child(2) {
            padding-bottom: calc($unit * 8);
            border-bottom: 1px solid color($color-dark a(0.1));
        }

        &:nth-child(3),
        &:nth-child(4) {
            padding-top: calc($unit * 8);
        }
    }
}
