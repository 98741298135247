/*------------------------------------*\
    # component.popup
\*------------------------------------*/

.popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $color-primary;
    padding: calc($unit * 4);
    color: white;
    animation-name: show;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-delay: 5s;
}

@keyframes show {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}
