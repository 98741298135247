/*------------------------------------*\
    # component.v-inbox
\*------------------------------------*/

.v-inbox {
}

.v-inbox__split {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
    padding: 0 calc($unit * 2);
    transform: translateX(0px);
    transition: transform 0.1s ease-out;
}

.v-inbox__split--opened {
    @media (--screen-to-med) {
        transform: translateX(220px);
    }

    .v-inbox__toggle {
        transform: rotate(0deg);
    }
}

.v-inbox__sidebar {
    max-height: 1000px;
    overflow-y: auto;

    grid-column: 1/5;

    @media (--screen-to-med) {
        min-width: 220px;
        margin-left: -236px !important;
    }
}

.v-inbox__details {
    grid-column: 5/13;
    padding: 0 calc($unit * 2);
}

.v-inbox__details__header {
    z-index: 1;
    position: sticky;
    top: 0;
    background: $color-bg;
    padding: calc($unit * 4) 0;
}

.v-inbox__button--unread {
}

.v-inbox__initial {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: calc($unit * 2);
    font-size: 20px;
    border-radius: $global-rounded;
    width: 32px;
    height: 32px;
    margin-right: calc($unit * 3);
    overflow: hidden;
}

.v-inbox__toggle {
    transform: rotate(180deg);

    @media (--screen-from-med) {
        display: none;
    }
}

.v-inbox__assisted {
    background-color: $color-primary;
    padding: calc($unit * 4);
    border-radius: $global-radial;
    color: white;
    z-index: 0;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(../assets/images/pattern-2.svg);
        background-size: 60%;
        background-position: right top;
        background-color: white;
        opacity: 0.2;
    }
}
