/*------------------------------------*\
    # view.home-diligence
\*------------------------------------*/

.v-home-diligence {
    padding-bottom: calc($unit * 8);

    @media (--screen-from-lrg) {
        padding-bottom: calc($unit * 15);
    }
}

.v-home-diligence__paragraph {
    max-width: 600px;
}

.v-home-diligence__selector {
    lost-flex-container: row;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: calc($unit * 20);
    padding-top: calc($unit * 10);
}

.v-home-diligence__sections {
    margin-top: calc($unit * 16);

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 6);
        margin-bottom: calc($unit * 6);
    }

    @media (--screen-from-lrg) {
        lost-column: 3/12;
    }

    button {
        font-weight: $type-weight-bold;

        position: relative;
        display: block;
        padding: 6px 0;

        @mixin type-scale $type-small, 5;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -24px;
            height: 100%;
            width: 1px;
            background: color($color-dark a(0.1));
            transition: all 0.2s ease-out, width 1ms;
        }

        &:hover {
            &:before {
                background: $color-primary;
                width: 2px;
                outline: 0;
            }
        }

        &.selected {
            color: $color-primary;

            &:before {
                background: $color-primary;
                width: 2px;
            }
        }
    }
}

.v-home-diligence__preview {
    lost-column: 1/1;

    @media (--screen-from-lrg) {
        lost-column: 9/12;
    }

    /** Removes numbers in front of sections that are detached on homepage. */
    .infoblock__title:before {
        display: none;
    }
}
