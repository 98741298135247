/*------------------------------------*\
    # view.checkout-subscription
\*------------------------------------*/

.checkout-subscription {
}

.checkout-subscription__header {
    z-index: 0;
    position: relative;
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;

    padding-top: calc($unit * 35);

    @media (--screen-from-lrg) {
        padding-top: calc($unit * 50);
    }

    &__content {
        display: flex;
        flex-direction: row;
    }

    &__text {
        @media (--screen-from-lrg) {
            margin-right: calc($unit * 20);
        }

        @media (--screen-from-xlrg) {
            margin-right: calc($unit * 35);
        }
    }

    &__illustration {
        display: none;

        @media (--screen-from-lrg) {
            display: block;
        }
    }
}

.checkout-subscription__featured {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgb(83, 13, 133);

    padding: calc($unit * 6) calc($unit * 5);

    &__companies {
        width: 100%;

        @media (--screen-from-sml) {
            width: 80%;
        }

        @media (--screen-from-lrg) {
            width: 100%;
        }
    }

    &__first-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: calc($unit * 7);

        & > * {
            opacity: 0.5;
        }

        @media (--screen-from-lrg) {
            display: inline;

            /* Every child except first */
            & > * + * {
                margin-left: calc($unit * 7);
            }
        }
    }

    &__second-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: calc($unit * 7);

        & > * {
            opacity: 0.5;
        }

        @media (--screen-from-lrg) {
            display: inline;
            & > * {
                margin-left: calc($unit * 7);
            }
        }
    }

    &__third-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > * {
            opacity: 0.5;
        }

        /* Every child except first */
        & > * + * {
            padding-left: calc($unit * 2);
        }

        @media (--screen-from-lrg) {
            display: inline;

            & > * {
                padding-left: 0;
                margin-left: calc($unit * 7);
            }
        }
    }

    @media (--screen-from-lrg) {
        display: block;
        text-align: center;
    }
}

.checkout-subscription__card-container {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    @media (--screen-from-lrg) {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: calc($unit * 20);
    }

    @media (--screen-from-xlrg) {
        grid-column-gap: calc($unit * 35);
    }
}

.checkout-subscription__progress {
    display: none;

    @media (--screen-from-lrg) {
        display: block;
        position: absolute;
        top: 60%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-36%, -50%);
    }

    @media (--screen-from-xlrg) {
        transform: translate(-50%, -50%);
    }
}

.checkout-subscription__vertical-card {
    position: relative;
    z-index: 0;
    background: rgb(74, 17, 114);
    color: white;
    box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.06);
    padding: calc($unit * 17) calc($unit * 10) calc($unit * 15) calc($unit * 10);
    border-radius: 16px;
    margin-top: 80px;

    &__content {
        color: white;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(../assets/images/smaller-card-pattern.svg);
        background-size: cover;
        background-position: right top;
    }
}

.checkout-subscription__vertical-card__icon {
    position: relative;
    left: 85%;
    margin-top: -120px;
    width: fit-content;
}

.checkout-subscription__solutions {
    lost-flex-container: row;
    text-align: center;
}

.checkout-subscription__solutions__item {
    position: relative;

    box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.06);
    padding: calc($unit * 15) calc($unit * 8) calc($unit * 6) calc($unit * 8);
    border-radius: 16px;
    width: 100%;

    @media (--screen-to-sml) {
        & + & {
            margin-top: calc($unit * 12);
        }
    }

    @media (--screen-from-sml) {
        lost-column: 1/3;
    }
}

.checkout-subscription__important-section {
    padding: calc($unit * 10) 0;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;

    color: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 25) 0;
    }
}

.checkout-subscription__alt-card {
    position: relative;
    background: white;
    border-radius: 16px;
    padding: calc($unit * 10);
    box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.06);
}

.checkout-subscription__alt-card__star {
    position: absolute;
    top: -30px;
    right: -22px;
}

.checkout-subscription__faq-item {
    width: 100%;
    display: block;
    text-align: left;
    background: $color-bg;
    padding: calc($unit * 8);
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: $color-text;
    margin-top: calc($unit * 5);
    cursor: pointer;

    &:hover,
    &:focus {
        border: 1px solid $color-primary;
    }
}

.checkout-subscription__faq-item__icon.is-active {
    transform: rotate(180deg);
}

.checkout-subscription__section {
    padding: calc($unit * 17) 0;
}

.checkout-subscription__solution-icon {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.checkout-subscription__vip-features {
    lost-flex-container: row;
}

.checkout-subscription__vip-feature {
    margin-top: calc($unit * 10);

    @media (--screen-from-sml) {
        lost-column: 1/2;
    }
}
.checkout-subscription__modules {
    display: block;
    height: auto;
    width: 100%;
    padding: calc($unit * 5);
}

.checkout-subscription__modules.is-visible {
    .checkout-subscription__modules__icon {
        transform: rotate(180deg);
    }
}

.checkout-subscription__modules__list {
    text-align: left;
    margin-top: calc($unit * 4);
}

.checkout-subscription__alt-card {
    li {
        padding-left: calc($unit * 8);
        margin-bottom: calc($unit * 4);
        color: $color-text;
    }
}

.checkout-subscription__testimonials {
    position: relative;
    background: white;
    background: #ffffff;

    box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    padding: calc($unit * 10) calc($unit * 5);
    padding-bottom: calc($unit * 25);

    @media (--screen-from-med) {
        padding: calc($unit * 10) calc($unit * 30);
    }

    .BrainhubCarouselItem {
        align-items: flex-start !important;
    }
}

.checkout-subscription__testimonials .BrainhubCarousel__dot {
    background: transparent;
}

.checkout-subscription__testimonials .BrainhubCarousel__dots {
    bottom: calc($unit * 6);
}

.checkout-subscription__testimonials__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    margin-top: calc($unit * 12);
    padding: 0 calc($unit * 8);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 20);
    }
}

.checkout-subscription__testimonials__carousel-right-arrow {
    transform: rotate(180deg);
}

.checkout-subscription__testimonials__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc($unit * 5);

    img {
        width: 50px;
        height: 50px;
        border-radius: $global-rounded;
        margin-right: calc($unit * 3);

        @media (--screen-from-med) {
            width: 80px;
            height: 80px;
        }
    }
}

.checkout-subscription__testimonials__content {
    text-align: center;
}

.checkout-subscription__table {
    overflow-y: auto;
    margin-bottom: calc($unit * 20);
    display: none;
    @media (--screen-from-lrg) {
        display: block;
    }

    table {
        min-width: 600px;
    }
}

.checkout-subscription__table--mobile {
    display: block;
    padding: 0 calc($unit * 5);

    @media (--screen-from-sml) {
        padding: 0 calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        display: none;
    }
}

.checkout-subscription__video-container {
    padding: calc($unit * 10) calc($unit * 10);

    @media (--screen-from-lrg) {
        padding: calc($unit * 15) calc($unit * 50);
    }
}

.checkout-subscription__video {
    position: relative;
    z-index: 0;

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 10px;
        left: -10px;
        background: $color-secondary;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }

    &:after {
        z-index: -1;
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        background: $color-primary;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }

    iframe {
        z-index: 1;
        border-radius: 16px;
        height: 380px;

        @media (--screen-from-med) {
            height: 466px;
        }
    }
}
