/*------------------------------------*\
    # block.modal
\*------------------------------------*/

@keyframes animateModal {
    to {
        backdrop-filter: blur(6px);
        opacity: 1;
    }
}

@keyframes animateModalWrapper {
    to {
        transform: translateY(0);
    }
}

.modal {
    position: fixed;
    z-index: 110;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh;

    overflow: hidden;
    width: 100%;

    background-color: color($color-primary a(0.4));

    backdrop-filter: blur(6px);
    opacity: 0;

    animation: animateModal 0.3s ease-out forwards;

    @media (--screen-from-sml) {
        display: flex;
        justify-items: center;
        align-items: center;
    }
}

.modal__wrapper {
    position: relative;
    overflow: auto;
    z-index: 2;

    margin: 0 auto;

    background-color: white;
    box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, 0.4);

    transition: all 0.2s;
    animation: animateModalWrapper 0.2s ease-out forwards;

    transform: translateY(-30px);

    @media (--screen-to-sml) {
        height: 100vh;
    }

    @media (--screen-from-sml) {
        width: 100%;
        max-width: 500px;
        border-radius: $global-radial;
    }
}

.modal__wrapper--wide {
    max-width: 800px;
}

.modal__content {
    overflow-y: auto;
    max-height: calc(100vh - 300px);

    @media (--screen-to-sml) {
        max-height: 540px;
        padding: 0 calc($unit * 8);
    }

    @media (--screen-from-sml) {
        padding: calc($unit * 4) calc($unit * 8);
    }
}

.modal__header {
    padding: calc($unit * 20) calc($unit * 8) calc($unit * 8) calc($unit * 8);

    @media (--screen-from-sml) {
        padding: calc($unit * 8);
    }
}

.modal__footer {
    padding: calc($unit * 8);
}

.modal__close {
    position: absolute;
    z-index: 2;
    top: calc($unit * 4);
    right: calc($unit * 4);

    opacity: 0.3;
    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
        opacity: 1;
    }

    @media (--screen-to-sml) {
        right: calc($unit * 22);
        top: calc($unit * 6);
    }
}
