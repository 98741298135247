/*------------------------------------*\
    # component.notification-details
\*------------------------------------*/

.notification-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: calc($unit * 4);
    transition: all 0.15s ease-out;

    white-space: initial;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:hover,
    &:focus {
        background: color($color-text a(0.1));
    }
}

.notification-details--unseen {
    background: color($color-secondary a(0.1));

    .notification-details__text {
        font-weight: $type-weight-bold;
        color: $color-text;
    }
}

.notification-details__text {
    @mixin type-scale $type-small, 5;
}

.notification-details__timestamp {
    text-align: right;
    color: color($color-text a(0.5));

    @mixin type-scale $type-small, 5;
}
