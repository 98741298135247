/*------------------------------------*\
    # component.partner-item
\*------------------------------------*/

.partner-item {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: calc($unit * 15);
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    @media (--screen-from-lrg) {
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2 0 0;

        &:nth-child(odd) {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        &:nth-child(1),
        &:nth-child(2) {
            border-top: 0;
        }
    }
}

.partner-item__content {
    margin-top: 170px;
    padding: 0 0 calc($unit * 30) 0;

    @media (--screen-to-med) {
        margin-top: 70px;
        padding: 0 0 calc($unit * 15) 0;
    }
}

.partner-item__header {
    display: flex;
    align-items: center;

    @media (--screen-to-lrg) {
        justify-content: center;
    }
    @media (--screen-from-lrg) {
        justify-content: space-between;
    }

    @media (--screen-to-med) {
        flex-direction: column;
        justify-content: space-between;
    }
}

.partner-item__header-offer {
    display: flex;
    align-items: center;
    width: 25%;
    margin-right: 20px;

    @media (--screen-to-med) {
        margin-top: 10px;
    }
}

.partner-item__footer {
    position: absolute;
    bottom: 60px;
}
