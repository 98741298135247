/*------------------------------------*\
    # view.valuation-intro
\*------------------------------------*/

.v-valuation-intro {
    padding: calc($unit * 15) 0;
}

.v-valuation-intro__content {
    @media (--screen-from-lrg) {
        lost-column: 3/12 2 60px;
        margin-top: calc($unit * 20);
    }
}

.v-valuation-intro__image {
    border-radius: $global-radial;

    @media (--screen-from-lrg) {
        lost-column: 9/12 2 60px;
    }
}

.v-valuation-intro__icons {
    margin-top: calc($unit * 14);
    text-align: center;
}

.v-valuation-intro__start-now-button {
    margin-top: calc($unit * 14);
    width: 100%;
    display: flex;
    justify-content: center;
}

.v-valuation-intro__wrapper {
    lost-flex-container: row;
    align-items: center;

    @media (--screen-to-lrg) {
        flex-direction: column;
        justify-content: center;
    }
}
