/*------------------------------------*\
    # view.home-features
\*------------------------------------*/

.v-home-features {
    padding: calc($unit * 15) 0;
    background: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.v-home-features--alt {
    background: transparent;
}

.v-home-features__heading {
    margin-bottom: calc($unit * 10);
    text-align: center;

    @media (--screen-from-lrg) {
        margin-bottom: calc($unit * 20);
    }
}

.v-home-features__heading--alt {
    text-align: left;

    max-width: 600px;
}

.v-home-features__grid {
    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.v-home-features__grid__item {
    text-align: left;

    @media (--screen-to-med) {
        text-align: center;
        margin-bottom: calc($unit * 5);

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (--screen-from-med) {
        display: flex;
        justify-content: space-between;
        lost-column: 1/2;
        padding-bottom: calc($unit * 20);
    }
}

.v-home-features__grid__item--alt {
    text-align: center;
    display: block;

    @media (--screen-from-med) {
        lost-column: 1/3;
    }
}

.v-home-features__grid__item__visual {
    @media (--screen-from-med) {
        min-width: 80px;
        margin-right: calc($unit * 8);
    }
}

.v-home-features__grid__item__visual__buyers {
    @media (--screen-from-med) {
        min-width: 80px;
    }
}
