/*------------------------------------*\
    # component.message-preview
\*------------------------------------*/

.message-preview {
    z-index: 0;
    position: relative;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.04);
    border-radius: $global-radial;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: white;
    transition: all 0.1s ease-out;
    max-height: 150px;
    overflow: hidden;

    &:hover,
    &:focus {
        background-color: color(black a(0.05));

        &:after {
            pointer-events: none;
            background: linear-gradient(
                180deg,
                color(black a(0)) 0%,
                #e8eaf2 100%
            );
        }
    }

    &:active {
        transform: scale(0.98, 0.99);
    }

    & + & {
        margin-top: calc($unit * 2);
    }
}

.message-preview__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message-preview--selected {
    background-color: color(black a(0.05));

    &:after {
        pointer-events: none;
        background: linear-gradient(180deg, color(black a(0)) 0%, #e8eaf2 100%);
    }

    .message-preview__author,
    .message-preview__timestamp,
    .message-preview__content {
        color: $color-text;
    }
}

.message-preview__button {
    white-space: initial;
    display: block;
    text-align: left;
    padding: calc($unit * 5) calc($unit * 3);
}

.message-preview__author {
    color: $color-text;
    max-width: 220px;
    padding-right: calc($unit * 3);
    overflow: hidden;
    text-overflow: ellipsis;
    @mixin type-scale $type-tiny, 4;
}

.message-preview__timestamp {
    color: color($color-text a(0.7));
}

.message-preview__content {
    color: $color-text-dark;
}

.message-preview.message-preview--unread {
    margin-top: 20px;
}

.message-preview__new {
    z-index: 2;
    position: absolute;
    top: -10px;
    left: 5px;
    background: $color-primary;
    color: white;
    padding: 0px 8px;
    font-size: 10px;
    border-radius: $global-radial;
}
