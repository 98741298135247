/*------------------------------------*\
    # view.home-packages
\*------------------------------------*/

.v-home-packages {
    padding: calc($unit * 15) 0;
}

.v-home-packages__grid {
    padding-top: calc($unit * 25);
    max-width: 1000px;
    margin: 0 auto;

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-packages__grid__item {
    position: relative;
    background: white;
    border-radius: 16px;

    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 15);
        padding: calc($unit * 10);

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (--screen-from-lrg) {
        lost-column: 6/12 2;
        padding: calc($unit * 20) calc($unit * 12);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.v-home-packages__grid__item__icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (--screen-to-lrg) {
        top: -32px;
        max-width: 60px;
    }

    @media (--screen-from-lrg) {
        top: -52px;
        max-width: 88px;
    }
}

.v-home-packages__grid__item__content {
}

.v-home-packages__grid__item__pricing {
    margin-top: calc($unit * 8);
    padding-top: calc($unit * 8);
    border-top: 1px solid color($color-dark a(0.1));
}

.v-home-packages__grid__item__pricing ul {
    margin-bottom: calc($unit * 4);
}

.v-home-packages__grid__item__pricing ul li {
    display: flex;
    padding: calc($unit * 2) 0;
    border-bottom: 1px solid color($color-dark a(0.1));
}

.v-home-packages__grid__item__pricing ul li span {
    &:nth-child(1) {
        flex: 0.3;
    }

    &:nth-child(2) {
        flex: 1;
    }

    &:nth-child(3) {
        flex: 1;
    }
}
