/*------------------------------------*\
    # block.generalinfo
\*------------------------------------*/

.generalinfo {
    padding-top: calc($unit * 5);

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.generalinfo__visual {
    position: relative;
    overflow: hidden;
    min-height: 240px;
    max-height: 400px;

    background: $color-secondary;
    background-size: cover;
    background-position: center;

    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12),
        0 3px 6px 0 rgba(0, 0, 0, 0.12);

    border-radius: 20px;

    @media (--screen-from-lrg) {
        min-height: 396px;
        lost-column: 5/12;
    }

    &:after {
        content: '';
        position: absolute;
        top: -130%;
        left: -112%;
        width: 200%;
        height: 200%;
        transform: rotate(45deg);
        background: color(white a(0.1));
    }
}

.generalinfo__details {
    padding-bottom: calc($unit * 10);

    @media (--screen-from-sml) {
        display: flex;
        flex-direction: column;
    }

    @media (--screen-to-lrg) {
        padding-top: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 6/12;
        lost-offset: 1/12;
        padding-bottom: calc($unit * 18);
    }
}

.generalinfo__details__tooltip {
    position: absolute;
    top: -30px;
    left: 0px;
    z-index: 2;
}

.generalinfo__details__top {
    lost-flex-container: row;
    padding-bottom: calc($unit * 6);
    border-bottom: 1px solid color($color-dark a(0.1));
}

.generalinfo__details__notice {
    @mixin type-scale $type-small, 4;
    background-color: color($color-secondary a(0.15));
    padding: calc($unit * 3);
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        min-width: 30px;
        width: 16px;
        height: 16px;
    }
}

.generalinfo__details__top__price {
    position: relative;
    lost-column: 1/2;
}

.generalinfo__details__top__averagerevenue {
    lost-column: 1/2;
}

.generalinfo__details__meta {
    lost-flex-container: row;
    margin-top: calc($unit * 0);
}

.generalinfo__details__meta-item {
    margin-top: calc($unit * 10);
    lost-column: 1/2;

    @media (--screen-from-sml) {
        lost-column: 1/4 0 0;
    }
}

.generalinfo__details__meta-item--large {
    @media (--screen-from-sml) {
        lost-column: 1/2 0 0;
    }
}

.generalinfo__notice {
    background-color: color($color-secondary a(0.15));
    padding: calc($unit * 4);
    border-radius: $global-radial;
    margin: calc($unit * 6) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 5) calc($unit * 9);
    }
}

.generalinfo__dutch-auction {
    z-index: 0;
    position: relative;
    padding: 18px 24px;
    background: #5ff19959;
    border: 1px solid color($color-success a(0.5));
    border-radius: 6px;
    margin-bottom: 40px;
    overflow: hidden;
}
