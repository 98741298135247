/*------------------------------------*\
    # component.comparison
\*------------------------------------*/

.comparison {
    padding: calc($unit * 15) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 30) 0;
    }
}

.comparison__tables {
    z-index: 0;
    lost-flex-container: row;
    position: relative;

    &:before {
        z-index: 2;
        content: 'VS';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-10deg);
        font-size: 25px;
        line-height: 25px;
        color: color($color-secondary lightness(-40%));
    }

    &:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-10deg);
        border-radius: $global-rounded;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
        width: 80px;
        height: 80px;
        border: 1px solid white;
        background: linear-gradient(
            180deg,
            rgba(252, 202, 113, 1) 0%,
            rgba(245, 187, 100, 1) 100%
        );
    }
}

.comparison__table {
    padding: calc($unit * 11) calc($unit * 13);
    border-radius: 16px;
    background: white;
    list-style-type: none;
    transform: scale(0.95);

    @media (--screen-from-lrg) {
        lost-column: 1/2 0 36px;
    }

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 8);
        padding: calc($unit * 6) calc($unit * 6);
    }
}

.comparison__table--primary {
    background: $color-primary;
    transform: scale(1);

    position: relative;
    z-index: 0;

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/pattern-1.svg);
        opacity: 0.2;
        background-repeat: repeat;
        background-size: 30%;
    }
}

.comparison__item {
    padding: calc($unit * 5) 0;
    padding-right: calc($unit * 5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    font-size: responsive 14px 20px;
    font-range: 320px 1440px;

    line-height: responsive 20px 28px;
    line-height-range: 320px 1440px;
}

.comparison__table--primary {
    .comparison__item {
        color: white;
        border-color: rgba(255, 255, 255, 0.1);
    }
}

.comparison__item__icon {
    margin-right: calc($unit * 7);
    min-width: 38px;
}
