/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    position: relative;
    display: inline-flex;
    align-items: center;

    touch-action: manipulation;
    user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: transparent;

    transition: all 0.15s ease-in-out;
    border-radius: $global-radial;
    padding: 0;

    &:focus {
        outline: 0;
    }
}

/**
    * Button styles
    */
.btn--primary {
    background: $color-primary;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    color: white;
    transition: all 0.15s ease-out;

    &:hover,
    &:focus {
        transform: translateY(-2px);
        background-color: color($color-primary lightness(+10%));
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);

        color: white;

        &:after {
            border-bottom-color: #4c007f;
        }
    }

    &:active {
        transform: translateY(0px);

        &:hover,
        &:focus {
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
        }

        &:before {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            border-radius: $global-radial;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 3px solid #2f004f;
        border-top: 2px solid color($color-primary lightness(+20%));
        border-radius: $global-radial;
    }

    & .btn--tooltip svg path {
        fill: color(white a(0.4));
    }
}

.btn--primary--outline {
    background: transparent;
    box-shadow: 0 0 0 1px color($color-primary a(0.6)) inset;
    color: color($color-primary a(0.8));
    transition: all 0.15s ease-out;

    svg {
        fill: $color-primary;
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 0 1px color($color-primary a(1)) inset;
        transform: translateY(-2px);
        background: $color-primary;
        color: white;

        svg {
            fill: white;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-bottom: 3px solid #2f004f;
            border-top: 2px solid color($color-primary lightness(+20%));
            border-radius: $global-radial;
        }
    }

    &:active {
        transform: translateY(0px);
    }
}

.btn--secondary {
    background: $color-secondary;
    color: $color-primary;
    transition: translate 0.1s ease-out;
    transition: all 0.15s ease-out;

    &:hover,
    &:focus {
        transform: translateY(-2px);
        background-color: color($color-secondary lightness(+5%));
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
        color: $color-primary;

        &:after {
            border-bottom-color: #d19528;
        }
    }

    &:active {
        transform: translateY(0px);

        &:hover,
        &:focus {
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
        }

        &:before {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            border-radius: $global-radial;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 2px solid #ffdb89;
        border-bottom: 3px solid #c79333;
        border-radius: $global-radial;
    }
}

.btn--secondary--static {
    background: $color-secondary;
    color: $color-primary;
    border-color: transparent;
    z-index: 1;

    &:hover,
    &:focus {
        background-color: color($color-secondary lightness(+5%));
        color: $color-primary;

        &:after {
            border-bottom-color: #d19528;
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 3px solid #c79333;
        transition: 0.15s ease all;
    }
}

.btn--neutral {
    background: color($color-bg blackness(+10%));
    color: $color-text;
    &:hover,
    &:focus {
        background: color($color-bg blackness(+30%));
        color: $color-text;
    }
}

.btn--neutral--outline {
    background: transparent;
    box-shadow: 0 0 0 1px color($color-dark a(0.1)) inset;
    color: color($color-text a(0.9));

    &:hover,
    &:focus {
        box-shadow: 0 0 0 1px color($color-dark a(0.3)) inset;
        color: color($color-dark a(1));
    }
}

.btn--warning {
    border: 1px solid $color-warning;
    color: $color-warning;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);

    &:hover,
    &:focus {
        background: color($color-warning a(0.2));
        color: $color-warning;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0px);
    }
}

.btn--success {
    border: 1px solid $color-success;
    color: $color-success;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);

    &:hover,
    &:focus {
        background: color($color-success a(0.2));
        color: $color-success;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0px);
    }
}

.btn--ghost {
    background-color: transparent;
    color: $color-primary;
    &:hover,
    &:focus {
        background-color: color($color-primary a(0.1));
        color: color($color-primary blackness(+10%));
    }
}

.btn--ghost--secondary {
    background-color: transparent;
    color: $color-secondary;
    &:hover,
    &:focus {
        color: color($color-secondary blackness(+10%));
    }
}

.btn--ghost--bordered {
    background-color: transparent;
    color: $color-text;
    border-color: $color-text;
    box-shadow: 0 0 0 1px rgb(210, 210, 210) inset;
    z-index: 0;

    &:hover,
    &:focus {
        &:after {
            border-bottom: 3px solid rgb(173, 173, 173);
        }
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 3px solid rgb(210, 210, 210);
        transition: 0.15s ease all;
    }
}

.btn--subscription--right {
    position: relative;
    left: -1px;
    border-radius: 0 $global-radial $global-radial 0;

    &:after {
        border-radius: $global-radial 0;
    }
}

.btn--subscription--left {
    position: relative;
    left: 1px;
    border-radius: $global-radial 0 0 $global-radial;

    &:after {
        border-radius: 0 $global-radial;
    }
}

.btn--ghost--text {
    background-color: transparent;
    color: $color-text;
    &:hover,
    &:focus {
        color: $color-dark;
    }
}

.btn--ghost--negative {
    background-color: transparent;
    color: white;
    &:hover,
    &:focus {
        color: color(white a(0.7));
    }
}

.btn--bordered {
    background-color: transparent;
    color: $color-secondary;
    border: 1px solid $color-secondary;

    &:hover,
    &:focus {
        background-color: $color-secondary;
        color: $color-primary;
        transform: translateY(-2px);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
    }

    &:active {
        color: $color-primary;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
        transform: translateY(0px);
    }
}

/*
* Andrej will finish this :)
*/

.btn--active {
    background-color: $color-primary;
}

/**
 * Disabled
 */
.btn.is-disabled,
.btn:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &:hover,
    &:focus {
        color: inherit;
    }
}

&.is-disabled {
    pointer-events: none;
}

/**
 * Button sizes
 */

.btn--sml {
    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 7);
    padding: 0 calc($unit * 5);
}

.btn--sml--inline {
    padding: 0 calc($unit * 3);
}

.btn--med {
    @mixin type-scale $type-small, 5;
    height: calc($unit * 9);
    padding: 0 calc($unit * 6);
}
.btn--lrg {
    @mixin type-scale $type-small, 5;
    height: calc($unit * 9);
    padding: 0 calc($unit * 8);

    @media (--screen-from-med) {
        height: calc($unit * 14);
        padding: 0 calc($unit * 9);
    }
}

.btn--longer {
    padding: 0 calc($unit * 9);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 15);
    }
}

.btn--xlonger {
    padding: 0 calc($unit * 12);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 20);
    }
}

/**
* Special
*/

.btn--with-icon {
    svg {
        margin-right: calc($unit * 3);
        min-width: 24px;
    }
}

.btn--nopadding {
    padding-left: 0;
    padding-right: 0;
}

.btn--no-text-align {
    text-align: left;
}

.btn--tooltip {
    padding: 0 calc($unit * 2);
    top: 3px;

    svg {
        path {
            fill: color($color-primary a(0.3));
        }
    }
}

.btn--tooltip-white {
    padding: 0 calc($unit * 2);

    svg {
        path {
            fill: color(white a(0.6));
        }
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(70, 35, 109, 0.8);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
}

.btn--tooltip-animated {
    border-radius: $global-rounded;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: shadow-pulse 2s infinite;
    background: color($color-primary a(0.6));
    fill: white;

    &:hover,
    &:focus {
        animation: notexistent;
    }
}

.btn--cta {
    font-weight: $type-weight-bold;

    @media (--screen-from-med) {
        @mixin type-scale $type-base, 5;
    }
}

.btn--toggleable {
    &:hover,
    &:focus {
        svg path {
            stroke: color($color-primary a(1));
        }
    }
}

.btn--toggleable svg path {
    stroke: color($color-primary a(0.2));
    transition: all 0.15s ease-out;
}

.btn--toggleable--active svg path {
    stroke: color($color-primary a(1));
}

.btn--full {
    width: 100%;
    display: flex;
    justify-content: center;
}

.btn--valuation {
    background: color($color-success);
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: $type-weight-bold;

    &:hover,
    &:focus {
        transform: translateY(-2px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    }
}

.btn--valuation-back {
    background: color(white a(0.3));
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: $type-weight-bold;

    &:hover,
    &:focus {
        transform: translateY(-2px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    }
}

.btn--rounded {
    border-radius: $global-rounded;
    color: white;
    font-weight: $type-weight-bold;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 100%
    );

    border-top: 1px color(white a(0.2)) solid;

    &:hover,
    &:focus {
        background: rgba(255, 255, 255, 0.1);
    }
}

.btn--rounded--selected {
    color: $color-primary;
    background: $color-secondary;
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25),
        inset 0px 3px 0px rgba(255, 255, 255, 0.25);

    &:hover,
    &:focus {
        background: $color-secondary;
        box-shadow: inset 0px -4px 0px rgba(0, 0, 0, 0.5),
            inset 0px 3px 0px rgba(255, 255, 255, 0.25);
    }
}

.btn--rounded--iconOnly {
    border-radius: $global-rounded;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    width: calc($unit * 10) !important;
    height: calc($unit * 10);

    &:after {
        display: none;
    }
}
