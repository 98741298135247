/*------------------------------------*\
    # component.notifications
\*------------------------------------*/

.notifications {
    z-index: 3;
    position: absolute;
    top: 76px;
    right: 0;
    background: white;
    width: 400px;
    border-radius: $global-radial;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s ease-out;

    @media (max-width: 1100px) {
        position: fixed;
        left: 0;
        width: 100%;
        top: 68px;
        padding-bottom: 60px;
    }

    @media (max-width: 1100px) {
        max-height: calc(100vh - 68px);
        overflow-y: auto;
    }
}

.notifications.is-active {
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;

    .notifications__empty__icon {
        transform: scale(1);
        opacity: 1;
    }
    .notifications__empty__text {
        transform: translateY(0px);
        opacity: 1;
    }
}

.notifications__header {
    padding: calc($unit * 4);
}

.notifications__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 16px;

    &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.notifications__item--unseen {
    .notifications__button {
        font-weight: $type-weight-bold;
        color: $color-text-dark;
    }
}

.notifications__button {
    display: block;
    text-align: left;
    margin: 0;

    border-radius: 0;
    padding: calc($unit * 2) calc($unit * 4);
    color: $color-text;
    width: 100%;
    white-space: initial;

    font-size: 13px;
    line-height: 18px;

    &:hover,
    &:focus {
        background: color($color-text a(0.1));
    }
}

.notifications__footer {
    display: flex;
    justify-content: space-between;
    text-align: right;
    padding: calc($unit * 1);
}

.notifications__timestamp {
    font-size: 12px;
    line-height: 16px;
    color: color($color-text a(0.6));
    margin-top: $unit;
}

.notifications__empty {
    padding: calc($unit * 2) calc($unit * 4);
}

.notifications__empty__icon {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.5s ease-out;
}

.notifications__empty__text {
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.5s ease-out;
}
