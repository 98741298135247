/*------------------------------------*\
    # component.redacted-card
\*------------------------------------*/

.redacted-card {
    margin-bottom: lost-vars('gutter');

    @media (--screen-from-med) {
        lost-column: 6/12 2;
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12 3;
    }

    @media (--screen-from-xlrg) {
        lost-column: 3/12 4;
    }
}

.redacted-card.is-locked {
    filter: grayscale(100);
}

.redacted-card__body {
    position: relative;
    height: 300px;
    display: flex;
    align-items: flex-end;

    background: $color-secondary;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;

    transition: all 0.2s ease-out;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1), 0 42px 64px rgba(0, 0, 0, 0.1);

    @media (--screen-to-med) {
        height: 200px;
    }

    &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(color(black a(0)), color(black a(0.4)));
        border-radius: 20px;
    }
}

.redacted-card__meta {
    position: relative;
    z-index: 1;
    flex: 1;
    margin-left: calc($unit * 7);
    padding: calc($unit * 3) calc($unit * 4) calc($unit * 4) 0;

    border-top: 1px dashed color(white a(0.5));
    color: white;
}

.redacted-card__meta__offer-status {
    width: 100%;
    margin: calc($unit * 1) 0;
    display: flex;
    align-items: center;
    opacity: 0.7;

    svg {
        background-color: transparent;
        animation: pulse 2s infinite ease-out;
        border-radius: $global-rounded;
        margin-right: calc($unit * 1);

        path {
            fill: white;
        }
    }
}

.redacted-card__monetization {
    position: absolute;
    top: calc($unit * 5);
    left: calc($unit * 5);
    background: $color-success;
    padding: 2px 6px;
    color: white;
    border-radius: 4px;
}

.redacted-card__countdown {
    position: absolute;
    top: calc($unit * 12);
    left: calc($unit * 5);
    background: rgba(0, 0, 0, 0.5);
    padding: 2px 6px;
    color: white;
    border-radius: 4px;
    transition: all 0.1s;

    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 1);
    }
}

.redacted-card__meta-title svg {
    margin-right: calc($unit * 2);
}

.redacted-card__meta-title svg path {
    fill: white;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}
