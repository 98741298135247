/*------------------------------------*\
    # utils.lists
\*------------------------------------*/

.l {
}

.l-bordered {
    & > li {
        padding: calc($unit * 3) 0;
        border-bottom: 1px solid color($color-dark a(0.2));
    }
}

.l-split {
    lost-flex-container: row;

    & > li {
        lost-column: 1/2;
    }
}

.l-triple {
    lost-flex-container: row;

    & > li {
        lost-column: 1/2;

        @media (--screen-from-lrg) {
            lost-column: 1/3;
        }
    }
}

.l-inside {
    list-style-position: inside;
}

.l-outside {
    list-style-position: outside;
}

.l-checked {
}

.l-checked li {
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;

        top: 3px;
        left: -16px;
        transform: rotate(45deg);

        height: 11px;
        width: 5px;
        border-bottom: 1px solid currentColor;
        border-right: 1px solid currentColor;
    }

    /* Checkmarks are inside on smaller screens */
    @media (--screen-to-med) {
        padding-left: 1em;

        &:before {
            left: 0;
        }
    }
}

.l-checked--inside li {
    padding-left: 2em;

    &:before {
        left: 0;
    }
}

.l-checked--negative li {
    &:before {
        border-bottom: 1px solid white;
        border-right: 1px solid white;
    }
}

.l-checked--success li {
    &:before {
        border-bottom: 1px solid $color-success;
        border-right: 1px solid $color-success;
    }
}

.l-numeratedcircles {
    list-style: none;
}

.l-numeratedcircles li {
    position: relative;
    padding-bottom: calc($unit * 5);
    padding-left: calc($unit * 15);
    counter-increment: list;

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 22px;
        width: 1px;
        height: 100%;
        background: color($color-dark a(0.1));
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 44px;
        height: 44px;
        line-height: 38px;
        font-size: 14px;

        content: counter(list);

        text-align: center;

        border: 4px solid $color-bg;
        box-shadow: 0 0 0 1px color($color-dark a(0.2)) inset;
        background: $color-bg;
        border-radius: 50%;
    }

    &:last-child {
        &:before {
            display: none;
        }
    }
}

.l-prices {
}

.l-prices li {
    display: flex;
    padding: calc($unit * 2) 0;
    border-bottom: 1px solid color($color-dark a(0.1));
}

.l-prices li span {
    margin-right: calc($unit * 4);

    &:nth-child(1) {
        flex: 1 0 15%;
    }
    &:nth-child(2) {
        flex: 1 0 40%;
    }
    &:nth-child(3) {
        flex: 1 0 45%;
    }
}

.l-special {
    li {
        position: relative;

        &:before {
            content: '';
            opacity: 0.5;
            background-image: url('../assets/icons/icon-star.svg');
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            height: 16px;
            width: 16px;
        }
    }
}

.l-bullet {
    list-style-type: disc;
    list-style-position: inside;
}

.l-bullet--outside {
    list-style-type: disc;
    list-style-position: outside;
}
