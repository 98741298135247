/*------------------------------------*\
    # plugins.toastify
\*------------------------------------*/

.Toastify {
}

.Toastify__toast {
    display: flex;
    border-radius: $global-radial !important;
    padding: calc($unit * 2) calc($unit * 4) !important;
    align-items: center;
    margin-bottom: calc($unit * 1);
    min-height: unset !important;
}

.Toastify__toast--success {
    background: $color-success;
}

.Toastify__toast--warning {
    background: $color-note;
}

.Toastify__toast--error {
    background: $color-warning;
}

.Toastify__close-button {
    align-self: center !important;
}
