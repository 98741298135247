/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.s {
    &-top {
        &--tny {
            margin-top: calc($unit * 1);
        }

        &--sml {
            margin-top: calc($unit * 2);
        }

        &--med {
            margin-top: calc($unit * 4);
        }

        &--lrg {
            margin-top: calc($unit * 6);
        }

        &--xlrg {
            margin-top: calc($unit * 8);
        }

        &--xxlrg {
            margin-top: calc($unit * 10);
        }

        &--xxxlrg {
            margin-top: calc($unit * 10);

            @media (--screen-from-sml) {
                margin-top: calc($unit * 15);
            }
        }
    }

    &-right {
        &--tny {
            margin-right: calc($unit * 1);
        }

        &--sml {
            margin-right: calc($unit * 2);
        }

        &--med {
            margin-right: calc($unit * 4);
        }

        &--lrg {
            margin-right: calc($unit * 6);
        }

        &--xlrg {
            margin-right: calc($unit * 8);
        }

        &--xxlrg {
            margin-right: calc($unit * 10);
        }

        &--xxxlrg {
            margin-right: calc($unit * 10);

            @media (--screen-from-sml) {
                margin-right: calc($unit * 15);
            }
        }
    }

    &-bottom {
        &--tny {
            margin-bottom: calc($unit * 1);
        }

        &--sml {
            margin-bottom: calc($unit * 2);
        }

        &--med {
            margin-bottom: calc($unit * 4);
        }

        &--lrg {
            margin-bottom: calc($unit * 6);
        }

        &--xlrg {
            margin-bottom: calc($unit * 8);
        }

        &--xxlrg {
            margin-bottom: calc($unit * 10);
        }

        &--xxxlrg {
            margin-bottom: calc($unit * 10);

            @media (--screen-from-sml) {
                margin-bottom: calc($unit * 15);
            }
        }
    }

    &-left {
        &--tny {
            margin-left: calc($unit * 1);
        }

        &--sml {
            margin-left: calc($unit * 2);
        }

        &--med {
            margin-left: calc($unit * 4);
        }

        &--lrg {
            margin-left: calc($unit * 6);
        }

        &--xlrg {
            margin-left: calc($unit * 8);
        }

        &--xxlrg {
            margin-left: calc($unit * 10);
        }

        &--xxxlrg {
            margin-left: calc($unit * 10);

            @media (--screen-from-sml) {
                margin-left: calc($unit * 15);
            }
        }
    }
}

.p {
    &-all {
        &--tny {
            padding: calc($unit * 1);
        }

        &--sml {
            padding: calc($unit * 2);
        }

        &--med {
            padding: calc($unit * 4);
        }

        &--lrg {
            padding: calc($unit * 6);
        }

        &--xlrg {
            padding: calc($unit * 8);
        }

        &--xxlrg {
            padding: calc($unit * 10);
        }

        &--xxxlrg {
            padding: calc($unit * 10);

            @media (--screen-from-sml) {
                padding: calc($unit * 15);
            }
        }
    }

    &-top {
        &--tny {
            padding-top: calc($unit * 1);
        }

        &--sml {
            padding-top: calc($unit * 2);
        }

        &--med {
            padding-top: calc($unit * 4);
        }

        &--lrg {
            padding-top: calc($unit * 6);
        }

        &--xlrg {
            padding-top: calc($unit * 8);
        }

        &--xxlrg {
            padding-top: calc($unit * 10);
        }

        &--xxxlrg {
            padding-top: calc($unit * 10);

            @media (--screen-from-sml) {
                padding-top: calc($unit * 15);
            }
        }
    }

    &-right {
        &--tny {
            padding-right: calc($unit * 1);
        }

        &--sml {
            padding-right: calc($unit * 2);
        }

        &--med {
            padding-right: calc($unit * 4);
        }

        &--lrg {
            padding-right: calc($unit * 6);
        }

        &--xlrg {
            padding-right: calc($unit * 8);
        }

        &--xxlrg {
            padding-right: calc($unit * 10);
        }

        &--xxxlrg {
            padding-right: calc($unit * 10);

            @media (--screen-from-sml) {
                padding-right: calc($unit * 15);
            }
        }
    }

    &-bottom {
        &--tny {
            padding-bottom: calc($unit * 1);
        }

        &--sml {
            padding-bottom: calc($unit * 2);
        }

        &--med {
            padding-bottom: calc($unit * 4);
        }

        &--lrg {
            padding-bottom: calc($unit * 6);
        }

        &--xlrg {
            padding-bottom: calc($unit * 8);
        }

        &--xxlrg {
            padding-bottom: calc($unit * 10);
        }

        &--xxxlrg {
            padding-bottom: calc($unit * 10);

            @media (--screen-from-sml) {
                padding-bottom: calc($unit * 15);
            }
        }
    }

    &-left {
        &--tny {
            padding-left: calc($unit * 1);
        }

        &--sml {
            padding-left: calc($unit * 2);
        }

        &--med {
            padding-left: calc($unit * 4);
        }

        &--lrg {
            padding-left: calc($unit * 6);
        }

        &--xlrg {
            padding-left: calc($unit * 8);
        }

        &--xxlrg {
            padding-left: calc($unit * 10);
        }

        &--xxxlrg {
            padding-left: calc($unit * 10);

            @media (--screen-from-sml) {
                padding-left: calc($unit * 15);
            }
        }
    }
}
