/*------------------------------------*\
    # component.earnings-potential
\*------------------------------------*/

.required-workload {
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1);

    background: $color-success;
    padding: 8px 16px;
    margin: 20px 0;
    border-radius: $global-rounded;

    color: white;
    font-weight: bold;
}

.required-workload__icon {
    width: 12px;
    height: 12px;
    padding-right: 18px;
}

.required-workload--high {
    color: white;

    background: linear-gradient(
        180deg,
        #d84a4a 0%,
        color(#d84a4a lightness(-20%)) 100%
    );
}

.required-workload--medium {
    background: linear-gradient(
        180deg,
        #fec45c 0%,
        color(#fec45c lightness(-15%)) 100%
    );
}

.required-workload--low {
    background: linear-gradient(
        180deg,
        #29af00 0%,
        color(#29af00 lightness(-10%)) 100%
    );
}
