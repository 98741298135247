/*------------------------------------*\
    # component.specialbox
\*------------------------------------*/

.specialbox {
    display: flex;

    padding: calc($unit * 7) calc($unit * 5);
    border-radius: 10px;
}

.specialbox__icon {
    min-width: 48px;
}

.specialbox--success {
    background: color($color-success a(0.15));
}

.specialbox--warning {
    background: color($color-warning a(0.15));
}

.specialbox--note {
    background: color($color-note a(0.15));
    border: 1px solid color($color-note a(0.5));
}
