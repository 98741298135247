/*------------------------------------*\
    # component.user-profile
\*------------------------------------*/

.user-profile {
    display: flex;
    align-items: center;
}

.user-profile__description {
    padding-left: calc($unit * 9);
}

.user-profile__img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}
