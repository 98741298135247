/*------------------------------------*\
    # view.home-testimonials
\*------------------------------------*/

.v-home-testimonials {
    padding: calc($unit * 10) 0;
    overflow: hidden;
    background-color: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.v-home-testimonials .wrapper {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-testimonials__list {
    position: relative;
}

.v-home-testimonials__heading {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 3/12;
    }
}

.v-home-testimonials__list {
    @media (--screen-from-lrg) {
        lost-column: 6/12;
        lost-offset: 1/12;
    }
}

.v-home-testimonials__item {
    display: flex;
    padding-bottom: calc($unit * 8);
}

.v-home-testimonials__item__content {
    margin-left: calc($unit * 8);
}

.v-home-testimonials__item__photo {
    width: 48px;
    height: 48px;
    border-radius: $global-rounded;

    @media (--screen-from-lrg) {
        width: 70px;
        height: 70px;
    }
}
