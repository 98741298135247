/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer {
    background: white;
    z-index: 0;
    padding: calc($unit * 10) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.footer__wrapper {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.footer__branding {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 12);
    }

    @media (--screen-from-lrg) {
        lost-column: 3/12;
    }
}

.footer__branding img,
.footer__branding svg {
    width: 16px;
    height: 16px;
}

.footer__social {
    margin-top: calc($unit * 4);
    margin-left: calc(-$unit * 2);
}

.footer__social a {
    &:hover {
        svg {
            path {
                transition: all 0.15s ease-out;
                fill: #fec45c;
            }
        }
    }
}

.footer__social__link {
    padding: calc($unit * 2);
}

.footer__links {
    display: flex;
    flex-wrap: wrap;

    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 8);
        max-width: 400px;
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12;
        lost-offset: 1/12;
    }
}

.footer__links li {
    flex: 1 1 50%;

    &:last-child {
        flex: initial;
        width: 50%;
    }
}

.footer__link {
    display: block;
    padding: calc($unit * 1) 0;
    color: $color-text;
}

.footer__copy {
    @media (--screen-from-lrg) {
        lost-column: 3/12;
        lost-offset: 1/12;
    }
}
