/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.bg {
}

.bg--blue {
    background: $color-bg;
}

.bg--white {
    background: white;
}
