/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;
    display: inline-flex;
    width: 100%;

    -webkit-border-radius: 0; /* Prefixed to remove border radius on iOS */
    -webkit-appearance: none;

    font-variant-numeric: lining-nums;
    -moz-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum';

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;

    @mixin type-scale $type-base, 8;
    color: $color-text;
    background: #fcfcfc;

    border: 1px solid color($color-dark a(0.12));
    box-shadow: 0 4px 0 color($color-dark a(0.02)) inset;
    border-radius: $global-radial;
    transition: background 0.15s ease-out;

    &::placeholder {
        color: color($color-text a(0.5));
    }

    &:hover {
        background: color($color-text a(0.08));
    }

    &:focus {
        outline: 0;
        background: color($color-text a(0.08));
        border-color: $color-primary;
    }
}

/**
 * input sizes
 */

.input--sml {
    @mixin type-scale $type-small, 5;
    height: calc($unit * 8);
    padding: 0 calc($unit * 4);

    &.input--with-prefix {
        padding-left: calc($unit * 5);
    }
}

.input--med {
    @mixin type-scale $type-small, 5;
    height: calc($unit * 9);
    padding: 0 calc($unit * 4);

    &.input--with-prefix {
        padding-left: calc($unit * 7);
    }
}

.input--lrg {
    @mixin type-scale $type-small, 5;
    height: calc($unit * 9);
    padding: 0 calc($unit * 4);

    @media (--screen-from-med) {
        padding: 0 calc($unit * 6);
    }

    &.input--with-prefix {
        padding-left: calc($unit * 8);
    }
}

/**
 * Input - alternative styles
 */

@keyframes autofill--alt {
    0%,
    100% {
        color: white;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.1) 100%
        );
    }
}

.input--alt {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 100%
    );
    border-top: 1px solid color(white a(0.3));
    border-radius: $global-rounded;
    color: white;
    padding: 0 calc($unit * 6);
    height: calc($unit * 12.5);

    &:-webkit-autofill {
        color: white !important;
        animation-delay: 0.1s;
        animation-name: autofill--alt;
        animation-fill-mode: both;
    }

    &::placeholder {
        color: color(white a(0.5));
    }
}

/**
 * compact
 */
.input--compact {
    max-width: 80px;
}

/**
*   Input Errors
*/

.input--error {
    color: $color-warning;
    border-color: $color-warning;
}

/**
 * input types
 */

.input--select {
    display: block;
    cursor: pointer;

    color: $color-text;
    background: #fcfcfc;

    border: 1px solid color($color-dark a(0.12));
    box-shadow: 0 -4px 0 color($color-dark a(0.02)) inset;
    border-radius: $global-radial;
    transition: background-color 0.15s ease-out;

    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'); */
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMjQgMTEuMzA1bC03Ljk5NyAxMS4zOUw4IDExLjMwNXoiLz48L3N2Zz4=');
    background-repeat: no-repeat, repeat;
    background-position: calc(100% - 12px) 50%;
    background-size: 8px;

    appearance: none;

    padding-right: calc($unit * 7);

    &::-ms-expand {
        display: none;
    }

    &:hover {
        background-color: color($color-text a(0.08));
    }

    &:focus {
        outline: 0;
        background-color: color($color-text a(0.08));
        border-color: $color-primary;
    }
}

.input--select option {
    font-weight: normal;
}

.input--dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    min-height: 120px;
    padding: calc($unit * 4) calc($unit * 8);
    border: 1px dashed $color-text;

    white-space: initial;
}

.input--dropzone__file {
    background-color: white;
    border: 1px solid $color-success;
    padding: calc($unit * 2) calc($unit * 4);
    border-radius: $global-radial;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 12px;

    & + & {
        margin: calc($unit * 4);
    }
}

.input--textarea {
    min-height: 160px;
    padding: calc($unit * 2) calc($unit * 4);
    resize: vertical;
    @mixin type-scale $type-small, 5;
    white-space: initial;
}

.input--check,
.input--radio {
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        display: block;
        padding: 0 0 0 calc($unit * 6);

        font-size: 14px;

        &:before,
        &:after {
            content: '';
            display: block;

            position: absolute;
            transition: all 0.2s ease-in-out;
            background: white;
        }

        &:before {
            width: 16px;
            height: 16px;

            top: 0;
            left: 0;

            border: 1px solid color($color-dark a(0.4));
            border-radius: 3px;
        }

        &:after {
            width: 10px;
            height: 10px;
            border-radius: 2px;

            top: 3px;
            left: 3px;
        }
    }

    &:focus {
        & + label,
        & + label {
            &:before {
                box-shadow: 0 0 0 1px $color-primary;
            }
        }
    }

    &:checked {
        & + label {
            &:after {
                background: $color-primary;
            }
        }
    }
}

.input--radio {
    & + label {
        &:before,
        &:after {
            border-radius: 1000px;
        }
    }
}

.input--radio--alternate {
    & + label {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        &:before {
            width: 13px;
            height: 13px;
            border-radius: 3px;
        }
        &:after {
            width: 7px;
            height: 7px;
            border-radius: 2px;
        }

        @media (--screen-to-sml) {
            font-size: 10px;
            padding-top: 4px;
        }
    }
}

.input__prefix {
    opacity: 0.7;
    @mixin type-scale $type-small, 5;
    height: calc($unit * 9);
    display: flex;
    align-items: center;
}

.input--title {
    @mixin type-scale $type-base, 5;
    font-weight: $type-weight-bold;
    color: $color-primary;
}

.input--switch {
    opacity: 0;
    border-radius: $global-rounded;

    &:checked + label {
        &:after {
            background: color($color-warning a(0.3));
        }

        &:before {
            right: -4px;
            background: $color-warning;
        }
    }

    & + label {
        position: relative;
        display: block;
        @mixin type-scale $type-small, 4;
        padding-right: calc($unit * 13);

        &:after {
            position: absolute;
            top: 0;
            right: 0;

            content: '';
            display: block;
            height: 16px;
            width: 36px;
            background: #dddddd;
            border-radius: 100px;
        }

        &:before {
            position: absolute;
            top: -2px;
            right: 18px;
            z-index: 1;

            transition: all 0.2s ease-out;

            content: '';
            display: block;
            height: 20px;
            width: 20px;
            background: #7d7d7d;
            border-radius: 100px;
        }
    }
}
