/*------------------------------------*\
    # view.home-keyfeatures
\*------------------------------------*/

.v-home-keyfeatures {
    padding: calc($unit * 10) 0;
    padding-bottom: 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 15) calc($unit * 20);
        padding-bottom: 0;
    }
}

.v-home-keyfeatures__heading {
    margin-bottom: calc($unit * 20);

    @media (--screen-from-med) {
        lost-column: 8/12;
        margin-bottom: calc($unit * 20);
    }
}

.v-home-keyfeatures__heading__full {
    margin-bottom: calc($unit * 20);

    @media (--screen-from-med) {
        margin-bottom: calc($unit * 20);
    }
}

.v-home-keyfeatures__list {
    display: flex;
    flex-direction: column;
    gap: calc($unit * 6);
    margin-left: calc($unit * 4);
}

.v-home-keyfeatures__grid {
    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.v-home-keyfeatures__grid__item {
    position: relative;
    background: white;
    border-radius: 16px;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);
    padding: calc($unit * 17) calc($unit * 9) calc($unit * 17) calc($unit * 9);

    @media (--screen-to-med) {
        margin-bottom: calc($unit * 15);
    }

    @media (--screen-from-med) {
        margin-bottom: calc($unit * 20);
        lost-column: 2/6 3;
    }
}

.v-home-keyfeatures__grid__item img {
    position: absolute;
    top: -44px;
}
