/*------------------------------------*\
    # view.home-steps
\*------------------------------------*/

.v-home-steps {
    margin: calc($unit * 20) 0;
}

.v-home-steps__wrapper {
    max-width: 870px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 calc($unit * 6);
}

.v-home-steps__item--odd {
    @media (--screen-from-med) {
        flex-direction: row-reverse;
    }
}

.v-home-steps__col {
    @media (--screen-from-med) {
        flex: 1 1 50%;
    }
}

.v-home-steps__item-wrapper {
    position: relative;
    padding-top: calc($unit * 4);
    padding-bottom: calc($unit * 10);
    padding-left: calc($unit * 5);

    @media (--screen-from-med) {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    &::after {
        content: '';
        position: absolute;
        height: calc(100% - 80px);
        width: 1px;
        top: 70px;
        background-color: #191919;
        opacity: 0.2;

        @media (--screen-to-med) {
            height: calc(100% - 70px);
            top: 60px;
            left: -26px;
        }
    }
}

.v-home-steps__item {
    display: flex;

    @media (--screen-from-med) {
        min-height: 135px;
    }

    &:last-child {
        & .v-home-steps__item-wrapper {
            &::after {
                display: none;
            }
        }
    }
}

.v-home-steps__item-wrapper--even {
    @media (--screen-from-med) {
        padding-left: calc($unit * 17);

        &::after {
            left: 0;
        }
    }
}

.v-home-steps__item-wrapper--odd {
    @media (--screen-from-med) {
        text-align: right;
        padding-right: calc($unit * 17);

        &:after {
            right: 0;
        }
    }
}

.v-home-steps__number {
    z-index: 1;
    position: relative;
    background-color: $color-primary;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $global-rounded;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.2);

    @media (--screen-from-med) {
        width: 60px;
        height: 60px;
    }

    &::after {
        z-index: 2;
        content: '';
        position: absolute;
        top: -4px;
        left: 6px;
        width: 100%;
        height: 100%;
        border-radius: $global-rounded;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.3) -20.83%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}

.v-home-steps__number--even {
    @media (--screen-from-med) {
        margin-right: 0;
        margin-left: auto;
        transform: translate3d(50%, 0, 0);
    }
}

.v-home-steps__number--odd {
    @media (--screen-from-med) {
        transform: translate3d(-50%, 0, 0);
    }
}
