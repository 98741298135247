/*------------------------------------*\
    # component.tooltip
\*------------------------------------*/

.tooltip {
    max-width: 200px;

    @media (--screen-from-sml) {
        max-width: 300px;
    }

    @media (--screen-from-med) {
        max-width: 400px;
    }
}
