/*------------------------------------*\
    # view.checkout
\*------------------------------------*/

.v-checkout {
}

.v-checkout__layout {
    @media (--screen-to-lrg) {
        padding: calc($unit * 10) 0;
    }

    position: relative;
    overflow: hidden;
}

.v-checkout__layout .wrapper {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
        min-height: 70vh;
    }
}

.v-checkout__layout__content {
    @media (--screen-from-lrg) {
        lost-column: 5/12;
        padding: calc($unit * 25) 0 calc($unit * 15) 0;
    }
}

.v-checkout__layout__visual {
    @media (--screen-from-lrg) {
        background: $color-primary;
        background-image: url(../assets/images/pattern-1.svg);
        background-blend-mode: overlay;
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
    }
}

.v-checkout__layout__visual--alt {
    @media (--screen-from-lrg) {
        background-color: $color-secondary;
    }
}

.v-checkout__fancybox {
    position: relative;
    padding: calc($unit * 6);
    border-radius: 20px;

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 4);
        border: 2px solid $color-primary;
        background: white;
    }

    @media (--screen-from-lrg) {
        background: $color-bg;
        padding: calc($unit * 12) calc($unit * 12) calc($unit * 12) 0;
        border-radius: 0 20px 20px 0;
        margin-top: calc($unit * 25);
        max-width: 420px;
    }
}

.v-checkout__fancybox__heading {
    margin-bottom: calc($unit * 20);
    max-width: 80%;
}

.v-checkout__fancybox__content {
    margin-bottom: calc($unit * 10);
}

.v-checkout__fancybox__action {
}

.v-checkout__fancybox__deco {
    position: absolute;
    top: calc($unit * -4);
    right: calc($unit * -4);
    width: 64px;
    height: 68px;
    background: url(../assets/icons/deco-key.svg);

    @media (--screen-from-lrg) {
        top: calc($unit * 6);
        right: calc($unit * -8);
    }
}

.v-checkout__fancybox__image {
    position: absolute;
    top: calc($unit * 5);
    right: calc($unit * 5);

    width: 72px;
    height: 72px;
    border-radius: 12px;

    background-position: center;
    background-size: cover;
}

.v-checkout__fancybox__offer {
    background: $color-warning;
    color: white;
    border-radius: $global-radial;
    padding: 10px 20px;
    display: inline-block;
}

.v-checkout__upsell {
    margin-top: calc($unit * 10);
    background-color: color($color-secondary a(0.5));
    border-radius: $global-radial;
    padding: calc($unit * 5);
}

.v-checkout__emphasized {
    border: 1px solid color($color-primary a(0.5));
    padding: calc($unit * 4) calc($unit * 6);
    border-radius: $global-radial;
    background: color(white a(0.8));
}
