/*------------------------------------*\
    # view.partners
\*------------------------------------*/

.v-partners {
    padding-bottom: calc($unit * 40); /* Push footer */
}

.v-partners__heading {
    z-index: 0;
    position: relative;
    padding: calc($unit * 40) 0 calc($unit * 20) 0;

    border-radius: 0 0 20px 20px;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;

    @media (--screen-from-lrg) {
        padding: calc($unit * 40) 0;
    }
}

.v-partners__heading__wrapper {
    max-width: 850px;
}

.v-partners__footer {
    padding: calc($unit * 15) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.v-partners__footer-outro {
    margin: calc($unit * 5) auto 0 auto;
    max-width: 600px;
}

.v-partners__footer-button {
    margin: calc($unit * 5) auto 0 auto;
}

.v-partners__intro {
    margin: calc($unit * 15) auto 0 auto;
    max-width: 600px;
}

.v-partners__gridbox {
    position: relative;
    z-index: 1;
    margin-top: -32px;

    @media (--screen-from-med) {
        margin-top: -72px;
    }
}

.v-partners__gridsection {
    margin-bottom: calc($unit * 10);
    border-radius: 20px;
    background: white;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);
}

.v-partners__grid {
    lost-flex-container: row;
}

.v-partners__grid-deafult-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 60px;

    @media (--screen-to-lrg) {
        margin: 0 auto;
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2 0 0;
    }
}

.v-partners__grid-deafult-item__text {
    padding: calc($unit * 10) 0 0 0;
    max-width: 150px;
}
