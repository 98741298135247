/*------------------------------------*\
    # component.message-details
\*------------------------------------*/

.message-details {
    position: relative;
    background: rgba(0, 0, 0, 0.03);
    border-radius: $global-radial;

    & + & {
        margin-top: calc($unit * 4);
    }

    &:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        width: 0px;
        height: 0px;
    }
}

.message-details__wrapper {
    max-height: 1000px;
    overflow-y: auto;
    padding-bottom: calc($unit * 20);
}

.message-details__content {
    @mixin type-scale $type-small, 5;
    padding: calc($unit * 3);
}

.message-details--mine {
    background-color: white;
    margin-left: 50px;
    border-radius: 16px 0px 16px 16px;

    @media (--screen-to-lrg) {
        background: white;
    }

    /* &:after {
        right: -16px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 16px solid #f6f8fc;
    } */

    .message-details__header {
        border-radius: 16px 0px 0px 0px;
    }
}

.message-details--theirs {
    background-color: color($color-primary a(0.05));
    margin-right: 50px;
    border-radius: 0px 16px 16px 16px;

    &:after {
        left: -16px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-right: 16px solid #f6f8fc;
    }

    .message-details__header {
        border-radius: 0px 16px 0px 0px;
    }
}

.message-details--agent {
    background-color: $color-secondary;
    color: $color-text-dark;
}

.message-details__timestamp {
    color: $color-text;
    margin-left: calc($unit * 2);
}

.message-details__header {
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.05);
    padding: calc($unit * 1) calc($unit * 3);
}
.message-details__header__agent {
    display: flex;
    align-items: center;
}
