/*------------------------------------*\
    # view.listingdetails
\*------------------------------------*/

.v-listingdetails {
    z-index: 0;
    position: relative;

    &:before {
        content: '';
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 650px;
        background-repeat: repeat;
        background-image: url(../assets/images/pattern-2.svg);
        background-size: 20%;
        background-position: right top;
        opacity: 0.25;

        @media (--screen-to-med) {
            height: 500px;
        }
    }

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: calc(-6 * $unit);
        left: 0;
        width: 100%;
        height: 500px;
        background: linear-gradient(
            180deg,
            color($color-bg a(0)) 50%,
            $color-bg 100%
        );
        opacity: 1;
    }
}

.v-listingdetails__notice {
    padding-top: calc(6 * $unit);
}

.v-listingdetails__btn {
    background-color: transparent;
    width: 0;
    overflow: visible;
    height: 0;
    padding: 1px;
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 0;
}

.v-listingdetails__icon {
    position: relative;
    z-index: 1;

    path {
        fill: #9590a0;
    }
}

.v-listingdetails__meta {
    position: relative;

    @media (--screen-from-sml) {
        display: flex;
        align-items: center;
    }
}

.v-listingdetails__meta svg {
    @media (--screen-from-sml) {
        align-self: center;
    }
}

.v-listingdetails__meta__status {
    margin-right: calc($unit * 6);
}

.v-listingdetails__meta__url {
    margin-right: calc($unit * 6);
}

.v-listingdetails__meta__niche svg {
    margin-top: 6px;
}

.v-listing-details__question-section {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 calc($unit * 16) 0 calc($unit * 82);

    @media (--screen-to-med) {
        padding: 0 calc($unit * 6);
    }

    @media (--screen-from-med) and (--screen-to-lrg) {
        padding: 0 calc($unit * 12);
    }
}

.v-listing-details__switch {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-success;
    border-radius: $global-rounded;
}

.v-listing-details__switch-item {
    padding: 0 4px;
    padding: 6px 16px;
    border-radius: $global-rounded;
    @mixin type-scale $type-small, 4;
    color: color($color-text lightness(+20%));

    &:hover,
    &:focus {
        color: color($color-success blackness(+20%));
    }

    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }
}

.v-listing-details__switch-item--active {
    background: color($color-success a(0.2));
    color: color($color-success blackness(+20%));
    border-left: 1px solid $color-success;
    border-right: 1px solid $color-success;
}
