/*------------------------------------*\
    # block.infoblock-tips
\*------------------------------------*/

.infoblock-tips {
}

.infoblock-tips__list {
}

.infoblock-tips__list__item {
    padding-bottom: calc($unit * 4);
    margin-bottom: calc($unit * 4);
    border-bottom: 1px solid color($color-dark a(0.1));

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @media (--screen-from-lrg) {
        lost-flex-container: row;
        padding-bottom: calc($unit * 8);
        margin-bottom: calc($unit * 6);
    }
}

.infoblock-tips__list__item__heading {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 4);
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12;
    }
}

.infoblock-tips__list__item__content {
    @media (--screen-from-lrg) {
        lost-column: 7/12;
    }
}
