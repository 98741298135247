/*------------------------------------*\
    # view.report
\*------------------------------------*/

.v-report {
    background-color: color($color-primary);
    padding: calc($unit * 45) 0 calc($unit * 25) 0;

    position: relative;
    z-index: 0;

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/pattern-1.svg);
        opacity: 0.2;
        background-repeat: repeat-x;
        background-size: 30%;
    }
}

.v-report__header__links {
    margin-top: calc($unit * 10);

    @media (--screen-from-lrg) {
        display: flex;
    }
}

.v-report__link-description {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 5);
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2;
    }
}
.v-report__link {
    height: 58px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $global-rounded;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    padding: 0 calc($unit * 6);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 100%
    );

    @media (--screen-from-lrg) {
        lost-column: 1/2;
        padding: 0 calc($unit * 10);
    }
}

.v-report__score-section {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: calc($unit * 5) 0 0 0;

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-report__score {
    @media (--screen-to-lrg) {
        margin-top: 50px;
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2;
    }
}

.v-report__score__calculation {
    @media (--screen-to-lrg) {
        text-align: center;
    }
}

.v-report__score-section__offer {
    @media (--screen-to-lrg) {
        margin-top: 50px;
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2;
    }
}

.v-report__copy-button {
    padding: calc($unit * 1) 0;
    position: absolute;
    top: 0;
    right: 4px;

    &:hover,
    &:focus {
        transform: translateY(-1px);
    }
}

.v-report__card-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    @media (--screen-from-med) {
        flex-direction: row;
        justify-content: center;
    }
}

.v-report__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 288px;
    min-height: 350px;
    color: color($color-primary);
    background: linear-gradient(180deg, #ffcf78 0%, #fec45c 100%);
    padding: calc($unit * 9);
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1);
    align-self: center;

    & + & {
        @media (--screen-to-lrg) {
            margin-top: calc($unit * 5);
        }
    }

    @media (--screen-from-med) {
        &:last-child {
            top: 25px;
            right: 18px;
            transform: rotate(4deg);
            z-index: 0;
            box-shadow: none;
        }

        &:first-child {
            top: 25px;
            left: 18px;
            transform: rotate(-4deg);
            z-index: 0;
            box-shadow: none;
        }
    }
}

.v-report__footer {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: calc($unit * 10);
    margin-top: calc($unit * 20);

    @media (--screen-from-med) {
        padding-top: calc($unit * 20);
        margin-top: calc($unit * 30);
    }

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-report__footer__offer {
    @media (--screen-to-lrg) {
        margin-top: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2 2 90px;
    }
}

.v-report__criteria {
    @media (--screen-to-lrg) {
        margin-top: calc($unit * 20);
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2 2 90px;
    }
}

.v-report__questions {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 0 0 calc($unit * 5);
}

.v-report__twist {
    border-left: 4px solid $color-secondary;
    padding-left: calc($unit * 4);
}

.v-report-upsell {
    margin-top: calc($unit * 20);
}
