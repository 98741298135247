/*------------------------------------*\
    # view.contentpage
\*------------------------------------*/

.v-contentpage {
}

.v-contentpage__heading {
    position: relative;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    background-size: 40%;
    background-position: right 10%;
    background-repeat: no-repeat;

    border-radius: 0 0 0 20px;
    color: white;

    @media (--screen-to-lrg) {
        padding: calc($unit * 35) 0 calc($unit * 8) 0;
    }

    @media (--screen-from-lrg) {
        padding: calc($unit * 50) 0 calc($unit * 10) 0;
    }
}

.v-contentpage__content {
    padding: 2.5% 0;
    max-width: 600px;
}
