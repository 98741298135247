/*------------------------------------*\
    # block.infoblock-financial
\*------------------------------------*/

.infoblock-financial {
}

.infoblock-financial--empty {
    background: url('../assets/images/BlurredBackgroundListing.webp') no-repeat;
    background-size: contain;
    background-color: white;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    aspect-ratio: 4/3;

    &--message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        gap: calc($unit * 8);
    }
}

.infoblock-financial__sources {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.infoblock-financial__sources__grid {
    lost-flex-container: row;
    width: 100%;
}

.infoblock-financial__sources__item {
    padding-bottom: calc($unit * 5);
    margin-bottom: calc($unit * 6);
    border-bottom: 1px solid color($color-dark a(0.1));

    @media (--screen-to-lrg) {
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2;
        lost-flex-container: row;
    }
}

.infoblock-financial__sources__item__heading,
.infoblock-financial__sources__item__content {
    @media (--screen-from-lrg) {
        lost-column: 1/2;
    }
}

.infoblock-financial__sources__item__content {
    @media (--screen-from-lrg) {
        text-align: right;
    }
}
