/*------------------------------------*\
    # view.newhome-header
\*------------------------------------*/

.newhome-header {
    z-index: 0;
    position: relative;
    padding: calc($unit * 15) 0;

    overflow: hidden;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.06), 0 42px 64px rgba(0, 0, 0, 0.04);
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;
    padding-bottom: calc($unit * 40);

    @media (--screen-to-lrg) {
        padding-top: calc($unit * 35);
    }

    @media (--screen-from-lrg) {
        padding-top: calc($unit * 35);
    }

    @media (--screen-to-med) {
        padding-bottom: calc($unit * 20);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/pattern-1.svg);
        opacity: 0.3;
        background-repeat: repeat-x;
        background-size: 30%;
    }
}

.newhome-header__producthunt {
    display: inline-block;
    background: #23273e;
    border: 2px solid $color-secondary;
    border-radius: $global-radial;
}

.newhome-header__title {
    margin: calc($unit * 6) 0;
}

.newhome-header__wrapper {
    position: relative;

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.newhome-header__content {
    flex: 1;
}

.newhome-header__image {
    position: absolute;
    top: 0;
    right: -90px;
    border-radius: 16px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);

    @media (--screen-to-lrg) {
        display: none;
    }
}
.newhome-header__image-wrapper {
    position: relative;
    flex: 1;
}

.newhome-header__example-link {
    color: $color-secondary;

    &:hover,
    &:focus {
        color: color($color-secondary lightness(+20%));
    }
}
