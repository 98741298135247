/*------------------------------------*\
    # view.success
\*------------------------------------*/

.v-success {
    padding-top: calc($unit * 10);
    padding-bottom: calc($unit * 20);

    text-align: center;

    min-height: 60vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    color: white;
}

.v-success__text {
    max-width: 400px;
    margin: 0 auto;
}
