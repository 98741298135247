/*------------------------------------*\
    # view.fancy
\*------------------------------------*/

.v-fancy {
}

.v-fancy__layout {
    @media (--screen-to-med) {
        padding-top: calc($unit * 25);
    }

    @media (--screen-from-med) {
        position: relative;
        overflow: hidden;
    }
}

.v-fancy__layout .wrapper {
    min-height: 100vh;

    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.v-fancy__layout__content {
    margin: 0 auto;
    max-width: 420px;

    @media (--screen-to-med) {
        padding: calc($unit * 8);
        background: white;
        border-radius: 16px;
    }

    @media (--screen-from-med) {
        padding: calc($unit * 40) 0 calc($unit * 20) 0;
        lost-column: 5/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12;
    }
}

.v-fancy__layout__visual {
    display: none;

    position: absolute;
    left: 50%;
    width: 100%;
    height: 100%;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;

    @media (--screen-from-med) {
        display: block;
    }
}
