/*------------------------------------*\
    # component.countdown
\*------------------------------------*/

.countdown {
    background: rgba(0, 0, 0, 1);
    border-radius: 2px;
    padding: 4px 8px;
    display: inline-block;
    margin-top: calc($unit * 2);
    opacity: 0.5;
    transition: all 0.1s;
    color: white;
    font-weight: bold;

    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 0.8);
    }
}
