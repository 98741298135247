/*------------------------------------*\
    # block.nav
\*------------------------------------*/

.nav {
    width: 100%;

    @media (max-width: 1100px) {
        visibility: hidden;
        pointer-events: none;

        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(0);
        background: color($color-primary a(0.7));

        transition: all 0.3s ease-out;

        &.is-open {
            backdrop-filter: blur(6px);
            pointer-events: auto;
            visibility: visible;
        }
    }
}

.nav__item {
    align-items: center;
    display: flex;
}

.nav__item--profile {
    @media (max-width: 1100px) {
        display: none;
    }
}

.nav__list {
    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        padding: calc($unit * 30) calc($unit * 6) 0 calc($unit * 6);

        transition: all 0.3s ease-out;
        opacity: 0;

        .is-open & {
            opacity: 1;
        }
    }

    @media (min-width: 1101px) {
        display: flex;
        justify-content: flex-end;
    }

    .nav__link--alt {
        @media (max-width: 1100px) {
            display: none;
        }
    }
}

.nav__link {
    position: relative;
    display: flex;
    align-items: center;
    color: $color-text-dark;
    transition: all 0.1s ease-out;

    &:hover,
    &:focus {
        color: color($color-text-dark a(0.6));
    }

    @media (max-width: 1100px) {
        padding: calc($unit * 4) 0;
        color: white;

        font-size: responsive 20px 31px;
        font-range: 320px 1440px;

        line-height: responsive 28px 40px;
        line-height-range: 320px 1440px;
    }

    @media (min-width: 1101px) {
        padding: calc($unit * 6) calc($unit * 4);

        font-size: responsive 12px 13px;
        font-range: 320px 1440px;

        line-height: responsive 16px 20px;
        line-height-range: 320px 1440px;
    }

    &.active {
        color: $color-primary;
        font-weight: $type-weight-bold;
    }
}

.nav__link--alt {
    border-radius: $global-rounded;
    padding: calc($unit * 3);
    transition: all 0.15 ease-out;

    color: $color-text-dark;
    font-weight: $type-weight-bold;
    background-color: color(black a(0.05));

    &:hover,
    &:focus {
        color: $color-text-dark;
        background-color: color(black a(0.1));
    }
}

.nav__link--new {
    background: linear-gradient(
        180deg,
        rgba(252, 202, 113, 1) 0%,
        rgba(245, 187, 85, 1) 100%
    );

    &:hover,
    &:focus {
        background: linear-gradient(
            180deg,
            rgba(252, 202, 113, 1) 0%,
            rgba(245, 187, 85, 1) 100%
        );
        opacity: 0.7;
    }

    .nav__icon {
        fill: white;
    }
}

.nav__link--button {
    padding: calc($unit * 5) calc($unit * 4);
    &:after {
        display: none;
    }

    &:hover,
    &:focus {
        &:after {
            display: none;
        }
    }
}

.nav__icon {
    transition: all 0.2s ease-out;
    fill: color(black a(0.6));
}

.nav__item--dropdown {
    position: relative;

    @media (max-width: 1100px) {
        display: none;
    }
}

.nav__mobile {
    display: flex;
    margin-right: 50px;

    @media (min-width: 1101px) {
        display: none;
    }

    .nav__item--profile,
    .nav__item--dropdown {
        @media (max-width: 1100px) {
            display: initial;
        }
    }
}

.nav__item__name {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 18px;

    @media (--screen-to-sml) {
        max-width: 50px;
    }
}

.nav__link--main {
    text-transform: uppercase;
    font-weight: $type-weight-bold;
    letter-spacing: 0.1em;

    &.active {
        color: $color-secondary;
    }
}
