/*------------------------------------*\
    # utils.type
\*------------------------------------*/

.t {
}

.t-capitalize {
    text-transform: capitalize;
}

.t-bold {
    font-weight: $type-weight-bold;
}

.t-regular {
    font-weight: $type-weight-regular;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

.t-left {
    text-align: left;
}

.t-upper {
    text-transform: uppercase;
}

.t-upperext {
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.t-serif {
    font-family: $ff-serif;
}

.t-sans {
    font-family: $ff-sans;
}

/* Sizes */

/* Alpha, beta, gamma and delta sizes scale by 2 sizes from small to large screens */
.t-alpha {
    font-size: responsive 39px 61px;
    font-range: 320px 1440px;

    line-height: responsive 48px 72px;
    line-height-range: 320px 1440px;
}

.t-beta {
    font-size: responsive 25px 49px; /* Shrinks 3 scales */
    font-range: 320px 1440px;

    line-height: responsive 35px 56px;
    line-height-range: 320px 1440px;
}

.t-gamma {
    font-size: responsive 25px 39px;
    font-range: 320px 1440px;

    line-height: responsive 35px 48px;
    line-height-range: 320px 1440px;
}

.t-delta {
    font-size: responsive 20px 31px;
    font-range: 320px 1440px;

    line-height: responsive 28px 40px;
    line-height-range: 320px 1440px;
}

.t-epsilon {
    font-size: responsive 20px 25px;
    font-range: 320px 1440px;

    line-height: responsive 28px 36px;
    line-height-range: 320px 1440px;
}

.t-zeta {
    font-size: responsive 16px 20px;
    font-range: 320px 1440px;

    line-height: responsive 24px 28px;
    line-height-range: 320px 1440px;
}

.t-base {
    font-size: responsive 14px 16px;
    font-range: 320px 1440px;

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;
}

.t-micro {
    font-size: responsive 12px 14px;
    font-range: 320px 1440px;

    line-height: responsive 16px 20px;
    line-height-range: 320px 1440px;
}

.t-tiny {
    @mixin type-scale $type-tiny, 4;
}

/* Colors */
.t-primary {
    color: $color-primary;
}

.t-secondary {
    color: $color-secondary;
}

.t-secondary-dark {
    color: color($color-secondary lightness(-10%));
}

.t-text {
    color: $color-text;
}

.t-negative {
    color: white;
}

.t-dark {
    color: $color-dark;
}

.t-darktext {
    color: $color-text-dark;
}

.t-underline {
    text-decoration: underline;
}

.t-emphasized {
    font-style: italic;
}

.t-smcp {
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 0.05em;
}

.t-warning {
    color: $color-warning;
}

.t-success {
    color: $color-success;
}

.t-note {
    color: $color-note;
}

.t-note--darker {
    color: color($color-note lightness(-20%));
}

.t-strike {
    text-decoration: line-through;
}

/* Shorten long links */
.t-shorten {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Special headings */
.t-smallheading {
    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
}

.t-prewrap {
    white-space: pre-wrap;
}

.t-overflowwrap {
    overflow-wrap: break-word;
}

.t-link {
    color: $color-primary;

    &:hover,
    &:focus {
        color: color($color-primary lightness(+20%));
    }
}

.t-link--alt {
    color: $color-secondary;

    &:hover,
    &:focus {
        color: color($color-secondary lightness(+20%));
    }
}

.t-turbo {
    background-image: linear-gradient(45deg, #9600ff, #ebe833);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
