/*------------------------------------*\
    # view.checkout-deals
\*------------------------------------*/

.v-checkout-deals {
}

.v-checkout-deals__header {
    lost-flex-container: row;
    margin-top: 60px;
    margin-bottom: 30px;

    @media (--screen-from-med) {
        margin-bottom: 100px;
    }
}

.v-checkout-deals__header__section {
    @media (--screen-from-med) {
        lost-column: 1/2 2 120px;
    }
}

.v-checkout-deals__header__section--criteria {
    display: flex;

    @media (--screen-to-med) {
        margin-top: calc($unit * 10);
    }
}

.v-checkout-deals__header__icon {
    width: auto;
    height: 84px;
}

.v-checkout-deals__offers {
    z-index: 0;
    position: relative;

    padding-top: 60px;
    padding-bottom: calc($unit * 10);

    @media (--screen-from-med) {
        padding-top: 60px;
        padding-bottom: 168px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        z-index: -1;
        background: $color-primary;
        background-image: url(../assets/images/pattern-1.svg);
        background-blend-mode: overlay;

        @media (--screen-from-med) {
            height: 500px;
        }
    }
}

.v-checkout-deals__offers__title {
    margin-top: calc($unit * 5);

    @media (--screen-from-med) {
        margin-top: calc($unit * 15);
    }
}

.v-checkout-deals__offers__grid {
    @media (--screen-from-med) {
        lost-flex-container: row;
        margin-top: calc($unit * 10);
        position: relative;
        top: 540px;
        margin-top: -500px;
    }
}

.v-checkout-deals__offers__item {
    background: white;
    border-radius: $global-radial;
    padding: calc($unit * 8);

    @media (--screen-from-med) {
        padding: calc($unit * 15);
        lost-column: 1/2;
    }

    @media (--screen-to-med) {
        margin-top: calc($unit * 8);
    }
}

.v-checkout-deals__how {
    @media (--screen-to-med) {
        margin-top: calc($unit * 10);
    }

    @media (--screen-from-med) {
        lost-flex-container: row;
        margin-top: 540px;
    }
}

.v-checkout-deals__how__item {
    @media (--screen-from-med) {
        lost-column: 1/2 2 120px;
    }
}

.v-checkout-deals__operator {
    background: $color-primary;
    border-radius: $global-radial;
    color: white;
    padding: calc($unit * 10);

    @media (--screen-from-med) {
        margin-right: calc($unit * -22);
        padding: calc($unit * 15);
    }
}

.v-checkout-deals__features {
    margin-top: calc($unit * 10);

    @media (--screen-from-med) {
        lost-flex-container: row;
        margin-top: calc($unit * 30);
    }
}

.v-checkout-deals__features__header {
    @media (--screen-to-med) {
        margin-bottom: calc($unit * 10);
        text-align: center;
    }

    @media (--screen-from-med) {
        lost-column: 3/12;
    }
}

.v-checkout-deals__features__grid {
    lost-flex-container: row;

    @media (--screen-from-med) {
        lost-flex-container: row;
        lost-column: 8/12;
        lost-offset: 1/12;
    }
}

.v-checkout-deals__features__item {
    lost-column: 1/2;
    text-align: center;

    @media (--screen-from-med) {
        lost-column: 1/3;
    }

    padding-bottom: calc($unit * 20);
}

.v-checkout-deals__features__item__icon {
    padding-bottom: calc($unit * 5);
}

.v-checkout-deals__faq {
    background: white;
    padding: calc($unit * 10);
    border-radius: $global-radial;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.04);

    @media (--screen-from-med) {
        lost-flex-container: row;
        padding: calc($unit * 25);
    }
}

.v-checkout-deals__faq__header {
    @media (--screen-to-med) {
        margin-bottom: calc($unit * 7);
        text-align: center;
    }

    @media (--screen-from-med) {
        lost-column: 1/2;
    }
}

.v-checkout-deals__faq__content {
    @media (--screen-from-med) {
        lost-column: 1/2;
    }
}

.v-checkout-deals__contact {
    padding: calc($unit * 15) 0;

    @media (--screen-from-med) {
        padding: calc($unit * 25) 0;
    }
}

.v-checkout-deals__contact .wrapper {
    @media (--screen-from-med) {
        text-align: center;
    }
}

.v-checkout-deals__contact__content {
    display: inline-block;
    margin-top: calc($unit * 6);
}

.v-checkout-deals__details__item {
    margin-top: calc($unit * 8);
    padding-bottom: calc($unit * 8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
        border-bottom: none;
    }
}

.v-checkout-deals__contact__email {
    background-color: color($color-secondary a(0.15));
    padding: calc($unit * 6) calc($unit * 5);
    border-radius: $global-radial;
}

.v-checkout-deals__detailed-button {
    margin-left: calc($unit * -6);
    .active {
        transform: rotate(180deg);
    }
}

.v-checkout-deals__offers__offer {
    background: $color-warning;
    color: white;
    border-radius: $global-radial;
    padding: 10px 20px;
    display: inline-block;
}

.v-checkout-deals__buy-now {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    span {
        @mixin type-scale $type-small, 4;
        margin-top: calc($unit * 4);
    }
}
