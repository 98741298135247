/*------------------------------------*\
    # view.listing-form
\*------------------------------------*/

.listing-form {
}

.listing-form__section {
    & + & {
        margin-top: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05),
            0 42px 64px rgba(0, 0, 0, 0.05);

        & + & {
            margin-top: calc($unit * 6);
        }

        background: white;
        border: 1px solid color($color-dark a(0.1));
        border-radius: $global-radial;
        padding: calc($unit * 10);
    }
}

.listing-form__section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc($unit * 6);
}

.listing-form__action {
    display: flex;
    justify-content: space-between;
}

.listing-form__action--top {
    margin-bottom: calc($unit * 5);
}

.listing-form__action--bottom {
    margin-top: calc($unit * 5);
}

.listing-form__file {
    display: block;
    padding: calc($unit * 2) calc($unit * 4);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: $global-radial;

    &:hover,
    &:focus {
        background-color: color($color-primary a(0.1));
    }

    & + & {
        margin-top: calc($unit * 2);
    }
}

.listing-form__image {
    border-radius: $global-radial;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    background-size: cover;
    background-position: center center;

    height: 250px;
}

.listing-form__image-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc($unit * 4);
    right: 0;

    border-radius: $global-rounded;
}

.listing-form__image-placeholder {
    width: 100%;
    background-size: cover;
    background-position: center center;
    min-height: 250px;
}
