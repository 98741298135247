/*------------------------------------*\
    # plugins.slick-slider
\*------------------------------------*/

.BrainhubCarousel-container {
    position: relative;
}

.BrainhubCarousel {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: hidden;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    display: flex;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
}

.BrainhubCarousel
    .BrainhubCarousel__trackContainer
    .BrainhubCarousel__track.BrainhubCarousel__track--transition {
    transition: transform;
}

/* arrows */
.BrainhubCarousel__arrows {
    cursor: pointer;
}

/* variables */
.BrainhubCarousel__dots {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 36px;
    height: 36px;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.1;
    cursor: pointer;
    appearance: none;
    color: transparent;
    background: transparent;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
    opacity: 1 !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:hover {
    /* Because on mobile and tablet hover can brake slider */
    @media (--screen-from-lrg) {
        opacity: 1;
    }
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: $color-primary;
}

.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    cursor: pointer;
}

.BrainhubCarousel__dots
    .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
    opacity: 1 !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover {
    opacity: 1;
}

.BrainhubCarousel__thumbnail[type='button'] {
    appearance: none;
}

.BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: #7b59ff;
}

.BrainhubCarousel__arrows span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #fff;
    border-width: 3px 3px 0 0;
    padding: 5px;
    transition: 0.3s;
    font-size: 0;
}

.BrainhubCarousel__arrows:hover {
    background-color: #8768ff;
}

.BrainhubCarousel__arrows:hover span {
    border-color: #fff;
    margin: 0;
}

.BrainhubCarousel__arrowLeft span {
    transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 2.45px;
}

.BrainhubCarousel__arrowRight span {
    transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px;
}

.BrainhubCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.BrainhubCarouselItem.BrainhubCarouselItem--clickable {
    cursor: pointer;
}

.BrainhubCarouselItem .debug-number {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    font-size: 2em;
    text-shadow: 0px 0px 9px white;
}
