/*------------------------------------*\
    # block.sellerinfo
\*------------------------------------*/

.sellerinfo {
    background: white;
    border-radius: $global-radial;

    @media (--screen-to-lrg) {
        padding: calc($unit * 10) 0;
    }
    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
        margin-top: calc($unit * -9);
    }

    border-bottom: 4px solid color($color-primary a(0.2));
}

.sellerinfo .wrapper {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.sellerinfo__heading {
    position: relative;

    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 6);
    }
    @media (--screen-from-lrg) {
        lost-column: 5/12;
    }
}

.sellerinfo__details {
    position: relative;

    @media (--screen-from-lrg) {
        lost-column: 6/12;
        lost-offset: 1/12;
    }
}

.sellerinfo__tooltip {
    position: absolute;
    z-index: 2;
    right: 100px;
    top: 0;
}

.sellerinfo__details__layout {
    margin-bottom: calc($unit * 8);
}

.sellerinfo__details__layout__col {
    @media (--screen-from-lrg) {
        lost-column: 1/2;
    }
}

.sellerinfo__details__seller {
    margin-bottom: calc($unit * 6);
}

.sellerinfo__details__verification--border {
    padding-top: calc($unit * 8);
    border-top: 1px solid color($color-dark a(0.1));
}

.sellerinfo__details__verification {
    lost-flex-container: row;
    padding-top: calc($unit * 8);
}

.sellerinfo__icons {
    lost-column: 1/2;
    display: flex;
    align-items: flex-start;

    &:nth-child(1),
    &:nth-child(2) {
        margin-bottom: calc($unit * 6);
    }

    @media (--screen-from-sml) {
        lost-column: 1/4;
    }

    svg {
        fill: $color-text-dark;
        margin-bottom: calc($unit * 2);
        min-width: 32px;
    }

    &.is-valid {
        svg {
            fill: $color-success;
        }
    }
}
