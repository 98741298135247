/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    z-index: 4;
    display: inline-flex;
    align-items: center;
    background: white;
    border-radius: $global-radial;
    padding: calc($unit * 4);
    position: fixed;
    bottom: calc($unit * 5);
    left: calc($unit * 5);
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1), 0 42px 64px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    border: 1px solid color($color-primary a(0.2));
    transform: translateX(-150%);
    opacity: 0;

    &:hover,
    &:focus {
        background-color: color($color-primary a(0.1));
    }

    &.is-visible {
        transform: translateX(0%);
        opacity: 1;
        transition: ease-out 0.25s;
    }
}

.alert--version {
    white-space: initial;
    justify-content: flex-start;
    max-width: calc(100% - 120px);
    border: 1px solid $color-primary;
    text-align: left;

    @media (--screen-to-med) {
        padding: calc($unit * 3);
    }
}

.alert--success {
    color: $color-success;
}

.alert--warning {
    color: $color-warning;
}

.alert--note {
    color: $color-note;
}
