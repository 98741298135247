/*------------------------------------*\
    # utils.flex
\*------------------------------------*/

.f {
    display: flex;
}

.f--i {
    display: inline-flex;
}

.f--col {
    flex-direction: column;
}

.f-align-center {
    margin: auto;
    align-self: center;
}

.f-align-left {
    margin-right: auto;
    align-self: center;
}

.f-align-right {
    margin-left: auto;
    align-self: center;
}

.f-align-x-center {
    margin-right: auto;
    margin-left: auto;
}

.f-align-x-left {
    margin-right: auto;
}

.f-align-x-right {
    margin-left: auto;
}

.f-align-y-center {
    align-self: center;
}

.f-align-y-top {
    align-self: flex-start;
}

.f-align-y-bottom {
    align-self: flex-end;
}

.f-justify-between {
    justify-content: space-between;
}

.f-justify-start {
    justify-content: flex-start;
}

.f-justify-end {
    justify-content: flex-end;
}

.f-justify-center {
    justify-content: center;
}

.f-align-items-center {
    align-items: center;
}

.f-align-items-bottom {
    align-items: flex-end;
}

.f-align-items-start {
    align-items: flex-start;
}
