/*------------------------------------*\
    # component.profile-badge
\*------------------------------------*/

.profile-badge {
    padding: 2px 4px;
    font-size: 11px;
    line-height: 11px;
    font-weight: $type-weight-bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: calc($unit * 1);
}

.profile-badge {
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.profile-badge--vip {
    background: $color-secondary;
    border-radius: 1px;
    color: black;
    text-shadow: none;
}

.profile-badge--100K {
    color: white;

    background: linear-gradient(
        180deg,
        #3527e8 0%,
        color(#3527e8 lightness(-20%)) 100%
    );
}

.profile-badge--250K {
    color: white;

    background: linear-gradient(
        180deg,
        #29af00 0%,
        color(#29af00 lightness(-10%)) 100%
    );
}

.profile-badge--500K {
    color: white;

    background: linear-gradient(
        180deg,
        #530d85 0%,
        color(#530d85 lightness(-20%)) 100%
    );
}

.profile-badge--750K {
    color: white;

    background: linear-gradient(
        180deg,
        #b77102 0%,
        color(#b77102 lightness(-15%)) 100%
    );
}

.profile-badge--1M {
    color: #f7dc4e;

    background: linear-gradient(
        180deg,
        #310000 0%,
        color(#310000 lightness(-20%)) 100%
    );
}
