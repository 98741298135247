/*------------------------------------*\
    # component.blob
\*------------------------------------*/

.blob {
    display: inline-flex;
    align-items: center;

    @mixin type-scale $type-small, 5;
    height: calc($unit * 9);
    padding: 0 calc($unit * 9);
    border-radius: $global-rounded;
}

.blob--success {
    background: color($color-success a(0.4));
}

.blob--warning {
    background: color($color-warning a(0.4));
    color: white;
}

.blob--note {
    background: color($color-note a(0.4));
}
