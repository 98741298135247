/*------------------------------------*\
    # block.analysis
\*------------------------------------*/

.analysis {
}

.analysis__layout {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.analysis__layout__nav {
    display: none;

    @media (--screen-from-lrg) {
        display: block;
        lost-column: 3/12;
    }
}

.analysis__layout__content {
    @media (--screen-from-med) and (--screen-to-lrg) {
        padding: 0 calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 9/12;
    }

    &.empty {
        display: flex;
        flex-direction: column;
        gap: calc($unit * 8);
    }
}

.analysis__btn {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (--screen-to-lrg) {
        padding: calc($unit * 4) 0;
    }

    @media (--screen-from-lrg) {
        display: none;
    }
}

.analysis__btn a {
    width: 100%;
}

.analysis-compare__input {
    width: auto;
    min-width: 185px;
}

.analysis__questions--empty {
    padding: calc($unit * 4);
    background: color(black a(0.1));
    color: $color-text-dark;
    border-radius: $global-radial;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);
}
