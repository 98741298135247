/*------------------------------------*\
    # view.contact
\*------------------------------------*/

.v-contact__header {
    z-index: 0;
    position: relative;
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;

    padding-top: calc($unit * 35);

    @media (--screen-from-lrg) {
        padding-top: calc($unit * 50);
    }

    &__content {
        display: flex;
        flex-direction: row;
    }

    &__text {
        @media (--screen-from-lrg) {
            margin-right: calc($unit * 20);
        }

        @media (--screen-from-xlrg) {
            margin-right: calc($unit * 35);
        }
    }

    &__illustration {
        display: none;

        @media (--screen-from-lrg) {
            display: block;
        }
    }
}

.v-contant__intro {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    z-index: 2;
}

.v-content__intro-content {
    position: relative;
    max-width: 554px;
}

.v-contact__section {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: calc($unit * 15);
    padding-top: calc($unit * 15);

    @media (--screen-from-med) {
        lost-flex-container: row;

        > div {
            lost-column: 4/12;
        }
    }
}

.v-contact__section-item {
    @media (--screen-to-med) {
        display: flex;
        align-items: center;
        padding-bottom: calc($unit * 5);

        svg {
            min-width: 76px;
            flex-basis: 76px;
        }

        > div {
            padding-left: calc($unit * 5);
        }
    }
}

.v-contact__link {
    &:hover,
    &:focus,
    &:active {
        color: $color-secondary;
    }
}

.v-contact__form-section {
    max-width: 1356px;
    padding: calc($unit * 16) calc($unit * 6);
    margin: 0 auto;

    @media (--screen-from-med) {
        padding: 0 calc($unit * 12);
    }

    > div {
        padding-bottom: calc($unit * 6);
    }

    @media (--screen-from-med) {
        padding: calc($unit * 16) calc($unit * 12) calc($unit * 30);
        lost-flex-container: row;
        justify-content: space-between;

        > div {
            lost-column: 4/12;
            padding-bottom: 0;

            &:last-child {
                lost-column: 7/12;
            }
        }
    }
}

.v-contact__button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.v-contact__button-group .btn {
    margin-right: calc($unit * 4);

    &:last-child {
        margin-right: 0;
    }
}
