/*------------------------------------*\
    # view.waiting-list
\*------------------------------------*/

.waiting-list {
}

.waiting-list__documents {
    display: flex;
    flex-flow: row wrap;
}

.waiting-list__document {
    svg {
        transition: all 0.1 ease-out;
        fill: $color-primary;
    }

    &:hover,
    &:focus {
        svg {
            fill: color($color-primary a(0.5));
        }
    }
}
