/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

html {
    @mixin typomatic-init;
    background: $color-tertiary;
    overflow-y: scroll;

    /* To override calendly css */
    height: auto !important;

    /* Lock scrolling on mobile nav open */
    &.is-locked {
        @media (--screen-to-lrg) {
            overflow: hidden;
            overflow-y: hidden;
        }
    }
}

body {
    /* To override calendly css */
    height: auto !important;

    position: relative;
    font-family: $ff-sans;
    color: $color-text;
    background: $color-bg;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    &.is-locked {
        overflow: hidden;
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

/* svg {
    max-width: 100%;
} */

iframe {
    display: block;
}

/** This fixes iframe overlay that's a bug from uncompatible versions of react scripts and other stuff. It's a hotfix, but fixing it properly introduces a lot more issues with version mismatch between all the other packages if you bump react-scripts to fix this. */
/* iframe:last-child {
    display: none;
} */
