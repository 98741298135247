/**
* Title: Protogator
* Author/s: Prototyp
* CSS build platform: Yarn
* CSS development build command: yarn
* CSS production build command: yarn build
* CSS processor: PostCSS
* CSS processor usage: autoprefixing, nesting, variables, minification, pxtorem, color functions
* CSS name convention: BEM
* Global indentation: 4 spaces
* Global units: px
* Global prefix: none
* Browser support: IE10+, last 3 versions of modern browsers
* Additional notes:
*/

/**
* Import: vars
* Description: variables
*/

/*------------------------------------*\
    # vars.globals
\*------------------------------------*/

/**
 * The $unit variable is a global variable to be used
 * in paddings, margins, for sizing and positioning
 */

/**
 * The $spacer variable is a global variable to be used
 * in to create a unified spacer for all kinds of elements
 */

/**
 * Global class prefix - usage: .$(global-prefix)classname
 */

/**
 * Global radius and rounded
 */

/*------------------------------------*\
    # vars.typography
\*------------------------------------*/

/**
 * Project base line height (unitless in order to work with typomatic)
 */

/**
 * Type scale sizes must be entered in their pixel size
 * (unitless in order to work with typomatic)
 * Default type scale ratio: 1.333;
 */

/** 
 * Type weights
 */

/**
 * Font stacks
 */

/*------------------------------------*\
    # vars.responsive
\*------------------------------------*/

/* 544px */

/* 543px */

/* 768px */

/* 767px */

/* 992px */

/* 991px */

/* 1200px */

/* 1199px */

/* 1440px */

/* 1439px */

/*------------------------------------*\
    # vars.colors
\*------------------------------------*/

/**
 * Generic colors
 * Color naming taken from: http://www.color-blindness.com/color-name-hue/
 */

/**
 * Brand colors
 */

/**
 * Global text color
 */

/**
 * Theme colors
 */

/**
 * Utility colors
 */

/**
* Import: tools
* Description: tools like typomatic and other mixins / libraries
*/

/*------------------------------------*\
    # tools.typomatic
\*------------------------------------*/

/**
 * Mixin name: typomatic-init
 * Description: creates a vertical rhythm on a page using font-size
 * and line-height on the html element
 * Parameters: 
 * does not take parameters
 */

/**
 * Mixin name: type-scale
 * Description: type-scale sets the type to baseline to achieve
 * vertical rhythm.
 * Parameters: 
 * $scale ($base-font-size is default) - font size (unitless) variable
 * $baselines (1 is default) - number of baselines
 */

/**
* Import: generic
* Description: normalize or reset CSS, box sizing
*/

/*------------------------------------*\
    # generic.boxsizing
\*------------------------------------*/

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}

html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/**
* Import: base
* Description: base structural stylings for setting the body, typography
* and other base styles (no classes should be added here)
*/

/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

html {
    font-size: 100%;
    line-height: 1.5;
    background: #4a1172;
    overflow-y: scroll;

    /* To override calendly css */
    height: auto !important;
}

/* Lock scrolling on mobile nav open */

@media       (max-width: 61.9375em) {

html.is-locked {
            overflow: hidden;
            overflow-y: hidden
    }
        }

body {
    /* To override calendly css */
    height: auto !important;

    position: relative;
    font-family: 'Rational Display', Helvetica, Arial, sans-serif;
    color: #786f82;
    background: #f4f7ff;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body.is-locked {
        overflow: hidden;
    }

img {
    max-width: 100%;
    vertical-align: middle;
}

/* svg {
    max-width: 100%;
} */

iframe {
    display: block;
}

/** This fixes iframe overlay that's a bug from uncompatible versions of react scripts and other stuff. It's a hotfix, but fixing it properly introduces a lot more issues with version mismatch between all the other packages if you bump react-scripts to fix this. */

/* iframe:last-child {
    display: none;
} */

/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 700;
    font-family: 'Rational Display', Helvetica, Arial, sans-serif;
    font-display: optional;
    font-size: 16px;
    line-height: 20px;
    color: #4a1172;
}

/**
 * Paragraphs
 */

p {
    margin: 0;

    font-size: calc(13.42857px + 0.17857vw);

    line-height: calc(18.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    p {
        line-height: 24px;
    }
}

@media screen and (max-width: 320px) {

    p {
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {

    p {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {

    p {
        font-size: 14px;
    }
}

/**
 * Lists (ol, ul, dd)
 */

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol {
    list-style: decimal;
}

ol li,
ul li,
dl dd,
dl dt {
    font-size: calc(13.42857px + 0.17857vw);

    line-height: calc(18.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    ol li,
ul li,
dl dd,
dl dt {
        line-height: 24px;
    }
}

@media screen and (max-width: 320px) {

    ol li,
ul li,
dl dd,
dl dt {
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {

    ol li,
ul li,
dl dd,
dl dt {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {

    ol li,
ul li,
dl dd,
dl dt {
        font-size: 14px;
    }
}

/**
 * Anchors
 */

a {
    color: #4a1172;
    text-decoration: none;
}

a:link {
    }

a:hover {
        color: rgb(37, 18, 51);
    }

a:focus {
        color: rgb(37, 18, 51);
    }

a:visited {
    }

.anchor--negative {
    color: white;
    text-decoration: underline;
}

.anchor--negative:hover,
    .anchor--negative:focus {
        color: white;
        text-decoration: none;
    }

.anchor--secondary {
    color: #fec45c;
}

.anchor--secondary:hover,
    .anchor--secondary:focus {
        color: rgba(254, 196, 92, 0.7);
    }

/**
 * Typographic details
 */

hr {
    padding: 8px 0;
    border: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
}

em {
}

b,
strong {
    font-weight: 700;
}

address {
}

small {
}

pre {
}

code {
}

sub {
}

sup {
}

strike {
}

/**
 * Tables
 */

table th {
        text-align: left;
    }

/**
 * Blockquote
 */

blockquote {
    margin: 0;
}

/**
* Import: component
* Description: cross project reusable
*/

/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
    margin: 0 auto;

    /* Sticky footer */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

/* Sticky footer push */

main {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1356px;
    padding: 0 24px;
    margin: 0 auto;
}

@media     (min-width: 48em) {

.wrapper {
        padding: 0 48px
}
    }

.wrapper--wide {
    max-width: 1600px;
}

.wrapper--narrow {
    max-width: 600px;
}

.wrapper--medium {
    max-width: 924px;
}

@media       (max-width: 61.9375em) {

.wrapper--noPadding--toLrg {
        padding: 0
}
    }

/*------------------------------------*\
    # component.logo
\*------------------------------------*/

.logo {
    margin: 24px 0;
}

.logo path {
        fill: #4a1172;
    }

@media       (max-width: 47.9375em) {

.logo {
        display: none
}
    }

.logo--logged-in {
    display: none;
}

.logo-mobile {
    margin: 24px 0;
    height: 20px;
    width: 20px;
    fill: #4a1172;
}

@media     (min-width: 48em) {

.logo-mobile {
        display: none
}
    }

.logo-mobile--logged-in {
    display: inline;
    display: initial;
}

/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    -ms-touch-action: manipulation;

        touch-action: manipulation;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: transparent;

    -webkit-transition: all 0.15s ease-in-out;

    transition: all 0.15s ease-in-out;
    border-radius: 6px;
    padding: 0;
}

.btn:focus {
        outline: 0;
    }

/**
    * Button styles
    */

.btn--primary {
    background: #4a1172;
    -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .1);
            box-shadow: 0px 8px 15px rgba(0, 0, 0, .1);
    color: white;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.btn--primary:hover,
    .btn--primary:focus {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        background-color: rgb(103, 24, 160);
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
                box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);

        color: white;
    }

.btn--primary:hover:after, .btn--primary:focus:after {
            border-bottom-color: #4c007f;
        }

.btn--primary:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

.btn--primary:active:hover,
        .btn--primary:active:focus {
            -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
                    box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
        }

.btn--primary:active:before {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .3);
                    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .3);
            border-radius: 6px;
        }

.btn--primary:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 3px solid #2f004f;
        border-top: 2px solid rgb(132, 30, 204);
        border-radius: 6px;
    }

.btn--primary .btn--tooltip svg path {
        fill: rgba(255, 255, 255, 0.4);
    }

.btn--primary--outline {
    background: transparent;
    -webkit-box-shadow: 0 0 0 1px rgba(74, 17, 114, 0.6) inset;
            box-shadow: 0 0 0 1px rgba(74, 17, 114, 0.6) inset;
    color: rgba(74, 17, 114, 0.8);
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.btn--primary--outline svg {
        fill: #4a1172;
    }

.btn--primary--outline:hover,
    .btn--primary--outline:focus {
        -webkit-box-shadow: 0 0 0 1px rgb(74, 17, 114) inset;
                box-shadow: 0 0 0 1px rgb(74, 17, 114) inset;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        background: #4a1172;
        color: white;
    }

.btn--primary--outline:hover svg, .btn--primary--outline:focus svg {
            fill: white;
        }

.btn--primary--outline:hover:after, .btn--primary--outline:focus:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-bottom: 3px solid #2f004f;
            border-top: 2px solid rgb(132, 30, 204);
            border-radius: 6px;
        }

.btn--primary--outline:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

.btn--secondary {
    background: #fec45c;
    color: #4a1172;
    -webkit-transition: translate 0.1s ease-out;
    transition: translate 0.1s ease-out;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.btn--secondary:hover,
    .btn--secondary:focus {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        background-color: rgb(254, 207, 118);
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
                box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
        color: #4a1172;
    }

.btn--secondary:hover:after, .btn--secondary:focus:after {
            border-bottom-color: #d19528;
        }

.btn--secondary:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

.btn--secondary:active:hover,
        .btn--secondary:active:focus {
            -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
                    box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
        }

.btn--secondary:active:before {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .3);
                    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, .3);
            border-radius: 6px;
        }

.btn--secondary:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 2px solid #ffdb89;
        border-bottom: 3px solid #c79333;
        border-radius: 6px;
    }

.btn--secondary--static {
    background: #fec45c;
    color: #4a1172;
    border-color: transparent;
    z-index: 1;
}

.btn--secondary--static:hover,
    .btn--secondary--static:focus {
        background-color: rgb(254, 207, 118);
        color: #4a1172;
    }

.btn--secondary--static:hover:after, .btn--secondary--static:focus:after {
            border-bottom-color: #d19528;
        }

.btn--secondary--static:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 3px solid #c79333;
        -webkit-transition: 0.15s ease all;
        transition: 0.15s ease all;
    }

.btn--neutral {
    background: rgb(231, 231, 231);
    color: #786f82;
}

.btn--neutral:hover,
    .btn--neutral:focus {
        background: rgb(194, 194, 194);
        color: #786f82;
    }

.btn--neutral--outline {
    background: transparent;
    -webkit-box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.1) inset;
            box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.1) inset;
    color: rgba(120, 111, 130, 0.9);
}

.btn--neutral--outline:hover,
    .btn--neutral--outline:focus {
        -webkit-box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.3) inset;
                box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.3) inset;
        color: rgb(57, 18, 84);
    }

.btn--warning {
    border: 1px solid #d84a4a;
    color: #d84a4a;
    -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .05);
            box-shadow: 0px 8px 15px rgba(0, 0, 0, .05);
}

.btn--warning:hover,
    .btn--warning:focus {
        background: rgba(216, 74, 74, 0.2);
        color: #d84a4a;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, .1);
                box-shadow: 0px 8px 20px rgba(0, 0, 0, .1);
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

.btn--warning:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

.btn--success {
    border: 1px solid #53cb83;
    color: #53cb83;
    -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .05);
            box-shadow: 0px 8px 15px rgba(0, 0, 0, .05);
}

.btn--success:hover,
    .btn--success:focus {
        background: rgba(83, 203, 131, 0.2);
        color: #53cb83;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, .1);
                box-shadow: 0px 8px 20px rgba(0, 0, 0, .1);
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

.btn--success:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

.btn--ghost {
    background-color: transparent;
    color: #4a1172;
}

.btn--ghost:hover,
    .btn--ghost:focus {
        background-color: rgba(74, 17, 114, 0.1);
        color: rgb(59, 18, 89);
    }

.btn--ghost--secondary {
    background-color: transparent;
    color: #fec45c;
}

.btn--ghost--secondary:hover,
    .btn--ghost--secondary:focus {
        color: rgb(230, 181, 92);
    }

.btn--ghost--bordered {
    background-color: transparent;
    color: #786f82;
    border-color: #786f82;
    -webkit-box-shadow: 0 0 0 1px rgb(210, 210, 210) inset;
            box-shadow: 0 0 0 1px rgb(210, 210, 210) inset;
    z-index: 0;
}

.btn--ghost--bordered:hover:after, .btn--ghost--bordered:focus:after {
            border-bottom: 3px solid rgb(173, 173, 173);
        }

.btn--ghost--bordered:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 3px solid rgb(210, 210, 210);
        -webkit-transition: 0.15s ease all;
        transition: 0.15s ease all;
    }

.btn--subscription--right {
    position: relative;
    left: -1px;
    border-radius: 0 6px 6px 0;
}

.btn--subscription--right:after {
        border-radius: 6px 0;
    }

.btn--subscription--left {
    position: relative;
    left: 1px;
    border-radius: 6px 0 0 6px;
}

.btn--subscription--left:after {
        border-radius: 0 6px;
    }

.btn--ghost--text {
    background-color: transparent;
    color: #786f82;
}

.btn--ghost--text:hover,
    .btn--ghost--text:focus {
        color: rgb(57, 18, 84);
    }

.btn--ghost--negative {
    background-color: transparent;
    color: white;
}

.btn--ghost--negative:hover,
    .btn--ghost--negative:focus {
        color: rgba(255, 255, 255, 0.7);
    }

.btn--bordered {
    background-color: transparent;
    color: #fec45c;
    border: 1px solid #fec45c;
}

.btn--bordered:hover,
    .btn--bordered:focus {
        background-color: #fec45c;
        color: #4a1172;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
                box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
    }

.btn--bordered:active {
        color: #4a1172;
        -webkit-box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
                box-shadow: 0px 8px 15px rgba(0, 0, 0, .15);
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

/*
* Andrej will finish this :)
*/

.btn--active {
    background-color: #4a1172;
}

/**
 * Disabled
 */

.btn.is-disabled,
.btn:disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.btn.is-disabled:hover,
    .btn.is-disabled:focus,
    .btn:disabled:hover,
    .btn:disabled:focus {
        color: inherit;
    }

&.is-disabled {
    pointer-events: none;
}

/**
 * Button sizes
 */

.btn--sml {
    font-size: 12px;
    line-height: 16px;
    height: 28px;
    padding: 0 20px;
}

.btn--sml--inline {
    padding: 0 12px;
}

.btn--med {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    padding: 0 24px;
}

.btn--lrg {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    padding: 0 32px;
}

@media     (min-width: 48em) {

.btn--lrg {
        height: 56px;
        padding: 0 36px
}
    }

.btn--longer {
    padding: 0 36px;
}

@media     (min-width: 48em) {

.btn--longer {
        padding: 0 60px
}
    }

.btn--xlonger {
    padding: 0 48px;
}

@media     (min-width: 48em) {

.btn--xlonger {
        padding: 0 80px
}
    }

/**
* Special
*/

.btn--with-icon svg {
        margin-right: 12px;
        min-width: 24px;
    }

.btn--nopadding {
    padding-left: 0;
    padding-right: 0;
}

.btn--no-text-align {
    text-align: left;
}

.btn--tooltip {
    padding: 0 8px;
    top: 3px;
}

.btn--tooltip svg path {
            fill: rgba(74, 17, 114, 0.3);
        }

.btn--tooltip-white {
    padding: 0 8px;
}

.btn--tooltip-white svg path {
            fill: rgba(255, 255, 255, 0.6);
        }

@-webkit-keyframes shadow-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0px rgba(70, 35, 109, .8);
                box-shadow: 0 0 0 0px rgba(70, 35, 109, .8);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
                box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
}

@keyframes shadow-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0px rgba(70, 35, 109, .8);
                box-shadow: 0 0 0 0px rgba(70, 35, 109, .8);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
                box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
}

.btn--tooltip-animated {
    border-radius: 1000px;
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-animation: shadow-pulse 2s infinite;
            animation: shadow-pulse 2s infinite;
    background: rgba(74, 17, 114, 0.6);
    fill: white;
}

.btn--tooltip-animated:hover,
    .btn--tooltip-animated:focus {
        -webkit-animation: notexistent;
                animation: notexistent;
    }

.btn--cta {
    font-weight: 700;
}

@media     (min-width: 48em) {

.btn--cta {
        font-size: 16px;
        line-height: 20px
}
    }

.btn--toggleable:hover svg path, .btn--toggleable:focus svg path {
            stroke: rgb(74, 17, 114);
        }

.btn--toggleable svg path {
    stroke: rgba(74, 17, 114, 0.2);
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.btn--toggleable--active svg path {
    stroke: rgb(74, 17, 114);
}

.btn--full {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.btn--valuation {
    background: rgb(83, 203, 131);
    border-bottom: 2px solid rgba(0, 0, 0, .3);
    color: white;
    font-weight: 700;
}

.btn--valuation:hover,
    .btn--valuation:focus {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
                box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
    }

.btn--valuation-back {
    background: rgba(255, 255, 255, 0.3);
    border-bottom: 2px solid rgba(0, 0, 0, .3);
    color: white;
    font-weight: 700;
}

.btn--valuation-back:hover,
    .btn--valuation-back:focus {
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
                box-shadow: 0px 8px 20px rgba(0, 0, 0, .2);
    }

.btn--rounded {
    border-radius: 1000px;
    color: white;
    font-weight: 700;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(255, 255, 255, 0)),
        to(rgba(255, 255, 255, .1))
    );
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .1) 100%
    );

    border-top: 1px rgba(255, 255, 255, 0.2) solid;
}

.btn--rounded:hover,
    .btn--rounded:focus {
        background: rgba(255, 255, 255, .1);
    }

.btn--rounded--selected {
    color: #4a1172;
    background: #fec45c;
    -webkit-box-shadow: inset 0px -3px 0px rgba(0, 0, 0, .25),
        inset 0px 3px 0px rgba(255, 255, 255, .25);
            box-shadow: inset 0px -3px 0px rgba(0, 0, 0, .25),
        inset 0px 3px 0px rgba(255, 255, 255, .25);
}

.btn--rounded--selected:hover,
    .btn--rounded--selected:focus {
        background: #fec45c;
        -webkit-box-shadow: inset 0px -4px 0px rgba(0, 0, 0, .5),
            inset 0px 3px 0px rgba(255, 255, 255, .25);
                box-shadow: inset 0px -4px 0px rgba(0, 0, 0, .5),
            inset 0px 3px 0px rgba(255, 255, 255, .25);
    }

.btn--rounded--iconOnly {
    border-radius: 1000px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
    overflow: hidden;
    width: 40px !important;
    height: 40px;
}

.btn--rounded--iconOnly:after {
        display: none;
    }

/*------------------------------------*\
    # component.switchbutton
\*------------------------------------*/

.switchbutton {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.switchbutton__button {
    padding: 16px 20px;
    border-radius: 3px;
    border: 1px solid rgba(74, 17, 114, 0.2);
    -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5px 8px rgba(0, 0, 0, .05);

    font-size: 12px;

    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
    background: rgba(255, 255, 255, 0.5);
    color: rgba(120, 111, 130, 0.5);
}

.switchbutton__button.is-active {
        background: #4a1172;
        color: white;
        border: 1px solid rgba(74, 17, 114, 0.2);
    }

.switchbutton__button.is-active:hover,
        .switchbutton__button.is-active:focus {
            background: rgba(74, 17, 114, 0.8);
            color: white;
        }

.switchbutton__button:hover,
    .switchbutton__button:focus {
        color: #786f82;
        background: white;
    }

.switchbutton__badge {
    position: absolute;
    width: 28px;
    height: 28px;
    top: -15px;
    right: -15px;
}

/*------------------------------------*\
    # component.toggle
\*------------------------------------*/

.toggle {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-shadow: 0 0px 8px 3px rgba(0, 0, 0, .05);
            box-shadow: 0 0px 8px 3px rgba(0, 0, 0, .05);
    border: 1px solid rgba(0, 0, 0, .1);
}

.toggle__button {
    height: 24px;
    padding: 2px 12px 0 12px;
    border-radius: 3px;
    outline: 0;

    font-size: 12px;

    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    color: rgba(120, 111, 130, 0.5);
    background: rgba(255, 255, 255, 0.8);
}

.toggle__button.is-active {
        color: #4a1172;
        background: white;
    }

.toggle__button:hover,
    .toggle__button:focus {
        color: #4a1172;
        background: white;
        background: rgba(74, 17, 114, 0.1);
    }

.toggle__button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

.toggle__button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
        border-left: 1px solid rgba(0, 0, 0, .1);
    }

/*------------------------------------*\
    # component.newsletter
\*------------------------------------*/

.newsletter-cta {
    padding: 160px 0;

    background-blend-mode: overlay;
    background-color: #c2aee0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/graphic-keyvisual.7c781be2.svg);
}

.newsletter-cta .input:focus,
        .newsletter-cta .input:hover {
            background: white;
        }

/*------------------------------------*\
    # component.input-group
\*------------------------------------*/

.input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 18px;
  max-width: 800px;
  margin: auto;
}

.input-group--inputs {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      gap: 12px;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
  }

.input-group--inputs .field {
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1;
      }

@media       (max-width: 33.9375em) {

.input-group > .btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%
}
  }

@media       (max-width: 33.9375em) {

.input-group--invertOnSmall {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse
}
  }

/*------------------------------------*\
    # component.btn-group
\*------------------------------------*/

.btn-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 6px;
}

@media       (max-width: 33.9375em) {

.btn-group > .btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        width: 100%
}
    }

@media       (max-width: 33.9375em) {

.btn-group--invertOnSmall {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse
}
    }

/*------------------------------------*\
    # component.btn-grid
\*------------------------------------*/

@media     (min-width: 48em) {

.btn-grid {
        display: flex;
        flex-flow: row wrap
}
    }

.btn-grid--1of3 > .btn {
    margin-bottom: 16px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/1 - (36px - 36px * 1/1));
    max-width: calc(99.9% * 1/1 - (36px - 36px * 1/1));
    width: calc(99.9% * 1/1 - (36px - 36px * 1/1));
}

.btn-grid--1of3 > .btn:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.btn-grid--1of3 > .btn:last-child {
    margin-right: 0;
}

.btn-grid--1of3 > .btn:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 34em) {

.btn-grid--1of3 > .btn {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        margin-bottom: 24px
}

.btn-grid--1of3 > .btn:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.btn-grid--1of3 > .btn:last-child {
        margin-right: 0
}

.btn-grid--1of3 > .btn:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 75em) {

.btn-grid--1of3 > .btn {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.btn-grid--1of3 > .btn:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.btn-grid--1of3 > .btn:last-child {
        margin-right: 0
}

.btn-grid--1of3 > .btn:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.btn-grid--1of2 > .btn {
    margin-bottom: 16px;
}

@media     (min-width: 34em) {

.btn-grid--1of2 > .btn {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        margin-bottom: 24px
}

.btn-grid--1of2 > .btn:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.btn-grid--1of2 > .btn:last-child {
        margin-right: 0
}

.btn-grid--1of2 > .btn:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # component.radio-group
\*------------------------------------*/

.radio-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.radio-group > .field {
    margin-right: 16px;
}

/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;

    -webkit-border-radius: 0; /* Prefixed to remove border radius on iOS */
    -webkit-appearance: none;

    font-variant-numeric: lining-nums;
    -webkit-font-feature-settings: 'lnum';
    font-feature-settings: 'lnum', "lnum";

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;

    font-size: 16px;

    line-height: 32px;
    color: #786f82;
    background: #fcfcfc;

    border: 1px solid rgba(57, 18, 84, 0.12);
    -webkit-box-shadow: 0 4px 0 rgba(57, 18, 84, 0.02) inset;
            box-shadow: 0 4px 0 rgba(57, 18, 84, 0.02) inset;
    border-radius: 6px;
    -webkit-transition: background 0.15s ease-out;
    transition: background 0.15s ease-out;
}

.input::-webkit-input-placeholder {
        color: rgba(120, 111, 130, 0.5);
    }

.input::-moz-placeholder {
        color: rgba(120, 111, 130, 0.5);
    }

.input::-ms-input-placeholder {
        color: rgba(120, 111, 130, 0.5);
    }

.input::placeholder {
        color: rgba(120, 111, 130, 0.5);
    }

.input:hover {
        background: rgba(120, 111, 130, 0.08);
    }

.input:focus {
        outline: 0;
        background: rgba(120, 111, 130, 0.08);
        border-color: #4a1172;
    }

/**
 * input sizes
 */

.input--sml {
    font-size: 14px;
    line-height: 20px;
    height: 32px;
    padding: 0 16px;
}

.input--sml.input--with-prefix {
        padding-left: 20px;
    }

.input--med {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    padding: 0 16px;
}

.input--med.input--with-prefix {
        padding-left: 28px;
    }

.input--lrg {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    padding: 0 16px;
}

@media     (min-width: 48em) {

.input--lrg {
        padding: 0 24px
}
    }

.input--lrg.input--with-prefix {
        padding-left: 32px;
    }

/**
 * Input - alternative styles
 */

@-webkit-keyframes autofill--alt {
    0%,
    100% {
        color: white;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgba(255, 255, 255, 0)),
            to(rgba(255, 255, 255, .1))
        );
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, .1) 100%
        );
    }
}

@keyframes autofill--alt {
    0%,
    100% {
        color: white;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgba(255, 255, 255, 0)),
            to(rgba(255, 255, 255, .1))
        );
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, .1) 100%
        );
    }
}

.input--alt {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(255, 255, 255, 0)),
        to(rgba(255, 255, 255, .1))
    );
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .1) 100%
    );
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1000px;
    color: white;
    padding: 0 24px;
    height: 50px;
}

.input--alt:-webkit-autofill {
        color: white !important;
        -webkit-animation-delay: 0.1s;
                animation-delay: 0.1s;
        -webkit-animation-name: autofill--alt;
                animation-name: autofill--alt;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
    }

.input--alt::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

.input--alt::-moz-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

.input--alt::-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

.input--alt::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

/**
 * compact
 */

.input--compact {
    max-width: 80px;
}

/**
*   Input Errors
*/

.input--error {
    color: #d84a4a;
    border-color: #d84a4a;
}

/**
 * input types
 */

.input--select {
    display: block;
    cursor: pointer;

    color: #786f82;
    background: #fcfcfc;

    border: 1px solid rgba(57, 18, 84, 0.12);
    -webkit-box-shadow: 0 -4px 0 rgba(57, 18, 84, 0.02) inset;
            box-shadow: 0 -4px 0 rgba(57, 18, 84, 0.02) inset;
    border-radius: 6px;
    -webkit-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;

    /* background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'); */
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMjQgMTEuMzA1bC03Ljk5NyAxMS4zOUw4IDExLjMwNXoiLz48L3N2Zz4=');
    background-repeat: no-repeat, repeat;
    background-position: calc(100% - 12px) 50%;
    background-size: 8px;

    -webkit-appearance: none;

       -moz-appearance: none;

            appearance: none;

    padding-right: 28px;
}

.input--select::-ms-expand {
        display: none;
    }

.input--select:hover {
        background-color: rgba(120, 111, 130, 0.08);
    }

.input--select:focus {
        outline: 0;
        background-color: rgba(120, 111, 130, 0.08);
        border-color: #4a1172;
    }

.input--select option {
    font-weight: normal;
}

.input--dropzone {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;

    min-height: 120px;
    padding: 16px 32px;
    border: 1px dashed #786f82;

    white-space: normal;

    white-space: initial;
}

.input--dropzone__file {
    background-color: white;
    border: 1px solid #53cb83;
    padding: 8px 16px;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 12px;
}

.input--dropzone__file + .input--dropzone__file {
        margin: 16px;
    }

.input--textarea {
    min-height: 160px;
    padding: 8px 16px;
    resize: vertical;
    font-size: 14px;
    line-height: 20px;
    white-space: normal;
    white-space: initial;
}

.input--check,
.input--radio {
    position: absolute;
    opacity: 0;
}

.input--check + label, .input--radio + label {
        position: relative;
        display: block;
        padding: 0 0 0 24px;

        font-size: 14px;
    }

.input--check + label:before,
        .input--check + label:after,
        .input--radio + label:before,
        .input--radio + label:after {
            content: '';
            display: block;

            position: absolute;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            background: white;
        }

.input--check + label:before, .input--radio + label:before {
            width: 16px;
            height: 16px;

            top: 0;
            left: 0;

            border: 1px solid rgba(57, 18, 84, 0.4);
            border-radius: 3px;
        }

.input--check + label:after, .input--radio + label:after {
            width: 10px;
            height: 10px;
            border-radius: 2px;

            top: 3px;
            left: 3px;
        }

.input--check:focus + label:before, .input--check:focus + label:before, .input--radio:focus + label:before, .input--radio:focus + label:before {
                -webkit-box-shadow: 0 0 0 1px #4a1172;
                        box-shadow: 0 0 0 1px #4a1172;
            }

.input--check:checked + label:after, .input--radio:checked + label:after {
                background: #4a1172;
            }

.input--radio + label:before,
        .input--radio + label:after {
            border-radius: 1000px;
        }

.input--radio--alternate + label {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

.input--radio--alternate + label:before {
            width: 13px;
            height: 13px;
            border-radius: 3px;
        }

.input--radio--alternate + label:after {
            width: 7px;
            height: 7px;
            border-radius: 2px;
        }

@media       (max-width: 33.9375em) {

.input--radio--alternate + label {
            font-size: 10px;
            padding-top: 4px
    }
        }

.input__prefix {
    opacity: 0.7;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.input--title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #4a1172;
}

.input--switch {
    opacity: 0;
    border-radius: 1000px;
}

.input--switch:checked + label:after {
            background: rgba(216, 74, 74, 0.3);
        }

.input--switch:checked + label:before {
            right: -4px;
            background: #d84a4a;
        }

.input--switch + label {
        position: relative;
        display: block;
        font-size: 14px;
        line-height: 16px;
        padding-right: 52px;
    }

.input--switch + label:after {
            position: absolute;
            top: 0;
            right: 0;

            content: '';
            display: block;
            height: 16px;
            width: 36px;
            background: #dddddd;
            border-radius: 100px;
        }

.input--switch + label:before {
            position: absolute;
            top: -2px;
            right: 18px;
            z-index: 1;

            -webkit-transition: all 0.2s ease-out;

            transition: all 0.2s ease-out;

            content: '';
            display: block;
            height: 20px;
            width: 20px;
            background: #7d7d7d;
            border-radius: 100px;
        }

/*------------------------------------*\
    # component.control-group
\*------------------------------------*/

.control-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.control-group > .btn,
.control-group > .field,
.control-group > .input,
.control-group > .field__divider,
.control-group > select {
    margin-right: 16px;
}

.control-group > .btn:last-child, .control-group > .field:last-child, .control-group > .input:last-child, .control-group > .field__divider:last-child, .control-group > select:last-child {
        margin-right: 0;
    }

.control-group > .input__prefix {
    position: absolute;
    left: 12px;
}

/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: 16px;
    color: #786f82;
}

.field__lbl {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    color: #2f2f30;
}

.field__lbl + .field__sublbl {
        margin-top: -4px;
    }

.field__sublbl {
    font-size: 12px;
    line-height: 20px;
    color: rgba(120, 111, 130, 0.7);
    margin-bottom: 4px;
}

.field__lbl--error {
    color: #d84a4a;
}

.field__error {
    display: block;
    margin-top: 8px;

    font-size: 12px;

    line-height: 16px;
    color: #d84a4a;
}

.field__divider {
    padding: 0 4px;
}

.field--alt .field__lbl {
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 8px;
    }

.field--alt .field__sublbl {
        color: rgba(255, 255, 255, 0.4);
    }

.field--alt .control-group .input__prefix {
            left: 20px;
            color: white;
        }

.field--alt .control-group .input--with-prefix {
            padding-left: 40px !important;
        }

/*------------------------------------*\
    # component.fieldset
\*------------------------------------*/

.fieldset {
    /**
     * Fieldset base
     */
    padding: 0;
    margin: 0;
    border: 0;
}

.fieldset__legend {
    padding: 32px 0;

    font-size: 14px;

    line-height: 16px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: 700;
}

/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    z-index: 4;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: white;
    border-radius: 6px;
    padding: 16px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1), 0 42px 64px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1), 0 42px 64px rgba(0, 0, 0, .1);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border: 1px solid rgba(74, 17, 114, 0.2);
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
    opacity: 0;
}

.alert:hover,
    .alert:focus {
        background-color: rgba(74, 17, 114, 0.1);
    }

.alert.is-visible {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        opacity: 1;
        -webkit-transition: ease-out 0.25s;
        transition: ease-out 0.25s;
    }

.alert--version {
    white-space: normal;
    white-space: initial;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    max-width: calc(100% - 120px);
    border: 1px solid #4a1172;
    text-align: left;
}

@media       (max-width: 47.9375em) {

.alert--version {
        padding: 12px
}
    }

.alert--success {
    color: #53cb83;
}

.alert--warning {
    color: #d84a4a;
}

.alert--note {
    color: #fec45c;
}

/*------------------------------------*\
    # component.notice
\*------------------------------------*/

.notice {
    z-index: 1;
    font-size: 14px;
    line-height: 20px;
    color: #786f82;
    padding: 8px 16px;
}

.notice:empty {
        display: none;
    }

.notice--success {
    border-left: 2px solid #53cb83;
    background: rgba(83, 203, 131, 0.1);
}

.notice--warning {
    border-left: 2px solid #d84a4a;
    background: rgba(216, 74, 74, 0.1);
}

.notice--note {
    border-left: 2px solid #fec45c;
    background: rgba(254, 196, 92, 0.1);
}

.notice--rounded {
    border-radius: 1000px;
    border: 1px solid #cae8e6;
    padding: 12px 24px;
}

/*------------------------------------*\
    # component.slider
\*------------------------------------*/

.slider {
    position: relative;
    width: 48px;
    border-radius: 1000px;
    background: #4a1172;
    height: 20px;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    border: 0;
    cursor: pointer;
}

.slider:focus,
    .slider:hover {
        background: rgba(74, 17, 114, 0.5);
        outline: 0;
    }

.slider__status {
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 1000px;
    position: absolute;
    top: 4px;
    left: 4px;

    -webkit-transition: all 0.1s ease-out;

    transition: all 0.1s ease-out;
}

.slider--active {
    background: #fec45c;
}

.slider--active:focus,
    .slider--active:hover {
        background: rgba(254, 196, 92, 0.5);
        outline: 0;
    }

.slider--active .slider__status {
        left: 32px;
        background-color: white;
    }

/*------------------------------------*\
    # component.hamburger
\*------------------------------------*/

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
    height: 40px;
    width: 40px;
    display: inline-block;
    cursor: pointer;

    text-transform: none;
    background: #4a1172;
    margin: 0;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: visible;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
}

.hamburger.is-active {
        background: white;
    }

.hamburger-inner {
    position: absolute;
    top: 16px;
    left: 10px;
}

.hamburger-inner:before,
    .hamburger-inner:after {
        height: 2px;
        background-color: white;
        position: absolute;
        border-radius: 2px;

        -webkit-transition: all 0.15s linear;

        transition: all 0.15s linear;
    }

.hamburger-inner:before,
    .hamburger-inner:after {
        content: '';
        display: block;
    }

.hamburger-inner:before {
        width: 20px;
        top: 0;
    }

.hamburger-inner:after {
        width: 12px;
        top: 6px;
    }

.is-active .hamburger-inner:before {
            width: 16px;
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            top: 3px;
            left: 2px;
            background: #4a1172;
        }

.is-active .hamburger-inner:after {
            width: 16px;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            top: 3px;
            left: 2px;
            background: #4a1172;
        }

/*------------------------------------*\
    # component.rtf-formatted
\*------------------------------------*/

.rtf-formatted p,
    .rtf-formatted ul,
    .rtf-formatted ol,
    .rtf-formatted dl,
    .rtf-formatted table,
    .rtf-formatted object,
    .rtf-formatted img {
        margin-bottom: 16px;
        font-size: calc(13.42857px + 0.17857vw);

        line-height: calc(18.85714px + 0.35714vw);
    }

@media screen and (min-width: 1440px) {

    .rtf-formatted p,
    .rtf-formatted ul,
    .rtf-formatted ol,
    .rtf-formatted dl,
    .rtf-formatted table,
    .rtf-formatted object,
    .rtf-formatted img {
        line-height: 24px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted p,
    .rtf-formatted ul,
    .rtf-formatted ol,
    .rtf-formatted dl,
    .rtf-formatted table,
    .rtf-formatted object,
    .rtf-formatted img {
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {

    .rtf-formatted p,
    .rtf-formatted ul,
    .rtf-formatted ol,
    .rtf-formatted dl,
    .rtf-formatted table,
    .rtf-formatted object,
    .rtf-formatted img {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted p,
    .rtf-formatted ul,
    .rtf-formatted ol,
    .rtf-formatted dl,
    .rtf-formatted table,
    .rtf-formatted object,
    .rtf-formatted img {
        font-size: 14px;
    }
}

.rtf-formatted h1,
    .rtf-formatted h2,
    .rtf-formatted h3,
    .rtf-formatted h4,
    .rtf-formatted h5,
    .rtf-formatted h6 {
        margin: 24px 0;
        font-weight: 400;
        color: #2f2f30;
    }

.rtf-formatted h1 {
        font-size: calc(16.85714px + 0.98214vw);

        line-height: calc(24.57143px + 1.07143vw);
    }

@media screen and (min-width: 1440px) {

    .rtf-formatted h1 {
        line-height: 40px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted h1 {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .rtf-formatted h1 {
        font-size: 31px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted h1 {
        font-size: 20px;
    }
}

.rtf-formatted h2 {
        font-size: calc(18.57143px + 0.44643vw);

        line-height: calc(25.71429px + 0.71429vw);
    }

@media screen and (min-width: 1440px) {

    .rtf-formatted h2 {
        line-height: 36px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted h2 {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .rtf-formatted h2 {
        font-size: 25px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted h2 {
        font-size: 20px;
    }
}

.rtf-formatted h3 {
        font-size: calc(14.85714px + 0.35714vw);

        line-height: calc(22.85714px + 0.35714vw);
    }

@media screen and (min-width: 1440px) {

    .rtf-formatted h3 {
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted h3 {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .rtf-formatted h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 320px) {

    .rtf-formatted h3 {
        font-size: 16px;
    }
}

.rtf-formatted ul,
    .rtf-formatted ol,
    .rtf-formatted dl {
        list-style: inherit;
        list-style-position: inside;
        margin: 16px 0;
    }

.rtf-formatted ol {
        list-style: decimal;
        list-style-position: inside;
    }

.rtf-formatted ul li,
    .rtf-formatted ol li {
        margin-bottom: 4px;

        font-size: 14px;

        line-height: 20px;
    }

.rtf-formatted ul li:last-child, .rtf-formatted ol li:last-child {
            margin-bottom: 0;
        }

.rtf-formatted ol li {
    }

.rtf-formatted p:last-child {
            margin-bottom: 0 !important;
        }

.rtf-formatted blockquote {
        position: relative;
        font-style: italic;
        padding-left: 8px;
        opacity: 0.8;
    }

.rtf-formatted blockquote:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 2px;
            background: #4a1172;
            opacity: 0.5;
        }

/*------------------------------------*\
    # component.formatted
\*------------------------------------*/

.formatted p,
    .formatted ul,
    .formatted ol,
    .formatted dl,
    .formatted table,
    .formatted object,
    .formatted img {
        margin-bottom: 16px;
    }

.formatted h1,
    .formatted h2,
    .formatted h3,
    .formatted h4,
    .formatted h5,
    .formatted h6 {
        margin: 24px 0;
        font-weight: 400;
        color: #2f2f30;
    }

.formatted h1 {
        font-size: calc(16.85714px + 0.98214vw);

        line-height: calc(24.57143px + 1.07143vw);
    }

@media screen and (min-width: 1440px) {

    .formatted h1 {
        line-height: 40px;
    }
}

@media screen and (max-width: 320px) {

    .formatted h1 {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .formatted h1 {
        font-size: 31px;
    }
}

@media screen and (max-width: 320px) {

    .formatted h1 {
        font-size: 20px;
    }
}

.formatted h2 {
        font-size: calc(18.57143px + 0.44643vw);

        line-height: calc(25.71429px + 0.71429vw);
    }

@media screen and (min-width: 1440px) {

    .formatted h2 {
        line-height: 36px;
    }
}

@media screen and (max-width: 320px) {

    .formatted h2 {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .formatted h2 {
        font-size: 25px;
    }
}

@media screen and (max-width: 320px) {

    .formatted h2 {
        font-size: 20px;
    }
}

.formatted h3 {
        font-size: calc(14.85714px + 0.35714vw);

        line-height: calc(22.85714px + 0.35714vw);
    }

@media screen and (min-width: 1440px) {

    .formatted h3 {
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {

    .formatted h3 {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .formatted h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 320px) {

    .formatted h3 {
        font-size: 16px;
    }
}

.formatted ul,
    .formatted ol,
    .formatted dl {
        list-style: inherit;
        list-style-position: inside;
        margin: 16px 0;
    }

.formatted ol {
        list-style: decimal;
        padding-left: 12px;
    }

.formatted ul li,
    .formatted ol li {
        margin-bottom: 4px;

        font-size: 14px;

        line-height: 20px;
    }

@media     (min-width: 48em) {

.formatted ul li,
    .formatted ol li {
            font-size: 16px;
            line-height: 28px
    }
        }

.formatted ul li:last-child, .formatted ol li:last-child {
            margin-bottom: 0;
        }

.formatted ol li {
    }

.formatted ul li {
        padding-left: 1.5em;
        text-indent: -1.4em;
    }

.formatted p {
        font-size: 14px;
        line-height: 20px;
    }

@media     (min-width: 48em) {

.formatted p {
            font-size: 16px;
            line-height: 28px
    }
        }

.formatted p:last-child {
            margin-bottom: 0 !important;
        }

/*------------------------------------*\
    # component.emptystate
\*------------------------------------*/

.emptystate {
    padding: 40px;
    background: white;
}

/*------------------------------------*\
    # component.blob
\*------------------------------------*/

.blob {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    font-size: 14px;

    line-height: 20px;
    height: 36px;
    padding: 0 36px;
    border-radius: 1000px;
}

.blob--success {
    background: rgba(83, 203, 131, 0.4);
}

.blob--warning {
    background: rgba(216, 74, 74, 0.4);
    color: white;
}

.blob--note {
    background: rgba(254, 196, 92, 0.4);
}

/*------------------------------------*\
    # component.specialbox
\*------------------------------------*/

.specialbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding: 28px 20px;
    border-radius: 10px;
}

.specialbox__icon {
    min-width: 48px;
}

.specialbox--success {
    background: rgba(83, 203, 131, 0.15);
}

.specialbox--warning {
    background: rgba(216, 74, 74, 0.15);
}

.specialbox--note {
    background: rgba(254, 196, 92, 0.15);
    border: 1px solid rgba(254, 196, 92, 0.5);
}

/*------------------------------------*\
    # component.loading
\*------------------------------------*/

@-webkit-keyframes spinnerAnimation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spinnerAnimation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.loading,
.loading:after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.loading {
    position: relative;
    margin: 60px auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 3px solid #4a1172;
    border-right: 3px solid #4a1172;
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation: spinnerAnimation 0.75s infinite linear;
            animation: spinnerAnimation 0.75s infinite linear;
}

.loading--page {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

.loading--page__logo {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    fill: #786f82;
}

.loading--page__logo path {
        opacity: 0.5;
    }

.loading--page__logo path:nth-child(1) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 50ms;
                animation-delay: 50ms;
    }

.loading--page__logo path:nth-child(2) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 100ms;
                animation-delay: 100ms;
    }

.loading--page__logo path:nth-child(3) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 150ms;
                animation-delay: 150ms;
    }

.loading--page__logo path:nth-child(4) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 200ms;
                animation-delay: 200ms;
    }

.loading--page__logo path:nth-child(5) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 250ms;
                animation-delay: 250ms;
    }

.loading--page__logo path:nth-child(6) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 300ms;
                animation-delay: 300ms;
    }

.loading--page__logo path:nth-child(7) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 350ms;
                animation-delay: 350ms;
    }

.loading--page__logo path:nth-child(8) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 400ms;
                animation-delay: 400ms;
    }

.loading--page__logo path:nth-child(9) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 450ms;
                animation-delay: 450ms;
    }

.loading--page__logo path:nth-child(10) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 500ms;
                animation-delay: 500ms;
    }

.loading--page__logo path:nth-child(11) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 550ms;
                animation-delay: 550ms;
    }

.loading--page__logo path:nth-child(12) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 600ms;
                animation-delay: 600ms;
    }

.loading--page__logo path:nth-child(13) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 650ms;
                animation-delay: 650ms;
    }

.loading--page__logo path:nth-child(14) {
        -webkit-animation: fill 3s linear infinite;
                animation: fill 3s linear infinite;
        -webkit-animation-delay: 700ms;
                animation-delay: 700ms;
    }

.loading--inline {
    margin: 0;
    height: 15px;
    width: 15px;
    border-width: 2px;
    margin-left: 8px;

    border-top-color: white;
    border-right-color: white;
}

.loading--small {
    margin: 0;
}

@-webkit-keyframes fill {
    0% {
        opacity: 0.5;
    }
    4% {
        fill: #4a1172;
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes fill {
    0% {
        opacity: 0.5;
    }
    4% {
        fill: #4a1172;
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

/*------------------------------------*\
    # component.tooltip
\*------------------------------------*/

.tooltip {
    max-width: 200px;
}

@media     (min-width: 34em) {

.tooltip {
        max-width: 300px
}
    }

@media     (min-width: 48em) {

.tooltip {
        max-width: 400px
}
    }

/*------------------------------------*\
    # component.social
\*------------------------------------*/

.social {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.social__link {
    display: block;
    width: 36px;
    height: 36px;
    margin-right: 12px;
    border-radius: 50%;
    border: 1px solid rgba(57, 18, 84, 0.1);
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
}

.social__link:hover,
.social__link:focus {
    cursor: pointer;
    background-color: rgba(74, 17, 114, 0.2);
}

.social__link--facebook {
    background: url(/static/media/smm-facebook.feb42940.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--instagram {
    background: url(/static/media/smm-instagram.2cd37700.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--linkedin {
    background: url(/static/media/smm-linkedin.2e89851a.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--twitter {
    background: url(/static/media/smm-twitter.28e13de2.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.social__link--skype {
    background: url(/static/media/smm-skype.debab3d0.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

/*------------------------------------*\
    # component.redacted-card
\*------------------------------------*/

.redacted-card {
    margin-bottom: 36px;
}

@media     (min-width: 48em) {

.redacted-card {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.redacted-card:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.redacted-card:last-child {
        margin-right: 0
}

.redacted-card:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.redacted-card {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.redacted-card:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.redacted-card:last-child {
        margin-right: 0
}

.redacted-card:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 75em) {

.redacted-card {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.redacted-card:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.redacted-card:last-child {
        margin-right: 0
}

.redacted-card:nth-child(4n) {
        margin-right: 0;
        margin-left: auto
}
    }

.redacted-card.is-locked {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="-77.74000000000001 71.52 7.22 0 0 21.259999999999998 -27.48 7.22 0 0 21.259999999999998 71.52 -91.78 0 0 0 0 0 1 0" /></filter></svg>#filter');
    -webkit-filter: grayscale(100);
            filter: grayscale(100);
}

.redacted-card__body {
    position: relative;
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;

    background: #fec45c;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1), 0 42px 64px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1), 0 42px 64px rgba(0, 0, 0, .1);
}

@media       (max-width: 47.9375em) {

.redacted-card__body {
        height: 200px
}
    }

.redacted-card__body:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.4)));
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        border-radius: 20px;
    }

.redacted-card__meta {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-left: 28px;
    padding: 12px 16px 16px 0;

    border-top: 1px dashed rgba(255, 255, 255, 0.5);
    color: white;
}

.redacted-card__meta__offer-status {
    width: 100%;
    margin: 4px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 0.7;
}

.redacted-card__meta__offer-status svg {
        background-color: transparent;
        -webkit-animation: pulse 2s infinite ease-out;
                animation: pulse 2s infinite ease-out;
        border-radius: 1000px;
        margin-right: 4px;
    }

.redacted-card__meta__offer-status svg path {
            fill: white;
        }

.redacted-card__monetization {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #53cb83;
    padding: 2px 6px;
    color: white;
    border-radius: 4px;
}

.redacted-card__countdown {
    position: absolute;
    top: 48px;
    left: 20px;
    background: rgba(0, 0, 0, .5);
    padding: 2px 6px;
    color: white;
    border-radius: 4px;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.redacted-card__countdown:hover,
    .redacted-card__countdown:focus {
        background: rgba(0, 0, 0, 1);
    }

.redacted-card__meta-title svg {
    margin-right: 8px;
}

.redacted-card__meta-title svg path {
    fill: white;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
                box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
                box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
                box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
                box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}

/*------------------------------------*\
    # component.listing-grid-item
\*------------------------------------*/

.listing-grid-item {
    margin-bottom: 36px;
}

@media     (min-width: 48em) {

.listing-grid-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.listing-grid-item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-grid-item:last-child {
        margin-right: 0
}

.listing-grid-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.listing-grid-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.listing-grid-item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-grid-item:last-child {
        margin-right: 0
}

.listing-grid-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 75em) {

.listing-grid-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.listing-grid-item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-grid-item:last-child {
        margin-right: 0
}

.listing-grid-item:nth-child(4n) {
        margin-right: 0;
        margin-left: auto
}
    }

.listing-grid-item--sold {
    position: relative;
    pointer-events: none;
}

.listing-grid-item--sold:after {
        content: 'SOLD!';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-10deg);
                transform: translate(-50%, -50%) rotate(-10deg);

        font-size: 14px;

        line-height: 20px;
        color: white;
        background: #d84a4a;
        font-weight: 700;
        padding: 8px 16px;
        border-radius: 6px;
    }

.listing-grid-item--sold .v-listings__grid__link__body {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
    }

.v-listings__grid__link__heading {
    margin-bottom: 8px;
}

.v-listings__grid__link__body {
    position: relative;
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;

    background: #fec45c;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
}

.v-listings__grid__link__body:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.4)));
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        border-radius: 20px;
    }

.v-listings__grid__link__body:hover,
    .v-listings__grid__link__body:focus {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
        -webkit-box-shadow: 0 20px 48px rgba(0, 0, 0, 0.15);
                box-shadow: 0 20px 48px rgba(0, 0, 0, 0.15);
    }

.v-listings__grid__link__body:hover .v-listings__grid__link__meta__arrow, .v-listings__grid__link__body:focus .v-listings__grid__link__meta__arrow {
            -webkit-transform: translateX(-6px);
                    transform: translateX(-6px);
        }

.v-listings__grid__link__body:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        -webkit-box-shadow: 0 10px 48px rgba(0, 0, 0, 0.15);
                box-shadow: 0 10px 48px rgba(0, 0, 0, 0.15);
    }

.v-listings__grid__link__meta {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-left: 28px;
    padding: 12px 16px 16px 0;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    border-top: 1px dashed rgba(255, 255, 255, 0.5);
    color: white;
}

.v-listings__grid__link__meta__monetization {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
    margin-top: 4px;
}

.v-listings__grid__link__meta__title svg {
    margin-right: 8px;
}

.v-listings__grid__link__meta__title svg path {
    fill: white;
}

.v-listings__grid__link__meta__arrow {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.v-listings__grid__link__meta__arrow svg path {
    stroke: white;
}

.v-listings__grid__offer-status {
    width: 100%;
    margin: 4px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 0.7;
}

.v-listings__grid__offer-status svg {
        background-color: transparent;
        -webkit-animation: pulse 2s infinite ease-out;
                animation: pulse 2s infinite ease-out;
        border-radius: 1000px;
        margin-right: 4px;
    }

.v-listings__grid__offer-status svg path {
            fill: white;
        }

.listing-grid-item--skeleton .v-listings__grid__link__heading {
        position: relative;
        background: #eee;
        overflow: hidden;
        height: 30px;
        border-radius: 1000px;
        margin-bottom: 20px;
    }

.listing-grid-item--skeleton .v-listings__grid__link__heading:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
            background: -webkit-gradient(
                linear,
                left top, right top,
                from(transparent),
                color-stop(50%, #e8e8e8),
                to(transparent)
            );
            background: linear-gradient(
                90deg,
                transparent 0%,
                #e8e8e8 50%,
                transparent 100%
            );
        }

.listing-grid-item--skeleton .v-listings__grid__link__body {
        background: #eee;
        overflow: hidden;
    }

.listing-grid-item--skeleton .v-listings__grid__link__body:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
            background: -webkit-gradient(
                linear,
                left top, right top,
                from(transparent),
                color-stop(50%, #e8e8e8),
                to(transparent)
            );
            background: linear-gradient(
                90deg,
                transparent 0%,
                #e8e8e8 50%,
                transparent 100%
            );
        }

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
                box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
                box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}

/*------------------------------------*\
    # component.listing-list-item
\*------------------------------------*/

.listing-list-item {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px dashed rgba(0, 0, 0, .1);

    display: flex;

    flex-flow: row wrap;
}

.listing-list-item__exclusive-badge {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 5px 6px;
    background-color: rgba(74, 17, 114, 0.7);
    border-radius: 0px 0px 16px 16px;
}

.listing-list-item--sold {
    position: relative;
    pointer-events: none;
}

.listing-list-item--sold .listing-list-item__badge-list {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
    }

.listing-list-item--sold .listing-list-item__image {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        position: relative;
    }

.listing-list-item--sold .listing-list-item__image:after {
            content: 'SOLD!';
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%) rotate(-10deg);
                    transform: translate(-50%, -50%) rotate(-10deg);

            font-size: 14px;

            line-height: 20px;
            color: white;
            background: #d84a4a;
            font-weight: 700;
            padding: 8px 16px;
            border-radius: 6px;
        }

.listing-list-item__image {
    position: relative;
    display: block;
    height: 115px;
    width: 115px;
    border-radius: 16px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
}

.listing-list-item__image:hover,
    .listing-list-item__image:focus {
        -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
                box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }

.listing-list-item__image:active {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

.listing-list-item__image.is-locked {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="-77.74000000000001 71.52 7.22 0 0 21.259999999999998 -27.48 7.22 0 0 21.259999999999998 71.52 -91.78 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(100);
                filter: grayscale(100);
    }

.listing-list-item__image-wrapper {
    position: relative;
    display: none;
}

@media     (min-width: 48em) {

.listing-list-item__image-wrapper {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        max-width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        width: calc(99.9% * 2/12 - (36px - 36px * 2/12))
}

.listing-list-item__image-wrapper:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__image-wrapper:last-child {
        margin-right: 0
}

.listing-list-item__image-wrapper:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.listing-list-item__image-wrapper {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1.5/12 - (36px - 36px * 1.5/12));
        max-width: calc(99.9% * 1.5/12 - (36px - 36px * 1.5/12));
        width: calc(99.9% * 1.5/12 - (36px - 36px * 1.5/12))
}

.listing-list-item__image-wrapper:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__image-wrapper:last-child {
        margin-right: 0
}

.listing-list-item__image-wrapper:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.listing-list-item__image-wrapper.is-locked {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="-77.74000000000001 71.52 7.22 0 0 21.259999999999998 -27.48 7.22 0 0 21.259999999999998 71.52 -91.78 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(100);
                filter: grayscale(100);
    }

@media       (max-width: 47.9375em) {

.listing-list-item__info {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.listing-list-item__info:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__info:last-child {
        margin-right: 0
}
    }

@media     (min-width: 48em) {

.listing-list-item__info {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        padding-left: 30px
}

.listing-list-item__info:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__info:last-child {
        margin-right: 0
}

.listing-list-item__info:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.listing-list-item__info {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2.5/12 - (36px - 36px * 2.5/12));
        max-width: calc(99.9% * 2.5/12 - (36px - 36px * 2.5/12));
        width: calc(99.9% * 2.5/12 - (36px - 36px * 2.5/12));
        padding-left: 20px
}

.listing-list-item__info:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__info:last-child {
        margin-right: 0
}

.listing-list-item__info:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.listing-list-item__graphs {
    display: flex;
    flex-flow: row wrap;
}

@media       (max-width: 47.9375em) {

.listing-list-item__graphs {
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
        width: 100%;

        margin-top: 24px
}
    }

@media     (min-width: 48em) and       (max-width: 61.9375em) {

.listing-list-item__graphs {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.listing-list-item__graphs:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__graphs:last-child {
        margin-right: 0
}

.listing-list-item__graphs:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.listing-list-item__graphs {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.listing-list-item__graphs:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__graphs:last-child {
        margin-right: 0
}

.listing-list-item__graphs:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.listing-list-item__graph {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    -ms-flex-item-align: end;
        align-self: flex-end;
}

.listing-list-item__graph:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.listing-list-item__graph:last-child {
    margin-right: 0;
}

.listing-list-item__graph:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 48em) and       (max-width: 61.9375em) {

.listing-list-item__graph {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/1 - (36px - 36px * 1/1));
        max-width: calc(99.9% * 1/1 - (36px - 36px * 1/1));
        width: calc(99.9% * 1/1 - (36px - 36px * 1/1))
}

.listing-list-item__graph:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.listing-list-item__graph:last-child {
        margin-right: 0
}

.listing-list-item__graph:nth-child(1n) {
        margin-right: 0;
        margin-left: auto
}

        .listing-list-item__graph .chartjs-render-monitor {
            height: 40px !important;
        }
    }

.listing-list-item__price {

    font-size: calc(9.57143px + 1.07143vw);

    line-height: calc(15.42857px + 1.42857vw);
}

@media screen and (min-width: 1440px) {

    .listing-list-item__price {
        line-height: 36px;
    }
}

@media screen and (max-width: 600px) {

    .listing-list-item__price {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .listing-list-item__price {
        font-size: 25px;
    }
}

@media screen and (max-width: 600px) {

    .listing-list-item__price {
        font-size: 16px;
    }
}

@media       (max-width: 47.9375em) {

.listing-list-item__price {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
}

.listing-list-item__price:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0;
}

.listing-list-item__price:last-child {
        margin-right: 0;
}

.listing-list-item__price:nth-child(2n) {
        margin-right: 0;
        margin-left: auto;
}
    }

@media     (min-width: 48em) {

.listing-list-item__price {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        max-width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
}

.listing-list-item__price:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0;
}

.listing-list-item__price:last-child {
        margin-right: 0;
}

.listing-list-item__price:nth-child(12n) {
        margin-right: 0;
        margin-left: auto;
}
    }

@media     (min-width: 48em) and       (max-width: 61.9375em) {

.listing-list-item__price-icon {
        display: none
}
    }

@media       (max-width: 33.9375em) {

.listing-list-item__price-icon {
        display: none
}
    }

.listing-list-item__range {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.listing-list-item__title {
    margin: 0;
}

.listing-list-item__title.is-locked {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="-77.74000000000001 71.52 7.22 0 0 21.259999999999998 -27.48 7.22 0 0 21.259999999999998 71.52 -91.78 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(100);
                filter: grayscale(100);
    }

@media     (min-width: 62em) {

.listing-list-item__title__sold {
        display: none
}
    }

.listing-list-item__revenue {
    margin-top: 8px;
}

@media     (min-width: 48em) {

.listing-list-item__revenue {
        margin-top: 16px;
        text-align: right
}
    }

.listing-list-item__offer-status svg {
        background-color: transparent;
        -webkit-animation: shadow-pulse 2s infinite ease-out;
                animation: shadow-pulse 2s infinite ease-out;
        border-radius: 1000px;
    }

@keyframes shadow-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 -10px rgba(70, 35, 109, .8);
                box-shadow: 0 0 0 -10px rgba(70, 35, 109, .8);
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
                box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
}

/*------------------------------------*\
    # component.table
\*------------------------------------*/

.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.table td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.table th:first-child {
        border-top-left-radius: 6px;
    }

.table th:last-child {
        border-top-right-radius: 6px;
    }

.table td:nth-child(2) {
        background-color: #fec45c;
        color: white;
    }

.table th,
    .table td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
    }

.table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;

        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgb(83, 13, 133)),
            to(rgb(68, 2, 114))
        );

        background: linear-gradient(
            180deg,
            rgb(83, 13, 133) 0%,
            rgb(68, 2, 114) 100%
        );
    }

.table tr {
        border-bottom: 1px solid black;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.table tr:nth-child(even) {
            background-color: #f1f1f1;
        }

.table tr:nth-child(even) {
            background-color: #f3f3f3;
        }

.table tr:hover td {
                color: #4a1172;
            }

.table-alt {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.table-alt td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.table-alt th:first-child {
        border-top-left-radius: 6px;
    }

.table-alt th:last-child {
        border-top-right-radius: 6px;
    }

.table-alt th,
    .table-alt td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
    }

.table-alt th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;

        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgb(83, 13, 133)),
            to(rgb(68, 2, 114))
        );

        background: linear-gradient(
            180deg,
            rgb(83, 13, 133) 0%,
            rgb(68, 2, 114) 100%
        );
    }

.table-alt tr {
        border-bottom: 1px solid black;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.table-alt tr:nth-child(even) {
            background-color: #f1f1f1;
        }

.table-alt tr:nth-child(even) {
            background-color: #f3f3f3;
        }

/*------------------------------------*\
    # component.analytics-table
\*------------------------------------*/

.analytics-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.analytics-table td {
        font-size: 12px;
        line-height: 16px;
        min-width: 100px;
    }

.analytics-table th,
    .analytics-table td {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        border-bottom: 1px solid rgba();
        font-size: 12px;
        line-height: 16px;
        padding: 12px;
        width: 33%;
    }

.analytics-table th {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: left;
    }

.analytics-table tr {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.analytics-table tr:hover td {
                color: #4a1172;
            }

/*------------------------------------*\
    # component.table
\*------------------------------------*/

.comparison-table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    min-width: 900px;
    max-width: 1000px;
}

.comparison-table thead {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

.comparison-table thead td {
        padding-top: 20px;
        padding-bottom: 20px;
    }

.comparison-table td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.comparison-table th,
    .comparison-table td {
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
        color: white;
    }

.comparison-table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;

        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgb(83, 13, 133)),
            to(rgb(68, 2, 114))
        );

        background: linear-gradient(
            180deg,
            rgb(83, 13, 133) 0%,
            rgb(68, 2, 114) 100%
        );
    }

.comparison-table tr {
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.comparison-table tr td:nth-child(2) {
        color: #4a1172;
    }

.comparison-table .comparison-table__special {
        position: relative;
    }

.comparison-table .comparison-table__special:after {
            z-index: -1;
            content: '';
            position: absolute;
            top: -5px;
            left: -13px;
            height: 416.5px;
            width: 100%;
            background: #fec45c;
            border-radius: 16px;
        }

/*------------------------------------*\
    # component.payment-card
\*------------------------------------*/

.payment-card {
    padding: 12px 20px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 16px;
    background: white;
    outline: 0;
}

.payment-card.is-active {
        border-color: #4a1172;
    }

/*------------------------------------*\
    # component.payment
\*------------------------------------*/

.payment {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 6px;
}

.payment--negative {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.payment__title {
    margin-bottom: 16px;
}

.payment--negative .payment__title {
    color: white;
}

.payment__billing__item {
    width: 100%;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid rgba(120, 111, 130, 0.1);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    opacity: 0.5;
}

.payment__billing__item + .payment__billing__item {
        margin-top: 20px;
    }

.payment__billing__item:hover {
        border: 1px solid #786f82;
    }

.payment--selected {
    border-color: #4a1172;
    opacity: 1;
}

/*------------------------------------*\
    # component.purchased-listing
\*------------------------------------*/

.purchased-listing {
    float: left;
    margin-right: 6px;
}

/*------------------------------------*\
    # component.partner-item
\*------------------------------------*/

.partner-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    padding: 60px;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

@media     (min-width: 62em) {
    }

@media     (min-width: 62em) {

.partner-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2);
        max-width: calc(99.9% * 1/2);
        width: calc(99.9% * 1/2)
}

.partner-item:nth-child(1n) {
        margin-right: 0;
        margin-left: 0
}

.partner-item:last-child {
        margin-right: 0
}

        .partner-item:nth-child(odd) {
            border-right: 1px solid rgba(0, 0, 0, .1);
        }

        .partner-item:nth-child(1),
        .partner-item:nth-child(2) {
            border-top: 0;
        }
    }

.partner-item__content {
    margin-top: 170px;
    padding: 0 0 120px 0;
}

@media       (max-width: 47.9375em) {

.partner-item__content {
        margin-top: 70px;
        padding: 0 0 60px 0
}
    }

.partner-item__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

@media       (max-width: 61.9375em) {

.partner-item__header {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }

@media     (min-width: 62em) {

.partner-item__header {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

@media       (max-width: 47.9375em) {

.partner-item__header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

.partner-item__header-offer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 25%;
    margin-right: 20px;
}

@media       (max-width: 47.9375em) {

.partner-item__header-offer {
        margin-top: 10px
}
    }

.partner-item__footer {
    position: absolute;
    bottom: 60px;
}

/*------------------------------------*\
    # component.countdown
\*------------------------------------*/

.countdown {
    background: rgba(0, 0, 0, 1);
    border-radius: 2px;
    padding: 4px 8px;
    display: inline-block;
    margin-top: 8px;
    opacity: 0.5;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    color: white;
    font-weight: bold;
}

.countdown:hover,
    .countdown:focus {
        background: rgba(0, 0, 0, .8);
    }

/*------------------------------------*\
    # component.popup
\*------------------------------------*/

.popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #4a1172;
    padding: 16px;
    color: white;
    -webkit-animation-name: show;
            animation-name: show;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
}

@-webkit-keyframes show {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }

    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

@keyframes show {
    0% {
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
    }

    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

/*------------------------------------*\
    # component.valuation-sidebar
\*------------------------------------*/

@media     (min-width: 62em) {

.valuation-sidebar {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.valuation-sidebar:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.valuation-sidebar:last-child {
        margin-right: 0
}

.valuation-sidebar:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 61.9375em) {

.valuation-sidebar {
        display: none
}
    }

.valuation-sidebar__step {
    display: block;
    padding: 16px 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    margin-left: 16px;

    font-size: 14px;

    line-height: 16px;
    color: rgba(255, 255, 255, 0.5);

    border-left: 3px solid #fec45c;
    padding-left: 16px;
}

.valuation-sidebar__step.active {
        font-weight: 700;
        opacity: 1;
        color: #fec45c;
    }

.valuation-sidebar__step.active ~ .valuation-sidebar__step {
            border-left: 2px solid rgba(255, 255, 255, 0.1);
            color: white;
        }

@media       (max-width: 47.9375em) {

.valuation-sidebar__step {
        margin-right: 16px
}
    }

/*------------------------------------*\
    # component.notifications
\*------------------------------------*/

.notifications {
    z-index: 3;
    position: absolute;
    top: 76px;
    right: 0;
    background: white;
    width: 400px;
    border-radius: 6px;
    -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
            box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, .2);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

@media (max-width: 1100px) {

.notifications {
        position: fixed;
        left: 0;
        width: 100%;
        top: 68px;
        padding-bottom: 60px
}
    }

@media (max-width: 1100px) {

.notifications {
        max-height: calc(100vh - 68px);
        overflow-y: auto
}
    }

.notifications.is-active {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
}

.notifications.is-active .notifications__empty__icon {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

.notifications.is-active .notifications__empty__text {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        opacity: 1;
    }

.notifications__header {
    padding: 16px;
}

.notifications__item {
    border-top: 1px solid rgba(0, 0, 0, .1);
    line-height: 16px;
}

.notifications__item:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

.notifications__item--unseen .notifications__button {
        font-weight: 700;
        color: #2f2f30;
    }

.notifications__button {
    display: block;
    text-align: left;
    margin: 0;

    border-radius: 0;
    padding: 8px 16px;
    color: #786f82;
    width: 100%;
    white-space: normal;
    white-space: initial;

    font-size: 13px;
    line-height: 18px;
}

.notifications__button:hover,
    .notifications__button:focus {
        background: rgba(120, 111, 130, 0.1);
    }

.notifications__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: right;
    padding: 4px;
}

.notifications__timestamp {
    font-size: 12px;
    line-height: 16px;
    color: rgba(120, 111, 130, 0.6);
    margin-top: 4px;
}

.notifications__empty {
    padding: 8px 16px;
}

.notifications__empty__icon {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.notifications__empty__text {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

/*------------------------------------*\
    # component.inbox
\*------------------------------------*/

.inbox {
    z-index: 3;
    position: absolute;
    top: 76px;
    right: 0;
    background: white;
    width: 400px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
    border: 1px solid rgba(0, 0, 0, .2);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

@media (max-width: 1100px) {

.inbox {
        position: fixed;
        left: 0;
        width: 100%;
        top: 68px;
        padding-bottom: 68px
}
    }

.inbox.is-active {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
}

.inbox.is-active .inbox__empty__icon {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

.inbox.is-active .inbox__empty__text {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        opacity: 1;
    }

.inbox__header {
    padding: 16px;
}

.inbox__item {
    border-top: 1px solid rgba(0, 0, 0, .1);
    line-height: 16px;
}

.inbox__item:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

.inbox__item__message {
    max-height: 100px;
    overflow: hidden;
}

.inbox__list {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.inbox__button {
    display: block;
    text-align: left;
    margin: 0;

    border-radius: 0;
    padding: 8px 16px;
    color: #786f82;
    width: 100%;
    white-space: normal;
    white-space: initial;

    font-size: 13px;
    line-height: 18px;
}

.inbox__button:hover,
    .inbox__button:focus {
        background: rgba(120, 111, 130, 0.1);
    }

.inbox__button--unread {
    font-weight: 700;
    color: #2f2f30;
}

.inbox__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: right;
    padding: 4px;
}

.inbox__timestamp {
    font-size: 12px;
    line-height: 16px;
    color: rgba(120, 111, 130, 0.6);
    margin-top: 4px;
}

.inbox__empty {
    padding: 8px 16px;
}

.inbox__empty__icon {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.inbox__empty__text {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

/*------------------------------------*\
    # component.question
\*------------------------------------*/

.question {
    padding: 20px;
    padding-left: 24px;
    padding-bottom: 8px;
    border-radius: 6px;
    background-color: white;
}

.question--pending {
    background-color: rgba(254, 196, 92, 0.08);
}

@media     (min-width: 34em) {

.question__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start
}
    }

.question__tag {
    display: inline-block;

    padding: 4px 20px;
    background-color: transparent;
    border-radius: 1000px;
    color: #786f82;
}

@media       (max-width: 33.9375em) {

.question__tag {
        margin: 8px 0
}
    }

@media     (min-width: 34em) {

.question__tag {
        margin-left: 8px
}
    }

.question--reply {
    margin-top: 16px;
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    border-radius: 0;
    padding-left: 0;
}

.question__tag--buyer {
    background-color: rgba(254, 196, 92, 0.1);
}

.question__tag--admin {
    background-color: rgba(74, 17, 114, 0.1);
}

.question__tag--seller {
    background-color: rgba(83, 203, 131, 0.1);
}

.question__author {
    font-weight: 700;
}

.question__date {
    margin-left: auto;
    color: rgba(120, 111, 130, 0.5);
}

.question__reply-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.question__footer {
    margin-left: -24px;
}

/*------------------------------------*\
    # component.pending-question
\*------------------------------------*/

.pending-question {
    padding: 20px;
    padding-left: 24px;
    padding-bottom: 8px;
    border-radius: 6px;
    background-color: rgba(74, 17, 114, 0.08);
    margin-bottom: 10px;
}

.pending-question__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.pending-question__tag {
    margin-left: 4px;
}

.pending-question__tag--buyer {
    color: #4a1172;
}

.pending-question__tag--admin {
    color: #53cb83;
}

.pending-question__tag--seller {
    color: #fec45c;
}

.pending-question__date {
    margin-left: auto;
}

.pending-question__footer {
    margin-left: -24px;
}

/*------------------------------------*\
    # component.pending-question-list
\*------------------------------------*/

.pending-question-list {
    margin-bottom: 40px;
    border-bottom: 2px solid rgba(57, 18, 84, 0.1);
}

/*------------------------------------*\
    # component.message-preview
\*------------------------------------*/

.message-preview {
    z-index: 0;
    position: relative;
    -webkit-box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, .04);
            box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, .04);
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: white;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    max-height: 150px;
    overflow: hidden;
}

.message-preview:hover,
    .message-preview:focus {
        background-color: rgba(0, 0, 0, 0.05);
    }

.message-preview:hover:after, .message-preview:focus:after {
            pointer-events: none;
            background: -webkit-gradient(
                linear,
                left top, left bottom,
                from(rgba(0, 0, 0, 0)),
                to(#e8eaf2)
            );
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #e8eaf2 100%
            );
        }

.message-preview:active {
        -webkit-transform: scale(0.98, 0.99);
                transform: scale(0.98, 0.99);
    }

.message-preview + .message-preview {
        margin-top: 8px;
    }

.message-preview__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.message-preview--selected {
    background-color: rgba(0, 0, 0, 0.05);
}

.message-preview--selected:after {
        pointer-events: none;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#e8eaf2));
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #e8eaf2 100%);
    }

.message-preview--selected .message-preview__author,
    .message-preview--selected .message-preview__timestamp,
    .message-preview--selected .message-preview__content {
        color: #786f82;
    }

.message-preview__button {
    white-space: normal;
    white-space: initial;
    display: block;
    text-align: left;
    padding: 20px 12px;
}

.message-preview__author {
    color: #786f82;
    max-width: 220px;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
}

.message-preview__timestamp {
    color: rgba(120, 111, 130, 0.7);
}

.message-preview__content {
    color: #2f2f30;
}

.message-preview.message-preview--unread {
    margin-top: 20px;
}

.message-preview__new {
    z-index: 2;
    position: absolute;
    top: -10px;
    left: 5px;
    background: #4a1172;
    color: white;
    padding: 0px 8px;
    font-size: 10px;
    border-radius: 6px;
}

/*------------------------------------*\
    # component.message-details
\*------------------------------------*/

.message-details {
    position: relative;
    background: rgba(0, 0, 0, .03);
    border-radius: 6px;
}

.message-details + .message-details {
        margin-top: 16px;
    }

.message-details:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        width: 0px;
        height: 0px;
    }

.message-details__wrapper {
    max-height: 1000px;
    overflow-y: auto;
    padding-bottom: 80px;
}

.message-details__content {
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
}

.message-details--mine {
    background-color: white;
    margin-left: 50px;
    border-radius: 16px 0px 16px 16px;
}

@media       (max-width: 61.9375em) {

.message-details--mine {
        background: white
}
    }

/* &:after {
        right: -16px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 16px solid #f6f8fc;
    } */

.message-details--mine .message-details__header {
        border-radius: 16px 0px 0px 0px;
    }

.message-details--theirs {
    background-color: rgba(74, 17, 114, 0.05);
    margin-right: 50px;
    border-radius: 0px 16px 16px 16px;
}

.message-details--theirs:after {
        left: -16px;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-right: 16px solid #f6f8fc;
    }

.message-details--theirs .message-details__header {
        border-radius: 0px 16px 0px 0px;
    }

.message-details--agent {
    background-color: #fec45c;
    color: #2f2f30;
}

.message-details__timestamp {
    color: #786f82;
    margin-left: 8px;
}

.message-details__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background: rgba(0, 0, 0, .05);
    padding: 4px 12px;
}

.message-details__header__agent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/*------------------------------------*\
    # component.notification-details
\*------------------------------------*/

.notification-details {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 16px;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;

    white-space: normal;

    white-space: initial;
    width: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.notification-details:hover,
    .notification-details:focus {
        background: rgba(120, 111, 130, 0.1);
    }

.notification-details--unseen {
    background: rgba(254, 196, 92, 0.1);
}

.notification-details--unseen .notification-details__text {
        font-weight: 700;
        color: #786f82;
    }

.notification-details__text {
    font-size: 14px;
    line-height: 20px;
}

.notification-details__timestamp {
    text-align: right;
    color: rgba(120, 111, 130, 0.5);

    font-size: 14px;

    line-height: 20px;
}

/*------------------------------------*\
    # component.offer-list-item
\*------------------------------------*/

.offer-list-item {
    background: white;
    border-radius: 6px;
    padding: 24px 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: 100%;
}

.offer-list-item + .offer-list-item {
        margin-top: 44px;
    }

@media     (min-width: 34em) and       (max-width: 61.9375em) {

.offer-list-item + .offer-list-item {
            margin-top: 0
    }
        }

@media     (min-width: 34em) and       (max-width: 61.9375em) {

.offer-list-item {
        margin-bottom: 40px
}
    }

@media      (max-width: 74.9375em) {

.offer-list-item {
        padding: 24px 32px
}
    }

@media       (max-width: 61.9375em) {

.offer-list-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start
}
    }

@media (min-width: 650px) and       (max-width: 61.9375em) {

.offer-list-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.offer-list-item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.offer-list-item:last-child {
        margin-right: 0
}

.offer-list-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.offer-list-item__image {
    min-width: 160px;
    width: 160px;
    height: 160px;
    margin-right: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
    border-radius: 16px;
    position: relative;
    margin-top: -30px;
}

@media      (max-width: 74.9375em) {

.offer-list-item__image {
        width: 100px;
        height: 100px;
        min-width: 100px
}
    }

.offer-list-item__from {
    overflow: hidden;
    text-overflow: ellipsis;
}

.offer-list-item__cta {
    margin-top: auto;
}

@media       (max-width: 61.9375em) {

.offer-list-item__cta {
        margin-top: 8px
}
    }

.offer-list-item__info {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 200px;
}

@media       (max-width: 61.9375em) {

.offer-list-item__info {
        margin: 12px 0
}
    }

.offer-list-item__offers {
    min-width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
}

@media     (min-width: 62em) {

.offer-list-item__offers {
        margin-left: auto
}
    }

@media       (max-width: 61.9375em) {

.offer-list-item__offers {
        margin-top: 16px;
        width: 100%
}
    }

.offer-list-item__offers:after {
        z-index: 1;
        content: '';
        position: absolute;
        width: 2px;
        background-color: rgba(0, 0, 0, .1);
        height: calc(50% - 5px);
        top: calc(50% - 5px);
        left: 3px;
    }

.offer-list-item--accepted .offer-list-item__offers__latest {
        color: #53cb83;
    }

.offer-list-item--accepted .offer-list-item__offers__latest:after {
            background-color: #53cb83;
        }

.offer-list-item--pending .offer-list-item__offers__latest {
        color: #fec45c;
    }

.offer-list-item--pending .offer-list-item__offers__latest:after {
            background-color: #fec45c;
        }

.offer-list-item--declined .offer-list-item__offers__latest {
        color: #d84a4a;
    }

.offer-list-item--declined .offer-list-item__offers__latest:after {
            background-color: #d84a4a;
        }

.offer-list-item__offers__initial {
    position: relative;
    padding-left: 24px;
}

@media       (max-width: 61.9375em) {

.offer-list-item__offers__initial {
        margin-top: 12px
}
    }

.offer-list-item__offers__initial:after {
        z-index: 2;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        background: #786f82;
        border-radius: 1000px;
    }

.offer-list-item__offers__latest {
    position: relative;
    padding-left: 24px;
}

@media       (max-width: 61.9375em) {

.offer-list-item__offers__latest {
        margin-top: 12px
}
    }

.offer-list-item__offers__latest:after {
        z-index: 2;
        content: '';
        position: absolute;
        top: 0;
        left: -2px;
        width: 12px;
        height: 12px;
        border-radius: 1000px;
    }

.offer-list-item__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 200px;
}

/*------------------------------------*\
    # component.comparison
\*------------------------------------*/

.comparison {
    padding: 60px 0;
}

@media     (min-width: 62em) {

.comparison {
        padding: 120px 0
}
    }

.comparison__tables {
    z-index: 0;
    display: flex;
    flex-flow: row wrap;
    position: relative;
}

.comparison__tables:before {
        z-index: 2;
        content: 'VS';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-10deg);
                transform: translate(-50%, -50%) rotate(-10deg);
        font-size: 25px;
        line-height: 25px;
        color: rgb(142, 93, 1);
    }

.comparison__tables:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-10deg);
                transform: translate(-50%, -50%) rotate(-10deg);
        border-radius: 1000px;
        -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .2);
                box-shadow: 0 5px 8px rgba(0, 0, 0, .2);
        width: 80px;
        height: 80px;
        border: 1px solid white;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgba(252, 202, 113, 1)),
            to(rgba(245, 187, 100, 1))
        );
        background: linear-gradient(
            180deg,
            rgba(252, 202, 113, 1) 0%,
            rgba(245, 187, 100, 1) 100%
        );
    }

.comparison__table {
    padding: 44px 52px;
    border-radius: 16px;
    background: white;
    list-style-type: none;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

@media     (min-width: 62em) {

.comparison__table {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.comparison__table:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.comparison__table:last-child {
        margin-right: 0
}
    }

@media       (max-width: 61.9375em) {

.comparison__table {
        margin-top: 32px;
        padding: 24px 24px
}
    }

.comparison__table--primary {
    background: #4a1172;
    -webkit-transform: scale(1);
            transform: scale(1);

    position: relative;
    z-index: 0;
}

.comparison__table--primary:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        opacity: 0.2;
        background-repeat: repeat;
        background-size: 30%;
    }

.comparison__item {
    padding: 20px 0;
    padding-right: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    font-size: calc(12.28571px + 0.53571vw);

    line-height: calc(17.71429px + 0.71429vw);
}

@media screen and (min-width: 1440px) {

    .comparison__item {
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {

    .comparison__item {
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {

    .comparison__item {
        font-size: 20px;
    }
}

@media screen and (max-width: 320px) {

    .comparison__item {
        font-size: 14px;
    }
}

.comparison__table--primary .comparison__item {
        color: white;
        border-color: rgba(255, 255, 255, .1);
    }

.comparison__item__icon {
    margin-right: 28px;
    min-width: 38px;
}

/*------------------------------------*\
    # component.submitted-listings
\*------------------------------------*/

.submitted-listings {
    margin-bottom: 120px;
}

.submitted-listings__item {
    background: white;
    border-radius: 6px;
    padding: 20px 32px;
    display: flex;
    flex-flow: row wrap;
}

.submitted-listings__item + .submitted-listings__item {
        margin-top: 20px;
    }

@media       (max-width: 33.9375em) {

.submitted-listings__column {
        width: 100%;
        margin-bottom: 16px
}
    }

@media     (min-width: 34em) {

.submitted-listings__column {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.submitted-listings__column:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.submitted-listings__column:last-child {
        margin-right: 0
}

.submitted-listings__column:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.submitted-listings__column {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.submitted-listings__column:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.submitted-listings__column:last-child {
        margin-right: 0
}

.submitted-listings__column:nth-child(4n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # component.badge
\*------------------------------------*/

.badge {
    padding: 4px 8px;

    border-radius: 4px;
}

.badge--grid:not(:last-child) {
        margin-bottom: 4px;
    }

.badge--list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 4px;
    margin-bottom: 4px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.badge--portfolio {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#fec45c),
        to(rgb(254, 180, 42))
    );
    background: linear-gradient(
        180deg,
        #fec45c 0%,
        rgb(254, 180, 42) 100%
    );
}

.badge--portfolio--alt {
    background: transparent;
    border: 1px solid rgb(244, 159, 1);
    color: rgb(244, 159, 1);
}

.badge--brokered {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#2f2f30),
        to(rgb(23, 23, 23))
    );
    background: linear-gradient(
        180deg,
        #2f2f30 0%,
        rgb(23, 23, 23) 100%
    );
}

.badge--not-published {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#d84a4a),
        to(rgb(198, 42, 42))
    );
    background: linear-gradient(
        180deg,
        #d84a4a 0%,
        rgb(198, 42, 42) 100%
    );
}

.badge--early-access {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#d84a4a),
        to(rgb(198, 42, 42))
    );
    background: linear-gradient(
        180deg,
        #d84a4a 0%,
        rgb(198, 42, 42) 100%
    );
}

.badge--exclusive {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#4a1172),
        to(rgb(46, 11, 71))
    );
    background: linear-gradient(
        180deg,
        #4a1172 0%,
        rgb(46, 11, 71) 100%
    );
}

.badge--success {
    border: 1px solid #53cb83;
    background: transparent;
}

.badge--updated {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#fec45c),
        to(rgb(254, 180, 42))
    );
    background: linear-gradient(
        180deg,
        #fec45c 0%,
        rgb(254, 180, 42) 100%
    );
}

.badge--new {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#53cb83),
        to(rgb(54, 181, 105))
    );
    background: linear-gradient(
        180deg,
        #53cb83 0%,
        rgb(54, 181, 105) 100%
    );
}

.listing-grid-item__badge-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    position: absolute;
    top: 20px;
    left: 16px;
    z-index: 2;
}

/*------------------------------------*\
    # component.bundle-header
\*------------------------------------*/

.bundle-header {
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    background-color: white;
    background-size: 45%;
    border-radius: 16px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
    margin-bottom: 20px;
    padding: 20px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

@media     (min-width: 62em) {

.bundle-header {
        padding: 40px
}
    }

@media     (min-width: 48em) {

.bundle-header {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (min-width: 48em) {

.bundle-header__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.bundle-header__header:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.bundle-header__header:last-child {
        margin-right: 0
}

.bundle-header__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.bundle-header__info {
    display: flex;
    flex-flow: row wrap;
}

@media       (max-width: 47.9375em) {

.bundle-header__info {
        margin-top: 40px
}
    }

@media     (min-width: 48em) {

.bundle-header__info {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.bundle-header__info:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.bundle-header__info:last-child {
        margin-right: 0
}

.bundle-header__info:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.bundle-header__details {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
    max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
    width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
}

.bundle-header__details:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.bundle-header__details:last-child {
    margin-right: 0;
}

.bundle-header__details:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

/*------------------------------------*\
    # component.portfolio-details
\*------------------------------------*/

.portfolio-details {
}

.portfolio-details__item + .portfolio-details__item {
        margin-top: 32px;
    }

.portfolio-details__row {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
}

/*------------------------------------*\
    # component.admin-overview
\*------------------------------------*/

.admin-overview__item {
    overflow-x: auto;
}

.admin-overview__item + .admin-overview__item {
        margin-top: 40px;
    }

.admin-overview__item {

    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05), 0 42px 64px rgba(0, 0, 0, .05);

            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05), 0 42px 64px rgba(0, 0, 0, .05);
}

.admin-overview__item + .admin-overview__item {
        margin-top: 24px;
    }

.admin-overview__item {

    background: white;
    border: 1px solid rgba(57, 18, 84, 0.1);
    border-radius: 6px;
    padding: 40px;
}

.admin-overview__header {
}

.admin-overview__legend-item {
    padding-left: 16px;
    position: relative;
}

.admin-overview__legend-item:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 6px;
    }

.admin-overview__legend-item--buyers:before {
        background-color: #4a1172;
    }

.admin-overview__legend-item--sellers:before {
        background-color: #fec45c;
    }

.admin-overview__doughnut {
    position: relative;
}

.admin-overview__doughnut:after {
        pointer-events: none;
        content: '';
        position: absolute;
        left: 50%;
        top: -8px;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        background: transparent;
        border-radius: 1000px;
        border-width: 4px;
        border: 4px #786f82 solid;
        opacity: 0.2;
        height: 165px;
        width: 165px;
    }

.admin-overview__doughnut__info {
    position: absolute;
    top: 48%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 700;
}

.admin-overview__graphs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.admin-overview__bar-chart {
    position: relative;
    height: 120px;
    width: 100%;
}

.admin-overview__bar-chart .chartjs-render-monitor {
        height: 120px !important;
        width: 100% !important;
    }

.admin-overview__line-chart {
    height: 150px;
}

.admin-overview__datepicker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.admin-overview__message-stats {
}

.admin-overview__message-stats__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.admin-overview__message-stats__graph {
}

/*------------------------------------*\
    # component.user-profile
\*------------------------------------*/

.user-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.user-profile__description {
    padding-left: 36px;
}

.user-profile__img {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
    -o-object-fit: cover;
       object-fit: cover;
}

/*------------------------------------*\
    # component.lifetime-upsell
\*------------------------------------*/

.lifetime-upsell {
    margin-top: 40px;
}

.lifetime-upsell__card {
    max-width: 500px;
}

/*------------------------------------*\
    # component.no-fee
\*------------------------------------*/

.no-fee {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    width: 100%;
    border-radius: 1000px;
    color: white;
    padding: 8px 40px;
    -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
    margin-bottom: 40px;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#53cb83),
        to(rgb(84, 153, 112))
    );

    background: linear-gradient(
        180deg,
        #53cb83 0%,
        rgb(84, 153, 112) 100%
    );
}

.no-fee__icon {
    min-width: 24px;
}

.no-fee__text {
    margin-left: 20px;
}

.no-fee__action {
    margin-left: auto;
    padding: 10px;
    color: white;
}

.no-fee__action:hover,
    .no-fee__action:focus {
        opacity: 0.5;
    }

/*------------------------------------*\
    # component.earnings-potential
\*------------------------------------*/

.earnings-potential {
}

.earnings-potential__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.earnings-potential__bar {
    position: relative;
    height: 8px;
    border-radius: 1000px;
    background: #53cb83;
    margin-bottom: 20px;
}

.earnings-potential__bar__current {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 1000px;
    background: #4a1172;
}

.earnings-potential__potential {
    margin-left: 100px;
}

.earnings-potential__details {
    margin-bottom: 48px;
    margin-top: 12px;
}

/*------------------------------------*\
    # component.earnings-potential
\*------------------------------------*/

.required-workload {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);
            box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);

    background: #53cb83;
    padding: 8px 16px;
    margin: 20px 0;
    border-radius: 1000px;

    color: white;
    font-weight: bold;
}

.required-workload__icon {
    width: 12px;
    height: 12px;
    padding-right: 18px;
}

.required-workload--high {
    color: white;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#d84a4a),
        to(rgb(156, 33, 33))
    );

    background: linear-gradient(
        180deg,
        #d84a4a 0%,
        rgb(156, 33, 33) 100%
    );
}

.required-workload--medium {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#fec45c),
        to(rgb(254, 171, 16))
    );
    background: linear-gradient(
        180deg,
        #fec45c 0%,
        rgb(254, 171, 16) 100%
    );
}

.required-workload--low {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#29af00),
        to(rgb(29, 122, 0))
    );
    background: linear-gradient(
        180deg,
        #29af00 0%,
        rgb(29, 122, 0) 100%
    );
}

/*------------------------------------*\
    # component.risk-status
\*------------------------------------*/

.risk-status {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);
            box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);

    padding: 8px 16px;
    margin: 20px 0;
    border-radius: 1000px;

    color: white;
    font-weight: bold;
}

.risk-status--10 {
    color: white;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#d84a4a),
        to(rgb(156, 33, 33))
    );

    background: linear-gradient(
        180deg,
        #d84a4a 0%,
        rgb(156, 33, 33) 100%
    );
}

.risk-status--9 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#cb5353),
        to(rgb(181, 54, 54))
    );
    background: linear-gradient(
        180deg,
        #cb5353 0%,
        rgb(181, 54, 54) 100%
    );
}

.risk-status--8 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#cb6a53),
        to(rgb(181, 79, 54))
    );
    background: linear-gradient(
        180deg,
        #cb6a53 0%,
        rgb(181, 79, 54) 100%
    );
}

.risk-status--7 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#cb7c53),
        to(rgb(181, 98, 54))
    );
    background: linear-gradient(
        180deg,
        #cb7c53 0%,
        rgb(181, 98, 54) 100%
    );
}

.risk-status--6 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#cb9253),
        to(rgb(181, 122, 54))
    );
    background: linear-gradient(
        180deg,
        #cb9253 0%,
        rgb(181, 122, 54) 100%
    );
}

.risk-status--5 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#cba353),
        to(rgb(181, 138, 54))
    );
    background: linear-gradient(
        180deg,
        #cba353 0%,
        rgb(181, 138, 54) 100%
    );
}

.risk-status--4 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#cbb053),
        to(rgb(181, 153, 54))
    );
    background: linear-gradient(
        180deg,
        #cbb053 0%,
        rgb(181, 153, 54) 100%
    );
}

.risk-status--3 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#abcb53),
        to(rgb(147, 181, 54))
    );
    background: linear-gradient(
        180deg,
        #abcb53 0%,
        rgb(147, 181, 54) 100%
    );
}

.risk-status--2 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#6acb53),
        to(rgb(77, 181, 54))
    );
    background: linear-gradient(
        180deg,
        #6acb53 0%,
        rgb(77, 181, 54) 100%
    );
}

.risk-status--1 {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#29af00),
        to(rgb(29, 122, 0))
    );
    background: linear-gradient(
        180deg,
        #29af00 0%,
        rgb(29, 122, 0) 100%
    );
}

/*------------------------------------*\
    # component.expandable
\*------------------------------------*/

.expandable {
}

.expandable--open svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

.expandable__button {
    margin-left: -20px;
    margin-top: 16px;
    display: block;
    color: #4a1172;
}

/*------------------------------------*\
    # component.slate
\*------------------------------------*/

.slate {
    position: relative;
}

.slate__editor {
    padding-top: 56px;
    display: block !important;
    word-wrap: anywhere !important;
    height: 300px;
    resize: vertical;
    overflow-y: scroll;
}

.slate__editor--error {
    border: 1px solid #d84a4a;
}

.slate__editor ul,
.slate__editor ol {
    list-style-position: inside;
}

.slate__editor ul {
    list-style-type: disc;
}

.slate__toolbar {
    position: absolute;
    top: 6px;
    left: 6px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: white;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
}

.slate__toolbar__icon {
    background: transparent;
    padding: 8px 12px;
    margin: 2px;
    opacity: 0.6;
}

.slate__toolbar__icon:hover,
    .slate__toolbar__icon:focus {
        background: rgba(0, 0, 0, .1);
    }

.slate__toolbar__icon--active {
    background: rgba(74, 17, 114, 0.15);
    border-radius: 6px;
}

.slate__toolbar__icon--active svg {
        fill: #4a1172;
    }

/*------------------------------------*\
    # component.top-bar
\*------------------------------------*/

.top-bar {
    margin: 0 auto;
    text-align: center;
    background: #5ff199;
    color: white;
    font-weight: 700;
    padding: 8px 24px;

    position: relative;
    z-index: 0;
    overflow: hidden;
}

.top-bar:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        opacity: 0.2;
        background-repeat: repeat;
        background-size: 13%;
    }

@media       (max-width: 61.9375em) {

.top-bar {
        padding-right: 60px
}
    }

@media       (max-width: 61.9375em) {

.top-bar__cta {
        display: block
}
    }

/*------------------------------------*\
    # component.profile-badge
\*------------------------------------*/

.profile-badge {
    padding: 2px 4px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 4px;
}

.profile-badge {
    text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
}

.profile-badge--vip {
    background: #fec45c;
    border-radius: 1px;
    color: black;
    text-shadow: none;
}

.profile-badge--100K {
    color: white;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#3527e8),
        to(rgb(25, 16, 152))
    );

    background: linear-gradient(
        180deg,
        #3527e8 0%,
        rgb(25, 16, 152) 100%
    );
}

.profile-badge--250K {
    color: white;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#29af00),
        to(rgb(29, 122, 0))
    );

    background: linear-gradient(
        180deg,
        #29af00 0%,
        rgb(29, 122, 0) 100%
    );
}

.profile-badge--500K {
    color: white;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#530d85),
        to(rgb(26, 4, 42))
    );

    background: linear-gradient(
        180deg,
        #530d85 0%,
        rgb(26, 4, 42) 100%
    );
}

.profile-badge--750K {
    color: white;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#b77102),
        to(rgb(106, 66, 1))
    );

    background: linear-gradient(
        180deg,
        #b77102 0%,
        rgb(106, 66, 1) 100%
    );
}

.profile-badge--1M {
    color: #f7dc4e;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#310000),
        to(rgb(0, 0, 0))
    );

    background: linear-gradient(
        180deg,
        #310000 0%,
        rgb(0, 0, 0) 100%
    );
}

/*------------------------------------*\
    # component.recently-sold
\*------------------------------------*/

.recently-sold {
    padding-top: 32px;
}

.recently-sold__list {
    position: relative;
}

.recently-sold__item {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .05);
            box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .05);
    border-radius: 6px;
    background: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    margin: 0 24px;
    margin-top: 8px;
    margin-bottom: 48px;
}

@media       (max-width: 33.9375em) {

.recently-sold__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        text-align: center
}
    }

.recently-sold--alt {
    background: white;
}

.recently-sold--alt .recently-sold__item {
        border: 1px solid rgba(0, 0, 0, .05);
        background: #f4f7ff;
    }

@media       (max-width: 33.9375em) {

.recently-sold__price {
        margin-top: 24px
}
    }

.recently-sold__loading {
    padding-top: 10px;
    padding-bottom: 70px;
}

/*------------------------------------*\
    # component.table
\*------------------------------------*/

.overview-table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    max-width: 500px;
}

.overview-table td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.overview-table th:first-child {
        border-top-left-radius: 6px;
    }

.overview-table th:last-child {
        border-top-right-radius: 6px;
    }

.overview-table th,
    .overview-table td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
    }

.overview-table th {
        width: 100%;
        text-align: left;
        padding: 12px 0;
    }

.overview-table tr {
        border-bottom: 1px solid black;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.overview-table tr:nth-child(even) {
            background-color: #f1f1f1;
        }

.overview-table tr:nth-child(even) {
            background-color: #f3f3f3;
        }

.overview-table tr:hover td {
                color: #4a1172;
            }

.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.table td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.table th:first-child {
        border-top-left-radius: 6px;
    }

.table th:last-child {
        border-top-right-radius: 6px;
    }

.table td:nth-child(2) {
        background-color: #fec45c;
        color: white;
    }

.table th,
    .table td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
    }

.table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }

.table tr {
        border-bottom: 1px solid black;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.table tr:nth-child(even) {
            background-color: #f1f1f1;
        }

.table tr:nth-child(even) {
            background-color: #f3f3f3;
        }

.table tr:hover td {
                color: #4a1172;
            }

.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.table td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.table th:first-child {
        border-top-left-radius: 6px;
    }

.table th:last-child {
        border-top-right-radius: 6px;
    }

.table th,
    .table td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
    }

.table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }

.table tr {
        border-bottom: 1px solid black;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.table tr:nth-child(even) {
            background-color: #f1f1f1;
        }

.table tr:nth-child(even) {
            background-color: #f3f3f3;
        }

.table tr:hover td {
                color: #4a1172;
            }

.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.table td {
        font-size: 14px;
        line-height: 16px;
        min-width: 100px;
    }

.table th:first-child {
        border-top-left-radius: 6px;
    }

.table th:last-child {
        border-top-right-radius: 6px;
    }

.table td:nth-child(2) {
        background-color: #fec45c;
        color: white;
    }

.table th,
    .table td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
    }

.table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }

.table tr {
        border-bottom: 1px solid black;
        -webkit-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.table tr:nth-child(even) {
            background-color: #f1f1f1;
        }

.table tr:nth-child(even) {
            background-color: #f3f3f3;
        }

.table tr:hover td {
                color: #4a1172;
            }

/*------------------------------------*\
    # component.checkout-table
\*------------------------------------*/

.checkout-table {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

/* Every row except first */

.checkout-table > * + * {
        margin-top: 8px;
    }

@media    (min-width: 75em) {
        .checkout-table > * + * {
            margin-top: 20px;
        }
    }

.checkout-table__row {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;
}

.checkout-table__row__data {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
            align-content: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 12px 24px;
        background: #f4f7ff;
        text-align: center;
    }

.checkout-table__row__data:nth-of-type(1) {
            text-align: center;
        }

@media    (min-width: 75em) {

.checkout-table__row__data:nth-of-type(1) {
                -webkit-box-pack: start;
                    -ms-flex-pack: start;
                        justify-content: flex-start;
                text-align: start
        }
            }

.checkout-table__row__data:nth-of-type(2) {
            border-radius: 10px 0 0 10px;
        }

.checkout-table__row__data:nth-of-type(3) {
            border-radius: 0 10px 10px 0;
        }

@media    (min-width: 75em) {

.checkout-table__row__data {
            border-radius: 10px
    }
        }

@media    (min-width: 75em) {

.checkout-table__row {
        grid-gap: 20px
}
    }

.checkout-table__first-row {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}

@media    (min-width: 75em) {

.checkout-table__first-row {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1
}
    }

.checkout-table__second-row {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}

@media    (min-width: 75em) {

.checkout-table__second-row {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2
}
    }

.checkout-table__third-row {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
}

.checkout-table__most-popular {
    position: absolute;
    top: -24px;

    padding: 4px 10px;
    color: white;
    background: #fec45c;
    border-radius: 0px 10px 0 10px;
}

@media    (min-width: 75em) {

.checkout-table__most-popular {
        top: 0;
        right: 0;
        background: #4a1172
}
    }

.checkout-table__icon {
    width: 20px;
    height: 20px;
}

@media    (min-width: 75em) {

.checkout-table__icon {
        width: 24px;
        height: 24px
}
    }

.checkout-table__initial-row {
    background: white;
    text-align: center;
}

@media    (min-width: 75em) {

.checkout-table__initial-row {
        background: #f4f7ff
}
    }

.checkout-table__unauthorized-message {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    color: #4a1172;
    word-break: break-word;
}

/*------------------------------------*\
    # component.checkout-modal
\*------------------------------------*/

.checkout-modal__price {
    display: inline;
}

.checkout-modal__sale {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}

.checkout-modal__black-friday {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    color: red;
    margin-bottom: 16px;
    margin-left: 12px;
}

/*------------------------------------*\
    # component.checkout-table-mobile
\*------------------------------------*/

.checkout-table-mobile {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    padding-bottom: 20px;
}

.checkout-table-mobile thead tr td {
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
            }

.checkout-table-mobile tbody {
        width: 100%;
    }

.checkout-table-mobile th,
    .checkout-table-mobile td {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

.checkout-table-mobile td {
        font-size: 16px;
        line-height: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 16px;
    }

.checkout-table-mobile tr:last-of-type td {
            border: none;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        }

.checkout-table-mobile tr:last-of-type td p {
                width: 100%;
                text-align: center;
            }

.checkout-table-mobile svg {
        height: 32px;
        width: 32px;
        -ms-flex-negative: 0;
            flex-shrink: 0;
    }

.checkout-table-mobile strong {
        margin-right: 8px;
    }

.checkout-table-mobile:first-of-type {
    background-color: #4a1172;
    color: white;
}

.checkout-table-mobile:first-of-type th,
    .checkout-table-mobile:first-of-type td {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

.checkout-table-mobile:nth-of-type(2) {
    background-color: white;
}

/*------------------------------------*\
    # component.content-metric
\*------------------------------------*/

.content-metric {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
}

@media     (min-width: 34em) {

.content-metric {
        grid-template-columns: 1fr 1fr
}
    }

@media     (min-width: 62em) {

.content-metric {
        grid-template-columns: 1fr 1fr 1fr 1fr
}
    }

.content-metric__item {
    padding: 16px;
    background: rgba(0, 0, 0, .05);
    border-radius: 6px;
    text-align: center;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    background: -webkit-gradient(
        linear,
        left top, left bottom,
        color-stop(20%, rgba(244, 247, 255, 0)),
        to(#f4f7ff)
    );

    background: linear-gradient(
        180deg,
        rgba(244, 247, 255, 0) 20%,
        #f4f7ff 100%
    );
}

.content-metric__number {
    font-size: 24px;
    font-weight: bold;
    display: block;
}

.content-metric__title {
    font-size: 12px;
    padding-top: 6px;
}

/**
* Import: block
* Description: specific website/app blocks
**/

/*------------------------------------*\
    # block.header
\*------------------------------------*/

.header {
    background: white;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .02);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .02);
}

.header__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.header__home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
}

@media (max-width: 1100px) {

.header__hamburger {
        position: fixed;
        z-index: 101;
        top: 12px;
        right: 24px
}
    }

@media (min-width: 1101px) {

.header__hamburger {
        display: none
}
    }

.header--alt {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.header--alt .logo path {
            fill: white;
        }

.header--alt .nav__link {
        color: white;
    }

.header--alt .nav__link:hover,
        .header--alt .nav__link:focus {
            color: rgba(255, 255, 255, 0.7);
        }

.header--alt .nav__link--alt {
        background-color: rgba(0, 0, 0, 0.2);
    }

.header--alt .nav__link--alt:hover,
        .header--alt .nav__link--alt:focus {
            color: white;
        }

.header--alt .nav__icon {
        fill: rgba(255, 255, 255, 0.5);
    }

.header--alt .nav__link--new {
        background-color: white;
    }

.header--alt .nav__link--new .nav__icon {
            fill: #4a1172;
        }

.header--alt .nav__link--new:hover,
        .header--alt .nav__link--new:focus {
            background-color: rgba(255, 255, 255, 0.7);
        }

.header--alt .logo-mobile {
        fill: white;
    }

/*------------------------------------*\
    # block.nav
\*------------------------------------*/

.nav {
    width: 100%;
}

@media (max-width: 1100px) {

.nav {
        visibility: hidden;
        pointer-events: none;

        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        -webkit-backdrop-filter: blur(0);
                backdrop-filter: blur(0);
        background: rgba(74, 17, 114, 0.7);

        -webkit-transition: all 0.3s ease-out;

        transition: all 0.3s ease-out
}

        .nav.is-open {
            -webkit-backdrop-filter: blur(6px);
                    backdrop-filter: blur(6px);
            pointer-events: auto;
            visibility: visible;
        }
    }

.nav__item {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 1100px) {

.nav__item--profile {
        display: none
}
    }

@media (max-width: 1100px) {

.nav__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 120px 24px 0 24px;

        -webkit-transition: all 0.3s ease-out;

        transition: all 0.3s ease-out;
        opacity: 0
}

        .is-open .nav__list {
            opacity: 1;
        }
    }

@media (min-width: 1101px) {

.nav__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end
}
    }

@media (max-width: 1100px) {

.nav__list .nav__link--alt {
            display: none
    }
        }

.nav__link {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #2f2f30;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.nav__link:hover,
    .nav__link:focus {
        color: rgba(47, 47, 48, 0.6);
    }

@media (max-width: 1100px) {

.nav__link {
        padding: 16px 0;
        color: white;

        font-size: calc(16.85714px + 0.98214vw);

        line-height: calc(24.57143px + 1.07143vw)
}

@media screen and (min-width: 1440px) {

        .nav__link {
            line-height: 40px;
        }
    }

@media screen and (max-width: 320px) {

        .nav__link {
            line-height: 28px;
        }
    }

@media screen and (min-width: 1440px) {

        .nav__link {
            font-size: 31px;
        }
    }

@media screen and (max-width: 320px) {

        .nav__link {
            font-size: 20px;
        }
    }
    }

@media (min-width: 1101px) {

.nav__link {
        padding: 24px 16px;

        font-size: calc(11.71429px + 0.08929vw);

        line-height: calc(14.85714px + 0.35714vw)
}

@media screen and (min-width: 1440px) {

        .nav__link {
            line-height: 20px;
        }
    }

@media screen and (max-width: 320px) {

        .nav__link {
            line-height: 16px;
        }
    }

@media screen and (min-width: 1440px) {

        .nav__link {
            font-size: 13px;
        }
    }

@media screen and (max-width: 320px) {

        .nav__link {
            font-size: 12px;
        }
    }
    }

.nav__link.active {
        color: #4a1172;
        font-weight: 700;
    }

.nav__link--alt {
    border-radius: 1000px;
    padding: 12px;
    -webkit-transition: all 0.15 ease-out;
    transition: all 0.15 ease-out;

    color: #2f2f30;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.05);
}

.nav__link--alt:hover,
    .nav__link--alt:focus {
        color: #2f2f30;
        background-color: rgba(0, 0, 0, 0.1);
    }

.nav__link--new {
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(252, 202, 113, 1)),
        to(rgba(245, 187, 85, 1))
    );
    background: linear-gradient(
        180deg,
        rgba(252, 202, 113, 1) 0%,
        rgba(245, 187, 85, 1) 100%
    );
}

.nav__link--new:hover,
    .nav__link--new:focus {
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgba(252, 202, 113, 1)),
            to(rgba(245, 187, 85, 1))
        );
        background: linear-gradient(
            180deg,
            rgba(252, 202, 113, 1) 0%,
            rgba(245, 187, 85, 1) 100%
        );
        opacity: 0.7;
    }

.nav__link--new .nav__icon {
        fill: white;
    }

.nav__link--button {
    padding: 20px 16px;
}

.nav__link--button:after {
        display: none;
    }

.nav__link--button:hover:after, .nav__link--button:focus:after {
            display: none;
        }

.nav__icon {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    fill: rgba(0, 0, 0, 0.6);
}

.nav__item--dropdown {
    position: relative;
}

@media (max-width: 1100px) {

.nav__item--dropdown {
        display: none
}
    }

.nav__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 50px;
}

@media (min-width: 1101px) {

.nav__mobile {
        display: none
}
    }

@media (max-width: 1100px) {

.nav__mobile .nav__item--profile,
    .nav__mobile .nav__item--dropdown {
            display: inline;
            display: initial
    }
        }

.nav__item__name {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 18px;
}

@media       (max-width: 33.9375em) {

.nav__item__name {
        max-width: 50px
}
    }

.nav__link--main {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
}

.nav__link--main.active {
        color: #fec45c;
    }

/*------------------------------------*\
    # block.sidenav
\*------------------------------------*/

.sidenav {
    position: sticky;
    top: 0;
    left: 0;

    padding: 80px 0 60px;
}

.sidenav__item {
}

.sidenav__item > a {
    position: relative;
    display: block;
    padding: 6px 0;

    font-size: 14px;

    line-height: 20px;
    color: #786f82;
}

.sidenav__item > a:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -24px;
        height: 100%;
        width: 1px;
        background: rgba(57, 18, 84, 0.1);
        -webkit-transition: all 0.2s ease-out, width 1ms;
        transition: all 0.2s ease-out, width 1ms;
    }

.sidenav__item > a:hover:before {
            background: #4a1172;
            width: 2px;
            outline: 0;
        }

.sidenav__item--active a:before {
            background: #4a1172;
            width: 2px;
        }

.sidenav__item > a > span {
    font-weight: 700;
    color: #4a1172;
}

.sidenav__item ul {
    padding-left: 12px;
}

.sidenav__item ul li {
    padding: 6px 0;
    font-size: 14px;
    line-height: 20px;
    color: #786f82;
}

.sidenav__btn {
    padding: 16px;
}

/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer {
    background: white;
    z-index: 0;
    padding: 40px 0;
}

@media     (min-width: 62em) {

.footer {
        padding: 80px 0
}
    }

@media     (min-width: 62em) {

.footer__wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 61.9375em) {

.footer__branding {
        margin-bottom: 48px
}
    }

@media     (min-width: 62em) {

.footer__branding {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.footer__branding:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.footer__branding:last-child {
        margin-right: 0
}

.footer__branding:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.footer__branding img,
.footer__branding svg {
    width: 16px;
    height: 16px;
}

.footer__social {
    margin-top: 16px;
    margin-left: -8px;
}

.footer__social a:hover svg path {
                -webkit-transition: all 0.15s ease-out;
                transition: all 0.15s ease-out;
                fill: #fec45c;
            }

.footer__social__link {
    padding: 8px;
}

.footer__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

@media       (max-width: 61.9375em) {

.footer__links {
        margin-bottom: 32px;
        max-width: 400px
}
    }

@media     (min-width: 62em) {

.footer__links {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.footer__links:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.footer__links:last-child {
        margin-right: 0
}

.footer__links:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.footer__links li {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
}

.footer__links li:last-child {
        -webkit-box-flex: initial;
            -ms-flex: initial;
                flex: initial;
        width: 50%;
    }

.footer__link {
    display: block;
    padding: 4px 0;
    color: #786f82;
}

@media     (min-width: 62em) {

.footer__copy {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.footer__copy:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.footer__copy:last-child {
        margin-right: 0
}

.footer__copy:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.modal
\*------------------------------------*/

@-webkit-keyframes animateModal {
    to {
        -webkit-backdrop-filter: blur(6px);
                backdrop-filter: blur(6px);
        opacity: 1;
    }
}

@keyframes animateModal {
    to {
        -webkit-backdrop-filter: blur(6px);
                backdrop-filter: blur(6px);
        opacity: 1;
    }
}

@-webkit-keyframes animateModalWrapper {
    to {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes animateModalWrapper {
    to {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.modal {
    position: fixed;
    z-index: 110;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh;

    overflow: hidden;
    width: 100%;

    background-color: rgba(74, 17, 114, 0.4);

    -webkit-backdrop-filter: blur(6px);

            backdrop-filter: blur(6px);
    opacity: 0;

    -webkit-animation: animateModal 0.3s ease-out forwards;

            animation: animateModal 0.3s ease-out forwards;
}

@media     (min-width: 34em) {

.modal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-items: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }

.modal__wrapper {
    position: relative;
    overflow: auto;
    z-index: 2;

    margin: 0 auto;

    background-color: white;
    -webkit-box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, .4);
            box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, .4);

    -webkit-transition: all 0.2s;

    transition: all 0.2s;
    -webkit-animation: animateModalWrapper 0.2s ease-out forwards;
            animation: animateModalWrapper 0.2s ease-out forwards;

    -webkit-transform: translateY(-30px);

            transform: translateY(-30px);
}

@media       (max-width: 33.9375em) {

.modal__wrapper {
        height: 100vh
}
    }

@media     (min-width: 34em) {

.modal__wrapper {
        width: 100%;
        max-width: 500px;
        border-radius: 6px
}
    }

.modal__wrapper--wide {
    max-width: 800px;
}

.modal__content {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
}

@media       (max-width: 33.9375em) {

.modal__content {
        max-height: 540px;
        padding: 0 32px
}
    }

@media     (min-width: 34em) {

.modal__content {
        padding: 16px 32px
}
    }

.modal__header {
    padding: 80px 32px 32px 32px;
}

@media     (min-width: 34em) {

.modal__header {
        padding: 32px
}
    }

.modal__footer {
    padding: 32px;
}

.modal__close {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 16px;

    opacity: 0.3;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.modal__close:hover,
    .modal__close:focus {
        opacity: 1;
    }

@media       (max-width: 33.9375em) {

.modal__close {
        right: 88px;
        top: 24px
}
    }

/*------------------------------------*\
    # block.infoblock-seo
\*------------------------------------*/

.tabs__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
}

.tabs__btn {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0 0 #4a1172;
            box-shadow: inset 0 0 0 0 #4a1172;
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;

    font-size: calc(17.71429px + 0.71429vw);

    line-height: calc(24.57143px + 1.07143vw);
    opacity: 0.7;
    color: #2f2f30;

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
}

@media screen and (min-width: 1440px) {

    .tabs__btn {
        line-height: 40px;
    }
}

@media screen and (max-width: 320px) {

    .tabs__btn {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .tabs__btn {
        font-size: 28px;
    }
}

@media screen and (max-width: 320px) {

    .tabs__btn {
        font-size: 20px;
    }
}

.tabs__btn + .tabs__btn {
        margin-left: 20px;
    }

.tabs__btn:hover,
    .tabs__btn:focus {
        opacity: 0.7;
        color: #4a1172;
    }

.tabs__btn--active {
    font-weight: 700;
    opacity: 1;
}

.tabs__btn--active:hover,
    .tabs__btn--active:focus {
        color: #2f2f30;
        opacity: 1;
    }

/*------------------------------------*\
    # block.cta
\*------------------------------------*/

.cta {
    padding: 40px;

    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    background-color: #4a1172;
    background-size: 25%;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
}

@media     (min-width: 48em) {

.cta .wrapper {
        padding: 100px;
        display: flex;
        flex-flow: row wrap
}
    }

.cta--small {
    background: white;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
    padding: 40px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 16px;
}

@media     (min-width: 48em) {

.cta--small {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 47.9375em) {

.cta__heading {
        max-width: 420px;
        margin-bottom: 40px
}
    }

@media     (min-width: 48em) {

.cta__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.cta__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.cta__heading:last-child {
        margin-right: 0
}

.cta__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 47.9375em) {

.cta__content {
        max-width: 420px
}
    }

@media     (min-width: 48em) {

.cta__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        margin-left: calc(99.9% * (-2/12 * -1) - (36px - 36px * (-2/12 * -1)) + 36px) !important
}

.cta__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.cta__content:last-child {
        margin-right: 0
}

.cta__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.splitview
\*------------------------------------*/

.splitview {
    margin-bottom: 60px;
}

/* Push footer */

@media     (min-width: 62em) {

.splitview {
        display: flex;
        flex-flow: row wrap;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start
}
    }

.splitview__sidebar {
    position: relative;
}

@media       (max-width: 61.9375em) {

.splitview__sidebar {
        margin-bottom: 24px
}
    }

@media     (min-width: 62em) {

.splitview__sidebar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 16px 32px 32px 32px;

        -webkit-box-orient: vertical;

        -webkit-box-direction: normal;

            -ms-flex-direction: column;

                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;

        flex-grow: 0;

        flex-shrink: 0;

        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));

        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));

        width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        position: sticky;
        top: 30px;
        left: 0;

        background: white;
        border-radius: 6px;
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04)
}

.splitview__sidebar:nth-child(1n) {

        margin-right: 36px;

        margin-left: 0
}

.splitview__sidebar:last-child {

        margin-right: 0
}

.splitview__sidebar:nth-child(12n) {

        margin-right: 0;

        margin-left: auto
}
    }

@media     (min-width: 62em) {

.splitview__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        max-width: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        width: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        padding: 32px;

        background: white;
        border-radius: 6px;
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04)
}

.splitview__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.splitview__content:last-child {
        margin-right: 0
}

.splitview__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.splitview__content--alt {
        background: transparent;
        -webkit-box-shadow: none;
                box-shadow: none;
        padding: 0;
    }

.splitview__content__heading {
    margin-bottom: 40px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.splitview__content__heading__title {
    margin-right: auto;
}

@media       (max-width: 47.9375em) {

.splitview__content__heading__title {
        display: none
}
    }

/* Horizontal nav on small screens */

@media       (max-width: 47.9375em) {

.splitview__sidebar__list {
        margin-right: -24px
}
    }

@media       (max-width: 61.9375em) {

.splitview__sidebar__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 16px
}
    }

.splitview__sidebar__item {
    position: relative;
}

@media       (max-width: 61.9375em) {

.splitview__sidebar__item {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto
}
    }

.splitview__sidebar__item--status {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: 12px;
}

.splitview__sidebar__link {
    display: block;
    padding: 16px 0;
    opacity: 0.7;
    color: #786f82;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    font-size: 14px;

    line-height: 16px;
}

.splitview__sidebar__link.active {
        font-weight: 700;
        opacity: 1;
        color: #4a1172;
        border-color: #4a1172;
    }

.splitview__sidebar__link:hover,
    .splitview__sidebar__link:focus {
        opacity: 1;
        color: #4a1172;
    }

@media       (max-width: 61.9375em) {

.splitview__sidebar__link {
        border-bottom: 2px solid transparent;
        margin-right: 16px
}
    }

@media     (min-width: 62em) {

.splitview__sidebar__link {
        margin-right: -32px;
        border-right: 2px solid transparent
}
    }

/* Holds the "Logout" button */

@media       (max-width: 61.9375em) {

.splitview__sidebar__bottom {
        position: absolute;
        top: -30px;
        right: 0
}
    }

@media     (min-width: 62em) {

.splitview__sidebar__bottom {
        margin-top: 24px;
        margin-left: -32px;
        margin-right: -32px;
        padding-top: 24px;
        padding-left: 32px;
        border-top: 1px solid rgba(57, 18, 84, 0.1)
}
    }

/*------------------------------------*\
    # block.sectionhead
\*------------------------------------*/

.sectionhead {
    padding: 24px 0 16px 0;
}

@media     (min-width: 62em) {

.sectionhead {
        padding: 36px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }

@media       (max-width: 61.9375em) {

.sectionhead__title {
        margin-bottom: 16px
}
    }

@media     (min-width: 62em) {

.sectionhead__title {
        padding-right: 36px
}
    }

.sectionhead__header {
    max-width: 1356px;
    padding: 0;
    margin: 0 auto;
}

@media     (min-width: 48em) {

.sectionhead__header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

@media     (min-width: 48em) {

.sectionhead__header {
        padding: 0 48px
}
    }

@media     (min-width: 48em) {

.sectionhead__actions__buttons {
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap
}
    }

.sectionhead-fixed {
    padding: 16px;
    z-index: 10;
    background: white;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 20px 20px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 20px 20px rgba(0, 0, 0, .04);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    top: 0;
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
}

@media       (max-width: 61.9375em) {

.sectionhead-fixed .sectionhead__title {
            -webkit-box-flex: 1;
                -ms-flex: 1 1 250px;
                    flex: 1 1 250px
    }

            .sectionhead-fixed .sectionhead__title h1 {
                margin-bottom: calc;
            }
        }

@media       (max-width: 33.9375em) {
            .sectionhead-fixed .sectionhead__title h1 {
                margin-bottom: 8px;
            }
        }

@media     (min-width: 62em) {

.sectionhead-fixed .sectionhead__title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center
    }
        }

@media       (max-width: 47.9375em) {

.sectionhead-fixed .sectionhead__actions__buttons {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between
    }
        }

@media       (max-width: 61.9375em) {

.sectionhead-fixed .sectionhead__wrapper {
            padding-bottom: 8px
    }
        }

@media     (min-width: 62em) {

.sectionhead-fixed .sectionhead__wrapper {
            margin: 0 16px
    }
        }

@media (max-width: 1100px) {

.sectionhead-fixed .v-listingdetails__meta__url {
            display: none
    }
        }

@media     (min-width: 62em) {

.sectionhead-fixed .sectionhead__actions {
            padding-right: 8px
    }
        }

@media     (min-width: 34em) and       (max-width: 61.9375em) {

.sectionhead-fixed .sectionhead__actions {
            padding-right: 56px
    }
        }

.sectionhead-fixed .v-listingdetails__meta {
        white-space: nowrap;
    }

@media      (max-width: 74.9375em) {

.sectionhead-fixed .v-listingdetails__meta {
            display: none
    }
        }

.sectionhead-fixed .sectionhead__title,
    .sectionhead-fixed .btn {
        margin-bottom: 0;
        width: auto;
    }

@media       (max-width: 33.9375em) {

.sectionhead-fixed .btn--secondary {
            position: absolute;
            right: 72px;
            top: 12px
    }
        }

.sectionhead-fixed .v-listingdetails__title {
        display: none;
    }

.sectionhead-fixed .v-listingdetails__title--current {
        display: block;
        background: none;
        color: #2f2f30;
    }

.sectionhead-fixed .badge--list {
        margin-bottom: 0;
        padding: 0 16px;
    }

.sectionhead-fixed--active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.sectionhead__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.sectionhead__wrapper {
    margin: 12px 0;
}

/*------------------------------------*\
    # block.coverhead
\*------------------------------------*/

.coverhead {
    padding: 40px 0 32px 0;
}

.coverhead__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.coverhead__title {
    padding-right: 36px;
}

.coverhead__action {
}

/*------------------------------------*\
    # block.receipts“
\*------------------------------------*/

.receipts {
    display: flex;
    flex-flow: row wrap;
}

@media       (max-width: 47.9375em) {

.receipts {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column
}
    }

.receipts__sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
    max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
    width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
}

.receipts__sidebar:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.receipts__sidebar:last-child {
    margin-right: 0;
}

.receipts__sidebar:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.receipts__sidebar__link {
    display: block;
    width: 100%;
    border-radius: 0;
    text-align: left;

    padding: 8px 0 8px 16px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    opacity: 0.7;
    border-left: 2px solid transparent;
}

.receipts__sidebar__link.is-active {
        opacity: 1;
        border-left-color: #4a1172;
    }

.receipts__sidebar__link:hover,
    .receipts__sidebar__link:focus {
        opacity: 1;
        border-left: 2px solid #4a1172;
        padding-left: 16px;
    }

.receipts__details {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (36px - 36px * 7/12));
    max-width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
    width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
}

.receipts__details:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.receipts__details:last-child {
    margin-right: 0;
}

.receipts__details:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

@media       (max-width: 47.9375em) {

.receipts__details {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        max-width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        margin-top: 24px
}

.receipts__details:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.receipts__details:last-child {
        margin-right: 0
}

.receipts__details:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 33.9375em) {

.receipts__details {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 12/12 - (36px - 36px * 12/12));
        max-width: calc(99.9% * 12/12 - (36px - 36px * 12/12));
        width: calc(99.9% * 12/12 - (36px - 36px * 12/12))
}

.receipts__details:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.receipts__details:last-child {
        margin-right: 0
}

.receipts__details:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.receipts__details__heading {
    margin-bottom: 40px;
}

.receipts__details__item {
    padding: 32px 0;
    border-top: 1px solid rgba(57, 18, 84, 0.1);
}

.receipts__details__summary {
    padding-bottom: 32px;
}

.receipts__details__total {
    padding-top: 32px;
    border-top: 1px solid rgba(57, 18, 84, 0.1);
}

.receipts__price {
    white-space: nowrap;
}

@media print {
        .receipts__details__summary > div {
            -ms-flex-preferred-size: 1 1 50%;
                flex-basis: 1 1 50%;
        }
}

/**
* Listing blocks
*/

/*------------------------------------*\
    # block.analysis
\*------------------------------------*/

.analysis {
}

@media     (min-width: 62em) {

.analysis__layout {
        display: flex;
        flex-flow: row wrap
}
    }

.analysis__layout__nav {
    display: none;
}

@media     (min-width: 62em) {

.analysis__layout__nav {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.analysis__layout__nav:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.analysis__layout__nav:last-child {
        margin-right: 0
}

.analysis__layout__nav:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 48em) and       (max-width: 61.9375em) {

.analysis__layout__content {
        padding: 0 40px
}
    }

@media     (min-width: 62em) {

.analysis__layout__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        max-width: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        width: calc(99.9% * 9/12 - (36px - 36px * 9/12))
}

.analysis__layout__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.analysis__layout__content:last-child {
        margin-right: 0
}

.analysis__layout__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.analysis__layout__content.empty {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 32px;
    }

.analysis__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}

@media       (max-width: 61.9375em) {

.analysis__btn {
        padding: 16px 0
}
    }

@media     (min-width: 62em) {

.analysis__btn {
        display: none
}
    }

.analysis__btn a {
    width: 100%;
}

.analysis-compare__input {
    width: auto;
    min-width: 185px;
}

.analysis__questions--empty {
    padding: 16px;
    background: rgba(0, 0, 0, 0.1);
    color: #2f2f30;
    border-radius: 6px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
}

/*------------------------------------*\
    # block.generalinfo
\*------------------------------------*/

.generalinfo {
    padding-top: 20px;
}

@media     (min-width: 62em) {

.generalinfo {
        display: flex;
        flex-flow: row wrap
}
    }

.generalinfo__visual {
    position: relative;
    overflow: hidden;
    min-height: 240px;
    max-height: 400px;

    background: #fec45c;
    background-size: cover;
    background-position: center;

    -webkit-box-shadow: 0 7px 14px 0 rgba(60, 66, 87, .12),
        0 3px 6px 0 rgba(0, 0, 0, .12);

            box-shadow: 0 7px 14px 0 rgba(60, 66, 87, .12),
        0 3px 6px 0 rgba(0, 0, 0, .12);

    border-radius: 20px;
}

@media     (min-width: 62em) {

.generalinfo__visual {
        min-height: 396px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.generalinfo__visual:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.generalinfo__visual:last-child {
        margin-right: 0
}

.generalinfo__visual:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.generalinfo__visual:after {
        content: '';
        position: absolute;
        top: -130%;
        left: -112%;
        width: 200%;
        height: 200%;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        background: rgba(255, 255, 255, 0.1);
    }

.generalinfo__details {
    padding-bottom: 40px;
}

@media     (min-width: 34em) {

.generalinfo__details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column
}
    }

@media       (max-width: 61.9375em) {

.generalinfo__details {
        padding-top: 40px
}
    }

@media     (min-width: 62em) {

.generalinfo__details {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important;
        padding-bottom: 72px
}

.generalinfo__details:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.generalinfo__details:last-child {
        margin-right: 0
}

.generalinfo__details:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.generalinfo__details__tooltip {
    position: absolute;
    top: -30px;
    left: 0px;
    z-index: 2;
}

.generalinfo__details__top {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(57, 18, 84, 0.1);
}

.generalinfo__details__notice {
    font-size: 14px;
    line-height: 16px;
    background-color: rgba(254, 196, 92, 0.15);
    padding: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.generalinfo__details__notice svg {
        min-width: 30px;
        width: 16px;
        height: 16px;
    }

.generalinfo__details__top__price {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
}

.generalinfo__details__top__price:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.generalinfo__details__top__price:last-child {
    margin-right: 0;
}

.generalinfo__details__top__price:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.generalinfo__details__top__averagerevenue {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
}

.generalinfo__details__top__averagerevenue:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.generalinfo__details__top__averagerevenue:last-child {
    margin-right: 0;
}

.generalinfo__details__top__averagerevenue:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.generalinfo__details__meta {
    display: flex;
    flex-flow: row wrap;
    margin-top: 0px;
}

.generalinfo__details__meta-item {
    margin-top: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
}

.generalinfo__details__meta-item:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.generalinfo__details__meta-item:last-child {
    margin-right: 0;
}

.generalinfo__details__meta-item:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 34em) {

.generalinfo__details__meta-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/4);
        max-width: calc(99.9% * 1/4);
        width: calc(99.9% * 1/4)
}

.generalinfo__details__meta-item:nth-child(1n) {
        margin-right: 0;
        margin-left: 0
}

.generalinfo__details__meta-item:last-child {
        margin-right: 0
}
    }

@media     (min-width: 34em) {

.generalinfo__details__meta-item--large {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2);
        max-width: calc(99.9% * 1/2);
        width: calc(99.9% * 1/2)
}

.generalinfo__details__meta-item--large:nth-child(1n) {
        margin-right: 0;
        margin-left: 0
}

.generalinfo__details__meta-item--large:last-child {
        margin-right: 0
}
    }

.generalinfo__notice {
    background-color: rgba(254, 196, 92, 0.15);
    padding: 16px;
    border-radius: 6px;
    margin: 24px 0;
}

@media     (min-width: 48em) {

.generalinfo__notice {
        padding: 20px 36px
}
    }

.generalinfo__dutch-auction {
    z-index: 0;
    position: relative;
    padding: 18px 24px;
    background: rgba(95, 241, 153, 0.34902);
    border: 1px solid rgba(83, 203, 131, 0.5);
    border-radius: 6px;
    margin-bottom: 40px;
    overflow: hidden;
}

/*------------------------------------*\
    # block.sellerinfo
\*------------------------------------*/

.sellerinfo {
    background: white;
    border-radius: 6px;

    border-bottom: 4px solid rgba(74, 17, 114, 0.2);
}

@media       (max-width: 61.9375em) {

.sellerinfo {
        padding: 40px 0;
}
    }

@media     (min-width: 62em) {

.sellerinfo {
        padding: 80px 0;
        margin-top: -36px;
}
    }

@media     (min-width: 62em) {

.sellerinfo .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.sellerinfo__heading {
    position: relative;
}

@media       (max-width: 61.9375em) {

.sellerinfo__heading {
        margin-bottom: 24px
}
    }

@media     (min-width: 62em) {

.sellerinfo__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.sellerinfo__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.sellerinfo__heading:last-child {
        margin-right: 0
}

.sellerinfo__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.sellerinfo__details {
    position: relative;
}

@media     (min-width: 62em) {

.sellerinfo__details {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.sellerinfo__details:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.sellerinfo__details:last-child {
        margin-right: 0
}

.sellerinfo__details:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.sellerinfo__tooltip {
    position: absolute;
    z-index: 2;
    right: 100px;
    top: 0;
}

.sellerinfo__details__layout {
    margin-bottom: 32px;
}

@media     (min-width: 62em) {

.sellerinfo__details__layout__col {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.sellerinfo__details__layout__col:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.sellerinfo__details__layout__col:last-child {
        margin-right: 0
}

.sellerinfo__details__layout__col:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.sellerinfo__details__seller {
    margin-bottom: 24px;
}

.sellerinfo__details__verification--border {
    padding-top: 32px;
    border-top: 1px solid rgba(57, 18, 84, 0.1);
}

.sellerinfo__details__verification {
    display: flex;
    flex-flow: row wrap;
    padding-top: 32px;
}

.sellerinfo__icons {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.sellerinfo__icons:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.sellerinfo__icons:last-child {
    margin-right: 0;
}

.sellerinfo__icons:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.sellerinfo__icons:nth-child(1),
    .sellerinfo__icons:nth-child(2) {
        margin-bottom: 24px;
    }

@media     (min-width: 34em) {

.sellerinfo__icons {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/4 - (36px - 36px * 1/4));
        max-width: calc(99.9% * 1/4 - (36px - 36px * 1/4));
        width: calc(99.9% * 1/4 - (36px - 36px * 1/4))
}

.sellerinfo__icons:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.sellerinfo__icons:last-child {
        margin-right: 0
}

.sellerinfo__icons:nth-child(4n) {
        margin-right: 0;
        margin-left: auto
}
    }

.sellerinfo__icons svg {
        fill: #2f2f30;
        margin-bottom: 8px;
        min-width: 32px;
    }

.sellerinfo__icons.is-valid svg {
            fill: #53cb83;
        }

/*------------------------------------*\
    # block.featuregrid
\*------------------------------------*/

.featuregrid {
    display: flex;
    flex-flow: row wrap;
}

@media       (max-width: 61.9375em) {

.featuregrid__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        margin-bottom: 24px
}

.featuregrid__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.featuregrid__item:last-child {
        margin-right: 0
}

.featuregrid__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.featuregrid__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/6 - (36px - 36px * 2/6));
        max-width: calc(99.9% * 2/6 - (36px - 36px * 2/6));
        width: calc(99.9% * 2/6 - (36px - 36px * 2/6));
        margin-bottom: 24px
}

.featuregrid__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.featuregrid__item:last-child {
        margin-right: 0
}

.featuregrid__item:nth-child(6n) {
        margin-right: 0;
        margin-left: auto
}

        .featuregrid__item:nth-child(2n) {
            position: relative;
            left: calc(99.9% * 1/6 - (36px - 36px * 1/6) + 36px);
        }
    }

.featuregrid__item__icon {
    margin-bottom: 20px;
}

/**
* Listing detail information blocks
*/

/*------------------------------------*\
    # block.infoblock
\*------------------------------------*/

/* A slight "hack" to make the counter work */

body {
    counter-reset: counter;
}

.infoblock {
    position: relative;
    overflow: hidden;
    background: white;
}

@media     (min-width: 48em) {

.infoblock {
        margin-top: 40px;
        border-radius: 6px;
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04);
        border: 1px solid rgba(0, 0, 0, .1)
}

        .infoblock:last-child {
            margin-bottom: 40px;
        }
    }

.infoblock--alt {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 0;
}

/* The infoblock__heading is a button element since it's used as a accordion toggle on small screens */

.infoblock__heading {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0;

    padding: 16px;

    border-radius: 0;
}

/* reset radius since it's a clickable btn */

@media       (max-width: 47.9375em) {

.infoblock__heading {
        position: relative;
        border-bottom: 1px solid rgba(57, 18, 84, 0.1)

        /* When toggled change + to - */
}

        .infoblock__heading:after,
        .infoblock__heading:before {
            content: '';
            display: block;
            position: absolute;

            background: rgb(210, 171, 237);
        }

        .infoblock__heading:before {
            right: 14px;
            top: 28px;

            width: 13px;
            height: 1px;
        }

        .infoblock__heading:after {
            right: 20px;
            top: 22px;

            width: 1px;
            height: 13px;
        }
            .is-expanded .infoblock__heading:after {
                display: none;
            }
    }

@media     (min-width: 48em) {

.infoblock__heading {
        padding: 40px;
        pointer-events: none
}
    }

@media     (min-width: 62em) {

.infoblock__heading {
        padding: 40px 100px
}
    }

@media       (max-width: 47.9375em) {

.infoblock__content {
        padding: 0 16px;

        height: 0;
        overflow: hidden
}

        .infoblock__content.is-open {
            padding-top: 16px;
            padding-bottom: 16px;
            height: auto;
        }
    }

@media     (min-width: 48em) {

.infoblock__content {
        padding: 0 40px 40px 40px
}
    }

@media     (min-width: 62em) {

.infoblock__content {
        padding: 0 100px 60px 100px
}
    }

.infoblock__section {
    position: relative;
    margin-bottom: 40px;
}

@media     (min-width: 62em) {

.infoblock__section {
        margin-bottom: 60px
}
    }

.infoblock__section:last-of-type:not(:first-of-type) {
        margin-bottom: 0;
    }

.infoblock__section:only-child {
        margin-bottom: 0;
    }

.infoblock__section--half {
    width: 50%;
    float: left;
}

.infoblock__section__tooltip {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 250px;
}

.infoblock__subheading {
    padding-bottom: 20px;
}

.infoblock__title {
    position: relative;
}

.infoblock__title:before {
        content: counter(counter) '. ';
        counter-increment: counter;
    }

@media     (min-width: 62em) {

.infoblock__title:before {
            content: counter(counter);

            display: block;

            position: absolute;
            top: -2px;
            left: -68px;

            font-size: 14px;
            font-weight: 400
    }
        }

.infoblock__title:after {
        content: '';

        position: absolute;

        display: none;
        width: 36px;
        height: 1px;
        background: #4a1172;

        top: 16px;
        left: -48px;
    }

@media     (min-width: 62em) {

.infoblock__title:after {
            display: block
    }
        }

.infoblock__introtext {
    margin-bottom: 40px;
}

.infoblock__datahead__options {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

@media     (min-width: 34em) {

.infoblock__datahead__options__types {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        max-width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        width: calc(99.9% * 7/12 - (36px - 36px * 7/12))
}

.infoblock__datahead__options__types:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock__datahead__options__types:last-child {
        margin-right: 0
}

.infoblock__datahead__options__types:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.infoblock__data {
    margin-bottom: 60px;
}

.infoblock__score {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.infoblock__score__progress {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    height: 12px;
    width: 80px;
    margin-right: 8px;

    border-radius: 1000px;
    background: rgba(57, 18, 84, 0.1);
}

.infoblock__score__progress__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 0;
    border-radius: 1000px;
    background: rgb(57, 18, 84);
}

.infoblock__score__progress__bar--success {
    background: #53cb83;
}

.infoblock__score__progress__bar--warning {
    background: #d84a4a;
}

.infoblock__score__progress__bar--note {
    background: #fec45c;
}

.infoblock__score__progress__bar--empty {
    background: #786f82;
}

.infoblock__score__text {
}

/* Hidden graph for PDF export */

.infoblock__graph {
    position: fixed;
    top: -10000px;
}

/* Exported graph quaility */

.infoblock__graph canvas {
        width: 800px !important;
    }

.infoblock__tooltip {
    z-index: 2;
    position: absolute;
    left: 400px;
    top: 40px;
}

.infoblock__section__header {
    margin-bottom: 16px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.infoblock__datahead {
    margin-left: auto;
}

@media       (max-width: 47.9375em) {

.infoblock__datahead {
        margin: auto;
        margin-top: 20px
}
    }

/*------------------------------------*\
    # block.infoblock-swot
\*------------------------------------*/

.infoblock-swot {
}

@media     (min-width: 62em) {

.infoblock-swot__layout {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap
}

        .infoblock-swot__layout:before {
            position: absolute;
            display: block;
            content: '';
        }

        .infoblock-swot__layout:before {
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background: rgba(57, 18, 84, 0.1);
        }
    }

@media       (max-width: 61.9375em) {

.infoblock-swot__layout__item {
        margin-bottom: 24px
}

        .infoblock-swot__layout__item:last-child {
            margin-bottom: 0;
        }
    }

@media     (min-width: 62em) {

.infoblock-swot__layout__item {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 50%;
                flex: 1 0 50%
}

        .infoblock-swot__layout__item:nth-child(n) {
            padding-right: 32px;
        }

        .infoblock-swot__layout__item:nth-child(2n) {
            padding-left: 32px;
        }

        .infoblock-swot__layout__item:nth-child(1),
        .infoblock-swot__layout__item:nth-child(2) {
            padding-bottom: 32px;
            border-bottom: 1px solid rgba(57, 18, 84, 0.1);
        }

        .infoblock-swot__layout__item:nth-child(3),
        .infoblock-swot__layout__item:nth-child(4) {
            padding-top: 32px;
        }
    }

/*------------------------------------*\
    # block.infoblock-financial
\*------------------------------------*/

.infoblock-financial {
}

.infoblock-financial--empty {
    background: url(/static/media/BlurredBackgroundListing.c57115d2.webp) no-repeat;
    background-size: contain;
    background-color: white;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 400px;
    aspect-ratio: 4/3;
}

.infoblock-financial--empty--message {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        gap: 32px;
    }

@media     (min-width: 62em) {

.infoblock-financial__sources {
        display: flex;
        flex-flow: row wrap
}
    }

.infoblock-financial__sources__grid {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.infoblock-financial__sources__item {
    padding-bottom: 20px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(57, 18, 84, 0.1);
}

@media       (max-width: 61.9375em) {
        .infoblock-financial__sources__item:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

@media     (min-width: 62em) {

.infoblock-financial__sources__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        display: flex;
        flex-flow: row wrap
}

.infoblock-financial__sources__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock-financial__sources__item:last-child {
        margin-right: 0
}

.infoblock-financial__sources__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.infoblock-financial__sources__item__heading,
.infoblock-financial__sources__item__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.infoblock-financial__sources__item__heading:nth-child(1n),
.infoblock-financial__sources__item__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock-financial__sources__item__heading:last-child,
.infoblock-financial__sources__item__content:last-child {
        margin-right: 0
}

.infoblock-financial__sources__item__heading:nth-child(2n),
.infoblock-financial__sources__item__content:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.infoblock-financial__sources__item__content {
        text-align: right
}
    }

/*------------------------------------*\
    # block.infoblock-seo
\*------------------------------------*/

.infoblock-seo {
}

.infoblock-seo__reports {
}

@media       (max-width: 61.9375em) {

.infoblock-seo__reports__item {
        margin-bottom: 48px
}
    }

@media     (min-width: 62em) {

.infoblock-seo__reports__item {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 32px;
        margin-bottom: 24px;
        border-bottom: 1px solid rgba(57, 18, 84, 0.1)
}
    }

@media       (max-width: 61.9375em) {

.infoblock-seo__reports__item__heading {
        margin-bottom: 24px
}
    }

@media     (min-width: 62em) {

.infoblock-seo__reports__item__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.infoblock-seo__reports__item__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock-seo__reports__item__heading:last-child {
        margin-right: 0
}

.infoblock-seo__reports__item__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.infoblock-seo__reports__item__report {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        position: relative;
        left: calc(99.9% * 1/12 - (36px - 36px * 1/12) + 36px)
}

.infoblock-seo__reports__item__report:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock-seo__reports__item__report:last-child {
        margin-right: 0
}

.infoblock-seo__reports__item__report:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 75em) {

.infoblock-seo__reports__item__report {
        position: relative;
        left: calc(99.9% * 2/12 - (36px - 36px * 2/12) + 36px)
}
    }

.infoblock-seo__tooltip {
    position: absolute;
    z-index: 2;
    right: 0;
    top: -10px;
}

.infoblock-seo__sitemap {
    margin-top: 12px;
    color: #53cb83;
}

/*------------------------------------*\
    # block.infoblock-tips
\*------------------------------------*/

.infoblock-tips {
}

.infoblock-tips__list {
}

.infoblock-tips__list__item {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(57, 18, 84, 0.1);
}

.infoblock-tips__list__item:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

@media     (min-width: 62em) {

.infoblock-tips__list__item {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 32px;
        margin-bottom: 24px
}
    }

@media       (max-width: 61.9375em) {

.infoblock-tips__list__item__heading {
        margin-bottom: 16px
}
    }

@media     (min-width: 62em) {

.infoblock-tips__list__item__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.infoblock-tips__list__item__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock-tips__list__item__heading:last-child {
        margin-right: 0
}

.infoblock-tips__list__item__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.infoblock-tips__list__item__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        max-width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        width: calc(99.9% * 7/12 - (36px - 36px * 7/12))
}

.infoblock-tips__list__item__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.infoblock-tips__list__item__content:last-child {
        margin-right: 0
}

.infoblock-tips__list__item__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.infoblock-business
\*------------------------------------*/

.infoblock-business {
}

.infoblock-business__item + .infoblock-business__item {
        margin-top: 20px;
    }

.infoblock-business__status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;

    border-bottom: 1px dashed rgba(0, 0, 0, .1);
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.infoblock-business__status svg {
        min-width: 18px;
        margin-right: 24px;
        margin-top: 5px;
    }

.infoblock-business__fit {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 8px 16px;
    border: 1px solid #53cb83;
    border-radius: 1000px;
    font-weight: 700;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: white;
    background: #53cb83;
}

.infoblock-business__fit--notfit {
    background: #d84a4a;
    border-color: #d84a4a;
}

.infoblock-business__section {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 40px;
}

.infoblock-business__section--grow {
    position: relative;
    background: #4a1172;
    border-radius: 6px;
    color: white;
    padding: 40px 40px;

    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    background-color: #4a1172;
    background-size: 50%;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);

    margin-left: -40px;
    width: calc(100% + 80px);
    margin-bottom: -40px;
}

@media     (min-width: 62em) {

.infoblock-business__section--grow {
        margin-left: -100px;
        width: calc(100% + 200px);
        margin-bottom: -60px;
        padding: 80px 100px
}
    }

.infoblock-business__grow {
    position: absolute;
    top: -20px;
    right: 0;
}

@media       (max-width: 47.9375em) {

.infoblock-business__grow {
        top: -50px
}
    }

.infoblock-business__section-dashed {
    border-bottom: 1px dashed rgba(0, 0, 0, .1);
    padding-bottom: 24px;
}

.infoblock-business__section-dashed:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

.infoblock-business__section-dashed--alt {
    border-left: 1px solid rgba(120, 111, 130, 0.2);
    padding: 24px;
}

.infoblock-business__spam-signals {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-left: -8px;
}

.infoblock-business__spam-signals__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 6px;
    padding: 12px 20px;
    margin-top: 8px;
    margin-left: 8px;
}

.infoblock-business__spam-signals__item--good {
    background: rgba(83, 203, 131, 0.1);
}

.infoblock-business__spam-signals__item--bad {
    background: rgba(216, 74, 74, 0.1);
}

.infoblock-business__expandable {
    position: relative;
    max-height: 200px;
    overflow: hidden;
}

.infoblock-business__expandable:after {
        content: '';
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            from(rgba(255, 255, 255, 0)),
            to(rgba(255, 255, 255, 1))
        );
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
    }

.infoblock-business__expandable--open {
    max-height: unset;
}

.infoblock-business__expandable--open svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

.infoblock-business__expandable--open:after {
        height: 0px;
    }

/**
* Import: views
* Description: specific website/app views (example: 404 view, login view)
*/

/*------------------------------------*\
    # view.auth
\*------------------------------------*/

.v-auth {
}

@media       (max-width: 47.9375em) {

.v-auth__layout {
        padding-top: 100px;
        padding-bottom: 40px
}
    }

@media     (min-width: 48em) {

.v-auth__layout {
        position: relative;
        overflow: hidden
}
    }

.v-auth__layout .wrapper {
    min-height: 100vh;
}

@media     (min-width: 48em) {

.v-auth__layout .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.v-auth__layout__content {
    margin: 0 auto;
    max-width: 420px;
}

@media       (max-width: 47.9375em) {

.v-auth__layout__content {
        padding: 32px;
        background: white;
        border-radius: 6px;

        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04);

                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02),
            0 42px 64px rgba(0, 0, 0, .04)
}
    }

@media     (min-width: 48em) {

.v-auth__layout__content {
        padding: 160px 0 80px 0;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.v-auth__layout__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-auth__layout__content:last-child {
        margin-right: 0
}

.v-auth__layout__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-auth__layout__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.v-auth__layout__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-auth__layout__content:last-child {
        margin-right: 0
}

.v-auth__layout__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-auth__layout__visual {
    display: none;

    position: absolute;
    left: 50%;
    width: 100%;
    height: 100%;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
}

@media     (min-width: 48em) {

.v-auth__layout__visual {
        display: block
}
    }

.v-auth__footer {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/*------------------------------------*\
    # view.contentpage
\*------------------------------------*/

.v-contentpage {
}

.v-contentpage__heading {
    position: relative;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    background-size: 40%;
    background-position: right 10%;
    background-repeat: no-repeat;

    border-radius: 0 0 0 20px;
    color: white;
}

@media       (max-width: 61.9375em) {

.v-contentpage__heading {
        padding: 140px 0 32px 0
}
    }

@media     (min-width: 62em) {

.v-contentpage__heading {
        padding: 200px 0 40px 0
}
    }

.v-contentpage__content {
    padding: 2.5% 0;
    max-width: 600px;
}

/*------------------------------------*\
    # view.home
\*------------------------------------*/

.v-home {
}

.v-home__work-with__wrapper {
    background: #4a1172;
    color: white;
    border-radius: 16px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .06), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .06), 0 42px 64px rgba(0, 0, 0, .04);

    padding: 80px;

    z-index: 0;
    position: relative;
    overflow: hidden;
}

.v-home__work-with__wrapper:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(/static/media/pattern-2.08b3179a.svg);
        background-size: 20%;
        background-position: right top;
        background-color: rgba(255, 255, 255, 0.75);
        opacity: 0.25;
    }

@media     (min-width: 62em) {

.v-home__work-with__wrapper {
        padding: 60px 0
}
    }

.v-home__criteria-grid {
    margin-top: 60px;
}

@media     (min-width: 62em) {

.v-home__criteria-grid {
        margin-top: 80px;
        display: flex;
        flex-flow: row wrap;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: row wrap
}
    }

.v-home__criteria-item {
    position: relative;
    background: white;
    border-radius: 6px;
    padding: 20px;
    padding-top: 60px;
}

@media       (max-width: 61.9375em) {

.v-home__criteria-item {
        margin-top: 48px
}
    }

@media     (min-width: 62em) {

.v-home__criteria-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
}

.v-home__criteria-item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home__criteria-item:last-child {
        margin-right: 0
}

.v-home__criteria-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home__criteria__number {
    z-index: 0;
    position: absolute;
    top: -27px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #4a1172;
    color: white;
    width: 55px;
    height: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 1000px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .2);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .2);
}

.v-home__criteria__number:after {
        z-index: 2;
        content: '';
        position: absolute;
        top: -4px;
        left: 6px;
        width: 100%;
        height: 100%;
        border-radius: 1000px;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            color-stop(-20.83%, rgba(255, 255, 255, .3)),
            to(rgba(255, 255, 255, 0))
        );
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, .3) -20.83%,
            rgba(255, 255, 255, 0) 100%
        );
    }

/*------------------------------------*\
    # view.home-header
\*------------------------------------*/

.v-home-header {
    z-index: 0;
    position: relative;
    padding: 60px 0;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgb(83, 13, 133)),
        to(rgb(68, 2, 114))
    );
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    border-radius: 0 0 0 20px;
    color: white;
}

@media       (max-width: 61.9375em) {

.v-home-header {
        padding-top: 140px
}
    }

@media     (min-width: 62em) {

.v-home-header {
        padding-top: 140px
}
    }

.v-home-header:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-repeat: repeat-x;
        background-image: url(/static/media/graphic-keyvisual.7c781be2.svg);
        background-size: cover;
        background-position: right top;
    }

.v-home-header__title {
    margin-bottom: 32px;
}

@media     (min-width: 62em) {

.v-home-header__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        max-width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        margin-bottom: 40px
}

.v-home-header__title:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-header__title:last-child {
        margin-right: 0
}

.v-home-header__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-header__content {
    z-index: 1;
}

@media     (min-width: 62em) {

.v-home-header__content {
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 61.9375em) {

.v-home-header__content__intro {
        margin-bottom: 40px
}
    }

@media     (min-width: 62em) {

.v-home-header__content__intro {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.v-home-header__content__intro:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-header__content__intro:last-child {
        margin-right: 0
}

.v-home-header__content__intro:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-header__content__info {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.v-home-header__content__info:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-header__content__info:last-child {
        margin-right: 0
}

.v-home-header__content__info:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-header__visual {

    z-index: -1;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;

    text-align: right;
}

@media       (max-width: 61.9375em) {

.v-home-header__visual {
        display: none;
}
    }

.v-home-header__visual img {
}

/* Sellers */

.v-home-header__features {
    margin-bottom: 40px;
}

@media     (min-width: 34em) {

.v-home-header__features {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-header__example-link:hover,
    .v-home-header__example-link:focus {
        color: rgb(255, 233, 194);
    }

.v-home-header__features__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media       (max-width: 47.9375em) {

.v-home-header__features__item {
        margin-bottom: 40px
}

        .v-home-header__features__item:last-child {
            margin-bottom: 0;
        }
    }

@media     (min-width: 48em) {

.v-home-header__features__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.v-home-header__features__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-header__features__item:last-child {
        margin-right: 0
}

.v-home-header__features__item:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-header__features__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.v-home-header__features__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-header__features__item:last-child {
        margin-right: 0
}

.v-home-header__features__item:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .v-home-header__features__item:nth-child(2),
        .v-home-header__features__item:nth-child(3) {
            margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important;
        }
    }

.v-home-header__features__item img {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    max-width: 60px;
    margin-right: 24px;
}

.v-home-header__features__item__content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

/*------------------------------------*\
    # view.home-desc
\*------------------------------------*/

.v-home-desc {
    padding: 60px 0;
    background: white;
}

@media     (min-width: 62em) {

.v-home-desc {
        padding: 140px 0
}
    }

.v-home-desc__wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.v-home-desc__title {
}

/*------------------------------------*\
    # view.home-stats
\*------------------------------------*/

.v-home-stats {
    margin-top: 40px;
}

@media     (min-width: 34em) {

.v-home-stats {
        display: flex;
        flex-flow: row wrap;
        margin-top: 80px
}
    }

.v-home-stats__item {
    position: relative;
    z-index: 1;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-item-align: start;
        align-self: flex-start;

    padding-left: 40px;
    margin-bottom: 16px;

    color: white;
}

.v-home-stats__item:after {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 0;

        width: 30px;
        height: 1px;
        background: #fec45c;
    }

@media     (min-width: 34em) {

.v-home-stats__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3));

        margin-bottom: 0
}

.v-home-stats__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-stats__item:last-child {
        margin-right: 0
}

.v-home-stats__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-stats__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/5 - (36px - 36px * 1/5));
        max-width: calc(99.9% * 1/5 - (36px - 36px * 1/5));
        width: calc(99.9% * 1/5 - (36px - 36px * 1/5))
}

.v-home-stats__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-stats__item:last-child {
        margin-right: 0
}

.v-home-stats__item:nth-child(5n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-stats__item__title {
    margin-bottom: 16px;
    color: #fec45c;
}

.v-home-stats__item__stats {
    color: white;
}

/*------------------------------------*\
    # view.home-keyfeatures
\*------------------------------------*/

.v-home-keyfeatures {
    padding: 40px 0;
    padding-bottom: 0;
}

@media     (min-width: 62em) {

.v-home-keyfeatures {
        padding: 60px 80px;
        padding-bottom: 0
}
    }

.v-home-keyfeatures__heading {
    margin-bottom: 80px;
}

@media     (min-width: 48em) {

.v-home-keyfeatures__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        max-width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        margin-bottom: 80px
}

.v-home-keyfeatures__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-keyfeatures__heading:last-child {
        margin-right: 0
}

.v-home-keyfeatures__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-keyfeatures__heading__full {
    margin-bottom: 80px;
}

@media     (min-width: 48em) {

.v-home-keyfeatures__heading__full {
        margin-bottom: 80px
}
    }

.v-home-keyfeatures__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 24px;
    margin-left: 16px;
}

@media     (min-width: 48em) {

.v-home-keyfeatures__grid {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-keyfeatures__grid__item {
    position: relative;
    background: white;
    border-radius: 16px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
    padding: 68px 36px 68px 36px;
}

@media       (max-width: 47.9375em) {

.v-home-keyfeatures__grid__item {
        margin-bottom: 60px
}
    }

@media     (min-width: 48em) {

.v-home-keyfeatures__grid__item {
        margin-bottom: 80px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/6 - (36px - 36px * 2/6));
        max-width: calc(99.9% * 2/6 - (36px - 36px * 2/6));
        width: calc(99.9% * 2/6 - (36px - 36px * 2/6))
}

.v-home-keyfeatures__grid__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-keyfeatures__grid__item:last-child {
        margin-right: 0
}

.v-home-keyfeatures__grid__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-keyfeatures__grid__item img {
    position: absolute;
    top: -44px;
}

/*------------------------------------*\
    # view.home-features
\*------------------------------------*/

.v-home-features {
    padding: 60px 0;
    background: white;
}

@media     (min-width: 62em) {

.v-home-features {
        padding: 80px 0
}
    }

.v-home-features--alt {
    background: transparent;
}

.v-home-features__heading {
    margin-bottom: 40px;
    text-align: center;
}

@media     (min-width: 62em) {

.v-home-features__heading {
        margin-bottom: 80px
}
    }

.v-home-features__heading--alt {
    text-align: left;

    max-width: 600px;
}

@media     (min-width: 48em) {

.v-home-features__grid {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-features__grid__item {
    text-align: left;
}

@media       (max-width: 47.9375em) {

.v-home-features__grid__item {
        text-align: center;
        margin-bottom: 20px
}

        .v-home-features__grid__item:last-child {
            margin-bottom: 0;
        }
    }

@media     (min-width: 48em) {

.v-home-features__grid__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        padding-bottom: 80px
}

.v-home-features__grid__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-features__grid__item:last-child {
        margin-right: 0
}

.v-home-features__grid__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-features__grid__item--alt {
    text-align: center;
    display: block;
}

@media     (min-width: 48em) {

.v-home-features__grid__item--alt {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
}

.v-home-features__grid__item--alt:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-features__grid__item--alt:last-child {
        margin-right: 0
}

.v-home-features__grid__item--alt:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 48em) {

.v-home-features__grid__item__visual {
        min-width: 80px;
        margin-right: 32px
}
    }

@media     (min-width: 48em) {

.v-home-features__grid__item__visual__buyers {
        min-width: 80px
}
    }

/*------------------------------------*\
    # view.home-pricing
\*------------------------------------*/

.v-home-pricing {
    padding: 60px 0;

    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-color: #4a1172;
    background-blend-mode: overlay;
    background-repeat: repeat-x;
    background-position: left 600px;
    color: white;
}

@media     (min-width: 62em) {

.v-home-pricing {
        padding: 140px 0
}
    }

.v-home-pricing__heading {
    margin-bottom: 40px;
}

@media   (min-width: 90em) {

.v-home-pricing__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-width: 1120px;
        margin: 0 auto
}
    }

.v-home-pricing__box {
    position: relative;
    border-radius: 6px;
    padding: 40px;
    color: #786f82;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

@media   (min-width: 90em) {

.v-home-pricing__box {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-home-pricing__box:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-pricing__box:last-child {
        margin-right: 0
}

.v-home-pricing__box:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-pricing__box .cta-button {
        -ms-flex-item-align: center;
            align-self: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

.v-home-pricing__box--vip {
    background-color: rgb(255, 255, 255);
}

.v-home-pricing__box--limited {
    border: 1px solid rgba(254, 196, 92, 0.5);
    margin-bottom: 30px;
}

@media     (min-width: 48em) {

.v-home-pricing__box--limited {
        margin-bottom: 0
}
    }

.v-home-pricing__additional {
    max-width: 1120px;
    margin: 0 auto;
    margin-top: 40px;
}

.v-home-pricing__additional-content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
}

.v-home-pricing__additional-content:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-home-pricing__additional-content:last-child {
    margin-right: 0;
}

.v-home-pricing__additional-content:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.v-home-pricing__description {
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 80px;
}

.v-home-pricing__features {
    margin-top: 20px;
    margin-bottom: 40px;
}

.v-home-pricing__features li {
        margin: 12px 0;
    }

.v-home-pricing__footer {
    margin-top: auto;
}

.v-home-pricing__star {
    position: absolute;
    right: -20px;
    top: -20px;
}

.v-home-pricing__offer {
    -ms-flex-item-align: start;
        align-self: flex-start;
    background: #d84a4a;
    color: white;
    border-radius: 6px;
    padding: 10px 20px;
}

/*------------------------------------*\
    # view.home-packages
\*------------------------------------*/

.v-home-packages {
    padding: 60px 0;
}

.v-home-packages__grid {
    padding-top: 100px;
    max-width: 1000px;
    margin: 0 auto;
}

@media     (min-width: 62em) {

.v-home-packages__grid {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-packages__grid__item {
    position: relative;
    background: white;
    border-radius: 16px;
}

@media       (max-width: 61.9375em) {

.v-home-packages__grid__item {
        margin-bottom: 60px;
        padding: 40px
}

        .v-home-packages__grid__item:last-child {
            margin-bottom: 0;
        }
    }

@media     (min-width: 62em) {

.v-home-packages__grid__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        padding: 80px 48px;

        display: -webkit-box;

        display: -ms-flexbox;

        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}

.v-home-packages__grid__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-packages__grid__item:last-child {
        margin-right: 0
}

.v-home-packages__grid__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-packages__grid__item__icon {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

@media       (max-width: 61.9375em) {

.v-home-packages__grid__item__icon {
        top: -32px;
        max-width: 60px
}
    }

@media     (min-width: 62em) {

.v-home-packages__grid__item__icon {
        top: -52px;
        max-width: 88px
}
    }

.v-home-packages__grid__item__content {
}

.v-home-packages__grid__item__pricing {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid rgba(57, 18, 84, 0.1);
}

.v-home-packages__grid__item__pricing ul {
    margin-bottom: 16px;
}

.v-home-packages__grid__item__pricing ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid rgba(57, 18, 84, 0.1);
}

.v-home-packages__grid__item__pricing ul li span:nth-child(1) {
        -webkit-box-flex: 0.3;
            -ms-flex: 0.3;
                flex: 0.3;
    }

.v-home-packages__grid__item__pricing ul li span:nth-child(2) {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
    }

.v-home-packages__grid__item__pricing ul li span:nth-child(3) {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
    }

/*------------------------------------*\
    # view.home-team
\*------------------------------------*/

.v-home-team {
    padding: 60px 0;
    background: #f4f7ff;
}

@media     (min-width: 62em) {

.v-home-team {
        padding: 140px 0
}
    }

.v-home-team--alt {
    background-color: white;
    border-bottom: 2px solid rgba(0, 0, 0, .2);
}

@media     (min-width: 62em) {

.v-home-team .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 61.9375em) {

.v-home-team__heading {
        margin-bottom: 40px
}
    }

@media     (min-width: 62em) {

.v-home-team__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.v-home-team__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-team__heading:last-child {
        margin-right: 0
}

.v-home-team__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-team__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-home-team__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-team__content:last-child {
        margin-right: 0
}

.v-home-team__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-team__person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
}

.v-home-team__person__photo {
    min-width: 70px;
    margin-right: 32px;
}

.v-home-team__person__content {
    padding-top: 8px;
}

/*------------------------------------*\
    # view.home-testimonials
\*------------------------------------*/

.v-home-testimonials {
    padding: 40px 0;
    overflow: hidden;
    background-color: white;
}

@media     (min-width: 62em) {

.v-home-testimonials {
        padding: 80px 0
}
    }

@media     (min-width: 62em) {

.v-home-testimonials .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-testimonials__list {
    position: relative;
}

@media       (max-width: 61.9375em) {

.v-home-testimonials__heading {
        margin-bottom: 40px
}
    }

@media     (min-width: 62em) {

.v-home-testimonials__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.v-home-testimonials__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-testimonials__heading:last-child {
        margin-right: 0
}

.v-home-testimonials__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-testimonials__list {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-home-testimonials__list:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-testimonials__list:last-child {
        margin-right: 0
}

.v-home-testimonials__list:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-testimonials__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 32px;
}

.v-home-testimonials__item__content {
    margin-left: 32px;
}

.v-home-testimonials__item__photo {
    width: 48px;
    height: 48px;
    border-radius: 1000px;
}

@media     (min-width: 62em) {

.v-home-testimonials__item__photo {
        width: 70px;
        height: 70px
}
    }

/*------------------------------------*\
    # view.home-risk
\*------------------------------------*/

.v-home-risk {
    padding: 60px 0;
    background: white;
}

@media     (min-width: 62em) {

.v-home-risk {
        padding: 140px 0
}
    }

@media     (min-width: 62em) {

.v-home-risk__heading {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (min-width: 62em) {

.v-home-risk__heading__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.v-home-risk__heading__title:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-risk__heading__title:last-child {
        margin-right: 0
}

.v-home-risk__heading__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-risk__heading__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-home-risk__heading__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-risk__heading__content:last-child {
        margin-right: 0
}

.v-home-risk__heading__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-risk__grid {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-risk__grid__item {
    background: #f4f7ff;
    padding: 32px;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 6px;
}

@media       (max-width: 61.9375em) {

.v-home-risk__grid__item {
        margin-bottom: 40px
}

        .v-home-risk__grid__item:last-child {
            margin-bottom: 0;
        }
    }

@media     (min-width: 62em) {

.v-home-risk__grid__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12));

        display: flex;

        flex-flow: row wrap;
        margin-bottom: 36px;

        padding: 32px
}

.v-home-risk__grid__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-risk__grid__item:last-child {
        margin-right: 0
}

.v-home-risk__grid__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 61.9375em) {

.v-home-risk__grid__item > h2 {
        margin-bottom: 20px
}
    }

@media     (min-width: 62em) {

.v-home-risk__grid__item > h2 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.v-home-risk__grid__item > h2:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-risk__grid__item > h2:last-child {
        margin-right: 0
}

.v-home-risk__grid__item > h2:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-risk__grid div > div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        max-width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        width: calc(99.9% * 7/12 - (36px - 36px * 7/12))
}

.v-home-risk__grid div > div:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-risk__grid div > div:last-child {
        margin-right: 0
}

.v-home-risk__grid div > div:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.home-agreement
\*------------------------------------*/

.v-home-agreement {
    padding: 40px 0;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;

    color: white;
}

@media     (min-width: 62em) {

.v-home-agreement {
        padding: 140px 0
}
    }

@media     (min-width: 62em) {

.v-home-agreement .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 61.9375em) {

.v-home-agreement__heading {
        margin-bottom: 40px
}
    }

@media     (min-width: 62em) {

.v-home-agreement__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.v-home-agreement__heading:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-agreement__heading:last-child {
        margin-right: 0
}

.v-home-agreement__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-agreement__content {
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.v-home-agreement__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-agreement__content:last-child {
        margin-right: 0
}

.v-home-agreement__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.home-redacted
\*------------------------------------*/

.v-home-redacted {
    padding: 48px 0;
    padding-bottom: 0;
}

@media     (min-width: 62em) {

.v-home-redacted {
        padding: 120px 0;
        padding-bottom: 0
}
    }

.v-home-redacted--alt {
    background: white;
}

.v-home-redacted__header {
    display: flex;
    flex-flow: row wrap;
}

@media       (max-width: 61.9375em) {

.v-home-redacted__title {
        padding-bottom: 40px
}
    }

@media     (min-width: 62em) {

.v-home-redacted__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-home-redacted__title:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-redacted__title:last-child {
        margin-right: 0
}

.v-home-redacted__title:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-home-redacted__description {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-home-redacted__description:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-redacted__description:last-child {
        margin-right: 0
}

.v-home-redacted__description:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-redacted__list {
    margin-top: 100px;
}

@media       (max-width: 61.9375em) {

.v-home-redacted__list {
        margin-top: 40px
}
    }

@media     (min-width: 48em) {

.v-home-redacted__list {
        display: flex;
        flex-flow: row wrap
}
    }

.v-home-redacted__list__cta {
    margin-top: 24px;
    padding-bottom: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

/*------------------------------------*\
    # view.home-steps
\*------------------------------------*/

.v-home-steps {
    margin: 80px 0;
}

.v-home-steps__wrapper {
    max-width: 870px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
}

@media     (min-width: 48em) {

.v-home-steps__item--odd {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse
}
    }

@media     (min-width: 48em) {

.v-home-steps__col {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 50%;
                flex: 1 1 50%
}
    }

.v-home-steps__item-wrapper {
    position: relative;
    padding-top: 16px;
    padding-bottom: 40px;
    padding-left: 20px;
}

@media     (min-width: 48em) {

.v-home-steps__item-wrapper {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0
}
    }

.v-home-steps__item-wrapper::after {
        content: '';
        position: absolute;
        height: calc(100% - 80px);
        width: 1px;
        top: 70px;
        background-color: #191919;
        opacity: 0.2;
    }

@media       (max-width: 47.9375em) {

.v-home-steps__item-wrapper::after {
            height: calc(100% - 70px);
            top: 60px;
            left: -26px
    }
        }

.v-home-steps__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media     (min-width: 48em) {

.v-home-steps__item {
        min-height: 135px
}
    }

.v-home-steps__item:last-child .v-home-steps__item-wrapper::after {
                display: none;
            }

@media     (min-width: 48em) {

.v-home-steps__item-wrapper--even {
        padding-left: 68px
}

        .v-home-steps__item-wrapper--even::after {
            left: 0;
        }
    }

@media     (min-width: 48em) {

.v-home-steps__item-wrapper--odd {
        text-align: right;
        padding-right: 68px
}

        .v-home-steps__item-wrapper--odd:after {
            right: 0;
        }
    }

.v-home-steps__number {
    z-index: 1;
    position: relative;
    background-color: #4a1172;
    color: white;
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 1000px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .2);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .2);
}

@media     (min-width: 48em) {

.v-home-steps__number {
        width: 60px;
        height: 60px
}
    }

.v-home-steps__number::after {
        z-index: 2;
        content: '';
        position: absolute;
        top: -4px;
        left: 6px;
        width: 100%;
        height: 100%;
        border-radius: 1000px;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            color-stop(-20.83%, rgba(255, 255, 255, .3)),
            to(rgba(255, 255, 255, 0))
        );
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, .3) -20.83%,
            rgba(255, 255, 255, 0) 100%
        );
    }

@media     (min-width: 48em) {

.v-home-steps__number--even {
        margin-right: 0;
        margin-left: auto;
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0)
}
    }

@media     (min-width: 48em) {

.v-home-steps__number--odd {
        -webkit-transform: translate3d(-50%, 0, 0);
                transform: translate3d(-50%, 0, 0)
}
    }

/*------------------------------------*\
    # view.home-start-free
\*------------------------------------*/

.v-home-start-free {
    padding: 40px 0 0;
    margin: 0 auto;
    max-width: 828px;
}

@media     (min-width: 48em) {

.v-home-start-free {
        padding: 120px 0
}
    }

.v-home-start-free__wrapper {
    padding: 60px 120px;
    -webkit-box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
            box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
    width: 100%;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #fec45c;
    color: white;
}

@media       (max-width: 47.9375em) {

.v-home-start-free__wrapper {
        text-align: center;
        padding: 40px 20px
}

        .v-home-start-free__wrapper .s-left--lrg {
            margin-left: 0;
        }
    }

@media     (min-width: 48em) {

.v-home-start-free__wrapper {
        padding: 100px 120px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

/*------------------------------------*\
    # view.newhome-header
\*------------------------------------*/

.newhome-header {
    z-index: 0;
    position: relative;
    padding: 60px 0;

    overflow: hidden;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .06), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .06), 0 42px 64px rgba(0, 0, 0, .04);
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgb(83, 13, 133)),
        to(rgb(68, 2, 114))
    );
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;
    padding-bottom: 160px;
}

@media       (max-width: 61.9375em) {

.newhome-header {
        padding-top: 140px
}
    }

@media     (min-width: 62em) {

.newhome-header {
        padding-top: 140px
}
    }

@media       (max-width: 47.9375em) {

.newhome-header {
        padding-bottom: 80px
}
    }

.newhome-header:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        opacity: 0.3;
        background-repeat: repeat-x;
        background-size: 30%;
    }

.newhome-header__producthunt {
    display: inline-block;
    background: #23273e;
    border: 2px solid #fec45c;
    border-radius: 6px;
}

.newhome-header__title {
    margin: 24px 0;
}

.newhome-header__wrapper {
    position: relative;
}

@media     (min-width: 62em) {

.newhome-header__wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.newhome-header__content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.newhome-header__image {
    position: absolute;
    top: 0;
    right: -90px;
    border-radius: 16px;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
            box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
}

@media       (max-width: 61.9375em) {

.newhome-header__image {
        display: none
}
    }

.newhome-header__image-wrapper {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.newhome-header__example-link {
    color: #fec45c;
}

.newhome-header__example-link:hover,
    .newhome-header__example-link:focus {
        color: rgb(255, 233, 194);
    }

/*------------------------------------*\
    # view.home-buyers
\*------------------------------------*/

.home-buyers {
    padding: 30px 0px;
    background-color: white;
}

@media     (min-width: 62em) {

.home-buyers {
        padding: 100px 0px;
        padding-bottom: 0px
}
    }

.home-buyers__split {
    display: flex;
    flex-flow: row wrap;
    margin-top: 40px;
}

@media     (min-width: 48em) {

.home-buyers__split {
        margin-top: 60px
}
    }

@media     (min-width: 62em) {

.home-buyers__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.home-buyers__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.home-buyers__content:last-child {
        margin-right: 0
}

.home-buyers__content:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-buyers__text {
    max-width: 400px;
}

.home-buyers__arrow {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    margin-left: 8px;
}

.home-buyers__images {
    width: 100%;
    position: relative;
}

@media       (max-width: 61.9375em) {

.home-buyers__images {
        margin-top: 80px
}
    }

@media     (min-width: 62em) {

.home-buyers__images {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        margin-bottom: -130px;
        top: 80px
}

.home-buyers__images:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.home-buyers__images:last-child {
        margin-right: 0
}

.home-buyers__images:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-buyers__title {
    max-width: 611px;
}

.home-buyers__image {
    width: 100%;
    position: relative;
}

.home-buyers__image img {
        border-radius: 16px;
        width: 100%;
    }

@media       (max-width: 33.9375em) {

.home-buyers__image-text {
        margin-top: 20px
}
    }

@media     (min-width: 34em) {

.home-buyers__image-text {
        position: absolute;
        padding: 40px 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

@media     (min-width: 48em) and      (max-width: 74.9375em) {

.home-buyers__image-text {
        padding: 20px 18px
}
    }

@media     (min-width: 34em) {

.home-buyers__image-description {
        width: 50%
}
    }

@media       (max-width: 33.9375em) {

.home-buyers__image-description {
        margin-top: 4px
}
    }

.home-buyers__features {
    margin-top: 80px;
    overflow: hidden;
}

.home-buyers__icon {
    width: 76px;
}

.home-buyers__feature {
    margin-bottom: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: align-start;
        -ms-flex-pack: align-start;
            justify-content: align-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.home-buyers__feature__description {
    max-width: 288px;
}

/*------------------------------------*\
    # view.home-sellers
\*------------------------------------*/

.home-sellers {
    padding: 40px 0px;
}

@media     (min-width: 48em) {

.home-sellers {
        padding: 120px 0px
}
    }

.home-sellers__features {
    display: flex;
    flex-flow: row wrap;
}

.home-sellers__feature {
    margin-top: 56px;
    position: relative;
    background-color: white;
    border-radius: 16px;
    padding: 56px 24px 60px 24px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
    width: 100%;
}

@media     (min-width: 62em) {

.home-sellers__feature {
        margin-top: 96px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
}

.home-sellers__feature:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.home-sellers__feature:last-child {
        margin-right: 0
}

.home-sellers__feature:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-sellers__icon {
    position: absolute;
    top: -42px;
    left: 36px;
}

.home-sellers__footer {
    margin-top: 60px;
}

@media     (min-width: 62em) {

.home-sellers__footer {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (min-width: 62em) {

.home-sellers__cta {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (60px - 60px * 4/12));
        max-width: calc(99.9% * 4/12 - (60px - 60px * 4/12));
        width: calc(99.9% * 4/12 - (60px - 60px * 4/12))
}

.home-sellers__cta:nth-child(1n) {
        margin-right: 60px;
        margin-left: 0
}

.home-sellers__cta:last-child {
        margin-right: 0
}

.home-sellers__cta:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-sellers__intro {
    max-width: 510px;
}

.home-sellers__footer__testimonials {
    position: relative;
}

@media     (min-width: 62em) {

.home-sellers__footer__testimonials {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (60px - 60px * 8/12));
        max-width: calc(99.9% * 8/12 - (60px - 60px * 8/12));
        width: calc(99.9% * 8/12 - (60px - 60px * 8/12))
}

.home-sellers__footer__testimonials:nth-child(1n) {
        margin-right: 60px;
        margin-left: 0
}

.home-sellers__footer__testimonials:last-child {
        margin-right: 0
}

.home-sellers__footer__testimonials:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-sellers__footer__testimonials__photo {
    width: 48px;
    height: 48px;
    border-radius: 1000px;
    margin-right: 20px;
}

@media     (min-width: 62em) {

.home-sellers__footer__testimonials__photo {
        width: 70px;
        height: 70px
}
    }

.home-sellers__footer__testimonials__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

/*------------------------------------*\
    # view.home-articles
\*------------------------------------*/

.home-articles {
    padding: 40px 0;
}

@media     (min-width: 62em) {

.home-articles {
        padding: 80px 0
}
    }

.home-articles__title {
    margin-bottom: 28px;
}

@media     (min-width: 62em) {

.home-articles__title {
        margin-bottom: 60px
}
    }

.home-articles__list {
    display: flex;
    flex-flow: row wrap;
}

.home-articles__image {
    width: 100%;
}

.home-articles__link {
    width: 100%;
    background-color: white;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 16px;
    margin-bottom: 32px;
}

@media     (min-width: 48em) {

.home-articles__link {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.home-articles__link:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.home-articles__link:last-child {
        margin-right: 0
}

.home-articles__link:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.home-articles__link {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
}

.home-articles__link:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.home-articles__link:last-child {
        margin-right: 0
}

.home-articles__link:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-articles__link:hover,
    .home-articles__link:focus {
        -webkit-transform: translateY(-7px);
                transform: translateY(-7px);
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .08),
            0 42px 64px rgba(0, 0, 0, .06);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .08),
            0 42px 64px rgba(0, 0, 0, .06);
    }

.home-articles__item {
    display: block;
}

.home-articles__content {
    padding: 36px;
    padding-bottom: 48px;
}

/*------------------------------------*\
    # view.home-cta
\*------------------------------------*/

.home-cta {
    padding: 160px 0;

    background-color: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    background-repeat: repeat-x;
    background-size: 50%;
}

@media     (min-width: 48em) {

.home-cta__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }

.home-cta__wrapper .btn {
    margin: 8px;
    margin-top: 32px;
}

@media     (min-width: 48em) {

.home-cta__input-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }

/*------------------------------------*\
    # view.home-info
\*------------------------------------*/

.home-info {
    background-color: white;
    padding: 60px 0px;
}

@media       (max-width: 47.9375em) {

.home-info {
        padding: 20px 0px;
        padding-top: 40px
}
    }

.home-info--alt {
    background: transparent;
    color: white;
}

.home-info--alt .home-info__grid {
        background: #4a1172;
        border-radius: 16px;
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .06),
            0 42px 64px rgba(0, 0, 0, .04);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .06),
            0 42px 64px rgba(0, 0, 0, .04);

        padding: 80px;

        z-index: 0;
        position: relative;
        overflow: hidden;
    }

.home-info--alt .home-info__grid:before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 500px;
            background-repeat: repeat;
            background-image: url(/static/media/pattern-2.08b3179a.svg);
            background-size: 20%;
            background-position: right top;
            background-color: rgba(255, 255, 255, 0.75);
            opacity: 0.25;
        }

.home-info--alt .home-info__amount {
        color: white;
    }

.home-info--alt .home-info__title:before {
        background: white;
    }

@media       (max-width: 47.9375em) {

.home-info__grid {
        text-align: center
}
    }

@media     (min-width: 48em) {

.home-info__grid {
        display: flex;
        flex-flow: row wrap
}
    }

.home-info__item {
    width: 100%;
}

@media       (max-width: 47.9375em) {

.home-info__item {
        margin-bottom: 32px
}
    }

@media     (min-width: 48em) {

.home-info__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
}

.home-info__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.home-info__item:last-child {
        margin-right: 0
}

.home-info__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.home-info__amount {
    color: #2f2f30;
}

.home-info__title {
    position: relative;
}

.home-info__title:before {
        content: '';
        position: absolute;
        top: 9px;
        left: -32px;
        width: 25px;
        height: 1px;
        background: rgba(0, 0, 0, .4);
    }

@media       (max-width: 47.9375em) {

.home-info__title:before {
            display: none
    }
        }

/*------------------------------------*\
    # view.home-diligence
\*------------------------------------*/

.v-home-diligence {
    padding-bottom: 32px;
}

@media     (min-width: 62em) {

.v-home-diligence {
        padding-bottom: 60px
}
    }

.v-home-diligence__paragraph {
    max-width: 600px;
}

.v-home-diligence__selector {
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin-top: 80px;
    padding-top: 40px;
}

.v-home-diligence__sections {
    margin-top: 64px;
}

@media       (max-width: 61.9375em) {

.v-home-diligence__sections {
        margin-top: 24px;
        margin-bottom: 24px
}
    }

@media     (min-width: 62em) {

.v-home-diligence__sections {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.v-home-diligence__sections:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-diligence__sections:last-child {
        margin-right: 0
}

.v-home-diligence__sections:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-home-diligence__sections button {
        font-weight: 700;

        position: relative;
        display: block;
        padding: 6px 0;

        font-size: 14px;

        line-height: 20px;
    }

.v-home-diligence__sections button:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -24px;
            height: 100%;
            width: 1px;
            background: rgba(57, 18, 84, 0.1);
            -webkit-transition: all 0.2s ease-out, width 1ms;
            transition: all 0.2s ease-out, width 1ms;
        }

.v-home-diligence__sections button:hover:before {
                background: #4a1172;
                width: 2px;
                outline: 0;
            }

.v-home-diligence__sections button.selected {
            color: #4a1172;
        }

.v-home-diligence__sections button.selected:before {
                background: #4a1172;
                width: 2px;
            }

.v-home-diligence__preview {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/1 - (36px - 36px * 1/1));
    max-width: calc(99.9% * 1/1 - (36px - 36px * 1/1));
    width: calc(99.9% * 1/1 - (36px - 36px * 1/1));
}

.v-home-diligence__preview:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-home-diligence__preview:last-child {
    margin-right: 0;
}

.v-home-diligence__preview:nth-child(1n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 62em) {

.v-home-diligence__preview {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        max-width: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        width: calc(99.9% * 9/12 - (36px - 36px * 9/12))
}

.v-home-diligence__preview:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-home-diligence__preview:last-child {
        margin-right: 0
}

.v-home-diligence__preview:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/** Removes numbers in front of sections that are detached on homepage. */

.v-home-diligence__preview .infoblock__title:before {
        display: none;
    }

/*------------------------------------*\
    # view.listings
\*------------------------------------*/

.v-listings {
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.v-listings:before {
        content: '';
        z-index: -2;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(/static/media/pattern-2.08b3179a.svg);
        background-size: 20%;
        background-position: right top;
        opacity: 0.25;
        -webkit-transform: scale(-1);
                transform: scale(-1);
    }

.v-listings:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            color-stop(50%, rgba(244, 247, 255, 0)),
            to(#f4f7ff)
        );
        background: linear-gradient(
            180deg,
            rgba(244, 247, 255, 0) 50%,
            #f4f7ff 100%
        );
        opacity: 1;
    }

.v-listings__header {
    padding: 24px 0 16px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

@media       (max-width: 61.9375em) {

.v-listings__header {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap
}
    }

@media     (min-width: 62em) {

.v-listings__header {
        padding: 36px 0
}
    }

.v-listings__header__title {
    margin-right: 24px;
}

.v-listings__view {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

@media     (min-width: 48em) {

.v-listings__view {
        padding-right: 20px
}
    }

.v-listings__item {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.v-listings__view-icon {
    fill: #4a1172;
    opacity: 0.5;
}

.v-listings__view-icon:hover,
    .v-listings__view-icon:focus {
        opacity: 0.6;
    }

.v-listings__view-icon--selected {
    opacity: 1;
}

.v-listings__view-icon--selected:hover,
    .v-listings__view-icon--selected:focus {
        opacity: 1;
    }

@media       (max-width: 33.9375em) {

.v-listings__filters {
        width: 100%
}
    }

@media     (min-width: 34em) {

.v-listings__filters {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }

.v-listings__filters__operator {
    width: 24px;
    height: 24px;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="-77.74000000000001 71.52 7.22 0 0 21.259999999999998 -27.48 7.22 0 0 21.259999999999998 71.52 -91.78 0 0 0 0 0 1 0" /></filter></svg>#filter');
    -webkit-filter: grayscale(100);
            filter: grayscale(100);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    margin-left: 16px;
}

.v-listings__filters__operator.active {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
        -webkit-filter: grayscale(0);
                filter: grayscale(0);
    }

@media       (max-width: 33.9375em) {

.v-listings__filter {
        margin-top: 8px;
        width: 100%
}
    }

@media     (min-width: 34em) {

.v-listings__filter {
        width: 150px;
        margin-right: 8px
}
    }

@media     (min-width: 34em) {

.v-listings__filter--wide {
        width: 200px
}
    }

.v-listings__filter__sort {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 12px;
}

.v-listings__list {
    width: 100%;
    margin-top: 48px;
}

@media     (min-width: 48em) {

.v-listings__list {
        display: flex;
        flex-flow: row wrap
}
    }

.v-listings__grid {
    width: 100%;

    padding: 40px 0;
}

@media     (min-width: 48em) {

.v-listings__grid {
        display: flex;
        flex-flow: row wrap;
}
    }

.v-listings__notice {
    padding: 20px 20px;

    background-image: url(/static/media/pattern-1-negative.b053082d.svg);
    background-color: white;
    background-size: 25%;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05);
    border-radius: 6px;
}

@media     (min-width: 34em) {

.v-listings__notice {
        padding: 20px 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }

@media       (max-width: 33.9375em) {

.v-listings__notice__cta {
        margin-top: 18px
}
    }

/** Keep these nested, required for proper functionality. */

.v-listings__placeholder {
    position: relative;
}

.v-listings__placeholder .v-listings__placeholder__title {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }

.v-listings__placeholder .v-listings__placeholder__graph {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="8" /></filter></svg>#filter');
        -webkit-filter: blur(8px);
                filter: blur(8px);
    }

/*------------------------------------*\
    # view.listingdetails
\*------------------------------------*/

.v-listingdetails {
    z-index: 0;
    position: relative;
}

.v-listingdetails:before {
        content: '';
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 650px;
        background-repeat: repeat;
        background-image: url(/static/media/pattern-2.08b3179a.svg);
        background-size: 20%;
        background-position: right top;
        opacity: 0.25;
    }

@media       (max-width: 47.9375em) {

.v-listingdetails:before {
            height: 500px
    }
        }

.v-listingdetails:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            color-stop(50%, rgba(244, 247, 255, 0)),
            to(#f4f7ff)
        );
        background: linear-gradient(
            180deg,
            rgba(244, 247, 255, 0) 50%,
            #f4f7ff 100%
        );
        opacity: 1;
    }

.v-listingdetails__notice {
    padding-top: 24px;
}

.v-listingdetails__btn {
    background-color: transparent;
    width: 0;
    overflow: visible;
    height: 0;
    padding: 1px;
    position: absolute;
    top: 10px;
    left: 5px;
    z-index: 0;
}

.v-listingdetails__icon {
    position: relative;
    z-index: 1;
}

.v-listingdetails__icon path {
        fill: #9590a0;
    }

.v-listingdetails__meta {
    position: relative;
}

@media     (min-width: 34em) {

.v-listingdetails__meta {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }

@media     (min-width: 34em) {

.v-listingdetails__meta svg {
        -ms-flex-item-align: center;
            align-self: center
}
    }

.v-listingdetails__meta__status {
    margin-right: 24px;
}

.v-listingdetails__meta__url {
    margin-right: 24px;
}

.v-listingdetails__meta__niche svg {
    margin-top: 6px;
}

.v-listing-details__question-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 64px 0 328px;
}

@media       (max-width: 47.9375em) {

.v-listing-details__question-section {
        padding: 0 24px
}
    }

@media     (min-width: 48em) and       (max-width: 61.9375em) {

.v-listing-details__question-section {
        padding: 0 48px
}
    }

.v-listing-details__switch {
    position: absolute;
    top: 24px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 1px solid #53cb83;
    border-radius: 1000px;
}

.v-listing-details__switch-item {
    padding: 0 4px;
    padding: 6px 16px;
    border-radius: 1000px;
    font-size: 14px;
    line-height: 16px;
    color: rgb(170, 164, 178);
}

.v-listing-details__switch-item:hover,
    .v-listing-details__switch-item:focus {
        color: rgb(84, 153, 112);
    }

.v-listing-details__switch-item:first-child {
        border-left: none;
    }

.v-listing-details__switch-item:last-child {
        border-right: none;
    }

.v-listing-details__switch-item--active {
    background: rgba(83, 203, 131, 0.2);
    color: rgb(84, 153, 112);
    border-left: 1px solid #53cb83;
    border-right: 1px solid #53cb83;
}

/*------------------------------------*\
    # view.listing-form
\*------------------------------------*/

.listing-form {
}

.listing-form__section + .listing-form__section {
        margin-top: 40px;
    }

@media     (min-width: 62em) {

.listing-form__section {
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05),
            0 42px 64px rgba(0, 0, 0, .05);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .05),
            0 42px 64px rgba(0, 0, 0, .05);

        background: white;
        border: 1px solid rgba(57, 18, 84, 0.1);
        border-radius: 6px;
        padding: 40px
}

        .listing-form__section + .listing-form__section {
            margin-top: 24px;
        }
    }

.listing-form__section-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 24px;
}

.listing-form__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.listing-form__action--top {
    margin-bottom: 20px;
}

.listing-form__action--bottom {
    margin-top: 20px;
}

.listing-form__file {
    display: block;
    padding: 8px 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 6px;
}

.listing-form__file:hover,
    .listing-form__file:focus {
        background-color: rgba(74, 17, 114, 0.1);
    }

.listing-form__file + .listing-form__file {
        margin-top: 8px;
    }

.listing-form__image {
    border-radius: 6px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
    width: 100%;
    background-size: cover;
    background-position: center center;

    height: 250px;
}

.listing-form__image-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    top: 16px;
    right: 0;

    border-radius: 1000px;
}

.listing-form__image-placeholder {
    width: 100%;
    background-size: cover;
    background-position: center center;
    min-height: 250px;
}

/*------------------------------------*\
    # view.mypurchases
\*------------------------------------*/

.v-mypurchases {
}

.v-mypurchases__item {
    margin-bottom: 40px;
    border-radius: 20px;
    background: white;
}

@media       (max-width: 47.9375em) {

.v-mypurchases__item {
        padding: 20px
}
    }

@media     (min-width: 48em) {

.v-mypurchases__item {
        padding: 64px 0;
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 47.9375em) {

.v-mypurchases__item__header {
        margin-bottom: 40px
}
    }

@media     (min-width: 48em) {

.v-mypurchases__item__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-mypurchases__item__header:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__item__header:last-child {
        margin-right: 0
}

.v-mypurchases__item__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-mypurchases__item__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-mypurchases__item__header:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__item__header:last-child {
        margin-right: 0
}

.v-mypurchases__item__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 48em) {

.v-mypurchases__item__details {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-mypurchases__item__details:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__item__details:last-child {
        margin-right: 0
}

.v-mypurchases__item__details:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-mypurchases__item__details {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-mypurchases__item__details:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__item__details:last-child {
        margin-right: 0
}

.v-mypurchases__item__details:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-mypurchases__item__visual {
    margin-top: -40px;

    max-width: 300px;
}

@media     (min-width: 48em) {

.v-mypurchases__item__visual {
        margin-top: -88px;
}
    }

.v-mypurchases__item__visual__image {
    height: 220px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: #fec45c;
    background-size: cover;
}

@media    (min-width: 75em) {

.v-mypurchases__item__visual__image {
        height: 300px
}
    }

.v-mypurchases__item__steps {
    list-style: none;
}

.v-mypurchases__step {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 12px;
}

.v-mypurchases__step:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 22px;
        width: 1px;
        height: 100%;
        background: rgba(57, 18, 84, 0.1);
    }

.v-mypurchases__step:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 16px;
        left: 20px;

        height: 11px;
        width: 5px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);

        border-bottom: 1px solid #53cb83;
        border-right: 1px solid #53cb83;
    }

.v-mypurchases__step.is-active:after {
            display: none;
        }

.v-mypurchases__step.is-active .v-mypurchases__step__text {
            display: block;
        }

.v-mypurchases__step.is-active ~ .v-mypurchases__step:after {
            display: none;
        }

.v-mypurchases__step.is-active ~ .v-mypurchases__step .v-mypurchases__step__counter {
            -webkit-box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.3) inset;
                    box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.3) inset;
            color: rgba(120, 111, 130, 0.6);
        }

.v-mypurchases__step:last-child:before {
            display: none;
        }

.v-mypurchases__step__counter {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 16px;

    position: relative;
    z-index: 1;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 44px;
    height: 44px;
    border: 4px solid white;
    -webkit-box-shadow: 0 0 0 1px #53cb83 inset;
            box-shadow: 0 0 0 1px #53cb83 inset;
    background: white;
    border-radius: 50%;

    font-size: 14px;
    color: transparent;
}

.is-active .v-mypurchases__step__counter {
        -webkit-box-shadow: 0 0 0 1px #4a1172 inset;
                box-shadow: 0 0 0 1px #4a1172 inset;
        color: #4a1172;
    }

.v-mypurchases__step__desc {
    padding-top: 8px;
}

.v-mypurchases__step__title {
    opacity: 0.8;
}

.is-active .v-mypurchases__step__title {
        opacity: 1;
        color: rgb(57, 18, 84);
    }

.v-mypurchases__step__text {
    display: none;
}

.v-mypurchases__empty-state {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 420px;
    margin: 0 auto;
    padding-bottom: 160px;
}

.v-mypurchases__smallitem {
    margin-bottom: 40px;
    border-radius: 20px;
    background: white;
}

@media       (max-width: 47.9375em) {

.v-mypurchases__smallitem {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        margin-top: -20px;
        padding: 20px
}
    }

@media     (min-width: 48em) {

.v-mypurchases__smallitem {
        height: 152px;
        padding: 48px 0 48px 0;
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 47.9375em) {

.v-mypurchases__smallitem__visual {
        display: none
}
    }

@media     (min-width: 48em) {

.v-mypurchases__smallitem__visual {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-mypurchases__smallitem__visual:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__smallitem__visual:last-child {
        margin-right: 0
}

.v-mypurchases__smallitem__visual:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-mypurchases__smallitem__visual {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        max-width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-mypurchases__smallitem__visual:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__smallitem__visual:last-child {
        margin-right: 0
}

.v-mypurchases__smallitem__visual:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-mypurchases__smallitem__visual__image {
    width: 140px;
    height: 140px;
    border-radius: 20px;
    background: #fec45c;
    background-size: cover;
}

@media       (max-width: 47.9375em) {

.v-mypurchases__smallitem__visual__image {
        margin-top: -36px
}
    }

@media     (min-width: 48em) {

.v-mypurchases__smallitem__visual__image {
        margin-top: -64px
}
    }

@media       (max-width: 47.9375em) {

.v-mypurchases__smallitem__status {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2
}
    }

@media     (min-width: 48em) {

.v-mypurchases__smallitem__status {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.v-mypurchases__smallitem__status:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__smallitem__status:last-child {
        margin-right: 0
}

.v-mypurchases__smallitem__status:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-mypurchases__smallitem__status__content {
    padding-top: 4px;
}

.v-mypurchases__smallitem__check {
    position: relative;
    display: block;
    float: left;
    margin-right: 16px;

    width: 44px;
    height: 44px;
    -webkit-box-shadow: 0 0 0 1px #53cb83 inset;
            box-shadow: 0 0 0 1px #53cb83 inset;
    background: white;
    border-radius: 50%;
}

.v-mypurchases__smallitem__check:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 16px;
        left: 20px;

        height: 11px;
        width: 5px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);

        border-bottom: 1px solid #53cb83;
        border-right: 1px solid #53cb83;
    }

@media       (max-width: 47.9375em) {

.v-mypurchases__smallitem__content {
        margin-bottom: 20px;
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1
}
    }

@media     (min-width: 48em) {

.v-mypurchases__smallitem__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.v-mypurchases__smallitem__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__smallitem__content:last-child {
        margin-right: 0
}

.v-mypurchases__smallitem__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-mypurchases__alldone {
    margin-bottom: 80px;
}

@media     (min-width: 48em) {

.v-mypurchases__alldone {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.v-mypurchases__alldone:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-mypurchases__alldone:last-child {
        margin-right: 0
}

.v-mypurchases__alldone:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-mypurchases__reports-grid {
    width: 100%;
    list-style-type: none;
}

@media     (min-width: 48em) {

.v-mypurchases__reports-grid {
        display: flex;
        flex-flow: row wrap
}
    }

/*------------------------------------*\
    # view.checkout
\*------------------------------------*/

.v-checkout {
}

.v-checkout__layout {

    position: relative;
    overflow: hidden;
}

@media       (max-width: 61.9375em) {

.v-checkout__layout {
        padding: 40px 0;
}
    }

@media     (min-width: 62em) {

.v-checkout__layout .wrapper {
        display: flex;
        flex-flow: row wrap;
        min-height: 70vh
}
    }

@media     (min-width: 62em) {

.v-checkout__layout__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        padding: 100px 0 60px 0
}

.v-checkout__layout__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-checkout__layout__content:last-child {
        margin-right: 0
}

.v-checkout__layout__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-checkout__layout__visual {
        background: #4a1172;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        background-blend-mode: overlay;
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%
}
    }

@media     (min-width: 62em) {

.v-checkout__layout__visual--alt {
        background-color: #fec45c
}
    }

.v-checkout__fancybox {
    position: relative;
    padding: 24px;
    border-radius: 20px;
}

@media       (max-width: 61.9375em) {

.v-checkout__fancybox {
        margin-top: 16px;
        border: 2px solid #4a1172;
        background: white
}
    }

@media     (min-width: 62em) {

.v-checkout__fancybox {
        background: #f4f7ff;
        padding: 48px 48px 48px 0;
        border-radius: 0 20px 20px 0;
        margin-top: 100px;
        max-width: 420px
}
    }

.v-checkout__fancybox__heading {
    margin-bottom: 80px;
    max-width: 80%;
}

.v-checkout__fancybox__content {
    margin-bottom: 40px;
}

.v-checkout__fancybox__action {
}

.v-checkout__fancybox__deco {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 64px;
    height: 68px;
    background: url(/static/media/deco-key.f4b2433d.svg);
}

@media     (min-width: 62em) {

.v-checkout__fancybox__deco {
        top: 24px;
        right: -32px
}
    }

.v-checkout__fancybox__image {
    position: absolute;
    top: 20px;
    right: 20px;

    width: 72px;
    height: 72px;
    border-radius: 12px;

    background-position: center;
    background-size: cover;
}

.v-checkout__fancybox__offer {
    background: #d84a4a;
    color: white;
    border-radius: 6px;
    padding: 10px 20px;
    display: inline-block;
}

.v-checkout__upsell {
    margin-top: 40px;
    background-color: rgba(254, 196, 92, 0.5);
    border-radius: 6px;
    padding: 20px;
}

.v-checkout__emphasized {
    border: 1px solid rgba(74, 17, 114, 0.5);
    padding: 16px 24px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
}

/*------------------------------------*\
    # view.checkout-deals
\*------------------------------------*/

.v-checkout-deals {
}

.v-checkout-deals__header {
    display: flex;
    flex-flow: row wrap;
    margin-top: 60px;
    margin-bottom: 30px;
}

@media     (min-width: 48em) {

.v-checkout-deals__header {
        margin-bottom: 100px
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__header__section {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (120px - 120px * 1/2));
        max-width: calc(99.9% * 1/2 - (120px - 120px * 1/2));
        width: calc(99.9% * 1/2 - (120px - 120px * 1/2))
}

.v-checkout-deals__header__section:nth-child(1n) {
        margin-right: 120px;
        margin-left: 0
}

.v-checkout-deals__header__section:last-child {
        margin-right: 0
}

.v-checkout-deals__header__section:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-checkout-deals__header__section--criteria {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media       (max-width: 47.9375em) {

.v-checkout-deals__header__section--criteria {
        margin-top: 40px
}
    }

.v-checkout-deals__header__icon {
    width: auto;
    height: 84px;
}

.v-checkout-deals__offers {
    z-index: 0;
    position: relative;

    padding-top: 60px;
    padding-bottom: 40px;
}

@media     (min-width: 48em) {

.v-checkout-deals__offers {
        padding-top: 60px;
        padding-bottom: 168px
}
    }

.v-checkout-deals__offers:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        z-index: -1;
        background: #4a1172;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        background-blend-mode: overlay;
    }

@media     (min-width: 48em) {

.v-checkout-deals__offers:before {
            height: 500px
    }
        }

.v-checkout-deals__offers__title {
    margin-top: 20px;
}

@media     (min-width: 48em) {

.v-checkout-deals__offers__title {
        margin-top: 60px
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__offers__grid {
        display: flex;
        flex-flow: row wrap;
        margin-top: 40px;
        position: relative;
        top: 540px;
        margin-top: -500px
}
    }

.v-checkout-deals__offers__item {
    background: white;
    border-radius: 6px;
    padding: 32px;
}

@media     (min-width: 48em) {

.v-checkout-deals__offers__item {
        padding: 60px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-checkout-deals__offers__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-checkout-deals__offers__item:last-child {
        margin-right: 0
}

.v-checkout-deals__offers__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 47.9375em) {

.v-checkout-deals__offers__item {
        margin-top: 32px
}
    }

@media       (max-width: 47.9375em) {

.v-checkout-deals__how {
        margin-top: 40px
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__how {
        display: flex;
        flex-flow: row wrap;
        margin-top: 540px
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__how__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (120px - 120px * 1/2));
        max-width: calc(99.9% * 1/2 - (120px - 120px * 1/2));
        width: calc(99.9% * 1/2 - (120px - 120px * 1/2))
}

.v-checkout-deals__how__item:nth-child(1n) {
        margin-right: 120px;
        margin-left: 0
}

.v-checkout-deals__how__item:last-child {
        margin-right: 0
}

.v-checkout-deals__how__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-checkout-deals__operator {
    background: #4a1172;
    border-radius: 6px;
    color: white;
    padding: 40px;
}

@media     (min-width: 48em) {

.v-checkout-deals__operator {
        margin-right: -88px;
        padding: 60px
}
    }

.v-checkout-deals__features {
    margin-top: 40px;
}

@media     (min-width: 48em) {

.v-checkout-deals__features {
        display: flex;
        flex-flow: row wrap;
        margin-top: 120px
}
    }

@media       (max-width: 47.9375em) {

.v-checkout-deals__features__header {
        margin-bottom: 40px;
        text-align: center
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__features__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        max-width: calc(99.9% * 3/12 - (36px - 36px * 3/12));
        width: calc(99.9% * 3/12 - (36px - 36px * 3/12))
}

.v-checkout-deals__features__header:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-checkout-deals__features__header:last-child {
        margin-right: 0
}

.v-checkout-deals__features__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-checkout-deals__features__grid {
    display: flex;
    flex-flow: row wrap;
}

@media     (min-width: 48em) {

.v-checkout-deals__features__grid {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        max-width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (36px - 36px * (-1/12 * -1)) + 36px) !important
}

.v-checkout-deals__features__grid:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-checkout-deals__features__grid:last-child {
        margin-right: 0
}

.v-checkout-deals__features__grid:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-checkout-deals__features__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    text-align: center;

    padding-bottom: 80px;
}

.v-checkout-deals__features__item:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-checkout-deals__features__item:last-child {
    margin-right: 0;
}

.v-checkout-deals__features__item:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 48em) {

.v-checkout-deals__features__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
}

.v-checkout-deals__features__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0;
}

.v-checkout-deals__features__item:last-child {
        margin-right: 0;
}

.v-checkout-deals__features__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto;
}
    }

.v-checkout-deals__features__item__icon {
    padding-bottom: 20px;
}

.v-checkout-deals__faq {
    background: white;
    padding: 40px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .04);
}

@media     (min-width: 48em) {

.v-checkout-deals__faq {
        display: flex;
        flex-flow: row wrap;
        padding: 100px
}
    }

@media       (max-width: 47.9375em) {

.v-checkout-deals__faq__header {
        margin-bottom: 28px;
        text-align: center
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__faq__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-checkout-deals__faq__header:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-checkout-deals__faq__header:last-child {
        margin-right: 0
}

.v-checkout-deals__faq__header:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__faq__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-checkout-deals__faq__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-checkout-deals__faq__content:last-child {
        margin-right: 0
}

.v-checkout-deals__faq__content:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-checkout-deals__contact {
    padding: 60px 0;
}

@media     (min-width: 48em) {

.v-checkout-deals__contact {
        padding: 100px 0
}
    }

@media     (min-width: 48em) {

.v-checkout-deals__contact .wrapper {
        text-align: center
}
    }

.v-checkout-deals__contact__content {
    display: inline-block;
    margin-top: 24px;
}

.v-checkout-deals__details__item {
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.v-checkout-deals__details__item:last-child {
        border-bottom: none;
    }

.v-checkout-deals__contact__email {
    background-color: rgba(254, 196, 92, 0.15);
    padding: 24px 20px;
    border-radius: 6px;
}

.v-checkout-deals__detailed-button {
    margin-left: -24px;
}

.v-checkout-deals__detailed-button .active {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

.v-checkout-deals__offers__offer {
    background: #d84a4a;
    color: white;
    border-radius: 6px;
    padding: 10px 20px;
    display: inline-block;
}

.v-checkout-deals__buy-now {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.v-checkout-deals__buy-now span {
        font-size: 14px;
        line-height: 16px;
        margin-top: 16px;
    }

/*------------------------------------*\
    # view.not-found
\*------------------------------------*/

.v-not-found {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 80px;
}

.v-not-found__text {
    max-width: 400px;
    margin: 0 auto;
}

.v-not-found .swing {
    -webkit-transform-origin: 37px 159px;
            transform-origin: 37px 159px;
    -webkit-animation: swing 4s ease-out;
            animation: swing 4s ease-out;
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
}

.v-not-found__svg {
    height: 240px;
}

@-webkit-keyframes swing {
    0% {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
    30% {
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
    }
    40% {
        -webkit-transform: rotate(39deg);
                transform: rotate(39deg);
    }
    50% {
        -webkit-transform: rotate(9deg);
                transform: rotate(9deg);
    }
    60% {
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }
    70% {
        -webkit-transform: rotate(12deg);
                transform: rotate(12deg);
    }
    80% {
        -webkit-transform: rotate(25deg);
                transform: rotate(25deg);
    }
    90% {
        -webkit-transform: rotate(18deg);
                transform: rotate(18deg);
    }
    100% {
        -webkit-transform: rotate(20deg);
                transform: rotate(20deg);
    }
}

@keyframes swing {
    0% {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
    30% {
        -webkit-transform: rotate(-40deg);
                transform: rotate(-40deg);
    }
    40% {
        -webkit-transform: rotate(39deg);
                transform: rotate(39deg);
    }
    50% {
        -webkit-transform: rotate(9deg);
                transform: rotate(9deg);
    }
    60% {
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }
    70% {
        -webkit-transform: rotate(12deg);
                transform: rotate(12deg);
    }
    80% {
        -webkit-transform: rotate(25deg);
                transform: rotate(25deg);
    }
    90% {
        -webkit-transform: rotate(18deg);
                transform: rotate(18deg);
    }
    100% {
        -webkit-transform: rotate(20deg);
                transform: rotate(20deg);
    }
}

/*------------------------------------*\
    # view.success
\*------------------------------------*/

.v-success {
    padding-top: 40px;
    padding-bottom: 80px;

    text-align: center;

    min-height: 60vh;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    color: white;
}

.v-success__text {
    max-width: 400px;
    margin: 0 auto;
}

/*------------------------------------*\
    # view.contact
\*------------------------------------*/

.v-contact__header {
    z-index: 0;
    position: relative;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgb(83, 13, 133)),
        to(rgb(68, 2, 114))
    );
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;

    padding-top: 140px;
}

@media     (min-width: 62em) {

.v-contact__header {
        padding-top: 200px
}
    }

.v-contact__header__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

@media     (min-width: 62em) {

.v-contact__header__text {
            margin-right: 80px
    }
        }

@media    (min-width: 75em) {

.v-contact__header__text {
            margin-right: 140px
    }
        }

.v-contact__header__illustration {
        display: none;
    }

@media     (min-width: 62em) {

.v-contact__header__illustration {
            display: block
    }
        }

.v-contant__intro {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    z-index: 2;
}

.v-content__intro-content {
    position: relative;
    max-width: 554px;
}

.v-contact__section {
    border-top: 1px solid rgba(255, 255, 255, .2);
    margin-top: 60px;
    padding-top: 60px;
}

@media     (min-width: 48em) {

.v-contact__section {
        display: flex;
        flex-flow: row wrap
}

        .v-contact__section > div {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
            max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
            width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        }

        .v-contact__section > div:nth-child(1n) {
            margin-right: 36px;
            margin-left: 0;
        }

        .v-contact__section > div:last-child {
            margin-right: 0;
        }

        .v-contact__section > div:nth-child(12n) {
            margin-right: 0;
            margin-left: auto;
        }
    }

@media       (max-width: 47.9375em) {

.v-contact__section-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding-bottom: 20px
}

        .v-contact__section-item svg {
            min-width: 76px;
            -ms-flex-preferred-size: 76px;
                flex-basis: 76px;
        }

        .v-contact__section-item > div {
            padding-left: 20px;
        }
    }

.v-contact__link:hover,
    .v-contact__link:focus,
    .v-contact__link:active {
        color: #fec45c;
    }

.v-contact__form-section {
    max-width: 1356px;
    padding: 64px 24px;
    margin: 0 auto;
}

@media     (min-width: 48em) {

.v-contact__form-section {
        padding: 0 48px
}
    }

.v-contact__form-section > div {
        padding-bottom: 24px;
    }

@media     (min-width: 48em) {

.v-contact__form-section {
        padding: 64px 48px 120px;
        display: flex;
        flex-flow: row wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}

        .v-contact__form-section > div {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
            max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
            width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
            padding-bottom: 0;
        }

        .v-contact__form-section > div:nth-child(1n) {
            margin-right: 36px;
            margin-left: 0;
        }

        .v-contact__form-section > div:last-child {
            margin-right: 0;
        }

        .v-contact__form-section > div:nth-child(12n) {
            margin-right: 0;
            margin-left: auto;
        }

            .v-contact__form-section > div:last-child {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(99.9% * 7/12 - (36px - 36px * 7/12));
                max-width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
                width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
            }

            .v-contact__form-section > div:last-child:nth-child(1n) {
                margin-right: 36px;
                margin-left: 0;
            }

            .v-contact__form-section > div:last-child:last-child {
                margin-right: 0;
            }

            .v-contact__form-section > div:last-child:nth-child(12n) {
                margin-right: 0;
                margin-left: auto;
            }
    }

.v-contact__button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.v-contact__button-group .btn {
    margin-right: 16px;
}

.v-contact__button-group .btn:last-child {
        margin-right: 0;
    }

/*------------------------------------*\
    # view.fancy
\*------------------------------------*/

.v-fancy {
}

@media       (max-width: 47.9375em) {

.v-fancy__layout {
        padding-top: 100px
}
    }

@media     (min-width: 48em) {

.v-fancy__layout {
        position: relative;
        overflow: hidden
}
    }

.v-fancy__layout .wrapper {
    min-height: 100vh;
}

@media     (min-width: 48em) {

.v-fancy__layout .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.v-fancy__layout__content {
    margin: 0 auto;
    max-width: 420px;
}

@media       (max-width: 47.9375em) {

.v-fancy__layout__content {
        padding: 32px;
        background: white;
        border-radius: 16px
}
    }

@media     (min-width: 48em) {

.v-fancy__layout__content {
        padding: 160px 0 80px 0;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        max-width: calc(99.9% * 5/12 - (36px - 36px * 5/12));
        width: calc(99.9% * 5/12 - (36px - 36px * 5/12))
}

.v-fancy__layout__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-fancy__layout__content:last-child {
        margin-right: 0
}

.v-fancy__layout__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.v-fancy__layout__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12))
}

.v-fancy__layout__content:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-fancy__layout__content:last-child {
        margin-right: 0
}

.v-fancy__layout__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-fancy__layout__visual {
    display: none;

    position: absolute;
    left: 50%;
    width: 100%;
    height: 100%;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
}

@media     (min-width: 48em) {

.v-fancy__layout__visual {
        display: block
}
    }

/*------------------------------------*\
    # view.home-form
\*------------------------------------*/

.v-home-form {
}

/*------------------------------------*\
    # view.partners
\*------------------------------------*/

.v-partners {
    padding-bottom: 160px; /* Push footer */
}

.v-partners__heading {
    z-index: 0;
    position: relative;
    padding: 160px 0 80px 0;

    border-radius: 0 0 20px 20px;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
}

@media     (min-width: 62em) {

.v-partners__heading {
        padding: 160px 0
}
    }

.v-partners__heading__wrapper {
    max-width: 850px;
}

.v-partners__footer {
    padding: 60px 0;
}

@media     (min-width: 62em) {

.v-partners__footer {
        padding: 80px 0
}
    }

.v-partners__footer-outro {
    margin: 20px auto 0 auto;
    max-width: 600px;
}

.v-partners__footer-button {
    margin: 20px auto 0 auto;
}

.v-partners__intro {
    margin: 60px auto 0 auto;
    max-width: 600px;
}

.v-partners__gridbox {
    position: relative;
    z-index: 1;
    margin-top: -32px;
}

@media     (min-width: 48em) {

.v-partners__gridbox {
        margin-top: -72px
}
    }

.v-partners__gridsection {
    margin-bottom: 40px;
    border-radius: 20px;
    background: white;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
}

.v-partners__grid {
    display: flex;
    flex-flow: row wrap;
}

.v-partners__grid-deafult-item {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 60px;
}

@media       (max-width: 61.9375em) {

.v-partners__grid-deafult-item {
        margin: 0 auto
}
    }

@media     (min-width: 62em) {

.v-partners__grid-deafult-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2);
        max-width: calc(99.9% * 1/2);
        width: calc(99.9% * 1/2)
}

.v-partners__grid-deafult-item:nth-child(1n) {
        margin-right: 0;
        margin-left: 0
}

.v-partners__grid-deafult-item:last-child {
        margin-right: 0
}
    }

.v-partners__grid-deafult-item__text {
    padding: 40px 0 0 0;
    max-width: 150px;
}

.v-about {
}

.v-about__header,
.v-about__banner {
    z-index: 0;
    position: relative;
    padding: 60px 0;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgb(83, 13, 133)),
        to(rgb(68, 2, 114))
    );
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    border-radius: 0 0 0 20px;
    color: white;
}

@media       (max-width: 61.9375em) {

.v-about__header,
.v-about__banner {
        padding-top: 140px
}
    }

@media     (min-width: 62em) {

.v-about__header,
.v-about__banner {
        padding-top: 140px
}
    }

.v-about__header:after, .v-about__banner:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background-repeat: repeat-x;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        background-size: 70%;
        background-position: right top;
    }

.v-about__team-content {
    position: relative;
    max-width: 1100px;
    z-index: 2;
    margin: 0 auto;
}

.v-about__header-content {
    z-index: 1;
}

@media     (min-width: 62em) {

.v-about__header-content {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (min-width: 62em) {

.v-about__header-content__info {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        max-width: calc(99.9% * 6/12 - (36px - 36px * 6/12));
        width: calc(99.9% * 6/12 - (36px - 36px * 6/12))
}

.v-about__header-content__info:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-about__header-content__info:last-child {
        margin-right: 0
}

.v-about__header-content__info:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-about__header-wrapper {
    padding: 96px 24px 60px;
    max-width: 768px;
}

@media     (min-width: 48em) {

.v-about__header-wrapper {
        padding: 132px 24px 60px
}
    }

.v-about__banner {
    padding: 80px 24px;
}

@media     (min-width: 48em) {

.v-about__banner {
        padding: 160px 24px
}
    }

.v-about__banner-content {
    max-width: 768px;
}

.v-about__team {
    background-color: #f4f7ff;
}

.v-about__team-content {
    padding: 40px 24px;
}

@media     (min-width: 48em) {

.v-about__team-content {
        padding: 100px 24px
}
    }

.v-about__team-grid {
    display: grid;
    grid-row-gap: 44px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media     (min-width: 48em) {

.v-about__team-grid {
        grid-row-gap: 88px;
        grid-template-columns: repeat(2, 1fr)
}
    }

.v-about__history {
    z-index: 0;
    background: white;
    padding: 50px 0;
}

@media     (min-width: 48em) {

.v-about__history {
        padding: 100px 0
}
    }

.v-about__history__title {
    max-width: 612px;
}

.v-about__timeline {
    margin-top: 40px;
}

@media     (min-width: 48em) {

.v-about__timeline {
        margin-top: 80px
}
    }

.v-about__timeline__item {
    display: flex;
    flex-flow: row wrap;
}

.v-about__timeline {
    position: relative;
}

.v-about__timeline__year {
    overflow: hidden;
    font-weight: 700;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (36px - 36px * 2/12));
    max-width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
    width: calc(99.9% * 2/12 - (36px - 36px * 2/12));
    padding-bottom: 80px;
}

.v-about__timeline__year:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-about__timeline__year:last-child {
    margin-right: 0;
}

.v-about__timeline__year:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 48em) {

.v-about__timeline__year {
        position: relative;
        left: calc(99.9% * 1/12 - (36px - 36px * 1/12) + 36px)
}
    }

@media     (min-width: 48em) {

.v-about__timeline__year:before {
            z-index: 1;
            content: '';
            position: absolute;
            top: 10px;
            left: 66px;
            height: 100%;
            border-left: 1px dashed rgba(0, 0, 0, .5);
            width: 5px
    }
        }

@media     (min-width: 48em) {

.v-about__timeline__year:after {
            z-index: 2;
            content: '';
            position: absolute;
            top: 6px;
            left: 64px;
            width: 6px;
            height: 6px;
            border-radius: 1000px;
            background: #786f82
    }
        }

.v-about__timeline__text {
    text-align: left;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (36px - 36px * 10/12));
    max-width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
    width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
}

.v-about__timeline__text:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-about__timeline__text:last-child {
    margin-right: 0;
}

.v-about__timeline__text:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

@media     (min-width: 48em) {

.v-about__timeline__text {
        position: relative;
        left: calc(99.9% * 1/12 - (36px - 36px * 1/12) + 36px);
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        max-width: calc(99.9% * 7/12 - (36px - 36px * 7/12));
        width: calc(99.9% * 7/12 - (36px - 36px * 7/12))
}

.v-about__timeline__text:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-about__timeline__text:last-child {
        margin-right: 0
}

.v-about__timeline__text:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-about__timeline__item--special {
    position: relative;
    z-index: 3;
    background: #f6f8fc;
    padding: 40px 40px;
}

@media screen     (min-width: 34em) {

.v-about__timeline__item--special {
        padding: 40px 104px
}
    }

.v-about__timeline__year--investors {
    max-width: 40px;
}

.v-about__timeline__item--investors .v-about__timeline__year {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        color: #4a1172;
    }

@media       (max-width: 47.9375em) {

.v-about__timeline__item--investors .v-about__timeline__year {
            display: block
    }
        }

.v-about__timeline__item--investors .v-about__timeline__year:before {
            display: none;
        }

.v-about__timeline__item--investors .v-about__timeline__year:after {
            display: none;
        }

.v-about__timeline__icon {
    z-index: 3;
    position: relative;
    height: 25px;
    width: 25px;
    margin-left: 14px;
}

@media       (max-width: 47.9375em) {

.v-about__timeline__icon {
        margin-left: 0
}
    }

/*------------------------------------*\
    # view.report
\*------------------------------------*/

.v-report {
    background-color: rgb(74, 17, 114);
    padding: 180px 0 100px 0;

    position: relative;
    z-index: 0;
}

.v-report:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        opacity: 0.2;
        background-repeat: repeat-x;
        background-size: 30%;
    }

.v-report__header__links {
    margin-top: 40px;
}

@media     (min-width: 62em) {

.v-report__header__links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
}
    }

@media       (max-width: 61.9375em) {

.v-report__link-description {
        margin-bottom: 20px
}
    }

@media     (min-width: 62em) {

.v-report__link-description {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-report__link-description:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-report__link-description:last-child {
        margin-right: 0
}

.v-report__link-description:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-report__link {
    height: 58px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 1000px;
    border-top: 2px solid rgba(255, 255, 255, .2);
    padding: 0 24px;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(255, 255, 255, 0)),
        to(rgba(255, 255, 255, .1))
    );
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .1) 100%
    );
}

@media     (min-width: 62em) {

.v-report__link {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        padding: 0 40px
}

.v-report__link:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-report__link:last-child {
        margin-right: 0
}

.v-report__link:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-report__score-section {
    border-top: 1px solid rgba(255, 255, 255, .3);
    padding: 20px 0 0 0;
}

@media     (min-width: 62em) {

.v-report__score-section {
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 61.9375em) {

.v-report__score {
        margin-top: 50px
}
    }

@media     (min-width: 62em) {

.v-report__score {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-report__score:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-report__score:last-child {
        margin-right: 0
}

.v-report__score:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 61.9375em) {

.v-report__score__calculation {
        text-align: center
}
    }

@media       (max-width: 61.9375em) {

.v-report__score-section__offer {
        margin-top: 50px
}
    }

@media     (min-width: 62em) {

.v-report__score-section__offer {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.v-report__score-section__offer:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-report__score-section__offer:last-child {
        margin-right: 0
}

.v-report__score-section__offer:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-report__copy-button {
    padding: 4px 0;
    position: absolute;
    top: 0;
    right: 4px;
}

.v-report__copy-button:hover,
    .v-report__copy-button:focus {
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px);
    }

.v-report__card-section {
    margin-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

@media     (min-width: 48em) {

.v-report__card-section {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }

.v-report__card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 288px;
    min-height: 350px;
    color: rgb(74, 17, 114);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffcf78), to(#fec45c));
    background: linear-gradient(180deg, #ffcf78 0%, #fec45c 100%);
    padding: 36px;
    border-radius: 10px;
    z-index: 1;
    -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);
            box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);
    -ms-flex-item-align: center;
        align-self: center;
}

@media       (max-width: 61.9375em) {

.v-report__card + .v-report__card {
            margin-top: 20px
    }
        }

@media     (min-width: 48em) {
        .v-report__card:last-child {
            top: 25px;
            right: 18px;
            -webkit-transform: rotate(4deg);
                    transform: rotate(4deg);
            z-index: 0;
            -webkit-box-shadow: none;
                    box-shadow: none;
        }

        .v-report__card:first-child {
            top: 25px;
            left: 18px;
            -webkit-transform: rotate(-4deg);
                    transform: rotate(-4deg);
            z-index: 0;
            -webkit-box-shadow: none;
                    box-shadow: none;
        }
    }

.v-report__footer {
    border-top: 1px solid rgba(255, 255, 255, .3);
    padding-top: 40px;
    margin-top: 80px;
}

@media     (min-width: 48em) {

.v-report__footer {
        padding-top: 80px;
        margin-top: 120px
}
    }

@media     (min-width: 62em) {

.v-report__footer {
        display: flex;
        flex-flow: row wrap
}
    }

@media       (max-width: 61.9375em) {

.v-report__footer__offer {
        margin-top: 40px
}
    }

@media     (min-width: 62em) {

.v-report__footer__offer {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (90px - 90px * 1/2));
        max-width: calc(99.9% * 1/2 - (90px - 90px * 1/2));
        width: calc(99.9% * 1/2 - (90px - 90px * 1/2))
}

.v-report__footer__offer:nth-child(1n) {
        margin-right: 90px;
        margin-left: 0
}

.v-report__footer__offer:last-child {
        margin-right: 0
}

.v-report__footer__offer:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media       (max-width: 61.9375em) {

.v-report__criteria {
        margin-top: 80px;
        margin-bottom: 40px
}
    }

@media     (min-width: 62em) {

.v-report__criteria {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (90px - 90px * 1/2));
        max-width: calc(99.9% * 1/2 - (90px - 90px * 1/2));
        width: calc(99.9% * 1/2 - (90px - 90px * 1/2))
}

.v-report__criteria:nth-child(1n) {
        margin-right: 90px;
        margin-left: 0
}

.v-report__criteria:last-child {
        margin-right: 0
}

.v-report__criteria:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-report__questions {
    border-left: 1px solid rgba(255, 255, 255, .3);
    padding: 0 0 0 20px;
}

.v-report__twist {
    border-left: 4px solid #fec45c;
    padding-left: 16px;
}

.v-report-upsell {
    margin-top: 80px;
}

/*------------------------------------*\
    # view.shared-report
\*------------------------------------*/

.v-shared-report {
    background-color: rgb(74, 17, 114);
    padding: 180px 0 80px 0;

    position: relative;
    z-index: 0;
}

.v-shared-report:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/static/media/pattern-1.e467f39f.svg);
        opacity: 0.3;
        background-repeat: repeat-x;
        background-size: 30%;
    }

.v-shared-report__card-section {
    margin-top: 100px;
}

.v-shared-report__card {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/14);
    max-width: calc(99.9% * 3/14);
    width: calc(99.9% * 3/14);
    color: rgb(74, 17, 114);
    background: -webkit-gradient(linear, left top, left bottom, from(#ffcf78), to(#fec45c));
    background: linear-gradient(180deg, #ffcf78 0%, #fec45c 100%);
    padding: 40px 20px 20px 40px;
    border-radius: 10px;
    z-index: 1;
    -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);
            box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, .1);
}

.v-shared-report__card:nth-child(1n) {
    margin-right: 0;
    margin-left: 0;
}

.v-shared-report__card:last-child {
    margin-right: 0;
}

.v-shared-report__card:last-child {
        top: 25px;
        right: 18px;
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
        z-index: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
    }

.v-shared-report__card:first-child {
        top: 25px;
        left: 18px;
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
        z-index: 0;
        -webkit-box-shadow: none;
                box-shadow: none;
    }

.v-shared-report__card-description {
    margin-top: 100px;
}

.v-shared-report__footer {
    border-top: 1px solid rgba(255, 255, 255, .3);
    padding: 20px 0 0 0;
}

.v-shared-report__footer__offer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
    max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
    width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
}

.v-shared-report__footer__offer:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-shared-report__footer__offer:last-child {
    margin-right: 0;
}

.v-shared-report__footer__offer:nth-child(3n) {
    margin-right: 0;
    margin-left: auto;
}

.v-shared-report__criteria {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
}

.v-shared-report__criteria:nth-child(1n) {
    margin-right: 36px;
    margin-left: 0;
}

.v-shared-report__criteria:last-child {
    margin-right: 0;
}

.v-shared-report__criteria:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.v-shared-report__questions {
    border-left: 1px solid rgba(255, 255, 255, .3);
    padding: 0 0 0 20px;
}

/*------------------------------------*\
    # view.valuation-intro
\*------------------------------------*/

.v-valuation-intro {
    padding: 60px 0;
}

@media     (min-width: 62em) {

.v-valuation-intro__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (60px - 60px * 3/12));
        max-width: calc(99.9% * 3/12 - (60px - 60px * 3/12));
        width: calc(99.9% * 3/12 - (60px - 60px * 3/12));
        margin-top: 80px
}

.v-valuation-intro__content:nth-child(1n) {
        margin-right: 60px;
        margin-left: 0
}

.v-valuation-intro__content:last-child {
        margin-right: 0
}

.v-valuation-intro__content:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-valuation-intro__image {
    border-radius: 6px;
}

@media     (min-width: 62em) {

.v-valuation-intro__image {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 9/12 - (60px - 60px * 9/12));
        max-width: calc(99.9% * 9/12 - (60px - 60px * 9/12));
        width: calc(99.9% * 9/12 - (60px - 60px * 9/12))
}

.v-valuation-intro__image:nth-child(1n) {
        margin-right: 60px;
        margin-left: 0
}

.v-valuation-intro__image:last-child {
        margin-right: 0
}

.v-valuation-intro__image:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-valuation-intro__icons {
    margin-top: 56px;
    text-align: center;
}

.v-valuation-intro__start-now-button {
    margin-top: 56px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.v-valuation-intro__wrapper {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

@media       (max-width: 61.9375em) {

.v-valuation-intro__wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }

/*------------------------------------*\
    # view.valuation
\*------------------------------------*/

.v-valuation {
    background: rgb(74, 17, 114);
    padding-top: 160px;
    padding-bottom: 240px;
}

.v-valuation__header {
    padding-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.v-valuation__counter {
    position: relative;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(255, 255, 255, .3)),
        to(rgba(255, 255, 255, .1))
    );
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, .3) 0%,
        rgba(255, 255, 255, .1) 100%
    );
    padding: 4px;
    border-radius: 50%;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .4);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .4);
    height: 72px;
    width: 72px;
}

@media       (max-width: 61.9375em) {

.v-valuation__counter {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2
}
    }

.v-valuation__counter__inner {
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, .2);
    width: 64px;
    height: 64px;
    position: absolute;
    top: 4px;
    left: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.v-valuation__splitview {
    margin-bottom: 60px;
    margin-top: 60px;
}

@media     (min-width: 62em) {

.v-valuation__splitview {
        display: flex;
        flex-flow: row wrap;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start
}
    }

@media     (min-width: 62em) {

.v-valuation__main {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        max-width: calc(99.9% * 9/12 - (36px - 36px * 9/12));
        width: calc(99.9% * 9/12 - (36px - 36px * 9/12))
}

.v-valuation__main:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.v-valuation__main:last-child {
        margin-right: 0
}

.v-valuation__main:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.v-valuation__counter-circle {
    top: 0;
    left: 0;
    position: absolute;
}

.v-valuation__counter-circle circle {
        -webkit-transition: stroke-dashoffset 0.35s;
        transition: stroke-dashoffset 0.35s;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
    }

@media       (max-width: 61.9375em) {

.v-valuation__metrics {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1
}
    }

@media       (max-width: 61.9375em) {

.v-valuation__points {
        margin-bottom: 16px
}
    }

@media     (min-width: 62em) {

.v-valuation__points {
        margin-right: 16px
}
    }

@media       (max-width: 61.9375em) {

.v-valuation__multiple {
        margin-bottom: 16px
}
    }

@media     (min-width: 62em) {

.v-valuation__multiple {
        margin-right: 16px
}
    }

/*------------------------------------*\
    # component.v-inbox
\*------------------------------------*/

.v-inbox {
}

.v-inbox__split {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
    padding: 0 8px;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-transition: -webkit-transform 0.1s ease-out;
    transition: -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
    transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}

@media       (max-width: 47.9375em) {

.v-inbox__split--opened {
        -webkit-transform: translateX(220px);
                transform: translateX(220px)
}
    }

.v-inbox__split--opened .v-inbox__toggle {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

.v-inbox__sidebar {
    max-height: 1000px;
    overflow-y: auto;

    grid-column: 1/5;
}

@media       (max-width: 47.9375em) {

.v-inbox__sidebar {
        min-width: 220px;
        margin-left: -236px !important
}
    }

.v-inbox__details {
    grid-column: 5/13;
    padding: 0 8px;
}

.v-inbox__details__header {
    z-index: 1;
    position: sticky;
    top: 0;
    background: #f4f7ff;
    padding: 16px 0;
}

.v-inbox__button--unread {
}

.v-inbox__initial {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    padding: 8px;
    font-size: 20px;
    border-radius: 1000px;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    overflow: hidden;
}

.v-inbox__toggle {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

@media     (min-width: 48em) {

.v-inbox__toggle {
        display: none
}
    }

.v-inbox__assisted {
    background-color: #4a1172;
    padding: 16px;
    border-radius: 6px;
    color: white;
    z-index: 0;
    position: relative;
    overflow: hidden;
}

.v-inbox__assisted:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(/static/media/pattern-2.08b3179a.svg);
        background-size: 60%;
        background-position: right top;
        background-color: white;
        opacity: 0.2;
    }

/*------------------------------------*\
    # component.v-offer-details
\*------------------------------------*/

.v-offer-details  {
}

.v-offer-details__asking-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    border-top: 1px solid rgba(0, 0, 0, .1);
    padding-top: 16px;
}

.v-offer-details__user {
    color: #4a1172;
}

.v-offer-details__offers {
    position: relative;
}

.v-offer-details__offers:after {
        content: '';
        position: absolute;
        top: 6px;
        left: 3px;
        background: rgba(0, 0, 0, .1);
        width: 2px;
        height: calc(100% - 45px);
    }

.v-offer-details__offer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 36px;
    padding-left: 20px;
}

.v-offer-details__offer:last-child .v-offer-details__user {
            font-weight: 700;
        }

.v-offer-details__offer:last-child .v-offer-details__user:after {
                background: #4a1172;
            }

.v-offer-details__offer:last-child .v-offer-details__amount {
            font-weight: 700;
            color: #4a1172;

            font-size: 20px;

            line-height: 24px;
        }

.v-offer-details__offer.is-accepted .v-offer-details__user {
            color: #53cb83;
        }

.v-offer-details__offer.is-accepted .v-offer-details__amount {
            color: #53cb83;
        }

.v-offer-details__offer.is-declined .v-offer-details__user {
            color: #d84a4a;
        }

.v-offer-details__offer.is-declined .v-offer-details__amount {
            color: #d84a4a;
        }

.v-offer-details__user {
    position: relative;
}

.v-offer-details__user:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 6px;
        left: -20px;
        border-radius: 1000px;
        width: 8px;
        height: 8px;
        background: #786f82;
    }

.v-offer-details__actions {

    margin: 32px 0;
}

@media     (min-width: 48em) {

.v-offer-details__actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
    }

/*------------------------------------*\
    # view.contentpage
\*------------------------------------*/

.page {
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.page:before {
        content: '';
        z-index: -2;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(/static/media/pattern-2.08b3179a.svg);
        background-size: 20%;
        background-position: right top;
        opacity: 0.25;
    }

.page:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: -webkit-gradient(
            linear,
            left top, left bottom,
            color-stop(50%, rgba(244, 247, 255, 0)),
            to(#f4f7ff)
        );
        background: linear-gradient(
            180deg,
            rgba(244, 247, 255, 0) 50%,
            #f4f7ff 100%
        );
        opacity: 1;
    }

.page__header {
    margin: 40px 0 16px 0;
}

/*------------------------------------*\
    # view.waiting-list
\*------------------------------------*/

.waiting-list {
}

.waiting-list__documents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.waiting-list__document svg {
        -webkit-transition: all 0.1 ease-out;
        transition: all 0.1 ease-out;
        fill: #4a1172;
    }

.waiting-list__document:hover svg, .waiting-list__document:focus svg {
            fill: rgba(74, 17, 114, 0.5);
        }

/*------------------------------------*\
    # view.page-form
\*------------------------------------*/

.page-form {
    padding-bottom: 160px; /* Push footer */
}

.page-form__heading {
    z-index: 0;
    position: relative;
    padding: 160px 0 80px 0;
    text-align: center;

    border-radius: 0 0 20px 20px;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
}

@media     (min-width: 62em) {

.page-form__heading {
        padding: 160px 0
}
    }

.page-form__heading__sell-page {
        padding: 80px 0 160px 0;
    }

.page-form__steps {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 60px auto 0 auto;
}

.page-form__steps__step {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    font-size: 14px;
    border-radius: 50%;
    color: #fec45c;
    -webkit-box-shadow: 0 0 0 1px #fec45c inset;
            box-shadow: 0 0 0 1px #fec45c inset;

    width: 20px;
    height: 20px;
    margin-right: 32px;
}

@media     (min-width: 34em) {

.page-form__steps__step {
        width: 28px;
        height: 28px;
        margin-right: 40px
}
    }

.page-form__steps__step:before,
    .page-form__steps__step:after {
        content: '';
        position: absolute;
    }

.page-form__steps__step:before {
        display: none;
        top: -4px;
        left: -4px;

        width: 28px;
        height: 28px;

        border-radius: 50%;
        -webkit-box-shadow: 0 0 0 1px #fec45c inset;
                box-shadow: 0 0 0 1px #fec45c inset;
    }

@media     (min-width: 34em) {

.page-form__steps__step:before {
            width: 36px;
            height: 36px
    }
        }

.page-form__steps__step:after {
        display: block;
        top: 50%;
        left: 28px;

        width: 16px;
        height: 1px;

        background: rgba(255, 255, 255, 0.2);
    }

@media     (min-width: 34em) {

.page-form__steps__step:after {
            left: 36px;
            width: 24px
    }
        }

.page-form__steps__step:last-child {
        margin-right: 0;
    }

.page-form__steps__step:last-child:after {
            display: none;
        }

.page-form__steps__step.is-active {
        background: #fec45c;
        color: #4a1172;
        -webkit-box-shadow: 0 0 1px #fec45c;
                box-shadow: 0 0 1px #fec45c;
    }

.page-form__steps__step.is-active:before {
            display: block;
        }

.page-form__steps__step.is-active:hover,
        .page-form__steps__step.is-active:focus {
            color: #4a1172;
        }

.page-form__steps__step.is-active:hover:before, .page-form__steps__step.is-active:focus:before {
                -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
                        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
            }

.page-form__steps__step:hover,
    .page-form__steps__step:focus {
        color: #fec45c;
    }

.page-form__steps__step:hover:before, .page-form__steps__step:focus:before {
            display: block;
            -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
                    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
        }

.page-form__intro {
    margin: 60px auto 0 auto;
    max-width: 600px;
}

.form__banner {
    padding-top: 0;
    padding-bottom: 0;
}

.form__banner .home-info__grid {
        padding: 32px;
    }

.page-form__formbox {
    position: relative;
    z-index: 1;
    margin-top: -32px;
}

@media     (min-width: 62em) {

.page-form__formbox {
        display: flex;
        flex-flow: row wrap;
        margin-top: -72px
}
    }

.page-form__formsection {
    padding: 40px;
    margin-bottom: 40px;
    border-radius: 20px;
    background: white;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
}

@media     (min-width: 48em) {

.page-form__formsection {
        padding: 60px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        max-width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        position: relative;
        left: calc(99.9% * 1/12 - (36px - 36px * 1/12) + 36px)
}

.page-form__formsection:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.page-form__formsection:last-child {
        margin-right: 0
}

.page-form__formsection:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 62em) {

.page-form__formsection {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        max-width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        width: calc(99.9% * 8/12 - (36px - 36px * 8/12));
        position: relative;
        left: calc(99.9% * 2/12 - (36px - 36px * 2/12) + 36px)
}

.page-form__formsection:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.page-form__formsection:last-child {
        margin-right: 0
}

.page-form__formsection:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (min-width: 34em) {

.page-form__formsection--small {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        max-width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        width: calc(99.9% * 10/12 - (36px - 36px * 10/12));
        position: relative;
        left: calc(99.9% * 1/12 - (36px - 36px * 1/12) + 36px)
}

.page-form__formsection--small:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.page-form__formsection--small:last-child {
        margin-right: 0
}

.page-form__formsection--small:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 75em) {

.page-form__formsection--small {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        max-width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        width: calc(99.9% * 4/12 - (36px - 36px * 4/12));
        position: relative;
        left: calc(99.9% * 4/12 - (36px - 36px * 4/12) + 36px)
}

.page-form__formsection--small:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.page-form__formsection--small:last-child {
        margin-right: 0
}

.page-form__formsection--small:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.page-form__buttons {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.page-form__emphasized {
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 16px;
    background: rgba(255, 255, 255, .1);
}

.page-form__type-select {
    text-align: center;
}

.page-form__type-button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 40px;
    margin: 0 20px;
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .1);
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.page-form__type-button:hover,
    .page-form__type-button:focus {
        -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .2);
                box-shadow: 0 5.3px 8px rgba(0, 0, 0, .2);
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
    }

/*------------------------------------*\
    # view.checkout-subscription
\*------------------------------------*/

.checkout-subscription {
}

.checkout-subscription__header {
    z-index: 0;
    position: relative;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgb(83, 13, 133)),
        to(rgb(68, 2, 114))
    );
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;

    padding-top: 140px;
}

@media     (min-width: 62em) {

.checkout-subscription__header {
        padding-top: 200px
}
    }

.checkout-subscription__header__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

@media     (min-width: 62em) {

.checkout-subscription__header__text {
            margin-right: 80px
    }
        }

@media    (min-width: 75em) {

.checkout-subscription__header__text {
            margin-right: 140px
    }
        }

.checkout-subscription__header__illustration {
        display: none;
    }

@media     (min-width: 62em) {

.checkout-subscription__header__illustration {
            display: block
    }
        }

.checkout-subscription__featured {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background: rgb(83, 13, 133);

    padding: 24px 20px;
}

.checkout-subscription__featured__companies {
        width: 100%;
    }

@media     (min-width: 34em) {

.checkout-subscription__featured__companies {
            width: 80%
    }
        }

@media     (min-width: 62em) {

.checkout-subscription__featured__companies {
            width: 100%
    }
        }

.checkout-subscription__featured__first-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-bottom: 28px;
    }

.checkout-subscription__featured__first-group > * {
            opacity: 0.5;
        }

@media     (min-width: 62em) {

.checkout-subscription__featured__first-group {
            display: inline

            /* Every child except first */
    }
            .checkout-subscription__featured__first-group > * + * {
                margin-left: 28px;
            }
        }

.checkout-subscription__featured__second-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-bottom: 28px;
    }

.checkout-subscription__featured__second-group > * {
            opacity: 0.5;
        }

@media     (min-width: 62em) {

.checkout-subscription__featured__second-group {
            display: inline
    }
            .checkout-subscription__featured__second-group > * {
                margin-left: 28px;
            }
        }

.checkout-subscription__featured__third-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

.checkout-subscription__featured__third-group > * {
            opacity: 0.5;
        }

/* Every child except first */

.checkout-subscription__featured__third-group > * + * {
            padding-left: 8px;
        }

@media     (min-width: 62em) {

.checkout-subscription__featured__third-group {
            display: inline
    }

            .checkout-subscription__featured__third-group > * {
                padding-left: 0;
                margin-left: 28px;
            }
        }

@media     (min-width: 62em) {

.checkout-subscription__featured {
        display: block;
        text-align: center
}
    }

.checkout-subscription__card-container {
    position: relative;
    display: grid;
    grid-template-rows: auto;
}

@media     (min-width: 62em) {

.checkout-subscription__card-container {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 80px
}
    }

@media    (min-width: 75em) {

.checkout-subscription__card-container {
        grid-column-gap: 140px
}
    }

.checkout-subscription__progress {
    display: none;
}

@media     (min-width: 62em) {

.checkout-subscription__progress {
        display: block;
        position: absolute;
        top: 60%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-36%, -50%);
                transform: translate(-36%, -50%)
}
    }

@media    (min-width: 75em) {

.checkout-subscription__progress {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%)
}
    }

.checkout-subscription__vertical-card {
    position: relative;
    z-index: 0;
    background: rgb(74, 17, 114);
    color: white;
    -webkit-box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .06);
            box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .06);
    padding: 68px 40px 60px 40px;
    border-radius: 16px;
    margin-top: 80px;
}

.checkout-subscription__vertical-card__content {
        color: white;
    }

.checkout-subscription__vertical-card:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/static/media/smaller-card-pattern.0928ecc4.svg);
        background-size: cover;
        background-position: right top;
    }

.checkout-subscription__vertical-card__icon {
    position: relative;
    left: 85%;
    margin-top: -120px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.checkout-subscription__solutions {
    display: flex;
    flex-flow: row wrap;
    text-align: center;
}

.checkout-subscription__solutions__item {
    position: relative;

    -webkit-box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .06);

            box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .06);
    padding: 60px 32px 24px 32px;
    border-radius: 16px;
    width: 100%;
}

@media       (max-width: 33.9375em) {
        .checkout-subscription__solutions__item + .checkout-subscription__solutions__item {
            margin-top: 48px;
        }
    }

@media     (min-width: 34em) {

.checkout-subscription__solutions__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
        width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
}

.checkout-subscription__solutions__item:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.checkout-subscription__solutions__item:last-child {
        margin-right: 0
}

.checkout-subscription__solutions__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.checkout-subscription__important-section {
    padding: 40px 0;

    background: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;

    color: white;
}

@media     (min-width: 62em) {

.checkout-subscription__important-section {
        padding: 100px 0
}
    }

.checkout-subscription__alt-card {
    position: relative;
    background: white;
    border-radius: 16px;
    padding: 40px;
    -webkit-box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .06);
            box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, .06);
}

.checkout-subscription__alt-card__star {
    position: absolute;
    top: -30px;
    right: -22px;
}

.checkout-subscription__faq-item {
    width: 100%;
    display: block;
    text-align: left;
    background: #f4f7ff;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    color: #786f82;
    margin-top: 20px;
    cursor: pointer;
}

.checkout-subscription__faq-item:hover,
    .checkout-subscription__faq-item:focus {
        border: 1px solid #4a1172;
    }

.checkout-subscription__faq-item__icon.is-active {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.checkout-subscription__section {
    padding: 68px 0;
}

.checkout-subscription__solution-icon {
    position: absolute;
    top: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.checkout-subscription__vip-features {
    display: flex;
    flex-flow: row wrap;
}

.checkout-subscription__vip-feature {
    margin-top: 40px;
}

@media     (min-width: 34em) {

.checkout-subscription__vip-feature {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2))
}

.checkout-subscription__vip-feature:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0
}

.checkout-subscription__vip-feature:last-child {
        margin-right: 0
}

.checkout-subscription__vip-feature:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

.checkout-subscription__modules {
    display: block;
    height: auto;
    width: 100%;
    padding: 20px;
}

.checkout-subscription__modules.is-visible .checkout-subscription__modules__icon {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

.checkout-subscription__modules__list {
    text-align: left;
    margin-top: 16px;
}

.checkout-subscription__alt-card li {
        padding-left: 32px;
        margin-bottom: 16px;
        color: #786f82;
    }

.checkout-subscription__testimonials {
    position: relative;
    background: white;
    background: #ffffff;

    -webkit-box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);

            box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
    border-radius: 16px;
    padding: 40px 20px;
    padding-bottom: 100px;
}

@media     (min-width: 48em) {

.checkout-subscription__testimonials {
        padding: 40px 120px
}
    }

.checkout-subscription__testimonials .BrainhubCarouselItem {
        -webkit-box-align: start !important;
            -ms-flex-align: start !important;
                align-items: flex-start !important;
    }

.checkout-subscription__testimonials .BrainhubCarousel__dot {
    background: transparent;
}

.checkout-subscription__testimonials .BrainhubCarousel__dots {
    bottom: 24px;
}

.checkout-subscription__testimonials__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-line-pack: center;
        align-content: center;

    margin-top: 48px;
    padding: 0 32px;
}

@media     (min-width: 48em) {

.checkout-subscription__testimonials__item {
        padding: 0 80px
}
    }

.checkout-subscription__testimonials__carousel-right-arrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.checkout-subscription__testimonials__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 20px;
}

.checkout-subscription__testimonials__image img {
        width: 50px;
        height: 50px;
        border-radius: 1000px;
        margin-right: 12px;
    }

@media     (min-width: 48em) {

.checkout-subscription__testimonials__image img {
            width: 80px;
            height: 80px
    }
        }

.checkout-subscription__testimonials__content {
    text-align: center;
}

.checkout-subscription__table {
    overflow-y: auto;
    margin-bottom: 80px;
    display: none;
}

@media     (min-width: 62em) {

.checkout-subscription__table {
        display: block
}
    }

.checkout-subscription__table table {
        min-width: 600px;
    }

.checkout-subscription__table--mobile {
    display: block;
    padding: 0 20px;
}

@media     (min-width: 34em) {

.checkout-subscription__table--mobile {
        padding: 0 40px
}
    }

@media     (min-width: 62em) {

.checkout-subscription__table--mobile {
        display: none
}
    }

.checkout-subscription__video-container {
    padding: 40px 40px;
}

@media     (min-width: 62em) {

.checkout-subscription__video-container {
        padding: 60px 200px
}
    }

.checkout-subscription__video {
    position: relative;
    z-index: 0;
}

.checkout-subscription__video:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 10px;
        left: -10px;
        background: #fec45c;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }

.checkout-subscription__video:after {
        z-index: -1;
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        background: #4a1172;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }

.checkout-subscription__video iframe {
        z-index: 1;
        border-radius: 16px;
        height: 380px;
    }

@media     (min-width: 48em) {

.checkout-subscription__video iframe {
            height: 466px
    }
        }

/*------------------------------------*\
    # view.affiliates
\*------------------------------------*/

.v-affiliates__intro {
    z-index: 0;
    position: relative;
    padding: 60px 0;
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgb(83, 13, 133)),
        to(rgb(68, 2, 114))
    );
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;
}

@media     (min-width: 62em) {

.v-affiliates__intro {
        padding-top: 140px
}
    }

.v-affiliates__intro:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

.v-affiliates__header {
    max-width: 720px;
}

.v-affiliates__claim {
    padding: 40px 0;
}

@media     (min-width: 62em) {

.v-affiliates__claim {
        padding: 140px 0
}
    }

.v-affiliates__why {
    padding: 40px 0;
}

@media     (min-width: 62em) {

.v-affiliates__why {
        padding: 140px 0
}
    }

.v-affiliates__card {
    position: relative;
    background: white;
    -webkit-box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
            box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
    border-radius: 16px;
    padding: 40px;
    padding-top: 68px;
    margin-top: 108px;
}

.v-affiliates__card.full-h {
        height: 100%;
    }

.v-affiliates__card.no-margin-t {
        margin-top: 0;
    }

.v-affiliates__card-overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
    -webkit-box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
            box-shadow: 0px 16px 36px rgba(0, 0, 0, .06);
    border-radius: 16px;
}

.v-affiliates__card-overlay:hover,
    .v-affiliates__card-overlay:focus {
        opacity: 0.1;
    }

.v-affiliates__disabled-card-title {
    color: #2f2f30;
}

.v-affiliates__disabled-card__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #4a1172;
    color: #fec45c;
    font-size: 25px;
    line-height: 32px;
    font-weight: 700;
    padding: 12px 24px;
    border-radius: 6px;
}

.v-affiliates__card__icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -40px;
}

.v-affiliates__card__icon--why {
    left: 40px;
    margin: 0;
}

.v-affiliates__card__icon--commission {
    top: -44px;
}

.v-affiliates__card--horizontal {
    padding: 24px;
    padding-top: 64px;
}

.v-affiliates__commision {
    padding: 40px 0;

    background-color: #4a1172;
    background-image: url(/static/media/pattern-1.e467f39f.svg);
    background-blend-mode: overlay;
    background-repeat: repeat-x;
    background-size: 50%;
}

@media     (min-width: 48em) {

.v-affiliates__commision {
        padding: 80px 0
}
    }

@media     (min-width: 48em) {

.v-affiliates__commision__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px
}
    }

@media     (min-width: 62em) {

.v-affiliates__commision__grid {
        grid-gap: 96px
}
    }

.v-affiliates__how {
    padding: 40px 0;
}

@media     (min-width: 62em) {

.v-affiliates__how {
        padding: 80px 0
}
    }

.v-affiliates__how_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 60px;
}

@media     (min-width: 48em) {

.v-affiliates__how_wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 60px
}
    }

.v-affiliates__how__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 60px;
}

@media     (min-width: 48em) {

.v-affiliates__how__grid {
        display: grid;
        grid-template-columns: repeat(7, auto);
        row-gap: 60px;
        -webkit-column-gap: 24px;
           -moz-column-gap: 24px;
                column-gap: 24px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }

@media      (max-width: 74.9375em) {

.v-affiliates__how__grid {
        grid-template-columns: repeat(4, auto)
}
    }

.v-affiliates__how__arrow {
    display: none;
}

@media     (min-width: 48em) {

.v-affiliates__how__arrow {
        display: block;
        margin-top: 108px
}
    }

.v-affiliates__faq {
    padding: 40px 0;
}

@media     (min-width: 62em) {

.v-affiliates__faq {
        padding: 80px 0
}
    }

.v-affiliates__faq__content {
    display: none;
}

.v-affiliates__faq__item {
    padding: 24px;
    background: #f4f7ff;
    border: 1px solid rgba(0, 0, 0, .05);

    -webkit-box-shadow: 0px 6px 20px rgba(0, 0, 0, .04);

            box-shadow: 0px 6px 20px rgba(0, 0, 0, .04);
    border-radius: 16px;

    text-align: left;
    color: #786f82;
    width: 100%;
}

.v-affiliates__faq__item:hover,
    .v-affiliates__faq__item:focus {
        background: rgb(242, 242, 242);
    }

.v-affiliates__faq__item + .v-affiliates__faq__item {
        margin-top: 20px;
    }

.v-affiliates__faq__item.active .v-affiliates__faq__content {
            margin-top: 16px;
            display: block;
        }

.v-affiliates__faq__item.active .v-affiliates__caret {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
        }

/**
* Import: utils
* Description: reusable utilities such as floats, spacers etc.
*/

/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.w-full {
        width: 100%;
    }

.w-half {
        width: 50%;
    }

/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u-display__block {
            display: block;
        }

.u-display__inline {
            display: inline;
        }

/*------------------------------------*\
    # utils.grid
\*------------------------------------*/

@media     (min-width: 34em) {

.g {
        display: flex;
        flex-flow: row wrap;
        margin-left: -18px;
        margin-right: -18px
}
    }

@media     (min-width: 34em) {
            .g-sml__col1 {
                flex: 0 0 auto;
                width: calc(99.9% * 1/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col2 {
                flex: 0 0 auto;
                width: calc(99.9% * 2/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col3 {
                flex: 0 0 auto;
                width: calc(99.9% * 3/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col4 {
                flex: 0 0 auto;
                width: calc(99.9% * 4/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col5 {
                flex: 0 0 auto;
                width: calc(99.9% * 5/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col6 {
                flex: 0 0 auto;
                width: calc(99.9% * 6/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col7 {
                flex: 0 0 auto;
                width: calc(99.9% * 7/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col8 {
                flex: 0 0 auto;
                width: calc(99.9% * 8/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col9 {
                flex: 0 0 auto;
                width: calc(99.9% * 9/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col10 {
                flex: 0 0 auto;
                width: calc(99.9% * 10/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col11 {
                flex: 0 0 auto;
                width: calc(99.9% * 11/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-sml__col12 {
                flex: 0 0 auto;
                width: calc(99.9% * 12/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }
        }

@media     (min-width: 48em) {
            .g-med__col1 {
                flex: 0 0 auto;
                width: calc(99.9% * 1/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col2 {
                flex: 0 0 auto;
                width: calc(99.9% * 2/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col3 {
                flex: 0 0 auto;
                width: calc(99.9% * 3/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col4 {
                flex: 0 0 auto;
                width: calc(99.9% * 4/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col5 {
                flex: 0 0 auto;
                width: calc(99.9% * 5/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col6 {
                flex: 0 0 auto;
                width: calc(99.9% * 6/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col7 {
                flex: 0 0 auto;
                width: calc(99.9% * 7/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col8 {
                flex: 0 0 auto;
                width: calc(99.9% * 8/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col9 {
                flex: 0 0 auto;
                width: calc(99.9% * 9/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col10 {
                flex: 0 0 auto;
                width: calc(99.9% * 10/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col11 {
                flex: 0 0 auto;
                width: calc(99.9% * 11/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-med__col12 {
                flex: 0 0 auto;
                width: calc(99.9% * 12/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }
        }

@media     (min-width: 62em) {
            .g-lrg__col1 {
                flex: 0 0 auto;
                width: calc(99.9% * 1/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col2 {
                flex: 0 0 auto;
                width: calc(99.9% * 2/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col3 {
                flex: 0 0 auto;
                width: calc(99.9% * 3/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col4 {
                flex: 0 0 auto;
                width: calc(99.9% * 4/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col5 {
                flex: 0 0 auto;
                width: calc(99.9% * 5/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col6 {
                flex: 0 0 auto;
                width: calc(99.9% * 6/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col7 {
                flex: 0 0 auto;
                width: calc(99.9% * 7/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col8 {
                flex: 0 0 auto;
                width: calc(99.9% * 8/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col9 {
                flex: 0 0 auto;
                width: calc(99.9% * 9/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col10 {
                flex: 0 0 auto;
                width: calc(99.9% * 10/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col11 {
                flex: 0 0 auto;
                width: calc(99.9% * 11/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-lrg__col12 {
                flex: 0 0 auto;
                width: calc(99.9% * 12/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }
        }

@media    (min-width: 75em) {
            .g-xlrg__col1 {
                flex: 0 0 auto;
                width: calc(99.9% * 1/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col2 {
                flex: 0 0 auto;
                width: calc(99.9% * 2/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col3 {
                flex: 0 0 auto;
                width: calc(99.9% * 3/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col4 {
                flex: 0 0 auto;
                width: calc(99.9% * 4/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col5 {
                flex: 0 0 auto;
                width: calc(99.9% * 5/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col6 {
                flex: 0 0 auto;
                width: calc(99.9% * 6/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col7 {
                flex: 0 0 auto;
                width: calc(99.9% * 7/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col8 {
                flex: 0 0 auto;
                width: calc(99.9% * 8/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col9 {
                flex: 0 0 auto;
                width: calc(99.9% * 9/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col10 {
                flex: 0 0 auto;
                width: calc(99.9% * 10/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col11 {
                flex: 0 0 auto;
                width: calc(99.9% * 11/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xlrg__col12 {
                flex: 0 0 auto;
                width: calc(99.9% * 12/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }
        }

@media   (min-width: 90em) {
            .g-xxlrg__col1 {
                flex: 0 0 auto;
                width: calc(99.9% * 1/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col2 {
                flex: 0 0 auto;
                width: calc(99.9% * 2/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col3 {
                flex: 0 0 auto;
                width: calc(99.9% * 3/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col4 {
                flex: 0 0 auto;
                width: calc(99.9% * 4/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col5 {
                flex: 0 0 auto;
                width: calc(99.9% * 5/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col6 {
                flex: 0 0 auto;
                width: calc(99.9% * 6/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col7 {
                flex: 0 0 auto;
                width: calc(99.9% * 7/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col8 {
                flex: 0 0 auto;
                width: calc(99.9% * 8/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col9 {
                flex: 0 0 auto;
                width: calc(99.9% * 9/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col10 {
                flex: 0 0 auto;
                width: calc(99.9% * 10/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col11 {
                flex: 0 0 auto;
                width: calc(99.9% * 11/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }

            .g-xxlrg__col12 {
                flex: 0 0 auto;
                width: calc(99.9% * 12/12 - 36px);
                margin-left: 18px;
                margin-right: 18px;
            }
        }

/*------------------------------------*\
    # utils.flex
\*------------------------------------*/

.f {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.f--i {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.f--col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.f-align-center {
    margin: auto;
    -ms-flex-item-align: center;
        align-self: center;
}

.f-align-left {
    margin-right: auto;
    -ms-flex-item-align: center;
        align-self: center;
}

.f-align-right {
    margin-left: auto;
    -ms-flex-item-align: center;
        align-self: center;
}

.f-align-x-center {
    margin-right: auto;
    margin-left: auto;
}

.f-align-x-left {
    margin-right: auto;
}

.f-align-x-right {
    margin-left: auto;
}

.f-align-y-center {
    -ms-flex-item-align: center;
        align-self: center;
}

.f-align-y-top {
    -ms-flex-item-align: start;
        align-self: flex-start;
}

.f-align-y-bottom {
    -ms-flex-item-align: end;
        align-self: flex-end;
}

.f-justify-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.f-justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.f-justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.f-justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.f-align-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.f-align-items-bottom {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}

.f-align-items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

/*------------------------------------*\
    # utils.visibiity
\*------------------------------------*/

.visi {
}

@media       (max-width: 33.9375em) {

.visi-hide--sml {
        display: none
}
    }

@media       (max-width: 47.9375em) {

.visi-hide--med {
        display: none
}
    }

@media       (max-width: 61.9375em) {

.visi-hide--lrg {
        display: none
}
    }

/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.s-top--tny {
            margin-top: 4px;
        }

.s-top--sml {
            margin-top: 8px;
        }

.s-top--med {
            margin-top: 16px;
        }

.s-top--lrg {
            margin-top: 24px;
        }

.s-top--xlrg {
            margin-top: 32px;
        }

.s-top--xxlrg {
            margin-top: 40px;
        }

.s-top--xxxlrg {
            margin-top: 40px;
        }

@media     (min-width: 34em) {

.s-top--xxxlrg {
                margin-top: 60px
        }
            }

.s-right--tny {
            margin-right: 4px;
        }

.s-right--sml {
            margin-right: 8px;
        }

.s-right--med {
            margin-right: 16px;
        }

.s-right--lrg {
            margin-right: 24px;
        }

.s-right--xlrg {
            margin-right: 32px;
        }

.s-right--xxlrg {
            margin-right: 40px;
        }

.s-right--xxxlrg {
            margin-right: 40px;
        }

@media     (min-width: 34em) {

.s-right--xxxlrg {
                margin-right: 60px
        }
            }

.s-bottom--tny {
            margin-bottom: 4px;
        }

.s-bottom--sml {
            margin-bottom: 8px;
        }

.s-bottom--med {
            margin-bottom: 16px;
        }

.s-bottom--lrg {
            margin-bottom: 24px;
        }

.s-bottom--xlrg {
            margin-bottom: 32px;
        }

.s-bottom--xxlrg {
            margin-bottom: 40px;
        }

.s-bottom--xxxlrg {
            margin-bottom: 40px;
        }

@media     (min-width: 34em) {

.s-bottom--xxxlrg {
                margin-bottom: 60px
        }
            }

.s-left--tny {
            margin-left: 4px;
        }

.s-left--sml {
            margin-left: 8px;
        }

.s-left--med {
            margin-left: 16px;
        }

.s-left--lrg {
            margin-left: 24px;
        }

.s-left--xlrg {
            margin-left: 32px;
        }

.s-left--xxlrg {
            margin-left: 40px;
        }

.s-left--xxxlrg {
            margin-left: 40px;
        }

@media     (min-width: 34em) {

.s-left--xxxlrg {
                margin-left: 60px
        }
            }

.p-all--tny {
            padding: 4px;
        }

.p-all--sml {
            padding: 8px;
        }

.p-all--med {
            padding: 16px;
        }

.p-all--lrg {
            padding: 24px;
        }

.p-all--xlrg {
            padding: 32px;
        }

.p-all--xxlrg {
            padding: 40px;
        }

.p-all--xxxlrg {
            padding: 40px;
        }

@media     (min-width: 34em) {

.p-all--xxxlrg {
                padding: 60px
        }
            }

.p-top--tny {
            padding-top: 4px;
        }

.p-top--sml {
            padding-top: 8px;
        }

.p-top--med {
            padding-top: 16px;
        }

.p-top--lrg {
            padding-top: 24px;
        }

.p-top--xlrg {
            padding-top: 32px;
        }

.p-top--xxlrg {
            padding-top: 40px;
        }

.p-top--xxxlrg {
            padding-top: 40px;
        }

@media     (min-width: 34em) {

.p-top--xxxlrg {
                padding-top: 60px
        }
            }

.p-right--tny {
            padding-right: 4px;
        }

.p-right--sml {
            padding-right: 8px;
        }

.p-right--med {
            padding-right: 16px;
        }

.p-right--lrg {
            padding-right: 24px;
        }

.p-right--xlrg {
            padding-right: 32px;
        }

.p-right--xxlrg {
            padding-right: 40px;
        }

.p-right--xxxlrg {
            padding-right: 40px;
        }

@media     (min-width: 34em) {

.p-right--xxxlrg {
                padding-right: 60px
        }
            }

.p-bottom--tny {
            padding-bottom: 4px;
        }

.p-bottom--sml {
            padding-bottom: 8px;
        }

.p-bottom--med {
            padding-bottom: 16px;
        }

.p-bottom--lrg {
            padding-bottom: 24px;
        }

.p-bottom--xlrg {
            padding-bottom: 32px;
        }

.p-bottom--xxlrg {
            padding-bottom: 40px;
        }

.p-bottom--xxxlrg {
            padding-bottom: 40px;
        }

@media     (min-width: 34em) {

.p-bottom--xxxlrg {
                padding-bottom: 60px
        }
            }

.p-left--tny {
            padding-left: 4px;
        }

.p-left--sml {
            padding-left: 8px;
        }

.p-left--med {
            padding-left: 16px;
        }

.p-left--lrg {
            padding-left: 24px;
        }

.p-left--xlrg {
            padding-left: 32px;
        }

.p-left--xxlrg {
            padding-left: 40px;
        }

.p-left--xxxlrg {
            padding-left: 40px;
        }

@media     (min-width: 34em) {

.p-left--xxxlrg {
                padding-left: 60px
        }
            }

/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.o {
}

.o-90 {
    opacity: 0.9;
}

.o-80 {
    opacity: 0.8;
}

.o-70 {
    opacity: 0.7;
}

.o-60 {
    opacity: 0.6;
}

.o-50 {
    opacity: 0.5;
}

.o-40 {
    opacity: 0.4;
}

.o-30 {
    opacity: 0.3;
}

.o-20 {
    opacity: 0.2;
}

.o-10 {
    opacity: 0.1;
}

/*------------------------------------*\
    # utils.type
\*------------------------------------*/

.t {
}

.t-capitalize {
    text-transform: capitalize;
}

.t-bold {
    font-weight: 700;
}

.t-regular {
    font-weight: 400;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

.t-left {
    text-align: left;
}

.t-upper {
    text-transform: uppercase;
}

.t-upperext {
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.t-serif {
    font-family: Georgia, Times, serif;
}

.t-sans {
    font-family: 'Rational Display', Helvetica, Arial, sans-serif;
}

/* Sizes */

/* Alpha, beta, gamma and delta sizes scale by 2 sizes from small to large screens */

.t-alpha {
    font-size: calc(32.71429px + 1.96429vw);

    line-height: calc(41.14286px + 2.14286vw);
}

@media screen and (min-width: 1440px) {

    .t-alpha {
        line-height: 72px;
    }
}

@media screen and (max-width: 320px) {

    .t-alpha {
        line-height: 48px;
    }
}

@media screen and (min-width: 1440px) {

    .t-alpha {
        font-size: 61px;
    }
}

@media screen and (max-width: 320px) {

    .t-alpha {
        font-size: 39px;
    }
}

.t-beta {
    font-size: calc(18.14286px + 2.14286vw); /* Shrinks 3 scales */

    line-height: calc(29px + 1.875vw);
}

@media screen and (min-width: 1440px) {

    .t-beta {
        line-height: 56px;
    }
}

@media screen and (max-width: 320px) {

    .t-beta {
        line-height: 35px;
    }
}

@media screen and (min-width: 1440px) {

    .t-beta {
        font-size: 49px;
    }
}

@media screen and (max-width: 320px) {

    .t-beta {
        font-size: 25px;
    }
}

.t-gamma {
    font-size: calc(21px + 1.25vw);

    line-height: calc(31.28571px + 1.16071vw);
}

@media screen and (min-width: 1440px) {

    .t-gamma {
        line-height: 48px;
    }
}

@media screen and (max-width: 320px) {

    .t-gamma {
        line-height: 35px;
    }
}

@media screen and (min-width: 1440px) {

    .t-gamma {
        font-size: 39px;
    }
}

@media screen and (max-width: 320px) {

    .t-gamma {
        font-size: 25px;
    }
}

.t-delta {
    font-size: calc(16.85714px + 0.98214vw);

    line-height: calc(24.57143px + 1.07143vw);
}

@media screen and (min-width: 1440px) {

    .t-delta {
        line-height: 40px;
    }
}

@media screen and (max-width: 320px) {

    .t-delta {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .t-delta {
        font-size: 31px;
    }
}

@media screen and (max-width: 320px) {

    .t-delta {
        font-size: 20px;
    }
}

.t-epsilon {
    font-size: calc(18.57143px + 0.44643vw);

    line-height: calc(25.71429px + 0.71429vw);
}

@media screen and (min-width: 1440px) {

    .t-epsilon {
        line-height: 36px;
    }
}

@media screen and (max-width: 320px) {

    .t-epsilon {
        line-height: 28px;
    }
}

@media screen and (min-width: 1440px) {

    .t-epsilon {
        font-size: 25px;
    }
}

@media screen and (max-width: 320px) {

    .t-epsilon {
        font-size: 20px;
    }
}

.t-zeta {
    font-size: calc(14.85714px + 0.35714vw);

    line-height: calc(22.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    .t-zeta {
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {

    .t-zeta {
        line-height: 24px;
    }
}

@media screen and (min-width: 1440px) {

    .t-zeta {
        font-size: 20px;
    }
}

@media screen and (max-width: 320px) {

    .t-zeta {
        font-size: 16px;
    }
}

.t-base {
    font-size: calc(13.42857px + 0.17857vw);

    line-height: calc(18.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    .t-base {
        line-height: 24px;
    }
}

@media screen and (max-width: 320px) {

    .t-base {
        line-height: 20px;
    }
}

@media screen and (min-width: 1440px) {

    .t-base {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {

    .t-base {
        font-size: 14px;
    }
}

.t-micro {
    font-size: calc(11.42857px + 0.17857vw);

    line-height: calc(14.85714px + 0.35714vw);
}

@media screen and (min-width: 1440px) {

    .t-micro {
        line-height: 20px;
    }
}

@media screen and (max-width: 320px) {

    .t-micro {
        line-height: 16px;
    }
}

@media screen and (min-width: 1440px) {

    .t-micro {
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {

    .t-micro {
        font-size: 12px;
    }
}

.t-tiny {
    font-size: 12px;
    line-height: 16px;
}

/* Colors */

.t-primary {
    color: #4a1172;
}

.t-secondary {
    color: #fec45c;
}

.t-secondary-dark {
    color: rgb(254, 180, 42);
}

.t-text {
    color: #786f82;
}

.t-negative {
    color: white;
}

.t-dark {
    color: rgb(57, 18, 84);
}

.t-darktext {
    color: #2f2f30;
}

.t-underline {
    text-decoration: underline;
}

.t-emphasized {
    font-style: italic;
}

.t-smcp {
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 0.05em;
}

.t-warning {
    color: #d84a4a;
}

.t-success {
    color: #53cb83;
}

.t-note {
    color: #fec45c;
}

.t-note--darker {
    color: rgb(244, 159, 1);
}

.t-strike {
    text-decoration: line-through;
}

/* Shorten long links */

.t-shorten {
    /* These are technically the same, but use both */
    word-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Special headings */

.t-smallheading {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
}

.t-prewrap {
    white-space: pre-wrap;
}

.t-overflowwrap {
    word-wrap: break-word;
}

.t-link {
    color: #4a1172;
}

.t-link:hover,
    .t-link:focus {
        color: rgb(132, 30, 204);
    }

.t-link--alt {
    color: #fec45c;
}

.t-link--alt:hover,
    .t-link--alt:focus {
        color: rgb(255, 233, 194);
    }

.t-turbo {
    background-image: linear-gradient(45deg, #9600ff, #ebe833);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*------------------------------------*\
    # utils.lists
\*------------------------------------*/

.l {
}

.l-bordered > li {
        padding: 12px 0;
        border-bottom: 1px solid rgba(57, 18, 84, 0.2);
    }

.l-split {
    display: flex;
    flex-flow: row wrap;
}

.l-split > li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    }

.l-split > li:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0;
    }

.l-split > li:last-child {
        margin-right: 0;
    }

.l-split > li:nth-child(2n) {
        margin-right: 0;
        margin-left: auto;
    }

.l-triple {
    display: flex;
    flex-flow: row wrap;
}

.l-triple > li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        max-width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
        width: calc(99.9% * 1/2 - (36px - 36px * 1/2));
    }

.l-triple > li:nth-child(1n) {
        margin-right: 36px;
        margin-left: 0;
    }

.l-triple > li:last-child {
        margin-right: 0;
    }

.l-triple > li:nth-child(2n) {
        margin-right: 0;
        margin-left: auto;
    }

@media     (min-width: 62em) {

.l-triple > li {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 1/3 - (36px - 36px * 1/3));
            max-width: calc(99.9% * 1/3 - (36px - 36px * 1/3));
            width: calc(99.9% * 1/3 - (36px - 36px * 1/3))
    }

.l-triple > li:nth-child(1n) {
            margin-right: 36px;
            margin-left: 0
    }

.l-triple > li:last-child {
            margin-right: 0
    }

.l-triple > li:nth-child(3n) {
            margin-right: 0;
            margin-left: auto
    }
        }

.l-inside {
    list-style-position: inside;
}

.l-outside {
    list-style-position: outside;
}

.l-checked {
}

.l-checked li {
    position: relative;
}

.l-checked li:before {
        content: '';
        display: block;
        position: absolute;

        top: 3px;
        left: -16px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);

        height: 11px;
        width: 5px;
        border-bottom: 1px solid currentColor;
        border-right: 1px solid currentColor;
    }

/* Checkmarks are inside on smaller screens */

@media       (max-width: 47.9375em) {

.l-checked li {
        padding-left: 1em
}

        .l-checked li:before {
            left: 0;
        }
    }

.l-checked--inside li {
    padding-left: 2em;
}

.l-checked--inside li:before {
        left: 0;
    }

.l-checked--negative li:before {
        border-bottom: 1px solid white;
        border-right: 1px solid white;
    }

.l-checked--success li:before {
        border-bottom: 1px solid #53cb83;
        border-right: 1px solid #53cb83;
    }

.l-numeratedcircles {
    list-style: none;
}

.l-numeratedcircles li {
    position: relative;
    padding-bottom: 20px;
    padding-left: 60px;
    counter-increment: list;
}

.l-numeratedcircles li:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 22px;
        width: 1px;
        height: 100%;
        background: rgba(57, 18, 84, 0.1);
    }

.l-numeratedcircles li:after {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 44px;
        height: 44px;
        line-height: 38px;
        font-size: 14px;

        content: counter(list);

        text-align: center;

        border: 4px solid #f4f7ff;
        -webkit-box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.2) inset;
                box-shadow: 0 0 0 1px rgba(57, 18, 84, 0.2) inset;
        background: #f4f7ff;
        border-radius: 50%;
    }

.l-numeratedcircles li:last-child:before {
            display: none;
        }

.l-prices {
}

.l-prices li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid rgba(57, 18, 84, 0.1);
}

.l-prices li span {
    margin-right: 16px;
}

.l-prices li span:nth-child(1) {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 15%;
                flex: 1 0 15%;
    }

.l-prices li span:nth-child(2) {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 40%;
                flex: 1 0 40%;
    }

.l-prices li span:nth-child(3) {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 45%;
                flex: 1 0 45%;
    }

.l-special li {
        position: relative;
    }

.l-special li:before {
            content: '';
            opacity: 0.5;
            background-image: url(/static/media/icon-star.150f6324.svg);
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            height: 16px;
            width: 16px;
        }

.l-bullet {
    list-style-type: disc;
    list-style-position: inside;
}

.l-bullet--outside {
    list-style-type: disc;
    list-style-position: outside;
}

/*------------------------------------*\
    # utils.well
\*------------------------------------*/

.well {
}

.well--basic {
    padding: 32px;
    border: 1px solid rgba(57, 18, 84, 0.1);
    border-radius: 6px;
}

/*------------------------------------*\
    # utils.shadow
\*------------------------------------*/

.shadow {
    -webkit-box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
            box-shadow: 0 5.3px 8px rgba(0, 0, 0, .02), 0 42px 64px rgba(0, 0, 0, .04);
}

/*------------------------------------*\
    # utils.overflow
\*------------------------------------*/

.overflow-h {
    overflow: hidden;
}

.overflow-a {
    overflow: auto;
}

/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {
}

.u-pos--rel {
    position: relative;
}

/*------------------------------------*\
    # utils.lost
\*------------------------------------*/

.lost-container {
    display: flex;
    flex-flow: row wrap;
}

/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.bg {
}

.bg--blue {
    background: #f4f7ff;
}

.bg--white {
    background: white;
}

/**
* Import: plugins
* Description: 3rd party code, external plugin CSS etc
* Note: when importing code from a 3rd party it should be stripped off
* any vendor prefixes since autoprefixer will use project specific vendor prefixes
*/

/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/

@font-face {
    font-family: 'Rational Display';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/RationalDisplayBook.ea9b6358.woff2) format('woff2'),
        url(/static/media/RationalDisplayBook.2d52f158.woff) format('woff');
}

@font-face {
    font-family: 'Rational Display';
    font-style: italic;
    font-weight: 400;
    src: url(/static/media/RationalDisplayBookItalic.c77412ff.woff2) format('woff2'),
        url(/static/media/RationalDisplayBookItalic.c0576042.woff) format('woff');
}

@font-face {
    font-family: 'Rational Display';
    font-style: normal;
    font-weight: 700;
    src: url(/static/media/RationalDisplaySemiBold.b1376e80.woff2) format('woff2'),
        url(/static/media/RationalDisplaySemiBold.f9efb510.woff) format('woff');
}

/*------------------------------------*\
    # plugins.react-table
\*------------------------------------*/

.react-table-wrap {
    overflow: auto;
    max-width: 100%;
}

.react-table {
    display: block;
}

.react-table tr {
        display: block;
    }

.react-table th,
    .react-table td {
        width: 1%;
        position: relative;
    }

.react-table thead {
        width: 100%;
        display: block;
    }

.react-table thead tr {
            border-bottom: 1px solid rgba(0, 0, 0, .05);
        }

.react-table thead th {
            position: relative;
        }

.react-table thead .react-table__content {
            padding-top: 8px;
            padding-bottom: 8px;
            border-right: 1px solid rgba(57, 18, 84, 0.1);
        }

.react-table thead th > div {
            padding: 16px 4px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            width: 100%;
        }

.react-table tbody {
        display: block;
        width: 100%;
    }

.react-table tbody tr {
            border-bottom: 1px solid rgba(0, 0, 0, .05);
        }

.react-table tbody td {
            font-size: 14px;
            line-height: 16px;
            padding: 12px 8px;
        }

.react-table input,
    .react-table select {
        border: 1px solid rgba(0, 0, 0, .1);
        background: white;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline-width: 0;
        width: 100%;
    }

.react-table .btn:hover,
        .react-table .btn:active,
        .react-table .btn:focus {
            -webkit-transform: none;
                    transform: none;
        }

.react-table__col {
    padding-top: 4px;
    padding-bottom: 4px;
}

.react-table__col--sortedAsc {
    -webkit-box-shadow: 0 -1px 0 0 #4a1172;
            box-shadow: 0 -1px 0 0 #4a1172;
}

.react-table__col--sortedDesc {
    -webkit-box-shadow: 0 1px 0 0 #4a1172;
            box-shadow: 0 1px 0 0 #4a1172;
}

.react-table__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.react-table__filter {
    position: relative;
    padding-top: 12px;
    padding-right: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.react-table__resizer {
    display: inline-block;
    position: absolute;
    width: 8px;
    top: 8px;
    bottom: 8px;
    right: 0;
    cursor: col-resize;
    z-index: 10;
}

.react-table-pagination {
    padding-top: 16px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    line-height: 16px;
}

.react-table-pagination input {
        height: 32px;
        border: 1px solid rgba(57, 18, 84, .1);
        padding: 5px 7px;
        border-radius: 0;
        outline-width: 0;
        background: transparent;
        width: 70px;
        text-align: center;
    }

.react-table-pagination select {
        height: 32px;
        border: 1px solid rgba(57, 18, 84, .1);
        padding: 5px 7px;
        border-radius: 0;
        outline-width: 0;
        background: transparent;
    }

.react-table-pagination__info {
    display: inline-block;
    white-space: nowrap;
}

.react-table-pagination__controls {
    padding: 0 32px;
}

.react-table-pagination__btn {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    background: transparent;
    display: block;
    height: 32px;
    padding: 0 24px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(74, 17, 114, .6);
            box-shadow: inset 0 0 0 1px rgba(74, 17, 114, .6);
    color: rgba(74, 17, 114, .8);
}

.react-table-pagination__btn--disabled {
    opacity: 0.5;
    cursor: default;
}

/*------------------------------------*\
    # plugins.react-month-picker
\*------------------------------------*/

.react-month-picker {
}

.react-month-picker .calendar-container {
    width: 260px;
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 2;
    background: white;
    border: 1px solid #eee;
}

/*  SECTIONS  */

.react-month-picker .section_mp {
    clear: both;
    padding: 0;
    margin: 0;
}

/*  COLUMN SETUP  */

.react-month-picker .col_mp {
    display: block;
    float: left;
    text-align: center;
}

.react-month-picker .col_mp:first-child {
    margin-left: 0;
}

/*  GROUPING  */

.react-month-picker .group_mp:before,
.react-month-picker .group:after {
    content: '';
    display: table;
}

.react-month-picker .group_mp:after {
    clear: both;
}

.react-month-picker .group_mp {
    zoom: 1; /* For IE 6/7 */
}

/*  GRID OF THREE  */

.react-month-picker .span_1_of_3_mp {
    width: 33.33%;
}

.react-month-picker .col_mp {
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 20px;
}

.react-month-picker .col_mp:hover {
    color: white;
    background-color: #4a1172;
    cursor: pointer;
}

.react-month-picker .calendar-container.readonly .col_mp:hover {
    background-color: transparent;
    cursor: default;
    color: inherit;
}

.react-month-picker .selected_date_mp {
    font-size: 12px;
    color: #4a1172;
    font-weight: bold;
}

.react-month-picker .selected_cell {
    background-color: #4a1172;
    font-style: italic;
    color: white;
}

.react-month-picker .arrows_mp {
    font-weight: bold;
    font-size: 14px;
}

.react-month-picker .month-input.readonly {
    background-color: #eee;
}

/*------------------------------------*\
    # plugins.slick-slider
\*------------------------------------*/

.BrainhubCarousel-container {
    position: relative;
}

.BrainhubCarousel {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer {
    overflow: hidden;
}

.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
}

.BrainhubCarousel
    .BrainhubCarousel__trackContainer
    .BrainhubCarousel__track.BrainhubCarousel__track--transition {
    -webkit-transition: -webkit-transform;
    transition: -webkit-transform;
    transition: transform;
    transition: transform, -webkit-transform;
}

/* arrows */

.BrainhubCarousel__arrows {
    cursor: pointer;
}

/* variables */

.BrainhubCarousel__dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;

    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    width: 36px;
    height: 36px;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.1;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    color: transparent;
    background: transparent;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
    opacity: 1 !important;
}

/* Because on mobile and tablet hover can brake slider */

@media     (min-width: 62em) {

.BrainhubCarousel__dots .BrainhubCarousel__dot:hover {
        opacity: 1
}
    }

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: #4a1172;
}

.BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    cursor: pointer;
}

.BrainhubCarousel__dots
    .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
    opacity: 1 !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover {
    opacity: 1;
}

.BrainhubCarousel__thumbnail[type='button'] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: #7b59ff;
}

.BrainhubCarousel__arrows span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #fff;
    border-width: 3px 3px 0 0;
    padding: 5px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 0;
}

.BrainhubCarousel__arrows:hover {
    background-color: #8768ff;
}

.BrainhubCarousel__arrows:hover span {
    border-color: #fff;
    margin: 0;
}

.BrainhubCarousel__arrowLeft span {
    -webkit-transform: translate(-50%, -50%) rotate(-135deg);
            transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 2.45px;
}

.BrainhubCarousel__arrowRight span {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px;
}

.BrainhubCarouselItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}

.BrainhubCarouselItem.BrainhubCarouselItem--clickable {
    cursor: pointer;
}

.BrainhubCarouselItem .debug-number {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 2em;
    text-shadow: 0px 0px 9px white;
}

/*------------------------------------*\
    # plugins.toastify
\*------------------------------------*/

.Toastify {
}

.Toastify__toast {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 6px !important;
    padding: 8px 16px !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 4px;
    min-height: unset !important;
}

.Toastify__toast--success {
    background: #53cb83;
}

.Toastify__toast--warning {
    background: #fec45c;
}

.Toastify__toast--error {
    background: #d84a4a;
}

.Toastify__close-button {
    -ms-flex-item-align: center !important;
        align-self: center !important;
}

/**
* Import: shame
* Description: CSS shame file
* Note: to be avoided, exists only if REALLY necessary or legacy code
*/

/* @import shame.css"; */

