/*------------------------------------*\
    # component.slider
\*------------------------------------*/

.slider {
    position: relative;
    width: calc($unit * 12);
    border-radius: $global-rounded;
    background: $color-primary;
    height: calc($unit * 5);
    transition: all 0.1s ease-out;
    border: 0;
    cursor: pointer;

    &:focus,
    &:hover {
        background: color($color-primary a(0.5));
        outline: 0;
    }
}

.slider__status {
    width: calc($unit * 3);
    height: calc($unit * 3);
    background: white;
    border-radius: $global-rounded;
    position: absolute;
    top: 4px;
    left: 4px;

    transition: all 0.1s ease-out;
}

.slider--active {
    background: $color-secondary;

    &:focus,
    &:hover {
        background: color($color-secondary a(0.5));
        outline: 0;
    }

    .slider__status {
        left: 32px;
        background-color: white;
    }
}
