/*------------------------------------*\
    # component.slate
\*------------------------------------*/

.slate {
    position: relative;
}

.slate__editor {
    padding-top: calc($unit * 14);
    display: block !important;
    overflow-wrap: anywhere !important;
    height: 300px;
    resize: vertical;
    overflow-y: scroll;
}

.slate__editor--error {
    border: 1px solid $color-warning;
}

.slate__editor ul,
.slate__editor ol {
    list-style-position: inside;
}

.slate__editor ul {
    list-style-type: disc;
}

.slate__toolbar {
    position: absolute;
    top: 6px;
    left: 6px;
    display: inline-flex;
    justify-content: flex-start;
    padding: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: $global-radial;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05);
}

.slate__toolbar__icon {
    background: transparent;
    padding: 8px 12px;
    margin: 2px;
    opacity: 0.6;

    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 0.1);
    }
}

.slate__toolbar__icon--active {
    background: color($color-primary a(0.15));
    border-radius: $global-radial;

    svg {
        fill: $color-primary;
    }
}
