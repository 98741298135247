/*------------------------------------*\
    # component.recently-sold
\*------------------------------------*/

.recently-sold {
    padding-top: calc($unit * 8);
}

.recently-sold__list {
    position: relative;
}

.recently-sold__item {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.05);
    border-radius: $global-radial;
    background: white;
    padding: calc($unit * 5);
    max-width: 400px;
    width: 100%;
    margin: 0 calc($unit * 6);
    margin-top: calc($unit * 2);
    margin-bottom: calc($unit * 12);

    @media (--screen-to-sml) {
        flex-direction: column;
        text-align: center;
    }
}

.recently-sold--alt {
    background: white;

    .recently-sold__item {
        border: 1px solid rgba(0, 0, 0, 0.05);
        background: $color-bg;
    }
}

.recently-sold__price {
    @media (--screen-to-sml) {
        margin-top: calc($unit * 6);
    }
}

.recently-sold__loading {
    padding-top: 10px;
    padding-bottom: 70px;
}
