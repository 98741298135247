/*------------------------------------*\
    # component.lifetime-upsell
\*------------------------------------*/

.lifetime-upsell {
    margin-top: calc($unit * 10);
}

.lifetime-upsell__card {
    max-width: 500px;
}
