/*------------------------------------*\
    # view.home-info
\*------------------------------------*/

.home-info {
    background-color: white;
    padding: 60px 0px;

    @media (--screen-to-med) {
        padding: 20px 0px;
        padding-top: 40px;
    }
}

.home-info--alt {
    background: transparent;
    color: white;

    .home-info__grid {
        background: $color-primary;
        border-radius: 16px;
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.06),
            0 42px 64px rgba(0, 0, 0, 0.04);

        padding: calc($unit * 20);

        z-index: 0;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 500px;
            background-repeat: repeat;
            background-image: url(../assets/images/pattern-2.svg);
            background-size: 20%;
            background-position: right top;
            background-color: color(white a(0.75));
            opacity: 0.25;
        }
    }

    .home-info__amount {
        color: white;
    }

    .home-info__title:before {
        background: white;
    }
}

.home-info__grid {
    @media (--screen-to-med) {
        text-align: center;
    }

    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.home-info__item {
    width: 100%;

    @media (--screen-to-med) {
        margin-bottom: calc($unit * 8);
    }

    @media (--screen-from-med) {
        lost-column: 1/3;
    }
}

.home-info__amount {
    color: $color-text-dark;
}

.home-info__title {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: -32px;
        width: 25px;
        height: 1px;
        background: rgba(0, 0, 0, 0.4);

        @media (--screen-to-med) {
            display: none;
        }
    }
}
