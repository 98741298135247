/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: calc($unit * 4);
    color: $color-text;
}

.field__lbl {
    @mixin type-scale $type-small, 5;
    margin-bottom: calc($unit * 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-text-dark;

    & + .field__sublbl {
        margin-top: calc($unit * -1);
    }
}

.field__sublbl {
    @mixin type-scale $type-tiny, 5;
    color: color($color-text a(0.7));
    margin-bottom: calc($unit * 1);
}

.field__lbl--error {
    color: $color-warning;
}

.field__error {
    display: block;
    margin-top: calc($unit * 2);

    @mixin type-scale $type-tiny, 4;
    color: $color-warning;
}

.field__divider {
    padding: 0 calc($unit * 1);
}

.field--alt {
    .field__lbl {
        color: color(white a(0.8));
        margin-bottom: calc($unit * 2);
    }
    .field__sublbl {
        color: color(white a(0.4));
    }

    .control-group {
        .input__prefix {
            left: calc($unit * 5);
            color: white;
        }

        .input--with-prefix {
            padding-left: calc($unit * 10) !important;
        }
    }
}
