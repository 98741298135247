/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.w {
    &-full {
        width: 100%;
    }

    &-half {
        width: 50%;
    }
}
