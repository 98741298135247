/*------------------------------------*\
    # view.home-stats
\*------------------------------------*/

.v-home-stats {
    margin-top: calc($unit * 10);

    @media (--screen-from-sml) {
        lost-flex-container: row;
        margin-top: calc($unit * 20);
    }
}

.v-home-stats__item {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-start;

    padding-left: calc($unit * 10);
    margin-bottom: calc($unit * 4);

    color: white;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 0;

        width: 30px;
        height: 1px;
        background: $color-secondary;
    }

    @media (--screen-from-sml) {
        lost-column: 1/3;

        margin-bottom: 0;
    }

    @media (--screen-from-lrg) {
        lost-column: 1/5;
    }
}

.v-home-stats__item__title {
    margin-bottom: calc($unit * 4);
    color: $color-secondary;
}

.v-home-stats__item__stats {
    color: white;
}
