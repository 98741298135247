/*------------------------------------*\
    # block.sectionhead
\*------------------------------------*/

.sectionhead {
    padding: calc($unit * 6) 0 calc($unit * 4) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 9) 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.sectionhead__title {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 4);
    }

    @media (--screen-from-lrg) {
        padding-right: lost-vars('gutter');
    }
}

.sectionhead__header {
    @media (--screen-from-med) {
        display: flex;
        justify-content: space-between;
    }
    max-width: 1356px;
    padding: 0;
    margin: 0 auto;

    @media (--screen-from-med) {
        padding: 0 calc($unit * 12);
    }
}

.sectionhead__actions__buttons {
    @media (--screen-from-med) {
        flex-wrap: nowrap;
    }
}

.sectionhead-fixed {
    padding: calc($unit * 4);
    z-index: 10;
    background: white;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 20px 20px rgba(0, 0, 0, 0.04);
    transition: transform 0.3s ease;
    top: 0;
    transform: translate3d(0, -110%, 0);

    .sectionhead__title {
        @media (--screen-to-lrg) {
            flex: 1 1 250px;

            h1 {
                margin-bottom: calc;
            }
        }
        @media (--screen-to-sml) {
            h1 {
                margin-bottom: calc($unit * 2);
            }
        }

        @media (--screen-from-lrg) {
            display: flex;
            align-items: center;
        }
    }

    .sectionhead__actions__buttons {
        @media (--screen-to-med) {
            display: flex;
            justify-content: space-between;
        }
    }

    .sectionhead__wrapper {
        @media (--screen-to-lrg) {
            padding-bottom: calc($unit * 2);
        }

        @media (--screen-from-lrg) {
            margin: 0 calc($unit * 4);
        }
    }

    .v-listingdetails__meta__url {
        @media (max-width: 1100px) {
            display: none;
        }
    }

    .sectionhead__actions {
        @media (--screen-from-lrg) {
            padding-right: calc($unit * 2);
        }

        @media (--screen-from-sml) and (--screen-to-lrg) {
            padding-right: calc($unit * 14);
        }
    }

    .v-listingdetails__meta {
        white-space: nowrap;

        @media (--screen-to-xlrg) {
            display: none;
        }
    }

    .sectionhead__title,
    .btn {
        margin-bottom: 0;
        width: auto;
    }

    .btn--secondary {
        @media (--screen-to-sml) {
            position: absolute;
            right: 72px;
            top: calc($unit * 3);
        }
    }

    .v-listingdetails__title {
        display: none;
    }

    .v-listingdetails__title--current {
        display: block;
        background: none;
        color: $color-text-dark;
    }

    .badge--list {
        margin-bottom: 0;
        padding: 0 calc($unit * 4);
    }
}

.sectionhead-fixed--active {
    transform: translate3d(0, 0, 0);
}

.sectionhead__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sectionhead__wrapper {
    margin: calc($unit * 3) 0;
}
