/*------------------------------------*\
    # component.radio-group
\*------------------------------------*/

.radio-group {
    display: flex;
}

.radio-group > .field {
    margin-right: calc($unit * 4);
}
