/*------------------------------------*\
    # view.shared-report
\*------------------------------------*/

.v-shared-report {
    background-color: color($color-primary);
    padding: calc($unit * 45) 0 calc($unit * 20) 0;

    position: relative;
    z-index: 0;

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/pattern-1.svg);
        opacity: 0.3;
        background-repeat: repeat-x;
        background-size: 30%;
    }
}

.v-shared-report__card-section {
    margin-top: 100px;
}

.v-shared-report__card {
    position: relative;
    lost-column: 3/14 0 0;
    color: color($color-primary);
    background: linear-gradient(180deg, #ffcf78 0%, #fec45c 100%);
    padding: calc($unit * 10) calc($unit * 5) calc($unit * 5) calc($unit * 10);
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1);

    &:last-child {
        top: 25px;
        right: 18px;
        transform: rotate(4deg);
        z-index: 0;
        box-shadow: none;
    }

    &:first-child {
        top: 25px;
        left: 18px;
        transform: rotate(-4deg);
        z-index: 0;
        box-shadow: none;
    }
}

.v-shared-report__card-description {
    margin-top: 100px;
}

.v-shared-report__footer {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: calc($unit * 5) 0 0 0;
}

.v-shared-report__footer__offer {
    lost-column: 1/3;
}

.v-shared-report__criteria {
    lost-column: 1/2;
}

.v-shared-report__questions {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 0 0 calc($unit * 5);
}
