/*------------------------------------*\
    # view.mypurchases
\*------------------------------------*/

.v-mypurchases {
}

.v-mypurchases__item {
    margin-bottom: calc($unit * 10);
    border-radius: 20px;
    background: white;

    @media (--screen-to-med) {
        padding: calc($unit * 5);
    }

    @media (--screen-from-med) {
        padding: calc($unit * 16) 0;
        lost-flex-container: row;
    }
}

.v-mypurchases__item__header {
    @media (--screen-to-med) {
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-from-med) {
        lost-column: 4/12;
        lost-offset: 1/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 5/12;
        lost-offset: 1/12;
    }
}

.v-mypurchases__item__details {
    @media (--screen-from-med) {
        lost-column: 5/12;
        lost-offset: 1/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12;
        lost-offset: 1/12;
    }
}

.v-mypurchases__item__visual {
    margin-top: -40px;

    @media (--screen-from-med) {
        margin-top: -88px;
    }

    max-width: 300px;
}

.v-mypurchases__item__visual__image {
    height: 220px;
    margin-bottom: calc($unit * 5);
    border-radius: 20px;
    background: $color-secondary;
    background-size: cover;

    @media (--screen-from-xlrg) {
        height: 300px;
    }
}

.v-mypurchases__item__steps {
    list-style: none;
}

.v-mypurchases__step {
    position: relative;
    display: flex;
    padding-bottom: calc($unit * 3);

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 22px;
        width: 1px;
        height: 100%;
        background: color($color-dark a(0.1));
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 16px;
        left: 20px;

        height: 11px;
        width: 5px;
        transform: rotate(45deg);

        border-bottom: 1px solid $color-success;
        border-right: 1px solid $color-success;
    }

    &.is-active {
        &:after {
            display: none;
        }

        .v-mypurchases__step__text {
            display: block;
        }
    }

    &.is-active ~ .v-mypurchases__step {
        &:after {
            display: none;
        }

        .v-mypurchases__step__counter {
            box-shadow: 0 0 0 1px color($color-dark a(0.3)) inset;
            color: color($color-text a(0.6));
        }
    }

    &:last-child {
        &:before {
            display: none;
        }
    }
}

.v-mypurchases__step__counter {
    flex-shrink: 0;
    margin-right: calc($unit * 4);

    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 4px solid white;
    box-shadow: 0 0 0 1px $color-success inset;
    background: white;
    border-radius: 50%;

    font-size: 14px;
    color: transparent;

    .is-active & {
        box-shadow: 0 0 0 1px $color-primary inset;
        color: $color-primary;
    }
}

.v-mypurchases__step__desc {
    padding-top: calc($unit * 2);
}

.v-mypurchases__step__title {
    opacity: 0.8;

    .is-active & {
        opacity: 1;
        color: $color-dark;
    }
}

.v-mypurchases__step__text {
    display: none;
}

.v-mypurchases__empty-state {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 420px;
    margin: 0 auto;
    padding-bottom: calc($unit * 40);
}

.v-mypurchases__smallitem {
    margin-bottom: calc($unit * 10);
    border-radius: 20px;
    background: white;

    @media (--screen-to-med) {
        display: flex;
        flex-direction: column;

        margin-top: calc($unit * -5);
        padding: calc($unit * 5);
    }

    @media (--screen-from-med) {
        height: 152px;
        padding: calc($unit * 12) 0 calc($unit * 12) 0;
        lost-flex-container: row;
    }
}

.v-mypurchases__smallitem__visual {
    @media (--screen-to-med) {
        display: none;
    }

    @media (--screen-from-med) {
        display: block;
        lost-column: 3/12;
        lost-offset: 1/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 2/12;
        lost-offset: 1/12;
    }
}

.v-mypurchases__smallitem__visual__image {
    width: 140px;
    height: 140px;
    border-radius: 20px;
    background: $color-secondary;
    background-size: cover;

    @media (--screen-to-med) {
        margin-top: -36px;
    }

    @media (--screen-from-med) {
        margin-top: -64px;
    }
}

.v-mypurchases__smallitem__status {
    @media (--screen-to-med) {
        order: 2;
    }

    @media (--screen-from-med) {
        lost-column: 4/12;
    }
}

.v-mypurchases__smallitem__status__content {
    padding-top: calc($unit * 1);
}

.v-mypurchases__smallitem__check {
    position: relative;
    display: block;
    float: left;
    margin-right: calc($unit * 4);

    width: 44px;
    height: 44px;
    box-shadow: 0 0 0 1px $color-success inset;
    background: white;
    border-radius: 50%;

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 16px;
        left: 20px;

        height: 11px;
        width: 5px;
        transform: rotate(45deg);

        border-bottom: 1px solid $color-success;
        border-right: 1px solid $color-success;
    }
}

.v-mypurchases__smallitem__content {
    @media (--screen-to-med) {
        margin-bottom: calc($unit * 5);
        order: 1;
    }

    @media (--screen-from-med) {
        lost-column: 3/12;
    }
}

.v-mypurchases__alldone {
    margin-bottom: calc($unit * 20);

    @media (--screen-from-med) {
        lost-column: 6/12;
    }
}

.v-mypurchases__reports-grid {
    width: 100%;
    list-style-type: none;

    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}
