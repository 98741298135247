/*------------------------------------*\
    # component.btn-group
\*------------------------------------*/

.btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
}

.btn-group > .btn {
    @media (--screen-to-sml) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.btn-group--invertOnSmall {
    @media (--screen-to-sml) {
        flex-direction: column-reverse;
    }
}
