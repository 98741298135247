/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u {
    &-display {
        &__block {
            display: block;
        }

        &__inline {
            display: inline;
        }
    }
}
