/*------------------------------------*\
    # component.listing-grid-item
\*------------------------------------*/

.listing-grid-item {
    margin-bottom: lost-vars('gutter');

    @media (--screen-from-med) {
        lost-column: 6/12 2;
    }

    @media (--screen-from-lrg) {
        lost-column: 4/12 3;
    }

    @media (--screen-from-xlrg) {
        lost-column: 3/12 4;
    }
}

.listing-grid-item--sold {
    position: relative;
    pointer-events: none;

    &:after {
        content: 'SOLD!';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-10deg);

        @mixin type-scale $type-small, 5;
        color: white;
        background: $color-warning;
        font-weight: $type-weight-bold;
        padding: calc($unit * 2) calc($unit * 4);
        border-radius: $global-radial;
    }

    .v-listings__grid__link__body {
        filter: grayscale(100%);
    }
}

.v-listings__grid__link__heading {
    margin-bottom: calc($unit * 2);
}

.v-listings__grid__link__body {
    position: relative;
    height: 300px;
    display: flex;
    align-items: flex-end;

    background: $color-secondary;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;

    transition: all 0.2s ease-out;

    &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(color(black a(0)), color(black a(0.4)));
        border-radius: 20px;
    }

    &:hover,
    &:focus {
        transform: translateY(-4px);
        box-shadow: 0 20px 48px color(black a(0.15));

        .v-listings__grid__link__meta__arrow {
            transform: translateX(-6px);
        }
    }

    &:active {
        transform: translateY(0px);
        box-shadow: 0 10px 48px color(black a(0.15));
    }
}

.v-listings__grid__link__meta {
    position: relative;
    z-index: 1;
    flex: 1;
    margin-left: calc($unit * 7);
    padding: calc($unit * 3) calc($unit * 4) calc($unit * 4) 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    border-top: 1px dashed color(white a(0.5));
    color: white;
}

.v-listings__grid__link__meta__monetization {
    flex: 1 0 100%;
    margin-top: calc($unit * 1);
}

.v-listings__grid__link__meta__title svg {
    margin-right: calc($unit * 2);
}

.v-listings__grid__link__meta__title svg path {
    fill: white;
}

.v-listings__grid__link__meta__arrow {
    transition: all 0.2s ease-out;
}

.v-listings__grid__link__meta__arrow svg path {
    stroke: white;
}

.v-listings__grid__offer-status {
    width: 100%;
    margin: calc($unit * 1) 0;
    display: flex;
    align-items: center;
    opacity: 0.7;

    svg {
        background-color: transparent;
        animation: pulse 2s infinite ease-out;
        border-radius: $global-rounded;
        margin-right: calc($unit * 1);

        path {
            fill: white;
        }
    }
}

.listing-grid-item--skeleton {
    .v-listings__grid__link__heading {
        position: relative;
        background: #eee;
        overflow: hidden;
        height: 30px;
        border-radius: $global-rounded;
        margin-bottom: 20px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background: linear-gradient(
                90deg,
                transparent 0%,
                #e8e8e8 50%,
                transparent 100%
            );
        }
    }
    .v-listings__grid__link__body {
        background: #eee;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background: linear-gradient(
                90deg,
                transparent 0%,
                #e8e8e8 50%,
                transparent 100%
            );
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 -10px rgba(255, 255, 255, 1);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
}
