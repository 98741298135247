/*------------------------------------*\
    # component.payment-card
\*------------------------------------*/

.payment-card {
    padding: calc($unit * 3) calc($unit * 5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background: white;
    outline: 0;

    &.is-active {
        border-color: $color-primary;
    }
}
