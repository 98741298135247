/*------------------------------------*\
    # view.home-pricing
\*------------------------------------*/

.v-home-pricing {
    padding: calc($unit * 15) 0;

    background-image: url(../assets/images/pattern-1.svg);
    background-color: $color-primary;
    background-blend-mode: overlay;
    background-repeat: repeat-x;
    background-position: left 600px;
    color: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-home-pricing__heading {
    margin-bottom: calc($unit * 10);
}

.v-home-pricing__content {
    @media (--screen-from-xxlrg) {
        display: flex;
        max-width: 1120px;
        margin: 0 auto;
    }
}

.v-home-pricing__box {
    position: relative;
    border-radius: 6px;
    padding: calc($unit * 10);
    color: $color-text;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (--screen-from-xxlrg) {
        lost-column: 1/2 flex;
    }

    .cta-button {
        align-self: center;
        width: fit-content;
    }
}

.v-home-pricing__box--vip {
    background-color: rgba(255, 255, 255);
}

.v-home-pricing__box--limited {
    border: 1px solid color($color-secondary a(0.5));
    margin-bottom: 30px;

    @media (--screen-from-med) {
        margin-bottom: 0;
    }
}

.v-home-pricing__additional {
    max-width: 1120px;
    margin: 0 auto;
    margin-top: calc($unit * 10);
}

.v-home-pricing__additional-content {
    lost-column: 1/2 flex;
}

.v-home-pricing__description {
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 80px;
}

.v-home-pricing__features {
    margin-top: calc($unit * 5);
    margin-bottom: calc($unit * 10);

    li {
        margin: calc($unit * 3) 0;
    }
}

.v-home-pricing__footer {
    margin-top: auto;
}

.v-home-pricing__star {
    position: absolute;
    right: -20px;
    top: -20px;
}

.v-home-pricing__offer {
    align-self: flex-start;
    background: $color-warning;
    color: white;
    border-radius: $global-radial;
    padding: 10px 20px;
}
