/*------------------------------------*\
    # view.home-sellers
\*------------------------------------*/

.home-sellers {
    padding: 40px 0px;

    @media (--screen-from-med) {
        padding: 120px 0px;
    }
}

.home-sellers__features {
    lost-flex-container: row;
}

.home-sellers__feature {
    margin-top: calc($unit * 14);
    position: relative;
    background-color: white;
    border-radius: 16px;
    padding: calc($unit * 14) calc($unit * 6) calc($unit * 15) calc($unit * 6);
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);
    width: 100%;

    @media (--screen-from-lrg) {
        margin-top: calc($unit * 24);
        lost-column: 1/3;
    }
}

.home-sellers__icon {
    position: absolute;
    top: -42px;
    left: 36px;
}

.home-sellers__footer {
    margin-top: calc($unit * 15);

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.home-sellers__cta {
    @media (--screen-from-lrg) {
        lost-column: 4/12 2 60px;
    }
}

.home-sellers__intro {
    max-width: 510px;
}

.home-sellers__footer__testimonials {
    position: relative;

    @media (--screen-from-lrg) {
        lost-column: 8/12 2 60px;
    }
}
.home-sellers__footer__testimonials__photo {
    width: 48px;
    height: 48px;
    border-radius: $global-rounded;
    margin-right: calc($unit * 5);

    @media (--screen-from-lrg) {
        width: 70px;
        height: 70px;
    }
}

.home-sellers__footer__testimonials__item {
    display: flex;
    justify-content: flex-start;
}
