/*------------------------------------*\
    # component.v-offer-details
\*------------------------------------*/

.v-offer-details  {
}

.v-offer-details__asking-price {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: calc($unit * 4);
}

.v-offer-details__user {
    color: $color-primary;
}

.v-offer-details__offers {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 6px;
        left: 3px;
        background: rgba(0, 0, 0, 0.1);
        width: 2px;
        height: calc(100% - 45px);
    }
}

.v-offer-details__offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: calc($unit * 9);
    padding-left: calc($unit * 5);

    &:last-child {
        .v-offer-details__user {
            font-weight: $type-weight-bold;

            &:after {
                background: $color-primary;
            }
        }

        .v-offer-details__amount {
            font-weight: $type-weight-bold;
            color: $color-primary;

            @mixin type-scale $type-medium, 6;
        }
    }

    &.is-accepted {
        .v-offer-details__user {
            color: $color-success;
        }

        .v-offer-details__amount {
            color: $color-success;
        }
    }

    &.is-declined {
        .v-offer-details__user {
            color: $color-warning;
        }
        .v-offer-details__amount {
            color: $color-warning;
        }
    }
}

.v-offer-details__user {
    position: relative;

    &:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 6px;
        left: -20px;
        border-radius: $global-rounded;
        width: 8px;
        height: 8px;
        background: #786f82;
    }
}

.v-offer-details__actions {
    @media (--screen-from-med) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    margin: calc($unit * 8) 0;
}
