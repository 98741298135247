/*------------------------------------*\
    # block.splitview
\*------------------------------------*/

.splitview {
    margin-bottom: calc($unit * 15); /* Push footer */

    @media (--screen-from-lrg) {
        lost-flex-container: row;
        align-items: flex-start;
    }
}

.splitview__sidebar {
    position: relative;

    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 6);
    }

    @media (--screen-from-lrg) {
        display: flex;
        padding: calc($unit * 4) calc($unit * 8) calc($unit * 8) calc($unit * 8);

        flex-direction: column;
        justify-content: space-between;

        lost-column: 3/12;
        position: sticky;
        top: 30px;
        left: 0;

        background: white;
        border-radius: $global-radial;
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02),
            0 42px 64px rgba(0, 0, 0, 0.04);
    }
}

.splitview__content {
    @media (--screen-from-lrg) {
        lost-column: 9/12;
        padding: calc($unit * 8);

        background: white;
        border-radius: $global-radial;
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02),
            0 42px 64px rgba(0, 0, 0, 0.04);
    }

    &--alt {
        background: transparent;
        box-shadow: none;
        padding: 0;
    }
}

.splitview__content__heading {
    margin-bottom: calc($unit * 10);

    display: flex;
    justify-content: space-between;
}

.splitview__content__heading__title {
    margin-right: auto;

    @media (--screen-to-med) {
        display: none;
    }
}

/* Horizontal nav on small screens */
.splitview__sidebar__list {
    @media (--screen-to-med) {
        margin-right: calc($unit * -6);
    }

    @media (--screen-to-lrg) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-bottom: calc($unit * 4);
    }
}

.splitview__sidebar__item {
    position: relative;
    @media (--screen-to-lrg) {
        flex: 0 0 auto;
    }
}

.splitview__sidebar__item--status {
    display: inline-flex;
    margin-left: 12px;
}

.splitview__sidebar__link {
    display: block;
    padding: calc($unit * 4) 0;
    opacity: 0.7;
    color: $color-text;
    transition: all 0.2s ease-out;

    @mixin type-scale $type-small, 4;

    &.active {
        font-weight: $type-weight-bold;
        opacity: 1;
        color: $color-primary;
        border-color: $color-primary;
    }

    &:hover,
    &:focus {
        opacity: 1;
        color: $color-primary;
    }

    @media (--screen-to-lrg) {
        border-bottom: 2px solid transparent;
        margin-right: calc($unit * 4);
    }

    @media (--screen-from-lrg) {
        margin-right: calc($unit * -8);
        border-right: 2px solid transparent;
    }
}

/* Holds the "Logout" button */
.splitview__sidebar__bottom {
    @media (--screen-to-lrg) {
        position: absolute;
        top: -30px;
        right: 0;
    }

    @media (--screen-from-lrg) {
        margin-top: calc($unit * 6);
        margin-left: calc($unit * -8);
        margin-right: calc($unit * -8);
        padding-top: calc($unit * 6);
        padding-left: calc($unit * 8);
        border-top: 1px solid color($color-dark a(0.1));
    }
}
