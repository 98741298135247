/*------------------------------------*\
    # view.home-cta
\*------------------------------------*/

.home-cta {
    padding: calc($unit * 40) 0;

    background-color: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    background-repeat: repeat-x;
    background-size: 50%;
}

.home-cta__wrapper {
    @media (--screen-from-med) {
        display: flex;
        justify-content: center;
    }
}

.home-cta__wrapper .btn {
    margin: calc($unit * 2);
    margin-top: calc($unit * 8);
}

.home-cta__input-wrapper {
    @media (--screen-from-med) {
        display: flex;
        justify-content: center;
    }
}
