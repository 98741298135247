/*------------------------------------*\
    # component.portfolio-details
\*------------------------------------*/

.portfolio-details {
}

.portfolio-details__item {
    & + & {
        margin-top: calc($unit * 8);
    }
}

.portfolio-details__row {
    margin-top: calc($unit * 2);
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
}
