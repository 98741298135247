/*------------------------------------*\
    # view.listings
\*------------------------------------*/

.v-listings {
    position: relative;
    z-index: 0;
    overflow: hidden;

    &:before {
        content: '';
        z-index: -2;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(../assets/images/pattern-2.svg);
        background-size: 20%;
        background-position: right top;
        opacity: 0.25;
        transform: scale(-1);
    }

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: linear-gradient(
            180deg,
            color($color-bg a(0)) 50%,
            $color-bg 100%
        );
        opacity: 1;
    }
}

.v-listings__header {
    padding: calc($unit * 6) 0 calc($unit * 4) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--screen-to-lrg) {
        flex-flow: row wrap;
    }

    @media (--screen-from-lrg) {
        padding: calc($unit * 9) 0;
    }
}

.v-listings__header__title {
    margin-right: calc($unit * 6);
}

.v-listings__view {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (--screen-from-med) {
        padding-right: calc($unit * 5);
    }
}

.v-listings__item {
    padding-left: calc($unit * 5);
    padding-right: calc($unit * 5);
    width: 100%;
}

.v-listings__view-icon {
    fill: $color-primary;
    opacity: 0.5;

    &:hover,
    &:focus {
        opacity: 0.6;
    }
}

.v-listings__view-icon--selected {
    opacity: 1;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.v-listings__filters {
    @media (--screen-to-sml) {
        width: 100%;
    }

    @media (--screen-from-sml) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.v-listings__filters__operator {
    width: 24px;
    height: 24px;
    filter: grayscale(100);
    transition: all 0.2s ease-out;
    margin-left: calc($unit * 4);

    &.active {
        filter: grayscale(0);
    }
}

.v-listings__filter {
    @media (--screen-to-sml) {
        margin-top: calc($unit * 2);
        width: 100%;
    }

    @media (--screen-from-sml) {
        width: 150px;
        margin-right: calc($unit * 2);
    }
}

.v-listings__filter--wide {
    @media (--screen-from-sml) {
        width: 200px;
    }
}

.v-listings__filter__sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.v-listings__list {
    width: 100%;
    margin-top: calc($unit * 12);

    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.v-listings__grid {
    width: 100%;

    @media (--screen-from-med) {
        lost-flex-container: row;
    }

    padding: calc($unit * 10) 0;
}

.v-listings__notice {
    padding: calc($unit * 5) calc($unit * 5);

    background-image: url(../assets/images/pattern-1-negative.svg);
    background-color: white;
    background-size: 25%;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05);
    border-radius: $global-radial;

    @media (--screen-from-sml) {
        padding: calc($unit * 5) calc($unit * 10);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.v-listings__notice__cta {
    @media (--screen-to-sml) {
        margin-top: 18px;
    }
}

/** Keep these nested, required for proper functionality. */
.v-listings__placeholder {
    position: relative;

    .v-listings__placeholder__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .v-listings__placeholder__graph {
        filter: blur(8px);
    }
}
