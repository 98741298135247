/*------------------------------------*\
    # component.analytics-table
\*------------------------------------*/

.analytics-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    td {
        @mixin type-scale $type-tiny, 4;
        min-width: 100px;
    }

    th,
    td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba();
        @mixin type-scale $type-tiny, 4;
        padding: calc($unit * 3);
        width: 33%;
    }

    th {
        @mixin type-scale $type-tiny, 4;
        font-weight: $type-weight-regular;
        text-align: left;
    }

    tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        transition: all 0.1s ease-out;

        &:hover {
            td {
                color: $color-primary;
            }
        }
    }
}
