/*------------------------------------*\
    # view.home-redacted
\*------------------------------------*/

.v-home-redacted {
    padding: calc($unit * 12) 0;
    padding-bottom: 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 30) 0;
        padding-bottom: 0;
    }
}

.v-home-redacted--alt {
    background: white;
}

.v-home-redacted__header {
    lost-flex-container: row;
}

.v-home-redacted__title {
    @media (--screen-to-lrg) {
        padding-bottom: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 1/2 flex;
    }
}

.v-home-redacted__description {
    @media (--screen-from-lrg) {
        lost-column: 1/2 flex;
    }
}

.v-home-redacted__list {
    margin-top: calc($unit * 25);

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 10);
    }

    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.v-home-redacted__list__cta {
    margin-top: calc($unit * 6);
    padding-bottom: calc($unit * 12);
    display: flex;
    justify-content: center;
}
