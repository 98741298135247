/*------------------------------------*\
    # component.offer-list-item
\*------------------------------------*/

.offer-list-item {
    background: white;
    border-radius: $global-radial;
    padding: calc($unit * 6) calc($unit * 15);
    display: flex;
    width: 100%;
    min-width: 100%;

    & + & {
        margin-top: calc($unit * 11);

        @media (--screen-from-sml) and (--screen-to-lrg) {
            margin-top: 0;
        }
    }

    @media (--screen-from-sml) and (--screen-to-lrg) {
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-to-xlrg) {
        padding: calc($unit * 6) calc($unit * 8);
    }

    @media (--screen-to-lrg) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (min-width: 650px) and (--screen-to-lrg) {
        lost-column: 1/2;
    }
}

.offer-list-item__image {
    min-width: 160px;
    width: 160px;
    height: 160px;
    margin-right: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: relative;
    margin-top: -30px;

    @media (--screen-to-xlrg) {
        width: 100px;
        height: 100px;
        min-width: 100px;
    }
}

.offer-list-item__from {
    overflow: hidden;
    text-overflow: ellipsis;
}

.offer-list-item__cta {
    margin-top: auto;

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 2);
    }
}

.offer-list-item__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 200px;

    @media (--screen-to-lrg) {
        margin: calc($unit * 3) 0;
    }
}

.offer-list-item__offers {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;

    @media (--screen-from-lrg) {
        margin-left: auto;
    }

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 4);
        width: 100%;
    }

    &:after {
        z-index: 1;
        content: '';
        position: absolute;
        width: 2px;
        background-color: rgba(0, 0, 0, 0.1);
        height: calc(50% - 5px);
        top: calc(50% - 5px);
        left: 3px;
    }
}

.offer-list-item--accepted {
    .offer-list-item__offers__latest {
        color: $color-success;

        &:after {
            background-color: $color-success;
        }
    }
}

.offer-list-item--pending {
    .offer-list-item__offers__latest {
        color: $color-note;

        &:after {
            background-color: $color-note;
        }
    }
}

.offer-list-item--declined {
    .offer-list-item__offers__latest {
        color: $color-warning;

        &:after {
            background-color: $color-warning;
        }
    }
}

.offer-list-item__offers__initial {
    position: relative;
    padding-left: calc($unit * 6);

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 3);
    }

    &:after {
        z-index: 2;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        background: #786f82;
        border-radius: $global-rounded;
    }
}

.offer-list-item__offers__latest {
    position: relative;
    padding-left: calc($unit * 6);

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 3);
    }

    &:after {
        z-index: 2;
        content: '';
        position: absolute;
        top: 0;
        left: -2px;
        width: 12px;
        height: 12px;
        border-radius: $global-rounded;
    }
}

.offer-list-item__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 200px;
}
