/*------------------------------------*\
    # view.contentpage
\*------------------------------------*/

.page {
    position: relative;
    z-index: 0;
    overflow: hidden;

    &:before {
        content: '';
        z-index: -2;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(../assets/images/pattern-2.svg);
        background-size: 20%;
        background-position: right top;
        opacity: 0.25;
    }

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: linear-gradient(
            180deg,
            color($color-bg a(0)) 50%,
            $color-bg 100%
        );
        opacity: 1;
    }
}

.page__header {
    margin: calc($unit * 10) 0 calc($unit * 4) 0;
}
