/*------------------------------------*\
    # component.expandable
\*------------------------------------*/

.expandable {
}

.expandable--open {
    svg {
        transform: rotate(180deg);
    }
}

.expandable__button {
    margin-left: calc($unit * -5);
    margin-top: calc($unit * 4);
    display: block;
    color: $color-primary;
}
