/*------------------------------------*\
    # component.newsletter
\*------------------------------------*/

.newsletter-cta {
    padding: calc($unit * 40) 0;

    background-blend-mode: overlay;
    background-color: #c2aee0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../assets/graphics/graphic-keyvisual.svg);

    .input {
        &:focus,
        &:hover {
            background: white;
        }
    }
}
