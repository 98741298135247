/*------------------------------------*\
    # block.cta
\*------------------------------------*/

.cta {
    padding: calc($unit * 10);

    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    background-color: $color-primary;
    background-size: 25%;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05);
}

.cta .wrapper {
    @media (--screen-from-med) {
        padding: calc($unit * 25);
        lost-flex-container: row;
    }
}

.cta--small {
    background: white;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05);
    padding: calc($unit * 10);
    width: 100%;
    margin-bottom: calc($unit * 4);
    border-radius: 16px;

    @media (--screen-from-med) {
        align-items: center;
        lost-flex-container: row;
    }
}

.cta__heading {
    @media (--screen-to-med) {
        max-width: 420px;
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-from-med) {
        lost-column: 5/12;
    }
}

.cta__content {
    @media (--screen-to-med) {
        max-width: 420px;
    }

    @media (--screen-from-med) {
        lost-column: 4/12;
        lost-offset: 2/12;
    }
}
