/*------------------------------------*\
    # view.home-articles
\*------------------------------------*/

.home-articles {
    padding: calc($unit * 10) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.home-articles__title {
    margin-bottom: calc($unit * 7);

    @media (--screen-from-lrg) {
        margin-bottom: calc($unit * 15);
    }
}

.home-articles__list {
    lost-flex-container: row;
}

.home-articles__image {
    width: 100%;
}

.home-articles__link {
    width: 100%;
    background-color: white;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);
    transition: all 0.2s;
    border-radius: 16px;
    margin-bottom: calc($unit * 8);

    @media (--screen-from-med) {
        lost-column: 1/2;
    }

    @media (--screen-from-lrg) {
        lost-column: 1/3;
    }

    &:hover,
    &:focus {
        transform: translateY(-7px);
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.08),
            0 42px 64px rgba(0, 0, 0, 0.06);
    }
}

.home-articles__item {
    display: block;
}

.home-articles__content {
    padding: calc($unit * 9);
    padding-bottom: calc($unit * 12);
}
