/*------------------------------------*\
    # component.control-group
\*------------------------------------*/

.control-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.control-group > .btn,
.control-group > .field,
.control-group > .input,
.control-group > .field__divider,
.control-group > select {
    margin-right: calc($unit * 4);

    &:last-child {
        margin-right: 0;
    }
}

.control-group > .input__prefix {
    position: absolute;
    left: calc($unit * 3);
}
