/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/

@font-face {
    font-family: 'Rational Display';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/RationalDisplayBook.woff2') format('woff2'),
        url('../assets/fonts/RationalDisplayBook.woff') format('woff');
}
@font-face {
    font-family: 'Rational Display';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/RationalDisplayBookItalic.woff2') format('woff2'),
        url('../assets/fonts/RationalDisplayBookItalic.woff') format('woff');
}

@font-face {
    font-family: 'Rational Display';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/RationalDisplaySemiBold.woff2') format('woff2'),
        url('../assets/fonts/RationalDisplaySemiBold.woff') format('woff');
}
