/*------------------------------------*\
    # component.switchbutton
\*------------------------------------*/

.switchbutton {
    display: inline-flex;
    align-items: center;
}

.switchbutton__button {
    padding: calc($unit * 4) calc($unit * 5);
    border-radius: 3px;
    border: 1px solid color($color-primary a(0.2));
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05);

    @mixin type-scale $type-tiny, 4;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: $type-weight-bold;
    background: color(white a(0.5));
    color: color($color-text a(0.5));

    &.is-active {
        background: $color-primary;
        color: white;
        border: 1px solid color($color-primary a(0.2));

        &:hover,
        &:focus {
            background: color($color-primary a(0.8));
            color: white;
        }
    }

    &:hover,
    &:focus {
        color: $color-text;
        background: white;
    }
}

.switchbutton__badge {
    position: absolute;
    width: 28px;
    height: 28px;
    top: -15px;
    right: -15px;
}
