/*------------------------------------*\
    # view.page-form
\*------------------------------------*/

.page-form {
    padding-bottom: calc($unit * 40); /* Push footer */
}

.page-form__heading {
    z-index: 0;
    position: relative;
    padding: calc($unit * 40) 0 calc($unit * 20) 0;
    text-align: center;

    border-radius: 0 0 20px 20px;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;

    @media (--screen-from-lrg) {
        padding: calc($unit * 40) 0;
    }

    &__sell-page {
        padding: calc($unit * 20) 0 calc($unit * 40) 0;
    }
}

.page-form__steps {
    display: inline-flex;
    justify-content: space-between;
    margin: calc($unit * 15) auto 0 auto;
}

.page-form__steps__step {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    border-radius: 50%;
    color: $color-secondary;
    box-shadow: 0 0 0 1px $color-secondary inset;

    width: 20px;
    height: 20px;
    margin-right: calc($unit * 8);

    @media (--screen-from-sml) {
        width: 28px;
        height: 28px;
        margin-right: calc($unit * 10);
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
    }

    &:before {
        display: none;
        top: -4px;
        left: -4px;

        width: 28px;
        height: 28px;

        border-radius: 50%;
        box-shadow: 0 0 0 1px $color-secondary inset;

        @media (--screen-from-sml) {
            width: 36px;
            height: 36px;
        }
    }

    &:after {
        display: block;
        top: 50%;
        left: 28px;

        width: 16px;
        height: 1px;

        background: color(white a(0.2));

        @media (--screen-from-sml) {
            left: 36px;
            width: 24px;
        }
    }

    &:last-child {
        margin-right: 0;

        &:after {
            display: none;
        }
    }

    &.is-active {
        background: $color-secondary;
        color: $color-primary;
        box-shadow: 0 0 1px $color-secondary;

        &:before {
            display: block;
        }

        &:hover,
        &:focus {
            color: $color-primary;

            &:before {
                box-shadow: 0 0 0 1px color(white a(0.4));
            }
        }
    }

    &:hover,
    &:focus {
        color: $color-secondary;

        &:before {
            display: block;
            box-shadow: 0 0 0 1px color(white a(0.4));
        }
    }
}

.page-form__intro {
    margin: calc($unit * 15) auto 0 auto;
    max-width: 600px;
}

.form__banner {
    padding-top: 0;
    padding-bottom: 0;

    .home-info__grid {
        padding: calc($unit * 8);
    }
}

.page-form__formbox {
    position: relative;
    z-index: 1;
    margin-top: -32px;

    @media (--screen-from-lrg) {
        lost-flex-container: row;
        margin-top: -72px;
    }
}

.page-form__formsection {
    padding: calc($unit * 10);
    margin-bottom: calc($unit * 10);
    border-radius: 20px;
    background: white;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.04);

    @media (--screen-from-med) {
        padding: calc($unit * 15);
        lost-column: 10/12;
        lost-move: 1/12;
    }

    @media (--screen-from-lrg) {
        lost-column: 8/12;
        lost-move: 2/12;
    }
}

.page-form__formsection--small {
    @media (--screen-from-sml) {
        lost-column: 10/12;
        lost-move: 1/12;
    }

    @media (--screen-from-xlrg) {
        lost-column: 4/12;
        lost-move: 4/12;
    }
}

.page-form__buttons {
    z-index: 1;
    display: flex;
    justify-content: center;
}

.page-form__emphasized {
    padding: calc($unit * 5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
}

.page-form__type-select {
    text-align: center;
}

.page-form__type-button {
    flex-direction: column;
    padding: calc($unit * 5) calc($unit * 10);
    margin: 0 calc($unit * 5);
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(0px);
    transition: all 0.15s ease-out;

    &:hover,
    &:focus {
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.2);
        transform: translateY(-4px);
    }
}
