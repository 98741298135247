/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
    margin: 0 auto;

    /* Sticky footer */
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

/* Sticky footer push */
main {
    flex: 1;
}
