/*------------------------------------*\
    # component.checkout-modal
\*------------------------------------*/

.checkout-modal__price {
    display: inline;
}

.checkout-modal__sale {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.checkout-modal__black-friday {
    display: flex;
    align-items: flex-end;
    color: red;
    margin-bottom: calc($unit * 4);
    margin-left: calc($unit * 3);
}
