/*------------------------------------*\
    # view.home-desc
\*------------------------------------*/

.v-home-desc {
    padding: calc($unit * 15) 0;
    background: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-home-desc__wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.v-home-desc__title {
}
