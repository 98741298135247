/*------------------------------------*\
    # block.featuregrid
\*------------------------------------*/

.featuregrid {
    lost-flex-container: row;
}

.featuregrid__item {
    @media (--screen-to-lrg) {
        lost-column: 1/2;
        margin-bottom: calc($unit * 6);
    }

    @media (--screen-from-lrg) {
        lost-column: 2/6;
        margin-bottom: calc($unit * 6);

        &:nth-child(2n) {
            lost-move: 1/6;
        }
    }
}

.featuregrid__item__icon {
    margin-bottom: calc($unit * 5);
}
