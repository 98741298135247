/*------------------------------------*\
    # component.logo
\*------------------------------------*/

.logo {
    margin: calc($unit * 6) 0;

    path {
        fill: $color-primary;
    }

    @media (--screen-to-med) {
        display: none;
    }
}

.logo--logged-in {
    display: none;
}

.logo-mobile {
    margin: calc($unit * 6) 0;
    height: 20px;
    width: 20px;
    fill: $color-primary;

    @media (--screen-from-med) {
        display: none;
    }
}

.logo-mobile--logged-in {
    display: initial;
}
