/*------------------------------------*\
    # component.no-fee
\*------------------------------------*/

.no-fee {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    width: 100%;
    border-radius: $global-rounded;
    color: white;
    padding: calc($unit * 2) calc($unit * 10);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: calc($unit * 10);

    background: linear-gradient(
        180deg,
        $color-success 0%,
        color($color-success blackness(+20%)) 100%
    );
}

.no-fee__icon {
    min-width: 24px;
}

.no-fee__text {
    margin-left: calc($unit * 5);
}

.no-fee__action {
    margin-left: auto;
    padding: 10px;
    color: white;

    &:hover,
    &:focus {
        opacity: 0.5;
    }
}
