/*------------------------------------*\
    # block.infoblock
\*------------------------------------*/

/* A slight "hack" to make the counter work */
body {
    counter-reset: counter;
}

.infoblock {
    position: relative;
    overflow: hidden;
    background: white;

    @media (--screen-from-med) {
        margin-top: calc($unit * 10);
        border-radius: $global-radial;
        box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02),
            0 42px 64px rgba(0, 0, 0, 0.04);
        border: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            margin-bottom: calc($unit * 10);
        }
    }
}

.infoblock--alt {
    background: transparent;
    box-shadow: none;
    border: 0;
}

/* The infoblock__heading is a button element since it's used as a accordion toggle on small screens */
.infoblock__heading {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0;

    padding: calc($unit * 4);

    border-radius: 0; /* reset radius since it's a clickable btn */

    @media (--screen-to-med) {
        position: relative;
        border-bottom: 1px solid color($color-dark a(0.1));

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;

            background: color($color-dark lightness(+60%));
        }

        &:before {
            right: 14px;
            top: 28px;

            width: 13px;
            height: 1px;
        }

        &:after {
            right: 20px;
            top: 22px;

            width: 1px;
            height: 13px;
        }

        /* When toggled change + to - */
        .is-expanded & {
            &:after {
                display: none;
            }
        }
    }

    @media (--screen-from-med) {
        padding: calc($unit * 10);
        pointer-events: none;
    }

    @media (--screen-from-lrg) {
        padding: calc($unit * 10) calc($unit * 25);
    }
}

.infoblock__content {
    @media (--screen-to-med) {
        padding: 0 calc($unit * 4);

        height: 0;
        overflow: hidden;

        &.is-open {
            padding-top: calc($unit * 4);
            padding-bottom: calc($unit * 4);
            height: auto;
        }
    }

    @media (--screen-from-med) {
        padding: 0 calc($unit * 10) calc($unit * 10) calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        padding: 0 calc($unit * 25) calc($unit * 15) calc($unit * 25);
    }
}

.infoblock__section {
    position: relative;
    margin-bottom: calc($unit * 10);

    @media (--screen-from-lrg) {
        margin-bottom: calc($unit * 15);
    }

    &:last-of-type:not(:first-of-type) {
        margin-bottom: 0;
    }

    &:only-child {
        margin-bottom: 0;
    }
}

.infoblock__section--half {
    width: 50%;
    float: left;
}

.infoblock__section__tooltip {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 250px;
}

.infoblock__subheading {
    padding-bottom: calc($unit * 5);
}

.infoblock__title {
    position: relative;

    &:before {
        content: counter(counter) '. ';
        counter-increment: counter;

        @media (--screen-from-lrg) {
            content: counter(counter);

            display: block;

            position: absolute;
            top: -2px;
            left: -68px;

            font-size: 14px;
            font-weight: $type-weight-regular;
        }
    }

    &:after {
        content: '';

        position: absolute;

        display: none;
        width: 36px;
        height: 1px;
        background: $color-primary;

        top: 16px;
        left: -48px;

        @media (--screen-from-lrg) {
            display: block;
        }
    }
}

.infoblock__introtext {
    margin-bottom: calc($unit * 10);
}

.infoblock__datahead__options {
    lost-flex-container: row;
    justify-content: space-between;
}

.infoblock__datahead__options__types {
    @media (--screen-from-sml) {
        lost-column: 7/12;
    }
}

.infoblock__data {
    margin-bottom: calc($unit * 15);
}

.infoblock__score {
    display: flex;
    position: relative;
}

.infoblock__score__progress {
    display: inline-flex;
    position: relative;
    height: 12px;
    width: 80px;
    margin-right: calc($unit * 2);

    border-radius: $global-rounded;
    background: color($color-dark a(0.1));
}

.infoblock__score__progress__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 0;
    border-radius: $global-rounded;
    background: $color-dark;
}

.infoblock__score__progress__bar--success {
    background: $color-success;
}

.infoblock__score__progress__bar--warning {
    background: $color-warning;
}

.infoblock__score__progress__bar--note {
    background: $color-note;
}

.infoblock__score__progress__bar--empty {
    background: $color-text;
}

.infoblock__score__text {
}

/* Hidden graph for PDF export */
.infoblock__graph {
    position: fixed;
    top: -10000px;

    /* Exported graph quaility */
    & canvas {
        width: 800px !important;
    }
}

.infoblock__tooltip {
    z-index: 2;
    position: absolute;
    left: 400px;
    top: 40px;
}

.infoblock__section__header {
    margin-bottom: calc($unit * 4);

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-flow: row wrap;
}

.infoblock__datahead {
    margin-left: auto;

    @media (--screen-to-med) {
        margin: auto;
        margin-top: calc($unit * 5);
    }
}
