/*------------------------------------*\
    # view.affiliates
\*------------------------------------*/

.v-affiliates__intro {
    z-index: 0;
    position: relative;
    padding: calc($unit * 15) 0;
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    color: white;

    @media (--screen-from-lrg) {
        padding-top: calc($unit * 35);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
}

.v-affiliates__header {
    max-width: 720px;
}

.v-affiliates__claim {
    padding: calc($unit * 10) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-affiliates__why {
    padding: calc($unit * 10) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-affiliates__card {
    position: relative;
    background: white;
    box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    padding: calc($unit * 10);
    padding-top: calc($unit * 17);
    margin-top: calc($unit * 27);

    &.full-h {
        height: 100%;
    }

    &.no-margin-t {
        margin-top: 0;
    }
}

.v-affiliates__card-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.06);
    border-radius: 16px;

    &:hover,
    &:focus {
        opacity: 0.1;
    }
}

.v-affiliates__disabled-card-title {
    color: $color-text-dark;
}

.v-affiliates__disabled-card__label {
    display: flex;
    background: $color-primary;
    color: $color-secondary;
    @mixin type-scale $type-large, 8;
    font-weight: $type-weight-bold;
    padding: calc($unit * 3) calc($unit * 6);
    border-radius: $global-radial;
}

.v-affiliates__card__icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: calc($unit * -10);
}

.v-affiliates__card__icon--why {
    left: calc($unit * 10);
    margin: 0;
}

.v-affiliates__card__icon--commission {
    top: calc($unit * -11);
}

.v-affiliates__card--horizontal {
    padding: calc($unit * 6);
    padding-top: calc($unit * 16);
}

.v-affiliates__commision {
    padding: calc($unit * 10) 0;

    background-color: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    background-repeat: repeat-x;
    background-size: 50%;

    @media (--screen-from-med) {
        padding: calc($unit * 20) 0;
    }
}

.v-affiliates__commision__grid {
    @media (--screen-from-med) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
    }
    @media (--screen-from-lrg) {
        grid-gap: 96px;
    }
}

.v-affiliates__how {
    padding: calc($unit * 10) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.v-affiliates__how_wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media (--screen-from-med) {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }
}

.v-affiliates__how__grid {
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media (--screen-from-med) {
        display: grid;
        grid-template-columns: repeat(7, auto);
        row-gap: 60px;
        column-gap: 24px;
        align-items: center;
    }
    @media (--screen-to-xlrg) {
        grid-template-columns: repeat(4, auto);
    }
}

.v-affiliates__how__arrow {
    display: none;
    @media (--screen-from-med) {
        display: block;
        margin-top: calc($unit * 27);
    }
}

.v-affiliates__faq {
    padding: calc($unit * 10) 0;

    @media (--screen-from-lrg) {
        padding: calc($unit * 20) 0;
    }
}

.v-affiliates__faq__content {
    display: none;
}

.v-affiliates__faq__item {
    padding: calc($unit * 6);
    background: $color-bg;
    border: 1px solid rgba(0, 0, 0, 0.05);

    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.04);
    border-radius: 16px;

    text-align: left;
    color: $color-text;
    width: 100%;

    &:hover,
    &:focus {
        background: color($color-bg blackness(+5%));
    }

    & + & {
        margin-top: calc($unit * 5);
    }

    &.active {
        .v-affiliates__faq__content {
            margin-top: calc($unit * 4);
            display: block;
        }

        .v-affiliates__caret {
            transform: rotate(180deg);
        }
    }
}
