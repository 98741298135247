/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.o {
}

.o-90 {
    opacity: 0.9;
}

.o-80 {
    opacity: 0.8;
}

.o-70 {
    opacity: 0.7;
}

.o-60 {
    opacity: 0.6;
}

.o-50 {
    opacity: 0.5;
}

.o-40 {
    opacity: 0.4;
}

.o-30 {
    opacity: 0.3;
}

.o-20 {
    opacity: 0.2;
}

.o-10 {
    opacity: 0.1;
}
