/*------------------------------------*\
    # block.receipts“
\*------------------------------------*/

.receipts {
    lost-flex-container: row;

    @media (--screen-to-med) {
        flex-direction: column;
    }
}

.receipts__sidebar {
    lost-column: 5/12;
}

.receipts__sidebar__link {
    display: block;
    width: 100%;
    border-radius: 0;
    text-align: left;

    padding: calc($unit * 2) 0 calc($unit * 2) calc($unit * 4);
    transition: all 0.2s ease-out;
    opacity: 0.7;
    border-left: 2px solid transparent;

    &.is-active {
        opacity: 1;
        border-left-color: $color-primary;
    }

    &:hover,
    &:focus {
        opacity: 1;
        border-left: 2px solid $color-primary;
        padding-left: calc($unit * 4);
    }
}

.receipts__details {
    lost-column: 7/12;

    @media (--screen-to-med) {
        lost-column: 10/12;
        margin-top: calc($unit * 6);
    }

    @media (--screen-to-sml) {
        lost-column: 12/12;
    }
}

.receipts__details__heading {
    margin-bottom: calc($unit * 10);
}

.receipts__details__item {
    padding: calc($unit * 8) 0;
    border-top: 1px solid color($color-dark a(0.1));
}

.receipts__details__summary {
    padding-bottom: calc($unit * 8);
}

.receipts__details__total {
    padding-top: calc($unit * 8);
    border-top: 1px solid color($color-dark a(0.1));
}

.receipts__price {
    white-space: nowrap;
}

@media print {
    .receipts__details__summary {
        > div {
            flex-basis: 1 1 50%;
        }
    }
}
