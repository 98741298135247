/*------------------------------------*\
    # view.valuation
\*------------------------------------*/

.v-valuation {
    background: color($color-primary);
    padding-top: calc($unit * 40);
    padding-bottom: calc($unit * 60);
}

.v-valuation__header {
    padding-bottom: calc($unit * 10);
    display: flex;
    justify-content: space-between;
}

.v-valuation__counter {
    position: relative;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.1) 100%
    );
    padding: calc($unit * 1);
    border-radius: 50%;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.4);
    height: 72px;
    width: 72px;

    @media (--screen-to-lrg) {
        order: 2;
    }
}

.v-valuation__counter__inner {
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 64px;
    height: 64px;
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-valuation__splitview {
    margin-bottom: calc($unit * 15);
    margin-top: calc($unit * 15);

    @media (--screen-from-lrg) {
        lost-flex-container: row;
        align-items: flex-start;
    }
}

.v-valuation__main {
    @media (--screen-from-lrg) {
        lost-column: 9/12;
    }
}

.v-valuation__counter-circle {
    top: 0;
    left: 0;
    position: absolute;

    circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}

.v-valuation__metrics {
    @media (--screen-to-lrg) {
        flex-direction: column;
        order: 1;
    }
}

.v-valuation__points {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 4);
    }

    @media (--screen-from-lrg) {
        margin-right: calc($unit * 4);
    }
}

.v-valuation__multiple {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 4);
    }

    @media (--screen-from-lrg) {
        margin-right: calc($unit * 4);
    }
}
