/*------------------------------------*\
    # view.home
\*------------------------------------*/

.v-home {
}

.v-home__work-with__wrapper {
    background: $color-primary;
    color: white;
    border-radius: 16px;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.06), 0 42px 64px rgba(0, 0, 0, 0.04);

    padding: calc($unit * 20);

    z-index: 0;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 500px;
        background-repeat: repeat;
        background-image: url(../assets/images/pattern-2.svg);
        background-size: 20%;
        background-position: right top;
        background-color: color(white a(0.75));
        opacity: 0.25;
    }

    @media (--screen-from-lrg) {
        padding: calc($unit * 15) 0;
    }
}

.v-home__criteria-grid {
    margin-top: calc($unit * 15);

    @media (--screen-from-lrg) {
        margin-top: calc($unit * 20);
        lost-center: 900px;
        lost-flex-container: row;
    }
}

.v-home__criteria-item {
    position: relative;
    background: white;
    border-radius: $global-radial;
    padding: calc($unit * 5);
    padding-top: calc($unit * 15);

    @media (--screen-to-lrg) {
        margin-top: calc($unit * 12);
    }

    @media (--screen-from-lrg) {
        lost-column: 1/3;
    }
}

.v-home__criteria__number {
    z-index: 0;
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-primary;
    color: white;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $global-rounded;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.2);

    &:after {
        z-index: 2;
        content: '';
        position: absolute;
        top: -4px;
        left: 6px;
        width: 100%;
        height: 100%;
        border-radius: $global-rounded;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.3) -20.83%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}
