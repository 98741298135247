/*------------------------------------*\
    # component.risk-status
\*------------------------------------*/

.risk-status {
    display: inline-flex;
    align-items: center;
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1);

    padding: 8px 16px;
    margin: 20px 0;
    border-radius: $global-rounded;

    color: white;
    font-weight: bold;
}

.risk-status--10 {
    color: white;

    background: linear-gradient(
        180deg,
        #d84a4a 0%,
        color(#d84a4a lightness(-20%)) 100%
    );
}

.risk-status--9 {
    background: linear-gradient(
        180deg,
        #cb5353 0%,
        color(#cb5353 lightness(-10%)) 100%
    );
}

.risk-status--8 {
    background: linear-gradient(
        180deg,
        #cb6a53 0%,
        color(#cb6a53 lightness(-10%)) 100%
    );
}

.risk-status--7 {
    background: linear-gradient(
        180deg,
        #cb7c53 0%,
        color(#cb7c53 lightness(-10%)) 100%
    );
}

.risk-status--6 {
    background: linear-gradient(
        180deg,
        #cb9253 0%,
        color(#cb9253 lightness(-10%)) 100%
    );
}

.risk-status--5 {
    background: linear-gradient(
        180deg,
        #cba353 0%,
        color(#cba353 lightness(-10%)) 100%
    );
}

.risk-status--4 {
    background: linear-gradient(
        180deg,
        #cbb053 0%,
        color(#cbb053 lightness(-10%)) 100%
    );
}

.risk-status--3 {
    background: linear-gradient(
        180deg,
        #abcb53 0%,
        color(#abcb53 lightness(-10%)) 100%
    );
}

.risk-status--2 {
    background: linear-gradient(
        180deg,
        #6acb53 0%,
        color(#6acb53 lightness(-10%)) 100%
    );
}

.risk-status--1 {
    background: linear-gradient(
        180deg,
        #29af00 0%,
        color(#29af00 lightness(-10%)) 100%
    );
}
