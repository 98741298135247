/*------------------------------------*\
    # component.bundle-header
\*------------------------------------*/

.bundle-header {
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    background-color: white;
    background-size: 45%;
    border-radius: 16px;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: calc($unit * 5);
    padding: calc($unit * 5) calc($unit * 4);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @media (--screen-from-lrg) {
        padding: calc($unit * 10);
    }

    @media (--screen-from-med) {
        lost-flex-container: row;
    }
}

.bundle-header__header {
    @media (--screen-from-med) {
        lost-column: 6/12;
    }
}

.bundle-header__info {
    lost-flex-container: row;
    @media (--screen-to-med) {
        margin-top: calc($unit * 10);
    }

    @media (--screen-from-med) {
        lost-column: 5/12;
        lost-offset: 1/12;
    }
}

.bundle-header__details {
    lost-column: 6/12;
}
