/*------------------------------------*\
    # block.infoblock-business
\*------------------------------------*/

.infoblock-business {
}

.infoblock-business__item {
    & + & {
        margin-top: calc($unit * 5);
    }
}

.infoblock-business__status {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding-bottom: calc($unit * 2);
    margin-bottom: calc($unit * 2);

    svg {
        min-width: 18px;
        margin-right: calc($unit * 6);
        margin-top: 5px;
    }
}

.infoblock-business__fit {
    display: inline-flex;
    padding: calc($unit * 2) calc($unit * 4);
    border: 1px solid $color-success;
    border-radius: $global-rounded;
    font-weight: $type-weight-bold;
    align-items: center;
    color: white;
    background: $color-success;
}

.infoblock-business__fit--notfit {
    background: $color-warning;
    border-color: $color-warning;
}

.infoblock-business__section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: calc($unit * 10);
}

.infoblock-business__section--grow {
    position: relative;
    background: $color-primary;
    border-radius: $global-radial;
    color: white;
    padding: calc($unit * 10) calc($unit * 10);

    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;
    background-color: $color-primary;
    background-size: 50%;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05);

    margin-left: -40px;
    width: calc(100% + 80px);
    margin-bottom: -40px;

    @media (--screen-from-lrg) {
        margin-left: -100px;
        width: calc(100% + 200px);
        margin-bottom: -60px;
        padding: calc($unit * 20) calc($unit * 25);
    }
}

.infoblock-business__grow {
    position: absolute;
    top: -20px;
    right: 0;

    @media (--screen-to-med) {
        top: -50px;
    }
}

.infoblock-business__section-dashed {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding-bottom: calc($unit * 6);

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}

.infoblock-business__section-dashed--alt {
    border-left: 1px solid color($color-text a(0.2));
    padding: 24px;
}

.infoblock-business__spam-signals {
    display: flex;
    justify-content: flex-start;
    margin-left: calc($unit * -2);
}

.infoblock-business__spam-signals__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $global-radial;
    padding: calc($unit * 3) calc($unit * 5);
    margin-top: calc($unit * 2);
    margin-left: calc($unit * 2);
}

.infoblock-business__spam-signals__item--good {
    background: color($color-success a(0.1));
}

.infoblock-business__spam-signals__item--bad {
    background: color($color-warning a(0.1));
}

.infoblock-business__expandable {
    position: relative;
    max-height: 200px;
    overflow: hidden;

    &:after {
        content: '';
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
    }
}

.infoblock-business__expandable--open {
    max-height: unset;

    svg {
        transform: rotate(180deg);
    }

    &:after {
        height: 0px;
    }
}
