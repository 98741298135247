/*------------------------------------*\
    # component.checkout-table-mobile
\*------------------------------------*/

.checkout-table-mobile {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: $global-radial;
    padding-bottom: calc($unit * 5);

    thead {
        tr {
            td {
                justify-content: center;
            }
        }
    }

    tbody {
        width: 100%;
    }

    th,
    td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    td {
        @mixin type-scale $type-base, 4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: calc($unit * 4);
    }

    tr:last-of-type {
        td {
            border: none;
            flex-wrap: wrap;
            p {
                width: 100%;
                text-align: center;
            }
        }
    }

    svg {
        height: 32px;
        width: 32px;
        flex-shrink: 0;
    }

    strong {
        margin-right: calc($unit * 2);
    }
}

.checkout-table-mobile:first-of-type {
    background-color: $color-primary;
    color: white;

    th,
    td {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}

.checkout-table-mobile:nth-of-type(2) {
    background-color: white;
}
