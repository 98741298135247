/*------------------------------------*\
    # component.payment
\*------------------------------------*/

.payment {
    background-color: color(white a(0.9));
    padding: calc($unit * 5);
    border-radius: $global-radial;
}

.payment--negative {
    background-color: color(black a(0.5));
    color: white;
}

.payment__title {
    margin-bottom: calc($unit * 4);
}

.payment--negative .payment__title {
    color: white;
}

.payment__billing__item {
    width: 100%;
    border-radius: $global-radial;
    padding: calc($unit * 5);
    border: 1px solid color($color-text a(0.1));
    flex-direction: column;
    align-items: flex-start;
    opacity: 0.5;

    & + & {
        margin-top: calc($unit * 5);
    }

    &:hover {
        border: 1px solid $color-text;
    }
}

.payment--selected {
    border-color: $color-primary;
    opacity: 1;
}
