/*------------------------------------*\
    # component.content-metric
\*------------------------------------*/

.content-metric {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;

    @media (--screen-from-sml) {
        grid-template-columns: 1fr 1fr;
    }

    @media (--screen-from-lrg) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.content-metric__item {
    padding: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: $global-radial;
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background: linear-gradient(
        180deg,
        color($color-bg a(0)) 20%,
        $color-bg 100%
    );
}

.content-metric__number {
    font-size: 24px;
    font-weight: bold;
    display: block;
}

.content-metric__title {
    font-size: 12px;
    padding-top: 6px;
}
