/*------------------------------------*\
    # block.coverhead
\*------------------------------------*/

.coverhead {
    padding: calc($unit * 10) 0 calc($unit * 8) 0;
}

.coverhead__wrapper {
    display: flex;
    justify-content: space-between;
}

.coverhead__title {
    padding-right: lost-vars('gutter');
}

.coverhead__action {
}
