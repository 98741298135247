/*------------------------------------*\
    # component.notice
\*------------------------------------*/

.notice {
    z-index: 1;
    @mixin type-scale $type-small, 5;
    color: $color-text;
    padding: calc($unit * 2) calc($unit * 4);

    &:empty {
        display: none;
    }
}

.notice--success {
    border-left: 2px solid $color-success;
    background: color($color-success a(0.1));
}

.notice--warning {
    border-left: 2px solid $color-warning;
    background: color($color-warning a(0.1));
}

.notice--note {
    border-left: 2px solid $color-note;
    background: color($color-note a(0.1));
}

.notice--rounded {
    border-radius: $global-rounded;
    border: 1px solid #cae8e6;
    padding: calc($unit * 3) calc($unit * 6);
}
