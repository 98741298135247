/*------------------------------------*\
    # view.home-agreement
\*------------------------------------*/

.v-home-agreement {
    padding: calc($unit * 10) 0;

    background: $color-primary;
    background-image: url(../assets/images/pattern-1.svg);
    background-blend-mode: overlay;

    color: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-home-agreement .wrapper {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-agreement__heading {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 10);
    }

    @media (--screen-from-lrg) {
        lost-column: 6/12;
    }
}

.v-home-agreement__content {
    @media (--screen-from-lrg) {
        lost-offset: 1/12;
        lost-column: 5/12;
    }
}
