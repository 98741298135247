/*------------------------------------*\
    # component.pending-question
\*------------------------------------*/

.pending-question {
    padding: calc($unit * 5);
    padding-left: calc($unit * 6);
    padding-bottom: calc($unit * 2);
    border-radius: $global-radial;
    background-color: color($color-primary a(0.08));
    margin-bottom: 10px;
}

.pending-question__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.pending-question__tag {
    margin-left: calc($unit * 1);
}

.pending-question__tag--buyer {
    color: $color-primary;
}

.pending-question__tag--admin {
    color: $color-success;
}

.pending-question__tag--seller {
    color: $color-secondary;
}

.pending-question__date {
    margin-left: auto;
}

.pending-question__footer {
    margin-left: calc($unit * -6);
}
