/*------------------------------------*\
    # component.question
\*------------------------------------*/

.question {
    padding: calc($unit * 5);
    padding-left: calc($unit * 6);
    padding-bottom: calc($unit * 2);
    border-radius: $global-radial;
    background-color: white;
}

.question--pending {
    background-color: color($color-secondary a(0.08));
}

.question__header {
    @media (--screen-from-sml) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.question__tag {
    display: inline-block;

    padding: calc($unit * 1) calc($unit * 5);
    background-color: transparent;
    border-radius: $global-rounded;
    color: $color-text;

    @media (--screen-to-sml) {
        margin: calc($unit * 2) 0;
    }

    @media (--screen-from-sml) {
        margin-left: calc($unit * 2);
    }
}

.question--reply {
    margin-top: calc($unit * 4);
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding-left: 0;
}

.question__tag--buyer {
    background-color: color($color-secondary a(0.1));
}

.question__tag--admin {
    background-color: color($color-primary a(0.1));
}

.question__tag--seller {
    background-color: color($color-success a(0.1));
}

.question__author {
    font-weight: $type-weight-bold;
}

.question__date {
    margin-left: auto;
    color: color($color-text a(0.5));
}

.question__reply-box {
    display: flex;
    flex-direction: column;
}

.question__footer {
    margin-left: calc($unit * -6);
}
