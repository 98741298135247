/*------------------------------------*\
    # block.header
\*------------------------------------*/

.header {
    background: white;
    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.02), 0 42px 64px rgba(0, 0, 0, 0.02);
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__home {
    display: flex;
    padding-left: calc($unit * 4);
    padding-right: calc($unit * 4);
    margin-left: calc($unit * -4);
}

.header__hamburger {
    @media (max-width: 1100px) {
        position: fixed;
        z-index: 101;
        top: calc($unit * 3);
        right: calc($unit * 6);
    }

    @media (min-width: 1101px) {
        display: none;
    }
}

.header--alt {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .logo {
        path {
            fill: white;
        }
    }

    .nav__link {
        color: white;

        &:hover,
        &:focus {
            color: color(white a(0.7));
        }
    }

    .nav__link--alt {
        background-color: color(black a(0.2));

        &:hover,
        &:focus {
            color: white;
        }
    }

    .nav__icon {
        fill: color(white a(0.5));
    }

    .nav__link--new {
        background-color: white;

        .nav__icon {
            fill: $color-primary;
        }

        &:hover,
        &:focus {
            background-color: color(white a(0.7));
        }
    }

    .logo-mobile {
        fill: white;
    }
}
