/*------------------------------------*\
    # view.home-risk
\*------------------------------------*/

.v-home-risk {
    padding: calc($unit * 15) 0;
    background: white;

    @media (--screen-from-lrg) {
        padding: calc($unit * 35) 0;
    }
}

.v-home-risk__heading {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-risk__heading__title {
    @media (--screen-from-lrg) {
        lost-column: 5/12;
    }
}

.v-home-risk__heading__content {
    @media (--screen-from-lrg) {
        lost-column: 5/12;
        lost-offset: 1/12;
    }
}

.v-home-risk__grid {
    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-home-risk__grid__item {
    background: $color-bg;
    padding: calc($unit * 8);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: $global-radial;

    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 10);

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (--screen-from-lrg) {
        lost-column: 6/12 2;

        lost-flex-container: row;
        margin-bottom: lost-vars('gutter');

        padding: calc($unit * 8);
    }
}

.v-home-risk__grid__item > h2 {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 5);
    }

    @media (--screen-from-lrg) {
        lost-column: 5/12;
    }
}

.v-home-risk__grid div > div {
    @media (--screen-from-lrg) {
        lost-column: 7/12;
    }
}
