/*------------------------------------*\
    # component.table
\*------------------------------------*/

.overview-table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: $global-radial;
    border-top-right-radius: $global-radial;
    max-width: 500px;

    td {
        @mixin type-scale $type-small, 4;
        min-width: 100px;
    }

    th:first-child {
        border-top-left-radius: $global-radial;
    }

    th:last-child {
        border-top-right-radius: $global-radial;
    }

    th,
    td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: calc($unit * 3);
        @mixin type-scale $type-small, 5;
    }

    th {
        width: 100%;
        text-align: left;
        padding: calc($unit * 3) 0;
    }

    tr {
        border-bottom: 1px solid black;
        transition: all 0.1s ease-out;

        &:nth-child(even) {
            background-color: #f1f1f1;
        }

        &:nth-child(even) {
            background-color: #f3f3f3;
        }

        &:hover {
            td {
                color: $color-primary;
            }
        }
    }
}

.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: $global-radial;
    border-top-right-radius: $global-radial;
    td {
        @mixin type-scale $type-small, 4;
        min-width: 100px;
    }
    th:first-child {
        border-top-left-radius: $global-radial;
    }
    th:last-child {
        border-top-right-radius: $global-radial;
    }
    td:nth-child(2) {
        background-color: $color-secondary;
        color: white;
    }
    th,
    td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: calc($unit * 3);
        @mixin type-scale $type-small, 5;
    }
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }
    tr {
        border-bottom: 1px solid black;
        transition: all 0.1s ease-out;
        &:nth-child(even) {
            background-color: #f1f1f1;
        }
        &:nth-child(even) {
            background-color: #f3f3f3;
        }
        &:hover {
            td {
                color: $color-primary;
            }
        }
    }
}

.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: $global-radial;
    border-top-right-radius: $global-radial;
    td {
        @mixin type-scale $type-small, 4;
        min-width: 100px;
    }
    th:first-child {
        border-top-left-radius: $global-radial;
    }
    th:last-child {
        border-top-right-radius: $global-radial;
    }
    th,
    td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: calc($unit * 3);
        @mixin type-scale $type-small, 5;
    }
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }
    tr {
        border-bottom: 1px solid black;
        transition: all 0.1s ease-out;
        &:nth-child(even) {
            background-color: #f1f1f1;
        }
        &:nth-child(even) {
            background-color: #f3f3f3;
        }
        &:hover {
            td {
                color: $color-primary;
            }
        }
    }
}
.table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-top-left-radius: $global-radial;
    border-top-right-radius: $global-radial;
    td {
        @mixin type-scale $type-small, 4;
        min-width: 100px;
    }
    th:first-child {
        border-top-left-radius: $global-radial;
    }
    th:last-child {
        border-top-right-radius: $global-radial;
    }
    td:nth-child(2) {
        background-color: $color-secondary;
        color: white;
    }
    th,
    td {
        border-bottom: 1px solid white;
        vertical-align: top;
        padding: calc($unit * 3);
        @mixin type-scale $type-small, 5;
    }
    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }
    tr {
        border-bottom: 1px solid black;
        transition: all 0.1s ease-out;
        &:nth-child(even) {
            background-color: #f1f1f1;
        }
        &:nth-child(even) {
            background-color: #f3f3f3;
        }
        &:hover {
            td {
                color: $color-primary;
            }
        }
    }
}
