/*------------------------------------*\
    # component.table
\*------------------------------------*/

.comparison-table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 auto;
    margin-bottom: calc($unit * 10);
    margin-top: calc($unit * 1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    min-width: 900px;
    max-width: 1000px;

    thead {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    thead td {
        padding-top: calc($unit * 5);
        padding-bottom: calc($unit * 5);
    }

    td {
        @mixin type-scale $type-small, 4;
        min-width: 100px;
    }

    th,
    td {
        vertical-align: top;
        padding: calc($unit * 3);
        @mixin type-scale $type-small, 5;
        color: white;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        color: white;

        background: linear-gradient(
            180deg,
            rgb(83, 13, 133) 0%,
            rgb(68, 2, 114) 100%
        );
    }

    tr {
        transition: all 0.1s ease-out;
    }

    tr td:nth-child(2) {
        color: $color-primary;
    }

    .comparison-table__special {
        position: relative;

        &:after {
            z-index: -1;
            content: '';
            position: absolute;
            top: -5px;
            left: -13px;
            height: 416.5px;
            width: 100%;
            background: $color-secondary;
            border-radius: 16px;
        }
    }
}
