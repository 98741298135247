/*------------------------------------*\
    # block.infoblock-seo
\*------------------------------------*/

.infoblock-seo {
}

.infoblock-seo__reports {
}

.infoblock-seo__reports__item {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 12);
    }
    @media (--screen-from-lrg) {
        lost-flex-container: row;
        padding-bottom: calc($unit * 8);
        margin-bottom: calc($unit * 6);
        border-bottom: 1px solid color($color-dark a(0.1));
    }
}

.infoblock-seo__reports__item__heading {
    @media (--screen-to-lrg) {
        margin-bottom: calc($unit * 6);
    }
    @media (--screen-from-lrg) {
        lost-column: 6/12;
    }
}

.infoblock-seo__reports__item__report {
    @media (--screen-from-lrg) {
        lost-column: 3/12;
        lost-move: 1/12;
    }

    @media (--screen-from-xlrg) {
        lost-move: 2/12;
    }
}

.infoblock-seo__tooltip {
    position: absolute;
    z-index: 2;
    right: 0;
    top: -10px;
}

.infoblock-seo__sitemap {
    margin-top: 12px;
    color: $color-success;
}
