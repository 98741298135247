.v-about {
}

.v-about__header,
.v-about__banner {
    z-index: 0;
    position: relative;
    padding: calc($unit * 15) 0;
    background: linear-gradient(
        180deg,
        rgb(83, 13, 133) 0%,
        rgb(68, 2, 114) 100%
    );

    border-radius: 0 0 0 20px;
    color: white;

    @media (--screen-to-lrg) {
        padding-top: calc($unit * 35);
    }

    @media (--screen-from-lrg) {
        padding-top: calc($unit * 35);
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background-repeat: repeat-x;
        background-image: url(../assets/images/pattern-1.svg);
        background-size: 70%;
        background-position: right top;
    }
}

.v-about__team-content {
    position: relative;
    max-width: 1100px;
    z-index: 2;
    margin: 0 auto;
}

.v-about__header-content {
    z-index: 1;

    @media (--screen-from-lrg) {
        lost-flex-container: row;
    }
}

.v-about__header-content__info {
    @media (--screen-from-lrg) {
        lost-column: 6/12;
    }
}

.v-about__header-wrapper {
    padding: calc($unit * 24) calc($unit * 6) calc($unit * 15);
    max-width: 768px;

    @media (--screen-from-med) {
        padding: calc($unit * 33) calc($unit * 6) calc($unit * 15);
    }
}

.v-about__banner {
    padding: calc($unit * 20) calc($unit * 6);

    @media (--screen-from-med) {
        padding: calc($unit * 40) calc($unit * 6);
    }
}

.v-about__banner-content {
    max-width: 768px;
}

.v-about__team {
    background-color: $color-bg;
}

.v-about__team-content {
    padding: calc($unit * 10) calc($unit * 6);

    @media (--screen-from-med) {
        padding: calc($unit * 25) calc($unit * 6);
    }
}

.v-about__team-grid {
    display: grid;
    grid-row-gap: calc($unit * 11);
    justify-content: center;

    @media (--screen-from-med) {
        grid-row-gap: calc($unit * 22);
        grid-template-columns: repeat(2, 1fr);
    }
}

.v-about__history {
    z-index: 0;
    background: white;
    padding: 50px 0;

    @media (--screen-from-med) {
        padding: 100px 0;
    }
}

.v-about__history__title {
    max-width: 612px;
}

.v-about__timeline {
    margin-top: 40px;

    @media (--screen-from-med) {
        margin-top: 80px;
    }
}

.v-about__timeline__item {
    lost-flex-container: row;
}

.v-about__timeline {
    position: relative;
}

.v-about__timeline__year {
    overflow: hidden;
    font-weight: $type-weight-bold;
    lost-column: 2/12;
    padding-bottom: calc($unit * 20);

    @media (--screen-from-med) {
        lost-move: 1/12;
    }

    &:before {
        @media (--screen-from-med) {
            z-index: 1;
            content: '';
            position: absolute;
            top: 10px;
            left: 66px;
            height: 100%;
            border-left: 1px dashed rgba(0, 0, 0, 0.5);
            width: 5px;
        }
    }

    &:after {
        @media (--screen-from-med) {
            z-index: 2;
            content: '';
            position: absolute;
            top: 6px;
            left: 64px;
            width: 6px;
            height: 6px;
            border-radius: $global-rounded;
            background: #786f82;
        }
    }
}

.v-about__timeline__text {
    text-align: left;
    lost-column: 10/12;

    @media (--screen-from-med) {
        lost-move: 1/12;
        lost-column: 7/12;
    }
}

.v-about__timeline__item--special {
    position: relative;
    z-index: 3;
    background: #f6f8fc;
    padding: calc($unit * 10) calc($unit * 10);

    @media screen (--screen-from-sml) {
        padding: calc($unit * 10) calc($unit * 26);
    }
}

.v-about__timeline__year--investors {
    max-width: 40px;
}
.v-about__timeline__item--investors {
    .v-about__timeline__year {
        display: flex;
        color: $color-primary;
        @media (--screen-to-med) {
            display: block;
        }
        &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }
}
.v-about__timeline__icon {
    z-index: 3;
    position: relative;
    height: 25px;
    width: 25px;
    margin-left: calc($unit * 3.5);
    @media (--screen-to-med) {
        margin-left: 0;
    }
}
