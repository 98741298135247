/*------------------------------------*\
    # plugins.react-month-picker
\*------------------------------------*/

.react-month-picker {
}

.react-month-picker .calendar-container {
    width: 260px;
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 2;
    background: white;
    border: 1px solid #eee;
}

/*  SECTIONS  */
.react-month-picker .section_mp {
    clear: both;
    padding: 0;
    margin: 0;
}

/*  COLUMN SETUP  */
.react-month-picker .col_mp {
    display: block;
    float: left;
    text-align: center;
}

.react-month-picker .col_mp:first-child {
    margin-left: 0;
}

/*  GROUPING  */
.react-month-picker .group_mp:before,
.react-month-picker .group:after {
    content: '';
    display: table;
}

.react-month-picker .group_mp:after {
    clear: both;
}

.react-month-picker .group_mp {
    zoom: 1; /* For IE 6/7 */
}

/*  GRID OF THREE  */
.react-month-picker .span_1_of_3_mp {
    width: 33.33%;
}

.react-month-picker .col_mp {
    padding-bottom: 5px;
    padding-top: 5px;
    @mixin type-scale $type-tiny, 5;
}

.react-month-picker .col_mp:hover {
    color: white;
    background-color: $color-primary;
    cursor: pointer;
}

.react-month-picker .calendar-container.readonly .col_mp:hover {
    background-color: transparent;
    cursor: default;
    color: inherit;
}

.react-month-picker .selected_date_mp {
    font-size: 12px;
    color: $color-primary;
    font-weight: bold;
}

.react-month-picker .selected_cell {
    background-color: $color-primary;
    font-style: italic;
    color: white;
}

.react-month-picker .arrows_mp {
    font-weight: bold;
    font-size: 14px;
}

.react-month-picker .month-input.readonly {
    background-color: #eee;
}
