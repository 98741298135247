/*------------------------------------*\
    # view.home-start-free
\*------------------------------------*/

.v-home-start-free {
    padding: calc($unit * 10) 0 0;
    margin: 0 auto;
    max-width: 828px;

    @media (--screen-from-med) {
        padding: calc($unit * 30) 0;
    }
}

.v-home-start-free__wrapper {
    padding: calc($unit * 15) calc($unit * 30);
    box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.06);
    width: 100%;
    border-radius: 16px;
    background: color(white a(0.1));
    border: 1px solid $color-secondary;
    color: white;

    @media (--screen-to-med) {
        text-align: center;
        padding: calc($unit * 10) calc($unit * 5);

        .s-left--lrg {
            margin-left: 0;
        }
    }

    @media (--screen-from-med) {
        padding: calc($unit * 25) calc($unit * 30);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
