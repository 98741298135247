/*------------------------------------*\
    # component.admin-overview
\*------------------------------------*/

.admin-overview__item {
    overflow-x: auto;

    & + & {
        margin-top: calc($unit * 10);
    }

    box-shadow: 0 5.3px 8px rgba(0, 0, 0, 0.05), 0 42px 64px rgba(0, 0, 0, 0.05);

    & + & {
        margin-top: calc($unit * 6);
    }

    background: white;
    border: 1px solid color($color-dark a(0.1));
    border-radius: $global-radial;
    padding: calc($unit * 10);
}

.admin-overview__header {
}

.admin-overview__legend-item {
    padding-left: calc($unit * 4);
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: $global-radial;
    }
}

.admin-overview__legend-item--buyers {
    &:before {
        background-color: $color-primary;
    }
}

.admin-overview__legend-item--sellers {
    &:before {
        background-color: $color-secondary;
    }
}

.admin-overview__doughnut {
    position: relative;

    &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        left: 50%;
        top: -8px;
        transform: translateX(-50%);
        background: transparent;
        border-radius: $global-rounded;
        border-width: 4px;
        border: 4px #786f82 solid;
        opacity: 0.2;
        height: 165px;
        width: 165px;
    }
}

.admin-overview__doughnut__info {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: $type-weight-bold;
}

.admin-overview__graphs {
    display: flex;
}

.admin-overview__bar-chart {
    position: relative;
    height: 120px;
    width: 100%;

    .chartjs-render-monitor {
        height: 120px !important;
        width: 100% !important;
    }
}

.admin-overview__line-chart {
    height: 150px;
}

.admin-overview__datepicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-overview__message-stats {
}

.admin-overview__message-stats__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.admin-overview__message-stats__graph {
}
