/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1356px;
    padding: 0 calc($unit * 6);
    margin: 0 auto;

    @media (--screen-from-med) {
        padding: 0 calc($unit * 12);
    }
}

.wrapper--wide {
    max-width: 1600px;
}

.wrapper--narrow {
    max-width: 600px;
}

.wrapper--medium {
    max-width: 924px;
}

.wrapper--noPadding--toLrg {
    @media (--screen-to-lrg) {
        padding: 0;
    }
}
