/*------------------------------------*\
    # component.badge
\*------------------------------------*/

.badge {
    padding: $unit calc($unit * 2);

    border-radius: $unit;
}

.badge--grid {
    &:not(:last-child) {
        margin-bottom: $unit;
    }
}

.badge--list {
    display: inline-flex;
    margin-right: $unit;
    margin-bottom: $unit;
    align-items: center;
}

.badge--portfolio {
    background: linear-gradient(
        180deg,
        $color-secondary 0%,
        color($color-secondary lightness(-10%)) 100%
    );
}

.badge--portfolio--alt {
    background: transparent;
    border: 1px solid color($color-secondary lightness(-20%));
    color: color($color-secondary lightness(-20%));
}

.badge--brokered {
    background: linear-gradient(
        180deg,
        $color-text-dark 0%,
        color($color-text-dark lightness(-10%)) 100%
    );
}

.badge--not-published {
    background: linear-gradient(
        180deg,
        $color-warning 0%,
        color($color-warning lightness(-10%)) 100%
    );
}

.badge--early-access {
    background: linear-gradient(
        180deg,
        $color-warning 0%,
        color($color-warning lightness(-10%)) 100%
    );
}

.badge--exclusive {
    background: linear-gradient(
        180deg,
        $color-primary 0%,
        color($color-primary lightness(-10%)) 100%
    );
}

.badge--success {
    border: 1px solid $color-success;
    background: transparent;
}

.badge--updated {
    background: linear-gradient(
        180deg,
        $color-note 0%,
        color($color-note lightness(-10%)) 100%
    );
}

.badge--new {
    background: linear-gradient(
        180deg,
        $color-success 0%,
        color($color-success lightness(-10%)) 100%
    );
}

.listing-grid-item__badge-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    position: absolute;
    top: calc($unit * 5);
    left: calc($unit * 4);
    z-index: 2;
}
