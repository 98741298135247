/*------------------------------------*\
    # block.infoblock-seo
\*------------------------------------*/

.tabs__nav {
    display: flex;
    overflow-x: auto;
}

.tabs__btn {
    border-radius: 0;
    box-shadow: inset 0 0 0 0 $color-primary;
    transition: box-shadow 0.3s ease-in-out;

    font-size: responsive 20px 28px;
    font-range: 320px 1440px;

    line-height: responsive 28px 40px;
    line-height-range: 320px 1440px;
    opacity: 0.7;
    color: $color-text-dark;

    transition: all 0.2s ease-out;

    & + & {
        margin-left: calc($unit * 5);
    }

    &:hover,
    &:focus {
        opacity: 0.7;
        color: $color-primary;
    }
}

.tabs__btn--active {
    font-weight: $type-weight-bold;
    opacity: 1;

    &:hover,
    &:focus {
        color: $color-text-dark;
        opacity: 1;
    }
}
